export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    /** The `ID` scalar type represents a unique identifier, often used to refetch an object or as key for a cache. The ID type appears in a JSON response as a String; however, it is not intended to be human-readable. When expected as an input type, any string (such as `"4"`) or integer (such as `4`) input value will be accepted as an ID. */
    ID: { input: string; output: string };
    /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
    String: { input: string; output: string };
    /** The `Boolean` scalar type represents `true` or `false`. */
    Boolean: { input: boolean; output: boolean };
    /** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1. */
    Int: { input: number; output: number };
    /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](https://en.wikipedia.org/wiki/IEEE_floating_point). */
    Float: { input: number; output: number };
    AssetUrlInput: { input: any; output: any };
    /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    Date: { input: any; output: any };
    /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    DateTime: { input: any; output: any };
    /**
     *
     *     A string representing a duration conforming to the ISO8601 standard,
     *     such as: P1W1DT13H23M34S
     *     P is the duration designator (for period) placed at the start of the duration representation.
     *     Y is the year designator that follows the value for the number of years.
     *     M is the month designator that follows the value for the number of months.
     *     W is the week designator that follows the value for the number of weeks.
     *     D is the day designator that follows the value for the number of days.
     *     T is the time designator that precedes the time components of the representation.
     *     H is the hour designator that follows the value for the number of hours.
     *     M is the minute designator that follows the value for the number of minutes.
     *     S is the second designator that follows the value for the number of seconds.
     *
     *     Note the time designator, T, that precedes the time value.
     *
     *     Matches moment.js, Luxon and DateFns implementations
     *     ,/. is valid for decimal places and +/- is a valid prefix
     *
     */
    Duration: { input: string; output: string };
    /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
    EmailAddress: { input: string; output: string };
    Hashids: { input: any; output: any };
    /** A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors. */
    HexColorCode: { input: string; output: string };
    /**
     *
     *     A string representing a duration conforming to the ISO8601 standard,
     *     such as: P1W1DT13H23M34S
     *     P is the duration designator (for period) placed at the start of the duration representation.
     *     Y is the year designator that follows the value for the number of years.
     *     M is the month designator that follows the value for the number of months.
     *     W is the week designator that follows the value for the number of weeks.
     *     D is the day designator that follows the value for the number of days.
     *     T is the time designator that precedes the time components of the representation.
     *     H is the hour designator that follows the value for the number of hours.
     *     M is the minute designator that follows the value for the number of minutes.
     *     S is the second designator that follows the value for the number of seconds.
     *
     *     Note the time designator, T, that precedes the time value.
     *
     *     Matches moment.js, Luxon and DateFns implementations
     *     ,/. is valid for decimal places and +/- is a valid prefix
     *
     */
    ISO8601Duration: { input: string; output: string };
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: { input: any; output: any };
    /** A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction. */
    JWT: { input: string; output: string };
    /** A field whose value is a valid decimal degrees latitude number (53.471): https://en.wikipedia.org/wiki/Latitude */
    Latitude: { input: string; output: string };
    /** A local time string (i.e., with no associated timezone) in 24-hr `HH:mm[:ss[.SSS]]` format, e.g. `14:25` or `14:25:06` or `14:25:06.123`. */
    LocalTime: { input: string; output: string };
    /** A field whose value is a valid decimal degrees longitude number (53.471): https://en.wikipedia.org/wiki/Longitude */
    Longitude: { input: string | number; output: string | number };
    /** Integers that will have a value of 0 or more. */
    NonNegativeInt: { input: number; output: number };
    /** Floats that will have a value greater than 0. */
    PositiveFloat: { input: number; output: number };
    /** Integers that will have a value greater than 0. */
    PositiveInt: { input: number; output: number };
    Secret: { input: any; output: any };
    Void: { input: void; output: void };
};

/** This represents an ActorAssociation. */
export type ActorAssociation = {
    readonly __typename?: "ActorAssociation";
    /** The unique id of the Actor. */
    readonly actorId: Scalars["String"]["output"];
    /** The Parent Actor unique id associated with the Actor. */
    readonly entityId: Scalars["String"]["output"];
    /** Type to allow logical association of an Actor with a group of actors. */
    readonly entityKey: Scalars["String"]["output"];
    /** Type of the Entity that the Actor is associated with. */
    readonly entityType: Scalars["String"]["output"];
    /** The family name of the Actor. */
    readonly familyName?: Maybe<Scalars["String"]["output"]>;
    /** The given name of the Actor. */
    readonly givenName?: Maybe<Scalars["String"]["output"]>;
    /** The unique id for the ActorAssociation. */
    readonly id: Scalars["String"]["output"];
    /** The name of the Actor. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** The other names of the Actor. */
    readonly otherNames?: Maybe<Scalars["String"]["output"]>;
    /** The type of the Actor. */
    readonly type?: Maybe<ActorTypeType>;
};

/** This represents a list of ActorAssociation. */
export type ActorAssociationList = {
    readonly __typename?: "ActorAssociationList";
    readonly nodes: ReadonlyArray<ActorAssociation>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type ActorAssociationUpsertInputType = {
    readonly actorId?: InputMaybe<Scalars["String"]["input"]>;
    readonly entityKey: Scalars["String"]["input"];
    readonly familyName?: InputMaybe<Scalars["String"]["input"]>;
    readonly givenName?: InputMaybe<Scalars["String"]["input"]>;
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    readonly otherNames?: InputMaybe<Scalars["String"]["input"]>;
    /** The type of the Actor. */
    readonly type?: InputMaybe<ActorTypeInput>;
};

/** Type of Actor */
export enum ActorTypeInput {
    Organisation = "ORGANISATION",
    Person = "PERSON",
}

export enum ActorTypeType {
    Organisation = "ORGANISATION",
    Person = "PERSON",
}

export type AdminStats = {
    readonly __typename?: "AdminStats";
    readonly bookingConfirmedCount?: Maybe<Scalars["Int"]["output"]>;
    readonly bookingUnconfirmedCount?: Maybe<Scalars["Int"]["output"]>;
    readonly partnerChannelCount?: Maybe<Scalars["Int"]["output"]>;
    readonly partnerCount?: Maybe<Scalars["Int"]["output"]>;
    readonly productAvailableCount?: Maybe<Scalars["Int"]["output"]>;
    readonly productRestrictedCount?: Maybe<Scalars["Int"]["output"]>;
    readonly productsCSV?: Maybe<Scalars["String"]["output"]>;
    readonly supplierCount?: Maybe<Scalars["Int"]["output"]>;
    readonly supplierExternalCount?: Maybe<Scalars["Int"]["output"]>;
    readonly supplierInternalCount?: Maybe<Scalars["Int"]["output"]>;
    readonly workstreamAssignedToYouCount?: Maybe<Scalars["Int"]["output"]>;
    readonly workstreamCreatedByYouCount?: Maybe<Scalars["Int"]["output"]>;
    readonly workstreamCriticalCount?: Maybe<Scalars["Int"]["output"]>;
    readonly workstreamPlanningCount?: Maybe<Scalars["Int"]["output"]>;
    readonly workstreamProcessingCount?: Maybe<Scalars["Int"]["output"]>;
};

export type AnalyticLinkOrigin = {
    readonly __typename?: "AnalyticLinkOrigin";
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly description?: Maybe<Scalars["String"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    readonly medium: Scalars["String"]["output"];
    readonly placement?: Maybe<Scalars["String"]["output"]>;
    readonly shortId: Scalars["Hashids"]["output"];
    readonly source: Scalars["String"]["output"];
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type AnalyticLinkOriginCreateInput = {
    readonly description?: InputMaybe<Scalars["String"]["input"]>;
    readonly medium: Scalars["String"]["input"];
    readonly placement?: InputMaybe<Scalars["String"]["input"]>;
    readonly source: Scalars["String"]["input"];
};

export type AnalyticLinkOriginDeleteInput = {
    readonly id: Scalars["ID"]["input"];
};

export type AnalyticLinkOriginList = {
    readonly __typename?: "AnalyticLinkOriginList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<AnalyticLinkOrigin>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type AnalyticLinkOriginListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AnalyticLinkOriginListFilter = {
    readonly idList?: InputMaybe<ReadonlyArray<Scalars["ID"]["input"]>>;
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    readonly shortIdList?: InputMaybe<ReadonlyArray<Scalars["Hashids"]["input"]>>;
};

export type AnalyticLinkOriginListSort = {
    readonly createdAt?: InputMaybe<SortDirection>;
};

export type ArticleListFilter = {
    /** Find article by name */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    /** Find article by name */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents an article */
export type ArticleListItem = {
    readonly __typename?: "ArticleListItem";
    /** date the article was created */
    readonly createdAt: Scalars["Date"]["output"];
    /** The description of the article. */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the artcile */
    readonly id: Scalars["String"]["output"];
    /** The Name of the article */
    readonly name: Scalars["String"]["output"];
    /** The position of the article in the contect of the type */
    readonly ordinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** Article type */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** date the article was last updated */
    readonly updatedAt?: Maybe<Scalars["Date"]["output"]>;
};

/** This represents an article */
export type ArticleListItemDescriptionArgs = {
    contentType?: InputMaybe<DescriptionContentType>;
};

/** This respresents the ArticleList response type */
export type ArticleListResponse = {
    readonly __typename?: "ArticleListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<ArticleListItem>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

/** This respresents the ArticleList response type */
export type ArticleListResponseRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ArticleListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

export type ArticleUpsertInput = {
    /** The descriptionJson of the article */
    readonly descriptionJson?: InputMaybe<Scalars["String"]["input"]>;
    /** article id */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The name of the article */
    readonly name: Scalars["String"]["input"];
    /** The position of the article in the context of the type */
    readonly ordinalPosition?: InputMaybe<Scalars["Int"]["input"]>;
    /** The type of the article */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
};

export type Asset = {
    readonly __typename?: "Asset";
    readonly id: Scalars["String"]["output"];
    readonly uri: Scalars["String"]["output"];
    readonly url: Scalars["String"]["output"];
};

export type AssetUrlArgs = {
    fit?: InputMaybe<AssetFit>;
    height?: InputMaybe<Scalars["PositiveInt"]["input"]>;
    position?: InputMaybe<AssetResizePosition>;
    width?: InputMaybe<Scalars["PositiveInt"]["input"]>;
};

export enum AssetFit {
    Contain = "contain",
    Cover = "cover",
    Fill = "fill",
    Inside = "inside",
    Outside = "outside",
}

export type AssetInput = {
    readonly contentType: Scalars["String"]["input"];
    readonly extension?: InputMaybe<Scalars["String"]["input"]>;
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    readonly type: AssetType;
};

export enum AssetResizePosition {
    Attention = 17,
    Bottom = 3,
    Center = 0,
    East = 2,
    Entropy = 16,
    Left = 4,
    LeftBottom = 7,
    LeftTop = 8,
    North = 1,
    NorthEast = 5,
    NorthWest = 8,
    Right = 2,
    RightBottom = 6,
    RightTop = 5,
    South = 3,
    SouthEast = 6,
    SouthWest = 7,
    Top = 1,
    West = 4,
}

export enum AssetType {
    Avatar = "avatar",
    BookingQuestion = "bookingQuestion",
    BrandFavicon = "brandFavicon",
    BrandLogo = "brandLogo",
    BrandVoucherLogo = "brandVoucherLogo",
    DistributionChannelAuthorAvatarImage = "distributionChannelAuthorAvatarImage",
    DistributionChannelPageCategoryImage = "distributionChannelPageCategoryImage",
    DistributionChannelPageImage = "distributionChannelPageImage",
    EntityAttachment = "entityAttachment",
    EntityContent = "entityContent",
    Font = "font",
    HbmlImage = "hbmlImage",
    HbmlSectionImage = "hbmlSectionImage",
    HbmlStackItemImage = "hbmlStackItemImage",
    HeroImage = "heroImage",
    HeroVideo = "heroVideo",
    HierarchyCurationMetaImage = "hierarchyCurationMetaImage",
    OpenGraphImage = "openGraphImage",
    OrganizationBannerImage = "organizationBannerImage",
    OrganizationLogo = "organizationLogo",
    ProductAttachment = "productAttachment",
    ProductImage = "productImage",
    StaticImage = "staticImage",
    SystemTagIcon = "systemTagIcon",
    TranslationImport = "translationImport",
}

export type AssetUpload = {
    readonly __typename?: "AssetUpload";
    readonly asset: Asset;
    readonly downloadUrl?: Maybe<Scalars["String"]["output"]>;
    readonly filename: Scalars["String"]["output"];
    readonly uploadUrl: Scalars["String"]["output"];
};

export type AssociatedProductFilter = {
    readonly type?: InputMaybe<ReadonlyArray<InputMaybe<ProductAssociationType>>>;
};

export type AuthAccount = {
    readonly __typename?: "AuthAccount";
    readonly provider: Scalars["String"]["output"];
    readonly providerAccountId: Scalars["ID"]["output"];
    readonly userId: Scalars["ID"]["output"];
};

/** A user meant for use by next-auth API access */
export type AuthUser = {
    readonly __typename?: "AuthUser";
    readonly email: Scalars["EmailAddress"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly name: Scalars["String"]["output"];
};

/** A user meant for use by next-auth API access */
export type AuthUserLogin = {
    readonly __typename?: "AuthUserLogin";
    readonly accessToken: Scalars["JWT"]["output"];
    readonly accessTokenExpiresAt: Scalars["Date"]["output"];
    readonly email: Scalars["EmailAddress"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly idToken: Scalars["JWT"]["output"];
    readonly idTokenExpiresAt: Scalars["Date"]["output"];
    readonly name: Scalars["String"]["output"];
};

export type AuthUserLoginError = {
    readonly __typename?: "AuthUserLoginError";
    readonly message: Scalars["String"]["output"];
};

export type AuthUserLoginResult = AuthUserLogin | AuthUserLoginError;

export type AuthenticateError = {
    readonly __typename?: "AuthenticateError";
    readonly code?: Maybe<AuthenticationErrorCode>;
    readonly message: Scalars["String"]["output"];
};

export type AuthenticateResult = AuthenticateError | AuthenticateSuccess;

export type AuthenticateSuccess = {
    readonly __typename?: "AuthenticateSuccess";
    readonly success: Scalars["Boolean"]["output"];
};

export enum AuthenticationErrorCode {
    InvalidToken = "INVALID_TOKEN",
    OauthMethodNotActive = "OAUTH_METHOD_NOT_ACTIVE",
    OauthMethodNotSupported = "OAUTH_METHOD_NOT_SUPPORTED",
    UserNotFound = "USER_NOT_FOUND",
}

/** This represents an Availability Sesssion */
export type Availability = {
    readonly __typename?: "Availability";
    /**
     * Remaining places/seats to book
     * @deprecated This field is deprecated
     */
    readonly capacity?: Maybe<Scalars["Int"]["output"]>;
    /**
     * Local start date of availability
     * @deprecated Use startAt.
     */
    readonly date?: Maybe<Scalars["Date"]["output"]>;
    /**
     * Duration as formatted text e.g 2 hours and 30 minutes
     * @deprecated This field is deprecated
     */
    readonly durationFormattedText?: Maybe<Scalars["String"]["output"]>;
    /**
     * Duration in minutes
     * @deprecated This field is deprecated
     */
    readonly durationMinutes?: Maybe<Scalars["Int"]["output"]>;
    /**
     * A guide price for this availability as a formatted string
     * @deprecated This field is deprecated
     */
    readonly guidePriceFormattedText?: Maybe<Scalars["String"]["output"]>;
    readonly id: Scalars["String"]["output"];
    /** Is the availability in a valid and bookable state. */
    readonly isValid: Scalars["Boolean"]["output"];
    /** The maximum number of participants for this availability */
    readonly maxParticipants?: Maybe<Scalars["Int"]["output"]>;
    /** The minimum number of participants for this availability */
    readonly minParticipants?: Maybe<Scalars["Int"]["output"]>;
    /**
     * Opening hours for this availability - relevant if availability has type DATE or PASS
     * @deprecated This field is deprecated
     */
    readonly openingHours?: Maybe<Scalars["String"]["output"]>;
    /** The options required before pricing categories can be selected */
    readonly optionList?: Maybe<AvailabilityOptionList>;
    /**
     * Expiration information for availabilities with type PASS
     * @deprecated This field is deprecated
     */
    readonly passExpiry?: Maybe<Scalars["String"]["output"]>;
    /** The available pricing categories for this availability */
    readonly pricingCategoryList?: Maybe<PricingCategoryList>;
    /** productId */
    readonly productId: Scalars["String"]["output"];
    /**
     * Date sold out
     * @deprecated This field is deprecated
     */
    readonly soldOut?: Maybe<Scalars["Boolean"]["output"]>;
    /** Start date time of the availability in UTC. null if can start any time. */
    readonly startAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** Availability time zone at the moment of booking. */
    readonly timeZone: Scalars["String"]["output"];
    /** The unit price for this availability. */
    readonly totalPrice: Price;
    /** Availability type at the moment of booking. */
    readonly type?: Maybe<AvailabilityType>;
    /** The unit price for this availability. */
    readonly unitPrice: Price;
};

export type AvailabilityFromBookingAvailabilityInputType = {
    readonly bookingAvailabilityId: Scalars["String"]["input"];
};

export type AvailabilityInput = {
    /** Answers for any required availability options */
    readonly optionList?: InputMaybe<ReadonlyArray<InputMaybe<AvailabilityOptionInput>>>;
    /** Answers for desired pricing categories */
    readonly pricingCategoryList?: InputMaybe<ReadonlyArray<InputMaybe<AvailabilityPricingCategoryInput>>>;
};

export type AvailabilityListFilter = {
    /** Search end date */
    readonly endDate?: InputMaybe<Scalars["Date"]["input"]>;
    /** include sold out */
    readonly includeSoldOut?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Filters for restaurants */
    readonly restaurant?: InputMaybe<RestaurantAvailabilityFilter>;
    /** list of pax configurations (adults + chilren). Currently used for hotels */
    readonly roomList?: InputMaybe<ReadonlyArray<InputMaybe<AvailabilityListRoomInput>>>;
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    /** Search start date */
    readonly startDate?: InputMaybe<Scalars["Date"]["input"]>;
};

/** This represents an Availability */
export type AvailabilityListItem = {
    readonly __typename?: "AvailabilityListItem";
    /** Start of availability */
    readonly date?: Maybe<Scalars["Date"]["output"]>;
    /** End of availability */
    readonly endDate?: Maybe<Scalars["Date"]["output"]>;
    /** A guide price for this availability */
    readonly guidePriceAmount?: Maybe<Scalars["Int"]["output"]>;
    /** A guide price currency for this availability */
    readonly guidePriceCurrency?: Maybe<Scalars["String"]["output"]>;
    /** A guide price for this availability as a formatted string */
    readonly guidePriceFormattedText?: Maybe<Scalars["String"]["output"]>;
    readonly id: Scalars["String"]["output"];
    /** Date sold out. */
    readonly soldOut: Scalars["Boolean"]["output"];
    /** Availability time zone at the moment of booking */
    readonly timeZone: Scalars["String"]["output"];
};

/** A question that must be answered before the underlying availability list is returned */
export type AvailabilityListOptionListItem = {
    readonly __typename?: "AvailabilityListOptionListItem";
    /** The option's data type e.g. TEXT */
    readonly dataType: AvailabilityListOptionListItemDataType;
    /** A list of error messages associated with the given item, usually pertaining to invalid or missing answers */
    readonly errorList?: Maybe<AvailabilityListOptionListItemErrorListType>;
    readonly id: Scalars["ID"]["output"];
    /** Human-friendly label that describes the item */
    readonly label?: Maybe<Scalars["String"]["output"]>;
    /** The ID of the given item's parent option, if it has one */
    readonly parentId?: Maybe<Scalars["ID"]["output"]>;
    /** Determines whether an answer must be provided for this option */
    readonly required: Scalars["Boolean"]["output"];
    /** The synchronisation source's internal identifier for the option */
    readonly synchronisationKey?: Maybe<Scalars["String"]["output"]>;
    /** The key that identifies the option's type e.g. START_DATE */
    readonly type: Scalars["String"]["output"];
    /** The user's answer to the given option, if one has previously been provided */
    readonly value?: Maybe<Scalars["String"]["output"]>;
};

export enum AvailabilityListOptionListItemDataType {
    Boolean = "BOOLEAN",
    Date = "DATE",
    DateAndTime = "DATE_AND_TIME",
    Double = "DOUBLE",
    Int = "INT",
    Text = "TEXT",
    Time = "TIME",
}

export type AvailabilityListOptionListItemErrorListType = {
    readonly __typename?: "AvailabilityListOptionListItemErrorListType";
    readonly nodes: ReadonlyArray<Scalars["String"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type AvailabilityListOptionListItemInput = {
    readonly id: Scalars["String"]["input"];
    /** The ID of this option's parent option. This is only required when following the opinionated query pattern. */
    readonly parentId?: InputMaybe<Scalars["ID"]["input"]>;
    /** The key that identifies the option's type e.g. START_DATE. This is only required when following the opinionated query pattern. */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
    /** The user's answer to the given option */
    readonly value: Scalars["String"]["input"];
};

export type AvailabilityListOptionListType = {
    readonly __typename?: "AvailabilityListOptionListType";
    readonly nodes: ReadonlyArray<AvailabilityListOptionListItem>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

/** This respresents the getAvailabilities response type */
export type AvailabilityListResponse = {
    readonly __typename?: "AvailabilityListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** Availability list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<AvailabilityListItem>>>;
    /** The next batch of availability list options that must be answers prior to the list of availabilities being returned */
    readonly optionList?: Maybe<AvailabilityListOptionListType>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /** Used to hold the current state of optionList in session */
    readonly sessionId?: Maybe<Scalars["ID"]["output"]>;
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type AvailabilityListRoomChildInput = {
    readonly age: Scalars["Int"]["input"];
};

export type AvailabilityListRoomInput = {
    readonly adultCount: Scalars["Int"]["input"];
    readonly childList?: InputMaybe<ReadonlyArray<AvailabilityListRoomChildInput>>;
};

export type AvailabilityListSort = {
    /** Sort by endDate */
    readonly endDate?: InputMaybe<SortDirection>;
    /** Sort by startDate */
    readonly startDate?: InputMaybe<SortDirection>;
};

export type AvailabilityOptionInput = {
    /** the availability option id you are answering */
    readonly id: Scalars["String"]["input"];
    /** the answer */
    readonly value: Scalars["String"]["input"];
};

/** This respresents the getAvailabilities response type */
export type AvailabilityOptionList = {
    readonly __typename?: "AvailabilityOptionList";
    readonly isComplete: Scalars["Boolean"]["output"];
    /** Availability list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<AvailabilityOptionListItem>>>;
    /** Total number of records matching query */
    readonly total?: Maybe<Scalars["Int"]["output"]>;
};

/** This represents an Availability */
export type AvailabilityOptionListItem = {
    readonly __typename?: "AvailabilityOptionListItem";
    readonly answerFile?: Maybe<Scalars["String"]["output"]>;
    readonly answerFileAsset?: Maybe<Asset>;
    /** The answer to this question formatted */
    readonly answerFormattedText?: Maybe<Scalars["String"]["output"]>;
    /** The answer to this question */
    readonly answerValue?: Maybe<Scalars["String"]["output"]>;
    /** The value that may be applied to the autocomplete attribute of a form input control */
    readonly autoCompleteValue?: Maybe<Scalars["String"]["output"]>;
    /** availableOptions */
    readonly availableOptions: ReadonlyArray<Maybe<AvailableOptions>>;
    /** question dataFormat */
    readonly dataFormat?: Maybe<Scalars["String"]["output"]>;
    /** question dataPattern */
    readonly dataPattern?: Maybe<Scalars["String"]["output"]>;
    /** question dataType */
    readonly dataType?: Maybe<Scalars["String"]["output"]>;
    /** question defaultValue */
    readonly defaultValue?: Maybe<Scalars["String"]["output"]>;
    /** question flags */
    readonly flags?: Maybe<Scalars["String"]["output"]>;
    /** question helper */
    readonly helper?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID for the  Question */
    readonly id: Scalars["String"]["output"];
    /** Has this question's answer been defaulted. */
    readonly isAnswerDefaulted: Scalars["Boolean"]["output"];
    /** is this question answered */
    readonly isAnswered: Scalars["Boolean"]["output"];
    /** isFromOptions */
    readonly isFromOptions: Scalars["Boolean"]["output"];
    /** isPerPerson */
    readonly isPerPerson?: Maybe<Scalars["Boolean"]["output"]>;
    /** isRequired */
    readonly isRequired: Scalars["Boolean"]["output"];
    /** isSelectMultiple */
    readonly isSelectMultiple: Scalars["Boolean"]["output"];
    /** option label */
    readonly label?: Maybe<Scalars["String"]["output"]>;
    /** The question level */
    readonly level?: Maybe<Scalars["Int"]["output"]>;
    /** The ordinalPosition for the  Question */
    readonly ordinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** The partnerChannelId for the  Question */
    readonly partnerChannelId?: Maybe<Scalars["String"]["output"]>;
    /** The partnerChannelName for the  Question */
    readonly partnerChannelName?: Maybe<Scalars["String"]["output"]>;
    /** question placeholder */
    readonly placeholder?: Maybe<Scalars["String"]["output"]>;
    readonly questionType: BookingQuestionQuestionType;
    readonly synchronisationQuestionId?: Maybe<Scalars["String"]["output"]>;
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The type for the  Question */
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

export type AvailabilityPricingCategoryInput = {
    /** The pricing category id. */
    readonly id: Scalars["String"]["input"];
    /** The number of units for this pricing category. */
    readonly units?: InputMaybe<Scalars["Int"]["input"]>;
    /**
     * The number of units for this pricing category.
     * @deprecated Please use units instead.
     */
    readonly value?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Type of availability */
export enum AvailabilityType {
    /** Must contact the supplier to check availability. */
    Contact = "CONTACT",
    /** Specific to a date. */
    Date = "DATE",
    /** Specific to a date and time. */
    DateTime = "DATE_TIME",
    /** Handled by external widget */
    ExternalWidget = "EXTERNAL_WIDGET",
    /** Independant of date. E.g ticket to museum which can be used on an day. */
    Pass = "PASS",
}

/** This represents an available option */
export type AvailableOptions = {
    readonly __typename?: "AvailableOptions";
    readonly key?: Maybe<Scalars["String"]["output"]>;
    readonly label?: Maybe<Scalars["String"]["output"]>;
    readonly value?: Maybe<Scalars["String"]["output"]>;
};

/** Represents banner pasted over an image */
export type BannerType = {
    readonly __typename?: "BannerType";
    /** Banner background color */
    readonly backgroundColor: Scalars["String"]["output"];
    /** Banner text */
    readonly text: Scalars["String"]["output"];
};

/** This represents a barcode */
export type Barcode = {
    readonly __typename?: "Barcode";
    /** barcode type */
    readonly type: BarcodeType;
    /** barcode value */
    readonly value: Scalars["String"]["output"];
};

export enum BarcodeType {
    Barcode_39 = "BARCODE_39",
    Barcode_128 = "BARCODE_128",
    QrCode = "QR_CODE",
}

/** This represents supplier price. */
export type BasicPrice = {
    readonly __typename?: "BasicPrice";
    /** The amount. */
    readonly amount: Scalars["Float"]["output"];
    /** The currency. */
    readonly currency: Scalars["String"]["output"];
    /** The amount and currency as formatted text. */
    readonly formattedText: Scalars["String"]["output"];
};

/** This represents a Booking */
export type Booking = {
    readonly __typename?: "Booking";
    /** The application that made the booking */
    readonly application?: Maybe<Scalars["String"]["output"]>;
    readonly availabilityList: BookingAvailabilityList;
    /** The URL to manage this booking */
    readonly bookingUrl: Scalars["String"]["output"];
    /** Can all availabilities be cancelled. */
    readonly canCancel?: Maybe<Scalars["Boolean"]["output"]>;
    /** have all required questions been completed, payment taken and has not already been committed. */
    readonly canCommit?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * have all required questions have been completed
     * @deprecated DEPREACTED - Use canCommit instead.
     */
    readonly canSubmit?: Maybe<Scalars["Boolean"]["output"]>;
    /** The price amount that would be returned if cancelled */
    readonly cancellationEffectiveRefundAmount?: Maybe<BasicPrice>;
    /** The securityPrincipalUser of cancellation initialiser */
    readonly cancellationInitialisedBySecurityPrincipalUser?: Maybe<SecurityPrincipalUser>;
    /** The date at which this booking was cancelled. */
    readonly cancelledAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The code for this booking. */
    readonly code: Scalars["String"]["output"];
    /** The securityPrincipalUser of confirmation initialiser */
    readonly confirmationInitialisedBySecurityPrincipalUser?: Maybe<SecurityPrincipalUser>;
    /** The date at which this booking was confirmed. */
    readonly confirmedAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly consumerTrip?: Maybe<ConsumerTripBasicTc>;
    /**
     * The URL that a consumer should be directed to in order to manage this booking.
     * @deprecated Please use bookingUrl in place.
     */
    readonly consumerUrl: Scalars["String"]["output"];
    /** Contact details for the booking. */
    readonly contact?: Maybe<BookingContact>;
    /** The date at which this booking was created. */
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The date at which the summary email was sent. */
    readonly emailSummarySentAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** Date of first availability. */
    readonly firstAvailabilityDate?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID for the available trip. */
    readonly id: Scalars["String"]["output"];
    /** Is booking complete. */
    readonly isComplete: Scalars["Boolean"]["output"];
    /** Indicates if the booking or any related booking availabilities have failed. */
    readonly isFailed: Scalars["Boolean"]["output"];
    readonly isPendingCommit?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is booking processing. */
    readonly isProcessing?: Maybe<Scalars["Boolean"]["output"]>;
    /** have all required questions have been completed. */
    readonly isQuestionsComplete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is booking sandboxed. */
    readonly isSandboxed: Scalars["Boolean"]["output"];
    /** Returns true if the Booking Lifecycle Manager is currently processing changes. */
    readonly isWorkflowProcessing: Scalars["Boolean"]["output"];
    /** Content language code */
    readonly languageCode: LanguageCode;
    /** The language id the booking was initially made in */
    readonly languageId: Scalars["String"]["output"];
    /** Lead person name. */
    readonly leadPassengerName?: Maybe<Scalars["String"]["output"]>;
    /** Name. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    readonly outstandingConsumerAmount: BasicPrice;
    readonly owningSecurityPrincipal?: Maybe<SecurityPrincipal>;
    readonly owningSecurityPrincipalUser?: Maybe<SecurityPrincipalUser>;
    /**
     * The name of the partner agent that created this booking.
     * @deprecated Use owningSecurityPrincipalUser instead
     */
    readonly partnerAgentName?: Maybe<Scalars["String"]["output"]>;
    /** The URL to the root of the channels booking site. */
    readonly partnerChannelBookingUrl?: Maybe<Scalars["String"]["output"]>;
    /** The partnerChannel id for this booking. */
    readonly partnerChannelId?: Maybe<Scalars["String"]["output"]>;
    /** The name of the partnerChannel that this booking is attributed to. */
    readonly partnerChannelName?: Maybe<Scalars["String"]["output"]>;
    /** The reference passed in to this booking when it was created by the partner. */
    readonly partnerExternalReference?: Maybe<Scalars["String"]["output"]>;
    /** The partner id for this booking. */
    readonly partnerId?: Maybe<Scalars["String"]["output"]>;
    /** The name of the partner that this booking is attributed to (via channel). */
    readonly partnerName?: Maybe<Scalars["String"]["output"]>;
    /** The payment state of this booking. */
    readonly paymentState?: Maybe<BookingPaymentStateType>;
    readonly paymentType: BookingPaymentType;
    /** Represents the current state of the booking lifecycle. */
    readonly processState: BookingProcessState;
    /** questions. */
    readonly questionList: BookingQuestionList;
    /**
     * The partner external reference for this booking.
     * @deprecated Please use partnerExternalReference instead.
     */
    readonly reference?: Maybe<Scalars["String"]["output"]>;
    /** sale price as string. */
    readonly saleCurrency?: Maybe<Scalars["String"]["output"]>;
    /**
     * sale currency symbol.
     * @deprecated Please use totalPrice in place.
     */
    readonly saleCurrencySymbol?: Maybe<Scalars["String"]["output"]>;
    /**
     * sale gross price total.
     * @deprecated Please use totalPrice in place.
     */
    readonly saleGrossPriceTotal?: Maybe<Scalars["Float"]["output"]>;
    /**
     * sale gross price total as a formatted string.
     * @deprecated Please use totalPrice in place.
     */
    readonly saleGrossPriceTotalFormattedText?: Maybe<Scalars["String"]["output"]>;
    /**
     * sale gross price total as a formatted string.
     * @deprecated Please use totalPrice in place.
     */
    readonly saleGrossPriceTotalText?: Maybe<Scalars["String"]["output"]>;
    /**
     * sale net price total.
     * @deprecated Please use totalPrice in place.
     */
    readonly saleNetPriceTotal?: Maybe<Scalars["Float"]["output"]>;
    /**
     * sale net price total as a formatted string.
     * @deprecated Please use totalPrice in place.
     */
    readonly saleNetPriceTotalFormattedText?: Maybe<Scalars["String"]["output"]>;
    /** The state of this booking. */
    readonly state: BookingStateType;
    /** The Terms and Conditions related to the booking */
    readonly termAndCondition?: Maybe<Scalars["String"]["output"]>;
    /** total price for this booking. */
    readonly totalPrice: Price;
    /** Does booking use life cycle manager */
    readonly useLifecycleManager: Scalars["Boolean"]["output"];
    readonly voucherCreatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The url from which the booking voucher may be downloaded - Note the URL will be valid for 10 minutes from time of request. */
    readonly voucherUrl?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Use emailSummarySentAt or id instead */
    readonly workflowContextData?: Maybe<WorkflowContextData>;
};

export type BookingAddAvailabilityInputType = {
    /** booking id */
    readonly bookingSelector: BookingSelector;
    /** id corresponding to an availability returned from availabilityList query */
    readonly id: Scalars["String"]["input"];
    /** id of the original availability in case of amendment */
    readonly originBookingAvailabilityId?: InputMaybe<Scalars["String"]["input"]>;
};

export type BookingAnswerInput = {
    /** the question id you are answering */
    readonly questionId: Scalars["String"]["input"];
    /** the answer */
    readonly value: Scalars["String"]["input"];
};

export type BookingAvailability = {
    readonly __typename?: "BookingAvailability";
    readonly bookingCode: Scalars["String"]["output"];
    readonly bookingId: Scalars["String"]["output"];
    readonly bookingInfoSummary: BookingInfoSummary;
    readonly bookingPartnerExternalReference?: Maybe<Scalars["String"]["output"]>;
    /** Booking questions */
    readonly bookingQuestionList: BookingQuestionList;
    /** The time difference in minutes between booking and consumptions dates. Metric that shows trends on how much in advance customers book the product. */
    readonly bookingWindow?: Maybe<Scalars["String"]["output"]>;
    /** Can the availability be amended. */
    readonly canAmend: Scalars["Boolean"]["output"];
    /** Can the availability be cancelled. */
    readonly canCancel: Scalars["Boolean"]["output"];
    /** Can the availability be edited before ever committed. */
    readonly canEdit: Scalars["Boolean"]["output"];
    /** Can the availability be cancelled outside cancellation policy. */
    readonly canForceCancel: Scalars["Boolean"]["output"];
    readonly canRevertCancellation: Scalars["Boolean"]["output"];
    /** Can revert the availability to the original one. */
    readonly canRevertEdit: Scalars["Boolean"]["output"];
    /** Cancellation penalty list at the moment of booking */
    readonly cancellationPenaltyList: CancellationPenaltyList;
    readonly cancellationState?: Maybe<BookingAvailabilityCancellationState>;
    readonly code: Scalars["String"]["output"];
    readonly confirmationResponse?: Maybe<BookingAvailabilityConfirmationResponse>;
    /** Indicates the date and time when the QR code was consumed, when there are no pax. if there are pax, then this field is treated as “lastConsumedAt“ based on the last QR code consumed */
    readonly consumedAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly consumedState: ConsumptionStateType;
    /** The URL to manage booking availability consumption. */
    readonly consumptionUrl?: Maybe<Scalars["String"]["output"]>;
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly cutOffDuration?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Date is ambiguous. Use startAt for UTC start date. */
    readonly date?: Maybe<Scalars["Date"]["output"]>;
    /** End date time of booking availability. */
    readonly endAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly finance: BookingAvailabilityFinance;
    readonly id: Scalars["String"]["output"];
    readonly isActive: Scalars["Boolean"]["output"];
    readonly isAmended: Scalars["Boolean"]["output"];
    readonly isCancelled: Scalars["Boolean"]["output"];
    readonly isConfirmed: Scalars["Boolean"]["output"];
    readonly isFailed: Scalars["Boolean"]["output"];
    readonly isForceCancelled: Scalars["Boolean"]["output"];
    readonly isQuestionsComplete: Scalars["Boolean"]["output"];
    /** The URL to manage booking availability. */
    readonly manageUrl?: Maybe<Scalars["String"]["output"]>;
    /** Availability options */
    readonly optionList: BookingAvailabilityOptionList;
    /** The origin booking availability used to create this booking availability. */
    readonly originBookingAvailability?: Maybe<BookingAvailabilitySimple>;
    /** Origin booking availability id used to creating this booking availability. */
    readonly originBookingAvailabilityId?: Maybe<Scalars["String"]["output"]>;
    /** The parent availability used to create this availability. */
    readonly parent?: Maybe<BookingAvailabilitySimple>;
    readonly paymentIntentId?: Maybe<Scalars["String"]["output"]>;
    readonly paymentState: Scalars["String"]["output"];
    /** Booking Availability Person */
    readonly personList: BookingAvailabilityPersonList;
    /** Represents the current state of the booking availability lifecycle. */
    readonly processState: BookingAvailabilityProcessState;
    readonly product: Product;
    readonly productId: Scalars["String"]["output"];
    readonly productName: Scalars["String"]["output"];
    /** The URL to see product details */
    readonly productUrl: Scalars["String"]["output"];
    /** Questions */
    readonly questionList: BookingAvailabilityQuestionList;
    readonly refreshStatus?: Maybe<BookingAvailabilityRefreshStatus>;
    /**
     * sale currency
     * @deprecated Please use totalPrice in place.
     */
    readonly saleCurrency?: Maybe<Scalars["String"]["output"]>;
    /**
     * sale gross price
     * @deprecated Please use totalPrice in place.
     */
    readonly saleGrossPriceTotal?: Maybe<Scalars["Float"]["output"]>;
    /**
     * sale gross price as formatted text
     * @deprecated Please use totalPrice in place.
     */
    readonly saleGrossPriceTotalFormattedText?: Maybe<Scalars["String"]["output"]>;
    /**
     * sale net price
     * @deprecated Please use totalPrice in place.
     */
    readonly saleNetPriceTotal?: Maybe<Scalars["Float"]["output"]>;
    /**
     * sale net price as formatted text
     * @deprecated Please use totalPrice in place.
     */
    readonly saleNetPriceTotalFormattedText?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Not in use. Invalid configuration. */
    readonly shouldShowQrCode: Scalars["Boolean"]["output"];
    /** Start date time of booking availability. */
    readonly startAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly state: BookingAvailabilityStateType;
    readonly timeZone: Scalars["String"]["output"];
    /** total price for this availability. */
    readonly totalPrice: Price;
    readonly type: BookingAvailabilityType;
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** Does availability use lifecycle manager */
    readonly useLifecycleManager: Scalars["Boolean"]["output"];
    /** The URL to download the booking availability voucher */
    readonly voucherUrl?: Maybe<Scalars["String"]["output"]>;
};

export type BookingAvailabilityPersonListArgs = {
    filter?: InputMaybe<BookingAvailabilityPersonListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<BookingAvailabilityPersonListSort>;
};

export enum BookingAvailabilityCancellationEffectiveRefundState {
    Full = "FULL",
    None = "NONE",
    Partial = "PARTIAL",
}

export type BookingAvailabilityCancellationInput = {
    readonly id: Scalars["String"]["input"];
    readonly reason: Scalars["String"]["input"];
};

/** This represents a Booking Availability Cancellation state. */
export type BookingAvailabilityCancellationState = {
    readonly __typename?: "BookingAvailabilityCancellationState";
    readonly effectivePenalty: CancellationPenalty;
    readonly effectiveRefundAmount: BasicPrice;
    readonly effectiveRefundState: BookingAvailabilityCancellationEffectiveRefundState;
};

/** This represents a Booking */
export type BookingAvailabilityConfirmationResponse = {
    readonly __typename?: "BookingAvailabilityConfirmationResponse";
    readonly barcode?: Maybe<Barcode>;
    readonly dropOffPlace?: Maybe<BookingAvailabilityConfirmationResponsePlace>;
    readonly duration?: Maybe<BookingAvailabilityConfirmationResponseDuration>;
    readonly hotel?: Maybe<BookingAvailabilityConfirmationResponseHotel>;
    readonly meetingPlace?: Maybe<BookingAvailabilityConfirmationResponsePlace>;
    readonly notes?: Maybe<ReadonlyArray<Maybe<BookingAvailabilityConfirmationResponseNote>>>;
    readonly operator?: Maybe<BookingAvailabilityConfirmationResponseOperator>;
    readonly pickUpPlace?: Maybe<BookingAvailabilityConfirmationResponsePlace>;
    /** Synchronisation source reference. */
    readonly reference: Scalars["String"]["output"];
    readonly state: BookingAvailabilityConfirmationResponseState;
    /** Synchronisation source unique booking reference. */
    readonly synchronisationKey: Scalars["String"]["output"];
    /** Synchronisation source human-readable booking reference. Accompanies the synchronisationKey. */
    readonly synchronisationReference?: Maybe<Scalars["String"]["output"]>;
    readonly ticket?: Maybe<BookingAvailabilityConfirmationResponseTicket>;
};

export type BookingAvailabilityConfirmationResponseDuration = {
    readonly __typename?: "BookingAvailabilityConfirmationResponseDuration";
    /** Description of the duration. */
    readonly description?: Maybe<Scalars["String"]["output"]>;
};

export type BookingAvailabilityConfirmationResponseHotel = {
    readonly __typename?: "BookingAvailabilityConfirmationResponseHotel";
    readonly roomList: BookingAvailabilityConfirmationResponseHotelRoomList;
};

export type BookingAvailabilityConfirmationResponseHotelRoom = {
    readonly __typename?: "BookingAvailabilityConfirmationResponseHotelRoom";
    readonly id: Scalars["String"]["output"];
    readonly synchronisationKey: Scalars["String"]["output"];
};

export type BookingAvailabilityConfirmationResponseHotelRoomList = {
    readonly __typename?: "BookingAvailabilityConfirmationResponseHotelRoomList";
    readonly nodes: ReadonlyArray<BookingAvailabilityConfirmationResponseHotelRoom>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type BookingAvailabilityConfirmationResponseNote = {
    readonly __typename?: "BookingAvailabilityConfirmationResponseNote";
    /** Description of the note. */
    readonly description?: Maybe<Scalars["String"]["output"]>;
};

export type BookingAvailabilityConfirmationResponseNoteDescriptionArgs = {
    contentType?: InputMaybe<DescriptionContentType>;
};

export type BookingAvailabilityConfirmationResponseOperator = {
    readonly __typename?: "BookingAvailabilityConfirmationResponseOperator";
    readonly contactEmail?: Maybe<Scalars["String"]["output"]>;
    readonly contactName?: Maybe<Scalars["String"]["output"]>;
    readonly contactPhone?: Maybe<Scalars["String"]["output"]>;
};

export type BookingAvailabilityConfirmationResponsePlace = {
    readonly __typename?: "BookingAvailabilityConfirmationResponsePlace";
    /** Description of the place. */
    readonly description?: Maybe<Scalars["String"]["output"]>;
};

export type BookingAvailabilityConfirmationResponsePlaceDescriptionArgs = {
    contentType?: InputMaybe<DescriptionContentType>;
};

export enum BookingAvailabilityConfirmationResponseState {
    Confirmed = "CONFIRMED",
    Failed = "FAILED",
    Pending = "PENDING",
}

export type BookingAvailabilityConfirmationResponseTicket = {
    readonly __typename?: "BookingAvailabilityConfirmationResponseTicket";
    readonly confirmationDate?: Maybe<Scalars["String"]["output"]>;
    readonly destinationName?: Maybe<Scalars["String"]["output"]>;
    readonly endDate?: Maybe<Scalars["String"]["output"]>;
    readonly excursionName?: Maybe<Scalars["String"]["output"]>;
    readonly leadPassengerName?: Maybe<Scalars["String"]["output"]>;
    readonly notes?: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
    readonly operator?: Maybe<BookingAvailabilityConfirmationResponseTicketOperator>;
    readonly participants?: Maybe<ReadonlyArray<Maybe<BookingAvailabilityConfirmationResponseTicketParticipant>>>;
    readonly provider?: Maybe<BookingAvailabilityConfirmationResponseTicketProvider>;
    readonly reference?: Maybe<Scalars["String"]["output"]>;
    readonly startDate?: Maybe<Scalars["String"]["output"]>;
    readonly type?: Maybe<BookingAvailabilityConfirmationResponseTicketType>;
};

export type BookingAvailabilityConfirmationResponseTicketOperator = {
    readonly __typename?: "BookingAvailabilityConfirmationResponseTicketOperator";
    readonly name?: Maybe<Scalars["String"]["output"]>;
    readonly reference?: Maybe<Scalars["String"]["output"]>;
};

export type BookingAvailabilityConfirmationResponseTicketParticipant = {
    readonly __typename?: "BookingAvailabilityConfirmationResponseTicketParticipant";
    readonly age?: Maybe<Scalars["Int"]["output"]>;
    readonly familyName?: Maybe<Scalars["String"]["output"]>;
    readonly givenName?: Maybe<Scalars["String"]["output"]>;
    readonly reference?: Maybe<Scalars["String"]["output"]>;
};

export type BookingAvailabilityConfirmationResponseTicketProvider = {
    readonly __typename?: "BookingAvailabilityConfirmationResponseTicketProvider";
    readonly name?: Maybe<Scalars["String"]["output"]>;
    readonly taxReference?: Maybe<Scalars["String"]["output"]>;
};

export type BookingAvailabilityConfirmationResponseTicketType = {
    readonly __typename?: "BookingAvailabilityConfirmationResponseTicketType";
    readonly name?: Maybe<Scalars["String"]["output"]>;
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

export type BookingAvailabilityConsumeMutationInput = {
    readonly id: Scalars["ID"]["input"];
    readonly isConsumed?: InputMaybe<Scalars["Boolean"]["input"]>;
    readonly personConsumeList: ReadonlyArray<PaxConsumeInputList>;
};

export type BookingAvailabilityConsumptionPersonListType = {
    readonly __typename?: "BookingAvailabilityConsumptionPersonListType";
    readonly nodes: ReadonlyArray<BookingAvailabilityConsumptionPersonType>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type BookingAvailabilityConsumptionPersonType = {
    readonly __typename?: "BookingAvailabilityConsumptionPersonType";
    readonly age?: Maybe<Scalars["Int"]["output"]>;
    /** date and time the QR code was consumed */
    readonly consumedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The unique ID for the person */
    readonly id: Scalars["String"]["output"];
    readonly isConsumed: Scalars["Boolean"]["output"];
    readonly name?: Maybe<Scalars["String"]["output"]>;
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

export type BookingAvailabilityConsumptionType = {
    readonly __typename?: "BookingAvailabilityConsumptionType";
    readonly bookingCode: Scalars["String"]["output"];
    readonly code: Scalars["String"]["output"];
    /** Indicates the date and time when the QR code was consumed, when there are no pax. if there are pax, then this field is treated as “lastConsumedAt“ based on the last QR code consumed */
    readonly consumedAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly consumedPaxCount?: Maybe<Scalars["Int"]["output"]>;
    readonly consumedState: ConsumptionStateType;
    readonly id: Scalars["String"]["output"];
    readonly isConsumed: Scalars["Boolean"]["output"];
    readonly leadPassengerName?: Maybe<Scalars["String"]["output"]>;
    readonly partnerExternalReference?: Maybe<Scalars["String"]["output"]>;
    readonly paxCount: Scalars["Int"]["output"];
    readonly personList: BookingAvailabilityConsumptionPersonListType;
    readonly productName: Scalars["String"]["output"];
    /** Start date time of booking availability. */
    readonly startAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly timeZone: Scalars["String"]["output"];
    readonly type: BookingAvailabilityType;
};

export type BookingAvailabilityExtended = {
    readonly __typename?: "BookingAvailabilityExtended";
    readonly availabilityResolverData?: Maybe<Scalars["JSON"]["output"]>;
    readonly bookingCode: Scalars["String"]["output"];
    readonly bookingData?: Maybe<Scalars["JSON"]["output"]>;
    readonly bookingId: Scalars["String"]["output"];
    readonly bookingInfoSummary: BookingInfoSummary;
    readonly bookingPartnerExternalReference?: Maybe<Scalars["String"]["output"]>;
    readonly bookingQuestionList: BookingQuestionList;
    /** The time difference in minutes between booking and consumptions dates. Metric that shows trends on how much in advance customers book the product. */
    readonly bookingWindow?: Maybe<Scalars["String"]["output"]>;
    readonly cacheAvailabilityData?: Maybe<Scalars["JSON"]["output"]>;
    /** Can the availability be amended. */
    readonly canAmend: Scalars["Boolean"]["output"];
    /** Can the availability be cancelled. */
    readonly canCancel: Scalars["Boolean"]["output"];
    /** Can the availability be edited before ever committed. */
    readonly canEdit: Scalars["Boolean"]["output"];
    /** Can the availability be cancelled outside cancellation policy. */
    readonly canForceCancel: Scalars["Boolean"]["output"];
    readonly canRevertCancellation: Scalars["Boolean"]["output"];
    /** Can revert the availability to the original one. */
    readonly canRevertEdit: Scalars["Boolean"]["output"];
    /** Cancellation penalty list at the moment of booking */
    readonly cancellationPenaltyList: CancellationPenaltyList;
    readonly cancellationState?: Maybe<BookingAvailabilityCancellationState>;
    readonly code: Scalars["String"]["output"];
    readonly confirmationResponse?: Maybe<BookingAvailabilityConfirmationResponse>;
    /** Indicates the date and time when the QR code was consumed, when there are no pax. if there are pax, then this field is treated as “lastConsumedAt“ based on the last QR code consumed */
    readonly consumedAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly consumedState: ConsumptionStateType;
    /** The URL to manage booking availability consumption. */
    readonly consumptionUrl?: Maybe<Scalars["String"]["output"]>;
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly cutOffDuration?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Date is ambiguous. Use startAt for UTC start date. */
    readonly date?: Maybe<Scalars["Date"]["output"]>;
    /** End date time of booking availability. */
    readonly endAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly finance: BookingAvailabilityFinance;
    readonly id: Scalars["String"]["output"];
    readonly isActive: Scalars["Boolean"]["output"];
    readonly isAmended: Scalars["Boolean"]["output"];
    readonly isCancelled: Scalars["Boolean"]["output"];
    readonly isConfirmed: Scalars["Boolean"]["output"];
    readonly isFailed: Scalars["Boolean"]["output"];
    readonly isForceCancelled: Scalars["Boolean"]["output"];
    readonly isQuestionsComplete: Scalars["Boolean"]["output"];
    /** The URL to manage booking availability. */
    readonly manageUrl?: Maybe<Scalars["String"]["output"]>;
    /** Availability options */
    readonly optionList: BookingAvailabilityOptionList;
    /** The origin booking availability used to create this booking availability. */
    readonly originBookingAvailability?: Maybe<BookingAvailabilitySimple>;
    /** Origin booking availability id used to creating this booking availability. */
    readonly originBookingAvailabilityId?: Maybe<Scalars["String"]["output"]>;
    /** The parent availability used to create this availability. */
    readonly parent?: Maybe<BookingAvailabilitySimple>;
    readonly paymentIntentId?: Maybe<Scalars["String"]["output"]>;
    readonly paymentState: Scalars["String"]["output"];
    readonly personList: BookingAvailabilityPersonExtendedList;
    readonly pricingCategories?: Maybe<ReadonlyArray<Scalars["JSON"]["output"]>>;
    /** Represents the current state of the booking availability lifecycle. */
    readonly processState: BookingAvailabilityProcessState;
    readonly product: Product;
    readonly productId: Scalars["String"]["output"];
    readonly productName: Scalars["String"]["output"];
    /** The URL to see product details */
    readonly productUrl: Scalars["String"]["output"];
    readonly questionList: BookingAvailabilityQuestionList;
    readonly refreshStatus?: Maybe<BookingAvailabilityRefreshStatus>;
    /**
     * sale currency
     * @deprecated Please use totalPrice in place.
     */
    readonly saleCurrency?: Maybe<Scalars["String"]["output"]>;
    /**
     * sale gross price
     * @deprecated Please use totalPrice in place.
     */
    readonly saleGrossPriceTotal?: Maybe<Scalars["Float"]["output"]>;
    /**
     * sale gross price as formatted text
     * @deprecated Please use totalPrice in place.
     */
    readonly saleGrossPriceTotalFormattedText?: Maybe<Scalars["String"]["output"]>;
    /**
     * sale net price
     * @deprecated Please use totalPrice in place.
     */
    readonly saleNetPriceTotal?: Maybe<Scalars["Float"]["output"]>;
    /**
     * sale net price as formatted text
     * @deprecated Please use totalPrice in place.
     */
    readonly saleNetPriceTotalFormattedText?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Not in use. Invalid configuration. */
    readonly shouldShowQrCode: Scalars["Boolean"]["output"];
    /** Start date time of booking availability. */
    readonly startAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly state: BookingAvailabilityStateType;
    /** @deprecated Use confirmationResponse. */
    readonly synchronisationResponse?: Maybe<Scalars["JSON"]["output"]>;
    readonly timeZone: Scalars["String"]["output"];
    /** total price for this availability. */
    readonly totalPrice: Price;
    readonly type: BookingAvailabilityType;
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** Does availability use lifecycle manager */
    readonly useLifecycleManager: Scalars["Boolean"]["output"];
    /** The URL to download the booking availability voucher */
    readonly voucherUrl?: Maybe<Scalars["String"]["output"]>;
};

export type BookingAvailabilityExtendedPersonListArgs = {
    filter?: InputMaybe<BookingAvailabilityPersonListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<BookingAvailabilityPersonListSort>;
};

export type BookingAvailabilityFinance = {
    readonly __typename?: "BookingAvailabilityFinance";
    readonly currency: Scalars["String"]["output"];
    readonly exchangeRate: Scalars["String"]["output"];
    readonly value: BasicPrice;
};

export type BookingAvailabilityFinanceCurrencyArgs = {
    of: BookingAvailabilityFinanceCurrency;
};

export type BookingAvailabilityFinanceExchangeRateArgs = {
    from: BookingAvailabilityFinanceCurrency;
    to: BookingAvailabilityFinanceCurrency;
};

export type BookingAvailabilityFinanceValueArgs = {
    currencyOf: BookingAvailabilityFinanceCurrency;
    type: BookingAvailabilityFinanceValueType;
};

export enum BookingAvailabilityFinanceCurrency {
    Base = "BASE",
    Consumer = "CONSUMER",
    Partner = "PARTNER",
    PartnerChannel = "PARTNER_CHANNEL",
    Supplier = "SUPPLIER",
}

export enum BookingAvailabilityFinanceValueType {
    ConsumerTransaction = "CONSUMER_TRANSACTION",
    PartnerCommission = "PARTNER_COMMISSION",
    PartnerTransaction = "PARTNER_TRANSACTION",
    SupplierTransaction = "SUPPLIER_TRANSACTION",
}

export type BookingAvailabilityList = {
    readonly __typename?: "BookingAvailabilityList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<BookingAvailability>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /** Generates a report of the booking availability list and returns the URL to download it */
    readonly reportUrl?: Maybe<Scalars["String"]["output"]>;
};

export type BookingAvailabilityListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type BookingAvailabilityListFilter = {
    readonly activityStartDateRange?: InputMaybe<DateTimeRangeInput>;
    readonly bookingIdList?: InputMaybe<ReadonlyArray<Scalars["ID"]["input"]>>;
    readonly consumerTripIdList?: InputMaybe<ReadonlyArray<Scalars["ID"]["input"]>>;
    readonly createdDateRange?: InputMaybe<DateTimeRangeInput>;
    readonly idList?: InputMaybe<ReadonlyArray<Scalars["ID"]["input"]>>;
    readonly modifiedDateRange?: InputMaybe<DateTimeRangeInput>;
    readonly productIdList?: InputMaybe<ReadonlyArray<Scalars["ID"]["input"]>>;
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    readonly stateList?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
    readonly supplierIdList?: InputMaybe<ReadonlyArray<Scalars["ID"]["input"]>>;
};

export type BookingAvailabilityListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

/** This represents a booking question */
export type BookingAvailabilityOption = {
    readonly __typename?: "BookingAvailabilityOption";
    readonly answerFile?: Maybe<Scalars["String"]["output"]>;
    readonly answerFileAsset?: Maybe<Asset>;
    /** The answer to this question formatted */
    readonly answerFormattedText?: Maybe<Scalars["String"]["output"]>;
    /** The answer to this question */
    readonly answerValue?: Maybe<Scalars["String"]["output"]>;
    /** The value that may be applied to the autocomplete attribute of a form input control */
    readonly autoCompleteValue?: Maybe<Scalars["String"]["output"]>;
    /** availableOptions */
    readonly availableOptions: ReadonlyArray<Maybe<AvailableOptions>>;
    /** The unique ID for the bookingAvailabilityId */
    readonly bookingAvailabilityId: Scalars["String"]["output"];
    /** The unique ID for the bookingId */
    readonly bookingId: Scalars["String"]["output"];
    /** question dataFormat */
    readonly dataFormat?: Maybe<Scalars["String"]["output"]>;
    /** question dataPattern */
    readonly dataPattern?: Maybe<Scalars["String"]["output"]>;
    /** question dataType */
    readonly dataType?: Maybe<Scalars["String"]["output"]>;
    /** question defaultValue */
    readonly defaultValue?: Maybe<Scalars["String"]["output"]>;
    /** question flags */
    readonly flags?: Maybe<Scalars["String"]["output"]>;
    /** question helper */
    readonly helper?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID for the  Question */
    readonly id: Scalars["String"]["output"];
    /** Has this question's answer been defaulted. */
    readonly isAnswerDefaulted: Scalars["Boolean"]["output"];
    /** is this question answered */
    readonly isAnswered: Scalars["Boolean"]["output"];
    /** isFromOptions */
    readonly isFromOptions: Scalars["Boolean"]["output"];
    /** isPerPerson */
    readonly isPerPerson?: Maybe<Scalars["Boolean"]["output"]>;
    /** isRequired */
    readonly isRequired: Scalars["Boolean"]["output"];
    /** isSelectMultiple */
    readonly isSelectMultiple: Scalars["Boolean"]["output"];
    /** option label */
    readonly label?: Maybe<Scalars["String"]["output"]>;
    /** The question level */
    readonly level?: Maybe<Scalars["Int"]["output"]>;
    /** The ordinalPosition for the  Question */
    readonly ordinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** The partnerChannelId for the  Question */
    readonly partnerChannelId?: Maybe<Scalars["String"]["output"]>;
    /** The partnerChannelName for the  Question */
    readonly partnerChannelName?: Maybe<Scalars["String"]["output"]>;
    /** question placeholder */
    readonly placeholder?: Maybe<Scalars["String"]["output"]>;
    readonly questionType: BookingQuestionQuestionType;
    readonly synchronisationQuestionId?: Maybe<Scalars["String"]["output"]>;
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The type for the  Question */
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

/** Availability option list. */
export type BookingAvailabilityOptionList = {
    readonly __typename?: "BookingAvailabilityOptionList";
    readonly nodes: ReadonlyArray<BookingAvailabilityOption>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

/** This represents a Booking Person */
export type BookingAvailabilityPerson = {
    readonly __typename?: "BookingAvailabilityPerson";
    readonly baseId: Scalars["Hashids"]["output"];
    /** booking availability id */
    readonly bookingAvailabilityId?: Maybe<Scalars["String"]["output"]>;
    /** booking id */
    readonly bookingId?: Maybe<Scalars["String"]["output"]>;
    /** date and time the QR code was consumed */
    readonly consumedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** date this record was created */
    readonly createdAt: Scalars["Date"]["output"];
    /** The unique ID for the person */
    readonly id: Scalars["String"]["output"];
    readonly isConsumed: Scalars["Boolean"]["output"];
    /** questions */
    readonly isQuestionsComplete: Scalars["Boolean"]["output"];
    /** Pricing Category for this person. */
    readonly pricingCategory?: Maybe<PricingCategory>;
    readonly pricingCategoryId?: Maybe<Scalars["String"]["output"]>;
    readonly pricingCategoryKey?: Maybe<Scalars["String"]["output"]>;
    readonly pricingCategoryLabel?: Maybe<Scalars["String"]["output"]>;
    /** Person questions */
    readonly questionList: BookingAvailabilityPersonQuestionList;
    /** @deprecated Please use totalPrice in place. */
    readonly saleCurrency?: Maybe<Scalars["String"]["output"]>;
    /**
     * sale price currency symbol string
     * @deprecated Please use totalPrice in place.
     */
    readonly saleCurrencySymbol: Scalars["String"]["output"];
    /**
     * sale gross price
     * @deprecated Please use totalPrice in place.
     */
    readonly saleGrossPriceTotal: Scalars["Float"]["output"];
    /**
     * sale gross price as string
     * @deprecated Please use totalPrice in place.
     */
    readonly saleGrossPriceTotalFormattedText: Scalars["String"]["output"];
    /** total price for this availability. */
    readonly totalPrice: Price;
    /** date this record was last updated */
    readonly updatedAt: Scalars["Date"]["output"];
};

/** This represents a Booking Person */
export type BookingAvailabilityPersonExtended = {
    readonly __typename?: "BookingAvailabilityPersonExtended";
    readonly baseId: Scalars["Hashids"]["output"];
    /** booking availability id */
    readonly bookingAvailabilityId?: Maybe<Scalars["String"]["output"]>;
    /** booking id */
    readonly bookingId?: Maybe<Scalars["String"]["output"]>;
    /** date and time the QR code was consumed */
    readonly consumedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** date this record was created */
    readonly createdAt: Scalars["Date"]["output"];
    /** The unique ID for the person */
    readonly id: Scalars["String"]["output"];
    readonly isConsumed: Scalars["Boolean"]["output"];
    /** questions */
    readonly isQuestionsComplete: Scalars["Boolean"]["output"];
    /** Pricing Category for this person. */
    readonly pricingCategory?: Maybe<PricingCategory>;
    readonly pricingCategoryId?: Maybe<Scalars["String"]["output"]>;
    readonly pricingCategoryKey?: Maybe<Scalars["String"]["output"]>;
    readonly pricingCategoryLabel?: Maybe<Scalars["String"]["output"]>;
    /** Person questions */
    readonly questionList: BookingAvailabilityPersonQuestionList;
    /** @deprecated Please use totalPrice in place. */
    readonly saleCurrency?: Maybe<Scalars["String"]["output"]>;
    /**
     * sale price currency symbol string
     * @deprecated Please use totalPrice in place.
     */
    readonly saleCurrencySymbol: Scalars["String"]["output"];
    /**
     * sale gross price
     * @deprecated Please use totalPrice in place.
     */
    readonly saleGrossPriceTotal: Scalars["Float"]["output"];
    /**
     * sale gross price as string
     * @deprecated Please use totalPrice in place.
     */
    readonly saleGrossPriceTotalFormattedText: Scalars["String"]["output"];
    /** total price for this availability. */
    readonly totalPrice: Price;
    /** date this record was last updated */
    readonly updatedAt: Scalars["Date"]["output"];
};

/** This represents the bookingAvailabilityPersonList response type. */
export type BookingAvailabilityPersonExtendedList = {
    readonly __typename?: "BookingAvailabilityPersonExtendedList";
    readonly nodes: ReadonlyArray<BookingAvailabilityPersonExtended>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

/** This represents the bookingAvailabilityPersonList response type. */
export type BookingAvailabilityPersonList = {
    readonly __typename?: "BookingAvailabilityPersonList";
    readonly nodes: ReadonlyArray<BookingAvailabilityPerson>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type BookingAvailabilityPersonListFilter = {
    /** filter by bookingAvailabilityId */
    readonly bookingAvailabilityId: Scalars["String"]["input"];
    /** filter by pricingCategory */
    readonly pricingCategory?: InputMaybe<Scalars["String"]["input"]>;
};

export type BookingAvailabilityPersonListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

/** This represents a booking question */
export type BookingAvailabilityPersonQuestion = {
    readonly __typename?: "BookingAvailabilityPersonQuestion";
    readonly answerFile?: Maybe<Scalars["String"]["output"]>;
    readonly answerFileAsset?: Maybe<Asset>;
    /** The answer to this question formatted */
    readonly answerFormattedText?: Maybe<Scalars["String"]["output"]>;
    /** The answer to this question */
    readonly answerValue?: Maybe<Scalars["String"]["output"]>;
    /** The value that may be applied to the autocomplete attribute of a form input control */
    readonly autoCompleteValue?: Maybe<Scalars["String"]["output"]>;
    /** availableOptions */
    readonly availableOptions: ReadonlyArray<Maybe<AvailableOptions>>;
    /** The unique ID for the bookingAvailabilityId */
    readonly bookingAvailabilityId: Scalars["String"]["output"];
    /** The unique ID for the bookingAvailabilityId */
    readonly bookingAvailabilityPersonId: Scalars["String"]["output"];
    /** The unique ID for the bookingId */
    readonly bookingId: Scalars["String"]["output"];
    /** question dataFormat */
    readonly dataFormat?: Maybe<Scalars["String"]["output"]>;
    /** question dataPattern */
    readonly dataPattern?: Maybe<Scalars["String"]["output"]>;
    /** question dataType */
    readonly dataType?: Maybe<Scalars["String"]["output"]>;
    /** question defaultValue */
    readonly defaultValue?: Maybe<Scalars["String"]["output"]>;
    /** question flags */
    readonly flags?: Maybe<Scalars["String"]["output"]>;
    /** question helper */
    readonly helper?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID for the  Question */
    readonly id: Scalars["String"]["output"];
    /** Has this question's answer been defaulted. */
    readonly isAnswerDefaulted: Scalars["Boolean"]["output"];
    /** is this question answered */
    readonly isAnswered: Scalars["Boolean"]["output"];
    /** isFromOptions */
    readonly isFromOptions: Scalars["Boolean"]["output"];
    /** isPerPerson */
    readonly isPerPerson?: Maybe<Scalars["Boolean"]["output"]>;
    /** isRequired */
    readonly isRequired: Scalars["Boolean"]["output"];
    /** isSelectMultiple */
    readonly isSelectMultiple: Scalars["Boolean"]["output"];
    /** Question Label. */
    readonly label?: Maybe<Scalars["String"]["output"]>;
    /** The question level */
    readonly level?: Maybe<Scalars["Int"]["output"]>;
    /** The ordinalPosition for the  Question */
    readonly ordinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** The partnerChannelId for the  Question */
    readonly partnerChannelId?: Maybe<Scalars["String"]["output"]>;
    /** The partnerChannelName for the  Question */
    readonly partnerChannelName?: Maybe<Scalars["String"]["output"]>;
    /** question placeholder */
    readonly placeholder?: Maybe<Scalars["String"]["output"]>;
    readonly questionType: BookingQuestionQuestionType;
    readonly synchronisationQuestionId?: Maybe<Scalars["String"]["output"]>;
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The type for the  Question */
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

/** This represents the bookingAvailabilityPersonQuestionList response type. */
export type BookingAvailabilityPersonQuestionList = {
    readonly __typename?: "BookingAvailabilityPersonQuestionList";
    readonly nodes: ReadonlyArray<BookingAvailabilityPersonQuestion>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export enum BookingAvailabilityProcessState {
    Amended = "AMENDED",
    Cancelled = "CANCELLED",
    Confirmed = "CONFIRMED",
    Ended = "ENDED",
    Expired = "EXPIRED",
    Failed = "FAILED",
    Open = "OPEN",
    PendingAmendment = "PENDING_AMENDMENT",
    PendingCancellation = "PENDING_CANCELLATION",
    PendingConfirmation = "PENDING_CONFIRMATION",
    PendingConfirmed = "PENDING_CONFIRMED",
    Started = "STARTED",
    Succeeded = "SUCCEEDED",
}

/** This represents a booking question */
export type BookingAvailabilityQuestion = {
    readonly __typename?: "BookingAvailabilityQuestion";
    readonly answerFile?: Maybe<Scalars["String"]["output"]>;
    readonly answerFileAsset?: Maybe<Asset>;
    /** The answer to this question formatted */
    readonly answerFormattedText?: Maybe<Scalars["String"]["output"]>;
    /** The answer to this question */
    readonly answerValue?: Maybe<Scalars["String"]["output"]>;
    /** The value that may be applied to the autocomplete attribute of a form input control */
    readonly autoCompleteValue?: Maybe<Scalars["String"]["output"]>;
    /** availableOptions */
    readonly availableOptions: ReadonlyArray<Maybe<AvailableOptions>>;
    /** The unique ID for the bookingAvailabilityId */
    readonly bookingAvailabilityId: Scalars["String"]["output"];
    /** The unique ID for the bookingId */
    readonly bookingId: Scalars["String"]["output"];
    /** question dataFormat */
    readonly dataFormat?: Maybe<Scalars["String"]["output"]>;
    /** question dataPattern */
    readonly dataPattern?: Maybe<Scalars["String"]["output"]>;
    /** question dataType */
    readonly dataType?: Maybe<Scalars["String"]["output"]>;
    /** question defaultValue */
    readonly defaultValue?: Maybe<Scalars["String"]["output"]>;
    /** question flags */
    readonly flags?: Maybe<Scalars["String"]["output"]>;
    /** question helper */
    readonly helper?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID for the  Question */
    readonly id: Scalars["String"]["output"];
    /** Has this question's answer been defaulted. */
    readonly isAnswerDefaulted: Scalars["Boolean"]["output"];
    /** is this question answered */
    readonly isAnswered: Scalars["Boolean"]["output"];
    /** isFromOptions */
    readonly isFromOptions: Scalars["Boolean"]["output"];
    /** isPerPerson */
    readonly isPerPerson?: Maybe<Scalars["Boolean"]["output"]>;
    /** isRequired */
    readonly isRequired: Scalars["Boolean"]["output"];
    /** isSelectMultiple */
    readonly isSelectMultiple: Scalars["Boolean"]["output"];
    /** Question Label. */
    readonly label?: Maybe<Scalars["String"]["output"]>;
    /** The question level */
    readonly level?: Maybe<Scalars["Int"]["output"]>;
    /** The ordinalPosition for the  Question */
    readonly ordinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** The partnerChannelId for the  Question */
    readonly partnerChannelId?: Maybe<Scalars["String"]["output"]>;
    /** The partnerChannelName for the  Question */
    readonly partnerChannelName?: Maybe<Scalars["String"]["output"]>;
    /** question placeholder */
    readonly placeholder?: Maybe<Scalars["String"]["output"]>;
    readonly questionType: BookingQuestionQuestionType;
    readonly synchronisationQuestionId?: Maybe<Scalars["String"]["output"]>;
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The type for the  Question */
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

/** This represents the bookingAvailabilityQuestionList response type. */
export type BookingAvailabilityQuestionList = {
    readonly __typename?: "BookingAvailabilityQuestionList";
    readonly nodes: ReadonlyArray<BookingAvailabilityQuestion>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

/** Booking state */
export enum BookingAvailabilityRefreshStatus {
    /** Error during refreshing. */
    Error = "ERROR",
    /** Availability has not changed. */
    NoChange = "NO_CHANGE",
    /** Options have changes. */
    OptionsChanged = "OPTIONS_CHANGED",
    /**
     * Price has changed.
     * @deprecated PRICE_INCREASED or PRICE_DECREASED.
     */
    PriceChanged = "PRICE_CHANGED",
    /** Price has decreased. */
    PriceDecreased = "PRICE_DECREASED",
    /** Price has increased. */
    PriceIncreased = "PRICE_INCREASED",
    /** Pricing categories have changed. */
    PricingCategoriesChanged = "PRICING_CATEGORIES_CHANGED",
    /**
     * Currency that this product was booked in has changed.
     * @deprecated This is no longer possible.
     */
    SaleCurrencyChanged = "SALE_CURRENCY_CHANGED",
    /** Availability is no longer available. */
    SoldOut = "SOLD_OUT",
    /**
     * Successfully refreshed.
     * @deprecated Changed to NO_CHANGE.
     */
    Success = "SUCCESS",
}

export type BookingAvailabilitySimple = {
    readonly __typename?: "BookingAvailabilitySimple";
    readonly bookingCode: Scalars["String"]["output"];
    readonly bookingId: Scalars["String"]["output"];
    readonly bookingPartnerExternalReference?: Maybe<Scalars["String"]["output"]>;
    readonly code: Scalars["String"]["output"];
    readonly createdAt: Scalars["DateTime"]["output"];
    /** @deprecated Date is ambiguous. Use startAt for UTC start date. */
    readonly date?: Maybe<Scalars["Date"]["output"]>;
    /** End date time of booking availability. */
    readonly endAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly id: Scalars["String"]["output"];
    /** Availability options */
    readonly optionList: BookingAvailabilityOptionList;
    /** Origin booking availability id used to creating this booking availability. */
    readonly originBookingAvailabilityId?: Maybe<Scalars["String"]["output"]>;
    readonly paymentIntentId?: Maybe<Scalars["String"]["output"]>;
    readonly paymentState: Scalars["String"]["output"];
    /** Booking Availability Person */
    readonly personList: BookingAvailabilityPersonList;
    /** Represents the current state of the booking availability lifecycle. */
    readonly processState: BookingAvailabilityProcessState;
    readonly productId: Scalars["String"]["output"];
    readonly productName: Scalars["String"]["output"];
    readonly refreshStatus?: Maybe<BookingAvailabilityRefreshStatus>;
    /** Start date time of booking availability. */
    readonly startAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly state: BookingAvailabilityStateType;
    readonly timeZone: Scalars["String"]["output"];
    /** total price for this availability. */
    readonly totalPrice: Price;
    readonly type: BookingAvailabilityType;
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type BookingAvailabilitySimplePersonListArgs = {
    filter?: InputMaybe<BookingAvailabilityPersonListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<BookingAvailabilityPersonListSort>;
};

/** Booking state */
export enum BookingAvailabilityStateType {
    /** Booking Availability has been successfully amended. */
    Amended = "AMENDED",
    /** Booking Availability has failed. */
    Cancelled = "CANCELLED",
    /** Booking Availability has been confirmed by supplier */
    Confirmed = "CONFIRMED",
    /** Booking availability activity has ended. */
    Ended = "ENDED",
    /** Booking Availability has expired. */
    Expired = "EXPIRED",
    /** Booking Availability has failed. */
    Failed = "FAILED",
    /** Booking Availability is open */
    Open = "OPEN",
    /**
     * Booking Availability is awaiting payment.
     * @deprecated Not in use. Payments are processed on the Booking level.
     */
    Payment = "PAYMENT",
    /**
     * Booking Availability is awaiting confirmation by supplier
     * @deprecated Use PENDING_CONFIRMATION instead
     */
    Pending = "PENDING",
    /** Booking Availability is awaiting to be amended */
    PendingAmendment = "PENDING_AMENDMENT",
    /** Booking Availability is awaiting to be cancelled */
    PendingCancellation = "PENDING_CANCELLATION",
    /** Booking Availability is awaiting confirmation by supplier */
    PendingConfirmation = "PENDING_CONFIRMATION",
    /** Booking Availability has been confirmed, but additional actions may be required (e.g. obtaining tickets). */
    PendingConfirmed = "PENDING_CONFIRMED",
    /** Indicates the state when the activity has started. No cancellation or amendment is possible at this point. */
    Started = "STARTED",
    /** Booking Availability has been successfully completed. */
    Succeeded = "SUCCEEDED",
}

export enum BookingAvailabilityType {
    /** Full date availability that does not have specific start time. */
    Date = "DATE",
    /** Availability that starts at specific time and date. */
    DateTime = "DATE_TIME",
    /** Availability that does not have the specific date. */
    Pass = "PASS",
}

export type BookingCancelInput = {
    /** the booking id */
    readonly id: Scalars["String"]["input"];
    /** reason for cancelling booking */
    readonly reason: Scalars["String"]["input"];
};

/** This represents a booking contact. */
export type BookingContact = {
    readonly __typename?: "BookingContact";
    /** Tje contact email address. */
    readonly email: Scalars["String"]["output"];
    /** The contact name. */
    readonly name: Scalars["String"]["output"];
    /** The contact phone number. */
    readonly phone: Scalars["String"]["output"];
};

export type BookingCreateInput = {
    /** Let system attempt to auto fill questions */
    readonly autoFillQuestions?: InputMaybe<Scalars["Boolean"]["input"]>;
    readonly consumerTrip?: InputMaybe<ConsumerTripInput>;
    /** Lead person name */
    readonly leadPassengerName?: InputMaybe<Scalars["String"]["input"]>;
    /** booking name */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** reference */
    readonly reference?: InputMaybe<Scalars["String"]["input"]>;
};

export type BookingDeleteAvailabilityInputType = {
    /** @deprecated DEPRECATED - not required. */
    readonly bookingSelector?: InputMaybe<BookingSelector>;
    /** Deleted Booking availability id. */
    readonly id: Scalars["String"]["input"];
};

export type BookingEditInput = {
    /** booking id */
    readonly bookingSelector: BookingSelector;
    readonly consumerTrip?: InputMaybe<ConsumerTripInput>;
    /** isSandboxed */
    readonly isSandboxed?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** person name */
    readonly leadPassengerName?: InputMaybe<Scalars["String"]["input"]>;
    /** booking name */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** reference */
    readonly reference?: InputMaybe<Scalars["String"]["input"]>;
    /** booking state */
    readonly state?: InputMaybe<BookingStateType>;
};

export type BookingEventsFilter = {
    /** Filter by the bookingId. */
    readonly bookingId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by the levels of the log. ie (INFO, DEBUG, ERROR) */
    readonly levels?: InputMaybe<ReadonlyArray<SystemLogLevel>>;
    /** Filter by the contents of the message. */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by the number of spanType to be added. */
    readonly spanDuration?: InputMaybe<SystemLogSpanDuration>;
    /** Filter by the type of spanDuration to be added (D,M,Y). */
    readonly spanType?: InputMaybe<SystemLogSpanType>;
    /** Filter by the Date form which the logs should be scanned. Default to current UTC hour */
    readonly startDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type BookingFinance = {
    readonly __typename?: "BookingFinance";
    readonly baseCurrency: Scalars["String"]["output"];
    readonly baseGrossProfit: Scalars["Float"]["output"];
    readonly baseGrossRoundingAmount: Scalars["Float"]["output"];
    readonly baseHolibobPurchaseCommissionAmount: Scalars["Float"]["output"];
    readonly baseMarginAmount: Scalars["Float"]["output"];
    readonly baseNetRoundingAmount: Scalars["Float"]["output"];
    readonly basePartnerChannelCommissionAmount: Scalars["Float"]["output"];
    readonly basePurchaseCommissionAmount: Scalars["Float"]["output"];
    readonly basePurchaseGrossPrice: Scalars["Float"]["output"];
    readonly basePurchaseNetPrice: Scalars["Float"]["output"];
    readonly baseSaleGrossPrice: Scalars["Float"]["output"];
    readonly baseSaleNetPrice: Scalars["Float"]["output"];
    readonly baseToPartnerChannelCurrencyRate: Scalars["Float"]["output"];
    readonly baseToSaleCurrencyRate: Scalars["Float"]["output"];
    readonly bookingAvailabilityId: Scalars["String"]["output"];
    readonly financeType: BookingFinanceType;
    readonly holibobGrossIncome: Scalars["Float"]["output"];
    readonly partnerChannelCurrency: Scalars["String"]["output"];
    readonly partnerCommissionAmount: Scalars["Float"]["output"];
    readonly partnerHolibobCommissionAmount: Scalars["Float"]["output"];
    readonly partnerMarginAmount: Scalars["Float"]["output"];
    readonly partnerNetAmount: Scalars["Float"]["output"];
    readonly partnerSwitchAmount: Scalars["Float"]["output"];
    readonly partnerSwitchFee: Scalars["Float"]["output"];
    readonly partnerTransactionAmount: Scalars["Float"]["output"];
    readonly partnerTransactionCurrency: Scalars["String"]["output"];
    readonly purchaseCommissionRate: Scalars["Float"]["output"];
    readonly purchaseCurrency: Scalars["String"]["output"];
    readonly purchaseGrossPrice: Scalars["Float"]["output"];
    readonly purchaseNetMarginRate: Scalars["Float"]["output"];
    readonly purchaseNetPrice: Scalars["Float"]["output"];
    readonly purchaseToBaseCurrencyRate: Scalars["Float"]["output"];
    readonly saleCommissionAmount: Scalars["Float"]["output"];
    readonly saleCurrency: Scalars["String"]["output"];
    readonly saleGrossPrice: Scalars["Float"]["output"];
    readonly saleGrossPriceRounded: Scalars["Float"]["output"];
    readonly saleNetPrice: Scalars["Float"]["output"];
    readonly saleNetPriceRounded: Scalars["Float"]["output"];
    readonly saleToPartnerTransactionCurrencyRate: Scalars["Float"]["output"];
    readonly supplierTransactionAmount: Scalars["Float"]["output"];
};

export type BookingFinanceListFilter = {
    readonly bookingAvailabilityIdList?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** This respresents the BookingFinanceList response type */
export type BookingFinanceListResponse = {
    readonly __typename?: "BookingFinanceListResponse";
    readonly nodes: ReadonlyArray<BookingFinance>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export enum BookingFinanceType {
    BookingAvailabilityFinance = "BookingAvailabilityFinance",
    BookingAvailabilityPersonFinance = "BookingAvailabilityPersonFinance",
}

export type BookingInfoSummary = {
    readonly __typename?: "BookingInfoSummary";
    /** The code for this booking. */
    readonly code: Scalars["String"]["output"];
    /** The unique ID for the available trip. */
    readonly id: Scalars["String"]["output"];
    /** Lead person name. */
    readonly leadPassengerName?: Maybe<Scalars["String"]["output"]>;
    /** The reference passed in to this booking when it was created by the partner. */
    readonly partnerExternalReference?: Maybe<Scalars["String"]["output"]>;
    /** The state of this booking. */
    readonly state: BookingStateType;
};

export type BookingInput = {
    /** Booking question answers */
    readonly answerList?: InputMaybe<ReadonlyArray<InputMaybe<BookingAnswerInput>>>;
    /** person name */
    readonly leadPassengerName?: InputMaybe<Scalars["String"]["input"]>;
    /** reference */
    readonly reference?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents the bookingList response type */
export type BookingList = {
    readonly __typename?: "BookingList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<BookingListItem>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    readonly reportUrl?: Maybe<Scalars["String"]["output"]>;
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

/** This represents the bookingList response type */
export type BookingListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** This represents the bookingList response type */
export type BookingListTotalArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type BookingListFilter = {
    /** filter by bookingOrigin */
    readonly bookingOrigin?: InputMaybe<Scalars["String"]["input"]>;
    /** filter on cancelledAt date range */
    readonly cancelledWithin?: InputMaybe<DateTimeRangeInput>;
    /** filter by booking code */
    readonly code?: InputMaybe<Scalars["String"]["input"]>;
    /** filter on confirmedAt date range */
    readonly confirmedWithin?: InputMaybe<DateTimeRangeInput>;
    readonly consumerExternalReference?: InputMaybe<Scalars["String"]["input"]>;
    readonly consumerId?: InputMaybe<Scalars["String"]["input"]>;
    readonly consumerTripExternalReference?: InputMaybe<Scalars["String"]["input"]>;
    readonly consumerTripId?: InputMaybe<Scalars["String"]["input"]>;
    /** filter on createdAt minimum value */
    readonly createdSince?: InputMaybe<Scalars["Date"]["input"]>;
    /** filter on createdAt maximum value */
    readonly createdTill?: InputMaybe<Scalars["Date"]["input"]>;
    /** filter on isSandboxed */
    readonly isSandboxed?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** filter on partnerChannelId */
    readonly partnerChannelId?: InputMaybe<Scalars["String"]["input"]>;
    /** filter by the reference passed into this booking by the partner */
    readonly partnerExternalReference?: InputMaybe<Scalars["String"]["input"]>;
    /** filter on partnerId */
    readonly partnerId?: InputMaybe<Scalars["String"]["input"]>;
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    /** Search string */
    readonly state?: InputMaybe<BookingStateType>;
    /** Filter by a list of states */
    readonly states?: InputMaybe<ReadonlyArray<InputMaybe<BookingStateType>>>;
    /** filter on supplierId */
    readonly supplierId?: InputMaybe<Scalars["String"]["input"]>;
    /** filter on synchronisationSource */
    readonly synchronisationSourceId?: InputMaybe<Scalars["String"]["input"]>;
    /** Synchronisation Source string */
    readonly synchronisationSourceName?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a Booking */
export type BookingListItem = {
    readonly __typename?: "BookingListItem";
    /** The application that made the booking */
    readonly application?: Maybe<Scalars["String"]["output"]>;
    readonly availabilityList: BookingAvailabilityList;
    /** The URL to manage this booking */
    readonly bookingUrl: Scalars["String"]["output"];
    /** Can all availabilities be cancelled. */
    readonly canCancel?: Maybe<Scalars["Boolean"]["output"]>;
    /** have all required questions been completed, payment taken and has not already been committed. */
    readonly canCommit?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * have all required questions have been completed
     * @deprecated DEPREACTED - Use canCommit instead.
     */
    readonly canSubmit?: Maybe<Scalars["Boolean"]["output"]>;
    /** The price amount that would be returned if cancelled */
    readonly cancellationEffectiveRefundAmount?: Maybe<BasicPrice>;
    /** The securityPrincipalUser of cancellation initialiser */
    readonly cancellationInitialisedBySecurityPrincipalUser?: Maybe<SecurityPrincipalUser>;
    /** The date at which this booking was cancelled. */
    readonly cancelledAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The code for this booking. */
    readonly code: Scalars["String"]["output"];
    /** The securityPrincipalUser of confirmation initialiser */
    readonly confirmationInitialisedBySecurityPrincipalUser?: Maybe<SecurityPrincipalUser>;
    /** The date at which this booking was confirmed. */
    readonly confirmedAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly consumerTrip?: Maybe<ConsumerTripBasicTc>;
    /**
     * The URL that a consumer should be directed to in order to manage this booking.
     * @deprecated Please use bookingUrl in place.
     */
    readonly consumerUrl: Scalars["String"]["output"];
    /** Contact details for the booking. */
    readonly contact?: Maybe<BookingContact>;
    /** The date at which this booking was created. */
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The date at which the summary email was sent. */
    readonly emailSummarySentAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** Date of first availability. */
    readonly firstAvailabilityDate?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID for the available trip. */
    readonly id: Scalars["String"]["output"];
    /** Is booking complete. */
    readonly isComplete: Scalars["Boolean"]["output"];
    /** Indicates if the booking or any related booking availabilities have failed. */
    readonly isFailed: Scalars["Boolean"]["output"];
    readonly isPendingCommit?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is booking processing. */
    readonly isProcessing?: Maybe<Scalars["Boolean"]["output"]>;
    /** have all required questions have been completed. */
    readonly isQuestionsComplete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is booking sandboxed. */
    readonly isSandboxed: Scalars["Boolean"]["output"];
    /** Returns true if the Booking Lifecycle Manager is currently processing changes. */
    readonly isWorkflowProcessing: Scalars["Boolean"]["output"];
    /** Content language code */
    readonly languageCode: LanguageCode;
    /** The language id the booking was initially made in */
    readonly languageId: Scalars["String"]["output"];
    /** Lead person name. */
    readonly leadPassengerName?: Maybe<Scalars["String"]["output"]>;
    /** Name. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    readonly outstandingConsumerAmount: BasicPrice;
    readonly owningSecurityPrincipal?: Maybe<SecurityPrincipal>;
    readonly owningSecurityPrincipalUser?: Maybe<SecurityPrincipalUser>;
    /**
     * The name of the partner agent that created this booking.
     * @deprecated Use owningSecurityPrincipalUser instead
     */
    readonly partnerAgentName?: Maybe<Scalars["String"]["output"]>;
    /** The URL to the root of the channels booking site. */
    readonly partnerChannelBookingUrl?: Maybe<Scalars["String"]["output"]>;
    /** The partnerChannel id for this booking. */
    readonly partnerChannelId?: Maybe<Scalars["String"]["output"]>;
    /** The name of the partnerChannel that this booking is attributed to. */
    readonly partnerChannelName?: Maybe<Scalars["String"]["output"]>;
    /** The reference passed in to this booking when it was created by the partner. */
    readonly partnerExternalReference?: Maybe<Scalars["String"]["output"]>;
    /** The partner id for this booking. */
    readonly partnerId?: Maybe<Scalars["String"]["output"]>;
    /** The name of the partner that this booking is attributed to (via channel). */
    readonly partnerName?: Maybe<Scalars["String"]["output"]>;
    /** The payment state of this booking. */
    readonly paymentState?: Maybe<BookingPaymentStateType>;
    readonly paymentType: BookingPaymentType;
    /** Represents the current state of the booking lifecycle. */
    readonly processState: BookingProcessState;
    /** questions. */
    readonly questionList: BookingQuestionList;
    /**
     * The partner external reference for this booking.
     * @deprecated Please use partnerExternalReference instead.
     */
    readonly reference?: Maybe<Scalars["String"]["output"]>;
    /** sale price as string. */
    readonly saleCurrency?: Maybe<Scalars["String"]["output"]>;
    /**
     * sale currency symbol.
     * @deprecated Please use totalPrice in place.
     */
    readonly saleCurrencySymbol?: Maybe<Scalars["String"]["output"]>;
    /**
     * sale gross price total.
     * @deprecated Please use totalPrice in place.
     */
    readonly saleGrossPriceTotal?: Maybe<Scalars["Float"]["output"]>;
    /**
     * sale gross price total as a formatted string.
     * @deprecated Please use totalPrice in place.
     */
    readonly saleGrossPriceTotalFormattedText?: Maybe<Scalars["String"]["output"]>;
    /**
     * sale gross price total as a formatted string.
     * @deprecated Please use totalPrice in place.
     */
    readonly saleGrossPriceTotalText?: Maybe<Scalars["String"]["output"]>;
    /**
     * sale net price total.
     * @deprecated Please use totalPrice in place.
     */
    readonly saleNetPriceTotal?: Maybe<Scalars["Float"]["output"]>;
    /**
     * sale net price total as a formatted string.
     * @deprecated Please use totalPrice in place.
     */
    readonly saleNetPriceTotalFormattedText?: Maybe<Scalars["String"]["output"]>;
    /** The state of this booking. */
    readonly state: BookingStateType;
    /** The Terms and Conditions related to the booking */
    readonly termAndCondition?: Maybe<Scalars["String"]["output"]>;
    /** total price for this booking. */
    readonly totalPrice: Price;
    /** Does booking use life cycle manager */
    readonly useLifecycleManager: Scalars["Boolean"]["output"];
    readonly voucherCreatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The url from which the booking voucher may be downloaded - Note the URL will be valid for 10 minutes from time of request. */
    readonly voucherUrl?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Use emailSummarySentAt or id instead */
    readonly workflowContextData?: Maybe<WorkflowContextData>;
};

export type BookingListSort = {
    /** Sort by leadPassengerName */
    readonly LeadPassengerName?: InputMaybe<SortDirection>;
};

export type BookingPaymentIntentCancelInput = {
    /** the booking id */
    readonly bookingId: Scalars["String"]["input"];
};

/** Booking payment state. */
export enum BookingPaymentStateType {
    /** Booking will be charged to account once committed. */
    OnAccount = "ON_ACCOUNT",
    /** Booking will be charged to account once committed. */
    OnRecord = "ON_RECORD",
    /** Booking has been paid for. */
    Paid = "PAID",
    /** Payment details/intent saved and awaiting funds capture. */
    Pending = "PENDING",
    /** Payment is required prior to this booking being commited. */
    Required = "REQUIRED",
}

export enum BookingPaymentType {
    Intent = "INTENT",
    OnAccount = "ON_ACCOUNT",
    OnRecord = "ON_RECORD",
}

/** This represents a Booking */
export type BookingPrivate = {
    readonly __typename?: "BookingPrivate";
    /** The application that made the booking */
    readonly application?: Maybe<Scalars["String"]["output"]>;
    readonly availabilityList: BookingAvailabilityList;
    /** The URL to manage this booking */
    readonly bookingUrl: Scalars["String"]["output"];
    /** Can all availabilities be cancelled. */
    readonly canCancel?: Maybe<Scalars["Boolean"]["output"]>;
    /** have all required questions been completed, payment taken and has not already been committed. */
    readonly canCommit?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * have all required questions have been completed
     * @deprecated DEPREACTED - Use canCommit instead.
     */
    readonly canSubmit?: Maybe<Scalars["Boolean"]["output"]>;
    /** The price amount that would be returned if cancelled */
    readonly cancellationEffectiveRefundAmount?: Maybe<BasicPrice>;
    /** The securityPrincipalUser of cancellation initialiser */
    readonly cancellationInitialisedBySecurityPrincipalUser?: Maybe<SecurityPrincipalUser>;
    /** The date at which this booking was cancelled. */
    readonly cancelledAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The code for this booking. */
    readonly code: Scalars["String"]["output"];
    /** The securityPrincipalUser of confirmation initialiser */
    readonly confirmationInitialisedBySecurityPrincipalUser?: Maybe<SecurityPrincipalUser>;
    /** The date at which this booking was confirmed. */
    readonly confirmedAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly consumerTrip?: Maybe<ConsumerTripBasicTc>;
    /**
     * The URL that a consumer should be directed to in order to manage this booking.
     * @deprecated Please use bookingUrl in place.
     */
    readonly consumerUrl: Scalars["String"]["output"];
    /** Contact details for the booking. */
    readonly contact?: Maybe<BookingContact>;
    /** The date at which this booking was created. */
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The date at which the summary email was sent. */
    readonly emailSummarySentAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** Date of first availability. */
    readonly firstAvailabilityDate?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID for the available trip. */
    readonly id: Scalars["String"]["output"];
    /** Is booking complete. */
    readonly isComplete: Scalars["Boolean"]["output"];
    /** Indicates if the booking or any related booking availabilities have failed. */
    readonly isFailed: Scalars["Boolean"]["output"];
    readonly isPendingCommit?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is booking processing. */
    readonly isProcessing?: Maybe<Scalars["Boolean"]["output"]>;
    /** have all required questions have been completed. */
    readonly isQuestionsComplete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is booking sandboxed. */
    readonly isSandboxed: Scalars["Boolean"]["output"];
    /** Returns true if the Booking Lifecycle Manager is currently processing changes. */
    readonly isWorkflowProcessing: Scalars["Boolean"]["output"];
    /** Content language code */
    readonly languageCode: LanguageCode;
    /** The language id the booking was initially made in */
    readonly languageId: Scalars["String"]["output"];
    /** Lead person name. */
    readonly leadPassengerName?: Maybe<Scalars["String"]["output"]>;
    /** Name. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    readonly outstandingConsumerAmount: BasicPrice;
    readonly owningSecurityPrincipal?: Maybe<SecurityPrincipal>;
    readonly owningSecurityPrincipalUser?: Maybe<SecurityPrincipalUser>;
    /**
     * The name of the partner agent that created this booking.
     * @deprecated Use owningSecurityPrincipalUser instead
     */
    readonly partnerAgentName?: Maybe<Scalars["String"]["output"]>;
    /** The URL to the root of the channels booking site. */
    readonly partnerChannelBookingUrl?: Maybe<Scalars["String"]["output"]>;
    /** The partnerChannel id for this booking. */
    readonly partnerChannelId?: Maybe<Scalars["String"]["output"]>;
    /** The name of the partnerChannel that this booking is attributed to. */
    readonly partnerChannelName?: Maybe<Scalars["String"]["output"]>;
    /** The reference passed in to this booking when it was created by the partner. */
    readonly partnerExternalReference?: Maybe<Scalars["String"]["output"]>;
    /** The partner id for this booking. */
    readonly partnerId?: Maybe<Scalars["String"]["output"]>;
    /** The name of the partner that this booking is attributed to (via channel). */
    readonly partnerName?: Maybe<Scalars["String"]["output"]>;
    /** The payment state of this booking. */
    readonly paymentState?: Maybe<BookingPaymentStateType>;
    readonly paymentType: BookingPaymentType;
    /** Represents the current state of the booking lifecycle. */
    readonly processState: BookingProcessState;
    /** questions. */
    readonly questionList: BookingQuestionList;
    /**
     * The partner external reference for this booking.
     * @deprecated Please use partnerExternalReference instead.
     */
    readonly reference?: Maybe<Scalars["String"]["output"]>;
    /** sale price as string. */
    readonly saleCurrency?: Maybe<Scalars["String"]["output"]>;
    /**
     * sale currency symbol.
     * @deprecated Please use totalPrice in place.
     */
    readonly saleCurrencySymbol?: Maybe<Scalars["String"]["output"]>;
    /**
     * sale gross price total.
     * @deprecated Please use totalPrice in place.
     */
    readonly saleGrossPriceTotal?: Maybe<Scalars["Float"]["output"]>;
    /**
     * sale gross price total as a formatted string.
     * @deprecated Please use totalPrice in place.
     */
    readonly saleGrossPriceTotalFormattedText?: Maybe<Scalars["String"]["output"]>;
    /**
     * sale gross price total as a formatted string.
     * @deprecated Please use totalPrice in place.
     */
    readonly saleGrossPriceTotalText?: Maybe<Scalars["String"]["output"]>;
    /**
     * sale net price total.
     * @deprecated Please use totalPrice in place.
     */
    readonly saleNetPriceTotal?: Maybe<Scalars["Float"]["output"]>;
    /**
     * sale net price total as a formatted string.
     * @deprecated Please use totalPrice in place.
     */
    readonly saleNetPriceTotalFormattedText?: Maybe<Scalars["String"]["output"]>;
    /** The state of this booking. */
    readonly state: BookingStateType;
    readonly stripePaymentIntent?: Maybe<StripePaymentIntent>;
    /** The Terms and Conditions related to the booking */
    readonly termAndCondition?: Maybe<Scalars["String"]["output"]>;
    /** total price for this booking. */
    readonly totalPrice: Price;
    /** Does booking use life cycle manager */
    readonly useLifecycleManager: Scalars["Boolean"]["output"];
    readonly voucherCreatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The url from which the booking voucher may be downloaded - Note the URL will be valid for 10 minutes from time of request. */
    readonly voucherUrl?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Use emailSummarySentAt or id instead */
    readonly workflowContextData?: Maybe<WorkflowContextData>;
};

export enum BookingProcessState {
    Closed = "CLOSED",
    Failed = "FAILED",
    Open = "OPEN",
}

/** This represents a booking question */
export type BookingQuestion = {
    readonly __typename?: "BookingQuestion";
    readonly answerFile?: Maybe<Scalars["String"]["output"]>;
    readonly answerFileAsset?: Maybe<Asset>;
    /** The answer to this question formatted */
    readonly answerFormattedText?: Maybe<Scalars["String"]["output"]>;
    /** The answer to this question */
    readonly answerValue?: Maybe<Scalars["String"]["output"]>;
    /** The value that may be applied to the autocomplete attribute of a form input control */
    readonly autoCompleteValue?: Maybe<Scalars["String"]["output"]>;
    /** availableOptions */
    readonly availableOptions: ReadonlyArray<Maybe<AvailableOptions>>;
    /** The unique ID for the bookingId */
    readonly bookingId: Scalars["String"]["output"];
    /** question dataFormat */
    readonly dataFormat?: Maybe<Scalars["String"]["output"]>;
    /** question dataPattern */
    readonly dataPattern?: Maybe<Scalars["String"]["output"]>;
    /** question dataType */
    readonly dataType?: Maybe<Scalars["String"]["output"]>;
    /** question defaultValue */
    readonly defaultValue?: Maybe<Scalars["String"]["output"]>;
    /** question flags */
    readonly flags?: Maybe<Scalars["String"]["output"]>;
    /** question helper */
    readonly helper?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID for the  Question */
    readonly id: Scalars["String"]["output"];
    /** Has this question's answer been defaulted. */
    readonly isAnswerDefaulted: Scalars["Boolean"]["output"];
    /** is this question answered */
    readonly isAnswered: Scalars["Boolean"]["output"];
    /** isFromOptions */
    readonly isFromOptions: Scalars["Boolean"]["output"];
    /** isPerPerson */
    readonly isPerPerson?: Maybe<Scalars["Boolean"]["output"]>;
    /** isRequired */
    readonly isRequired: Scalars["Boolean"]["output"];
    /** isSelectMultiple */
    readonly isSelectMultiple: Scalars["Boolean"]["output"];
    /** Question Label. */
    readonly label?: Maybe<Scalars["String"]["output"]>;
    /** The question level */
    readonly level?: Maybe<Scalars["Int"]["output"]>;
    /** The ordinalPosition for the  Question */
    readonly ordinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** The partnerChannelId for the  Question */
    readonly partnerChannelId?: Maybe<Scalars["String"]["output"]>;
    /** The partnerChannelName for the  Question */
    readonly partnerChannelName?: Maybe<Scalars["String"]["output"]>;
    /** question placeholder */
    readonly placeholder?: Maybe<Scalars["String"]["output"]>;
    readonly questionType: BookingQuestionQuestionType;
    readonly synchronisationQuestionId?: Maybe<Scalars["String"]["output"]>;
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The type for the  Question */
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

/** This represents the bookingQuestionList response type. */
export type BookingQuestionList = {
    readonly __typename?: "BookingQuestionList";
    readonly nodes: ReadonlyArray<BookingQuestion>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export enum BookingQuestionQuestionType {
    BookingAvailabilityPersonQuestion = "BookingAvailabilityPersonQuestion",
    BookingAvailabilityQuestion = "BookingAvailabilityQuestion",
    BookingQuestion = "BookingQuestion",
}

export type BookingQuestionUpload = {
    readonly __typename?: "BookingQuestionUpload";
    readonly asset: Asset;
    readonly uploadUrl: Scalars["String"]["output"];
};

/** This represents a Booking selector */
export type BookingSelector = {
    /** booking code */
    readonly code?: InputMaybe<Scalars["String"]["input"]>;
    /** booking id */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** booking reference */
    readonly reference?: InputMaybe<Scalars["String"]["input"]>;
};

/** Booking state */
export enum BookingStateType {
    /**
     * Booking has been cancelled
     * @deprecated No longer in use. Cancellation is happening on Booking Availability level
     */
    Cancelled = "CANCELLED",
    /** All booking availabilities have been completed or failed */
    Closed = "CLOSED",
    /**
     * Booking has been confirmed by supplier
     * @deprecated No longer in use. Confirmation is happening on Booking Availability level
     */
    Confirmed = "CONFIRMED",
    /**
     * Booking has expired
     * @deprecated No longer in use. Expiration is happening on Booking Availability level
     */
    Expired = "EXPIRED",
    /** Booking has failed */
    Failed = "FAILED",
    /** Booking is open and awaiting for availabilities to end, cancel or fail */
    Open = "OPEN",
    /**
     * Booking is awaiting payment.
     * @deprecated No longer in use. Payments are processed internally by Booking Lifecycle Manager
     */
    Payment = "PAYMENT",
    /** No longer in use. Pending activities are happening on Booking Availability level. */
    Pending = "PENDING",
}

export type BookingVoucher = {
    readonly __typename?: "BookingVoucher";
    readonly availabilityTicketAttachmentList?: Maybe<BookingVoucherAvailabilityTicketAttachmentList>;
    readonly booking: Booking;
    readonly brand: Brand;
    readonly contactAddress?: Maybe<Scalars["String"]["output"]>;
    readonly contactEmail?: Maybe<Scalars["String"]["output"]>;
    readonly contactName?: Maybe<Scalars["String"]["output"]>;
    readonly contactPhone?: Maybe<Scalars["String"]["output"]>;
    readonly includeSummary?: Maybe<Scalars["Boolean"]["output"]>;
    readonly url?: Maybe<Scalars["String"]["output"]>;
};

export type BookingVoucherAvailabilityTicketAttachment = {
    readonly __typename?: "BookingVoucherAvailabilityTicketAttachment";
    readonly id: Scalars["String"]["output"];
    readonly s3Key: Scalars["String"]["output"];
    readonly url: Scalars["String"]["output"];
};

export type BookingVoucherAvailabilityTicketAttachmentList = {
    readonly __typename?: "BookingVoucherAvailabilityTicketAttachmentList";
    readonly nodes?: Maybe<ReadonlyArray<Maybe<BookingVoucherAvailabilityTicketAttachment>>>;
    readonly total: Scalars["Int"]["output"];
};

export type Brand = {
    readonly __typename?: "Brand";
    readonly airiness: Scalars["NonNegativeInt"]["output"];
    readonly createdAt: Scalars["Date"]["output"];
    readonly description?: Maybe<Scalars["String"]["output"]>;
    readonly entitySystemConfigurationAssociationList?: Maybe<EntitySystemConfigurationAssociationList>;
    readonly faviconImage?: Maybe<Asset>;
    readonly footerColour: BrandColourPreset;
    readonly footerLogo: Asset;
    readonly headerColour: BrandColourPreset;
    readonly headerContrastingLogo?: Maybe<Asset>;
    readonly headerLogo: Asset;
    readonly headerLogoPaddingBottom?: Maybe<Scalars["Int"]["output"]>;
    readonly headerLogoPaddingTop?: Maybe<Scalars["Int"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    /** Is the brand being used by multiple DistributionChannels */
    readonly isSharedBrand: Scalars["Boolean"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly openGraphImage?: Maybe<Asset>;
    readonly primaryButtonColour: BrandColourPreset;
    readonly primaryColour: BrandColourPreset;
    readonly primaryFont?: Maybe<Scalars["String"]["output"]>;
    readonly roundness: Scalars["NonNegativeInt"]["output"];
    readonly searchPlaceHolder?: Maybe<Scalars["String"]["output"]>;
    readonly secondaryButtonColour: BrandColourPreset;
    readonly secondaryColour: BrandColourPreset;
    readonly specialFont?: Maybe<Font>;
    readonly spotColours: ReadonlyArray<BrandSpotColour>;
    readonly updatedAt?: Maybe<Scalars["Date"]["output"]>;
    readonly useToursAndExperiences: Scalars["Boolean"]["output"];
    readonly voucherLogo?: Maybe<Asset>;
};

export type BrandColourPreset = {
    readonly __typename?: "BrandColourPreset";
    readonly contrast: Scalars["HexColorCode"]["output"];
    readonly main: Scalars["HexColorCode"]["output"];
};

export type BrandColourPresetInput = {
    readonly contrast: Scalars["HexColorCode"]["input"];
    readonly main: Scalars["HexColorCode"]["input"];
};

export type BrandCreateInput = {
    readonly airiness: Scalars["NonNegativeInt"]["input"];
    readonly description?: InputMaybe<Scalars["String"]["input"]>;
    readonly entitySystemConfigurationAssociationList?: InputMaybe<
        ReadonlyArray<EntitySystemConfigurationAssociationInput>
    >;
    readonly faviconImage?: InputMaybe<Scalars["AssetUrlInput"]["input"]>;
    readonly footerColour: BrandColourPresetInput;
    readonly footerLogo: Scalars["AssetUrlInput"]["input"];
    readonly headerColour: BrandColourPresetInput;
    readonly headerContrastingLogo: Scalars["AssetUrlInput"]["input"];
    readonly headerLogo: Scalars["AssetUrlInput"]["input"];
    readonly headerLogoPaddingBottom?: InputMaybe<Scalars["Int"]["input"]>;
    readonly headerLogoPaddingTop?: InputMaybe<Scalars["Int"]["input"]>;
    readonly name: Scalars["String"]["input"];
    readonly openGraphImage?: InputMaybe<Scalars["AssetUrlInput"]["input"]>;
    readonly primaryButtonColour: BrandColourPresetInput;
    readonly primaryColour: BrandColourPresetInput;
    readonly primaryFont?: InputMaybe<Scalars["String"]["input"]>;
    readonly roundness: Scalars["NonNegativeInt"]["input"];
    readonly searchPlaceHolder?: InputMaybe<Scalars["String"]["input"]>;
    readonly secondaryButtonColour: BrandColourPresetInput;
    readonly secondaryColour: BrandColourPresetInput;
    readonly specialFont?: InputMaybe<FontInput>;
    readonly spotColours: ReadonlyArray<BrandSpotColourInput>;
    readonly useToursAndExperiences: Scalars["Boolean"]["input"];
    readonly voucherLogo?: InputMaybe<Scalars["AssetUrlInput"]["input"]>;
};

export type BrandDeleteInput = {
    /** The id of the brand */
    readonly id: Scalars["String"]["input"];
};

export type BrandListFilter = {
    readonly match?: InputMaybe<MatchFilterInput>;
};

export type BrandListSort = {
    readonly createdAt?: InputMaybe<SortDirection>;
    readonly name?: InputMaybe<SortDirection>;
};

export type BrandListType = {
    readonly __typename?: "BrandListType";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<Brand>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type BrandListTypeRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type BrandListTypeTotalArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type BrandSpotColour = {
    readonly __typename?: "BrandSpotColour";
    readonly index: Scalars["Int"]["output"];
    readonly shade40: Scalars["HexColorCode"]["output"];
    readonly shade70: Scalars["HexColorCode"]["output"];
    readonly shade100: Scalars["HexColorCode"]["output"];
    readonly shade400: Scalars["HexColorCode"]["output"];
    readonly shade700: Scalars["HexColorCode"]["output"];
};

export type BrandSpotColourInput = {
    readonly index: Scalars["Int"]["input"];
    readonly shade40: Scalars["HexColorCode"]["input"];
    readonly shade70: Scalars["HexColorCode"]["input"];
    readonly shade100: Scalars["HexColorCode"]["input"];
    readonly shade400: Scalars["HexColorCode"]["input"];
    readonly shade700: Scalars["HexColorCode"]["input"];
};

export type BrandUpdateInput = {
    readonly airiness?: InputMaybe<Scalars["NonNegativeInt"]["input"]>;
    readonly description?: InputMaybe<Scalars["String"]["input"]>;
    readonly entitySystemConfigurationAssociationList?: InputMaybe<
        ReadonlyArray<EntitySystemConfigurationAssociationInput>
    >;
    readonly faviconImage?: InputMaybe<Scalars["AssetUrlInput"]["input"]>;
    readonly footerColour?: InputMaybe<BrandColourPresetInput>;
    readonly footerLogo?: InputMaybe<Scalars["AssetUrlInput"]["input"]>;
    readonly headerColour?: InputMaybe<BrandColourPresetInput>;
    readonly headerContrastingLogo?: InputMaybe<Scalars["AssetUrlInput"]["input"]>;
    readonly headerLogo?: InputMaybe<Scalars["AssetUrlInput"]["input"]>;
    readonly headerLogoPaddingBottom?: InputMaybe<Scalars["Int"]["input"]>;
    readonly headerLogoPaddingTop?: InputMaybe<Scalars["Int"]["input"]>;
    readonly id: Scalars["ID"]["input"];
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    readonly openGraphImage?: InputMaybe<Scalars["AssetUrlInput"]["input"]>;
    readonly primaryButtonColour?: InputMaybe<BrandColourPresetInput>;
    readonly primaryColour?: InputMaybe<BrandColourPresetInput>;
    readonly primaryFont?: InputMaybe<Scalars["String"]["input"]>;
    readonly roundness?: InputMaybe<Scalars["NonNegativeInt"]["input"]>;
    readonly searchPlaceHolder?: InputMaybe<Scalars["String"]["input"]>;
    readonly secondaryButtonColour?: InputMaybe<BrandColourPresetInput>;
    readonly secondaryColour?: InputMaybe<BrandColourPresetInput>;
    readonly specialFont?: InputMaybe<FontInput>;
    readonly spotColours?: InputMaybe<ReadonlyArray<BrandSpotColourInput>>;
    readonly useToursAndExperiences?: InputMaybe<Scalars["Boolean"]["input"]>;
    readonly voucherLogo?: InputMaybe<Scalars["AssetUrlInput"]["input"]>;
};

export type BreadcrumbItem = CurationBreadcrumb;

export type BreadcrumbList = {
    readonly __typename?: "BreadcrumbList";
    readonly nodes: ReadonlyArray<BreadcrumbItem>;
};

export type BusinessPrincipal =
    | CompanyBusinessPrincipal
    | PartnerBusinessPrincipal
    | PartnerChannelBusinessPrincipal
    | SaleAgencyBusinessPrincipal
    | SupplierBusinessPrincipal;

/** This represents a CancellationPenalty. */
export type CancellationPenalty = {
    readonly __typename?: "CancellationPenalty";
    readonly amount?: Maybe<Scalars["Float"]["output"]>;
    readonly amountCurrency?: Maybe<Scalars["String"]["output"]>;
    readonly amountType?: Maybe<Scalars["String"]["output"]>;
    readonly duration?: Maybe<Scalars["String"]["output"]>;
    readonly formattedText?: Maybe<Scalars["String"]["output"]>;
    readonly ordinalPosition?: Maybe<Scalars["Int"]["output"]>;
    readonly refundPercentage?: Maybe<Scalars["Float"]["output"]>;
    readonly relativeTo: CancellationPenaltyRelativeToType;
    readonly type: CancellationPenaltyType;
};

/** This represents a list of CancellationPenalty. */
export type CancellationPenaltyList = {
    readonly __typename?: "CancellationPenaltyList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<CancellationPenalty>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

/** This represents a list of CancellationPenalty. */
export type CancellationPenaltyListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum CancellationPenaltyRelativeToType {
    BookingDate = "BOOKING_DATE",
    TravelDate = "TRAVEL_DATE",
}

export enum CancellationPenaltyType {
    Absolute = "ABSOLUTE",
    Percentage = "PERCENTAGE",
}

export enum CapacityConsumptionTypeType {
    /** The capacity is reduced for every booking that is booked. */
    PerBookingAvailability = "PER_BOOKING_AVAILABILITY",
    /** The capacity is reduced for every person that is booked. */
    PerPerson = "PER_PERSON",
}

/** This represents a securityPrincipal */
export type CompanyBusinessPrincipal = {
    readonly __typename?: "CompanyBusinessPrincipal";
    readonly foreignId: Scalars["String"]["output"];
    readonly hierarchyNodeType: SecurityPrincipalHiearchyNodeType;
    readonly id: Scalars["String"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly parentId: Scalars["String"]["output"];
    readonly parentSecurityPrincipalId: Scalars["String"]["output"];
    readonly parentSecurityPrincipalType?: Maybe<SecurityPrincipalTypeType>;
    readonly securityPermissionList: SecurityPermissionList;
    readonly securityPrincipalId: Scalars["String"]["output"];
    readonly securityPrincipalType: SecurityPrincipalTypeType;
    readonly type: SecurityPrincipalTypeType;
};

export type CompanyEntityConfigurationInput = {
    /** entityConfigurationList */
    readonly entitySystemConfigurationAssociationList?: InputMaybe<
        ReadonlyArray<EntitySystemConfigurationAssociationInput>
    >;
};

export type Consumer = {
    readonly __typename?: "Consumer";
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly email?: Maybe<Scalars["String"]["output"]>;
    readonly familyName?: Maybe<Scalars["String"]["output"]>;
    readonly givenName?: Maybe<Scalars["String"]["output"]>;
    readonly id: Scalars["String"]["output"];
    readonly name?: Maybe<Scalars["String"]["output"]>;
    readonly partnerChannelId?: Maybe<Scalars["String"]["output"]>;
    readonly partnerChannelName?: Maybe<Scalars["String"]["output"]>;
    readonly partnerExternalReference?: Maybe<Scalars["String"]["output"]>;
    readonly partnerName?: Maybe<Scalars["String"]["output"]>;
    readonly phone?: Maybe<Scalars["String"]["output"]>;
    readonly tripListCount: Scalars["Int"]["output"];
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ConsumerBookingHierarchyItem = {
    readonly __typename?: "ConsumerBookingHierarchyItem";
    readonly id: Scalars["String"]["output"];
    readonly label?: Maybe<Scalars["String"]["output"]>;
    readonly type: ConsumerBookingHierarchyType;
};

export type ConsumerBookingHierarchyItemInput = {
    readonly id: Scalars["String"]["input"];
    readonly label?: InputMaybe<Scalars["String"]["input"]>;
    readonly type: ConsumerBookingHierarchyType;
};

export type ConsumerBookingHierarchyList = {
    readonly __typename?: "ConsumerBookingHierarchyList";
    readonly nodes: ReadonlyArray<ConsumerBookingHierarchyItem>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type ConsumerBookingHierarchyListFilter = {
    readonly hierarchyItem?: InputMaybe<ConsumerBookingHierarchyItemInput>;
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ConsumerBookingHierarchyType {
    Booking = "BOOKING",
    BookingAvailability = "BOOKING_AVAILABILITY",
    Consumer = "CONSUMER",
    ConsumerTrip = "CONSUMER_TRIP",
}

export type ConsumerConcrete = {
    readonly __typename?: "ConsumerConcrete";
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly email: Scalars["String"]["output"];
    readonly familyName: Scalars["String"]["output"];
    readonly givenName: Scalars["String"]["output"];
    readonly id: Scalars["String"]["output"];
    readonly name?: Maybe<Scalars["String"]["output"]>;
    readonly partnerChannelId?: Maybe<Scalars["String"]["output"]>;
    readonly partnerChannelName?: Maybe<Scalars["String"]["output"]>;
    readonly partnerExternalReference?: Maybe<Scalars["String"]["output"]>;
    readonly partnerName?: Maybe<Scalars["String"]["output"]>;
    readonly phone?: Maybe<Scalars["String"]["output"]>;
    readonly tripListCount: Scalars["Int"]["output"];
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ConsumerConcreteCreateInput = {
    readonly email: Scalars["String"]["input"];
    readonly familyName: Scalars["String"]["input"];
    readonly givenName: Scalars["String"]["input"];
    readonly phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type ConsumerConcreteUpdateInput = {
    readonly email: Scalars["String"]["input"];
    readonly familyName: Scalars["String"]["input"];
    readonly givenName: Scalars["String"]["input"];
    readonly id: Scalars["String"]["input"];
    readonly phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type ConsumerInput = {
    readonly email?: InputMaybe<Scalars["String"]["input"]>;
    readonly familyName?: InputMaybe<Scalars["String"]["input"]>;
    readonly givenName?: InputMaybe<Scalars["String"]["input"]>;
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    readonly phone?: InputMaybe<Scalars["String"]["input"]>;
    readonly selector?: InputMaybe<SelectorInput>;
};

export type ConsumerList = {
    readonly __typename?: "ConsumerList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<Consumer>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type ConsumerListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ConsumerListFilter = {
    /** Whether the consumer has been identified. */
    readonly isIdentified?: InputMaybe<Scalars["Boolean"]["input"]>;
    readonly partnerChannelId?: InputMaybe<Scalars["ID"]["input"]>;
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
};

export type ConsumerListSort = {
    /** Sort by created date */
    readonly createdAt?: InputMaybe<SortDirection>;
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

/** This represents a consumer trip. */
export type ConsumerTrip = {
    readonly __typename?: "ConsumerTrip";
    readonly accommodationAddress?: Maybe<Scalars["String"]["output"]>;
    readonly accommodationName?: Maybe<Scalars["String"]["output"]>;
    readonly accommodationPlaceId?: Maybe<Scalars["String"]["output"]>;
    readonly arrivalAirportName?: Maybe<Scalars["String"]["output"]>;
    readonly arrivalDate?: Maybe<Scalars["DateTime"]["output"]>;
    readonly arrivalFlightNumber?: Maybe<Scalars["String"]["output"]>;
    readonly bookingDate?: Maybe<Scalars["DateTime"]["output"]>;
    readonly childCount?: Maybe<Scalars["NonNegativeInt"]["output"]>;
    readonly code: Scalars["Hashids"]["output"];
    readonly consumer?: Maybe<Consumer>;
    readonly consumerId: Scalars["String"]["output"];
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly demoUrlWithParams: Scalars["String"]["output"];
    readonly departureAirportCode?: Maybe<Scalars["String"]["output"]>;
    readonly departureAirportName?: Maybe<Scalars["String"]["output"]>;
    readonly departureCountryName?: Maybe<Scalars["String"]["output"]>;
    readonly departureDate?: Maybe<Scalars["DateTime"]["output"]>;
    readonly departureFlightClass?: Maybe<Flight_Class>;
    readonly departureFlightNumber?: Maybe<Scalars["String"]["output"]>;
    readonly destination?: Maybe<ConsumerTripDestination>;
    /** @deprecated Please use airportCode from destination. */
    readonly destinationAirportCode?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Please use destinationCountryName from consumerTripDestination. */
    readonly destinationCountryName?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Please use name from destination. */
    readonly destinationName?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Please use pagePath from destination. */
    readonly destinationPagePath?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Please use placeId from destination. */
    readonly destinationPlaceId?: Maybe<Scalars["String"]["output"]>;
    readonly externalProductName?: Maybe<Scalars["String"]["output"]>;
    readonly id: Scalars["String"]["output"];
    readonly infantCount?: Maybe<Scalars["NonNegativeInt"]["output"]>;
    readonly isBrazeDemoAvailable: Scalars["Boolean"]["output"];
    readonly name?: Maybe<Scalars["String"]["output"]>;
    readonly partnerConsumerExternalReference?: Maybe<Scalars["String"]["output"]>;
    readonly partnerExternalReference?: Maybe<Scalars["String"]["output"]>;
    readonly personCount?: Maybe<Scalars["NonNegativeInt"]["output"]>;
    readonly place?: Maybe<Place>;
    readonly placeId?: Maybe<Scalars["String"]["output"]>;
    readonly systemTagList?: Maybe<ConsumerTripSystemTagList>;
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly venueAddress?: Maybe<Scalars["String"]["output"]>;
    readonly venueName?: Maybe<Scalars["String"]["output"]>;
    readonly venuePlaceId?: Maybe<Scalars["String"]["output"]>;
    readonly weatherForecast?: Maybe<ReadonlyArray<ConsumerTripWeatherForecast>>;
    readonly whiteLabelUrl?: Maybe<Scalars["String"]["output"]>;
};

export type ConsumerTripAvailabilityPerson = {
    readonly __typename?: "ConsumerTripAvailabilityPerson";
    readonly baseId: Scalars["Hashids"]["output"];
    readonly bookingAvailabilityId: Scalars["ID"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly pricingCategoryLabel?: Maybe<Scalars["String"]["output"]>;
    /** Person questions */
    readonly questionList: BookingQuestionList;
    /** Total price for this availability. */
    readonly totalPrice: Price;
};

export type ConsumerTripAvailabilityPersonList = {
    readonly __typename?: "ConsumerTripAvailabilityPersonList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<ConsumerTripAvailabilityPerson>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type ConsumerTripAvailabilityPersonListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ConsumerTripAvailabilityPersonListFilter = {
    readonly bookingAvailabilityIdList?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
    readonly idList?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
};

export type ConsumerTripAvailabilityPersonListSort = {
    readonly createdAt?: InputMaybe<SortDirection>;
};

/** This represents a consumer trip. */
export type ConsumerTripBasicTc = {
    readonly __typename?: "ConsumerTripBasicTC";
    readonly consumer?: Maybe<Consumer>;
    readonly id: Scalars["String"]["output"];
    readonly partnerExternalReference?: Maybe<Scalars["String"]["output"]>;
    readonly placeId?: Maybe<Scalars["String"]["output"]>;
};

/** This represents a consumer trip. */
export type ConsumerTripConcrete = {
    readonly __typename?: "ConsumerTripConcrete";
    readonly accommodationAddress?: Maybe<Scalars["String"]["output"]>;
    readonly accommodationName?: Maybe<Scalars["String"]["output"]>;
    readonly accommodationPlaceId?: Maybe<Scalars["String"]["output"]>;
    readonly arrivalAirportName?: Maybe<Scalars["String"]["output"]>;
    readonly arrivalDate?: Maybe<Scalars["DateTime"]["output"]>;
    readonly arrivalFlightNumber?: Maybe<Scalars["String"]["output"]>;
    readonly bookingDate?: Maybe<Scalars["DateTime"]["output"]>;
    readonly childCount?: Maybe<Scalars["NonNegativeInt"]["output"]>;
    readonly code: Scalars["Hashids"]["output"];
    readonly consumer?: Maybe<Consumer>;
    readonly consumerId: Scalars["String"]["output"];
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly demoUrlWithParams: Scalars["String"]["output"];
    readonly departureAirportCode?: Maybe<Scalars["String"]["output"]>;
    readonly departureAirportName?: Maybe<Scalars["String"]["output"]>;
    readonly departureCountryName?: Maybe<Scalars["String"]["output"]>;
    readonly departureDate?: Maybe<Scalars["DateTime"]["output"]>;
    readonly departureFlightClass?: Maybe<Flight_Class>;
    readonly departureFlightNumber?: Maybe<Scalars["String"]["output"]>;
    readonly destination?: Maybe<ConsumerTripDestination>;
    /** @deprecated Please use airportCode from destination. */
    readonly destinationAirportCode?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Please use destinationCountryName from consumerTripDestination. */
    readonly destinationCountryName?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Please use name from destination. */
    readonly destinationName?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Please use placeId from destination. */
    readonly destinationPlaceId?: Maybe<Scalars["String"]["output"]>;
    readonly externalProductName?: Maybe<Scalars["String"]["output"]>;
    readonly id: Scalars["String"]["output"];
    readonly infantCount?: Maybe<Scalars["NonNegativeInt"]["output"]>;
    readonly isBrazeDemoAvailable: Scalars["Boolean"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly partnerConsumerExternalReference?: Maybe<Scalars["String"]["output"]>;
    readonly partnerExternalReference?: Maybe<Scalars["String"]["output"]>;
    readonly personCount?: Maybe<Scalars["NonNegativeInt"]["output"]>;
    readonly place?: Maybe<Place>;
    readonly placeId?: Maybe<Scalars["String"]["output"]>;
    readonly systemTagList?: Maybe<ConsumerTripSystemTagList>;
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly venueAddress?: Maybe<Scalars["String"]["output"]>;
    readonly venueName?: Maybe<Scalars["String"]["output"]>;
    readonly venuePlaceId?: Maybe<Scalars["String"]["output"]>;
    readonly weatherForecast?: Maybe<ReadonlyArray<ConsumerTripWeatherForecast>>;
    readonly whiteLabelUrl?: Maybe<Scalars["String"]["output"]>;
};

export type ConsumerTripConcreteCreateInput = {
    readonly arrivalDate?: InputMaybe<Scalars["DateTime"]["input"]>;
    readonly consumerId: Scalars["String"]["input"];
    readonly departureDate?: InputMaybe<Scalars["DateTime"]["input"]>;
    readonly name: Scalars["String"]["input"];
};

export type ConsumerTripConcreteUpdateInput = {
    readonly accommodationAddress?: InputMaybe<Scalars["String"]["input"]>;
    readonly accommodationName?: InputMaybe<Scalars["String"]["input"]>;
    readonly arrivalAirportName?: InputMaybe<Scalars["String"]["input"]>;
    readonly arrivalDate?: InputMaybe<Scalars["DateTime"]["input"]>;
    readonly arrivalFlightNumber?: InputMaybe<Scalars["String"]["input"]>;
    readonly bookingDate?: InputMaybe<Scalars["DateTime"]["input"]>;
    readonly childCount?: InputMaybe<Scalars["NonNegativeInt"]["input"]>;
    readonly departureAirportCode?: InputMaybe<Scalars["String"]["input"]>;
    readonly departureAirportName?: InputMaybe<Scalars["String"]["input"]>;
    readonly departureCountryName?: InputMaybe<Scalars["String"]["input"]>;
    readonly departureDate?: InputMaybe<Scalars["DateTime"]["input"]>;
    readonly departureFlightClass?: InputMaybe<Flight_Class>;
    readonly departureFlightNumber?: InputMaybe<Scalars["String"]["input"]>;
    readonly destinationAirportCode?: InputMaybe<Scalars["String"]["input"]>;
    readonly destinationCountryName?: InputMaybe<Scalars["String"]["input"]>;
    readonly destinationName?: InputMaybe<Scalars["String"]["input"]>;
    readonly externalProductName?: InputMaybe<Scalars["String"]["input"]>;
    readonly id: Scalars["String"]["input"];
    readonly infantCount?: InputMaybe<Scalars["NonNegativeInt"]["input"]>;
    readonly name: Scalars["String"]["input"];
    readonly partnerExternalReference?: InputMaybe<Scalars["String"]["input"]>;
    readonly personCount?: InputMaybe<Scalars["NonNegativeInt"]["input"]>;
    readonly venueAddress?: InputMaybe<Scalars["String"]["input"]>;
    readonly venueName?: InputMaybe<Scalars["String"]["input"]>;
};

export type ConsumerTripContent = {
    readonly __typename?: "ConsumerTripContent";
    readonly destinationPagePath?: Maybe<Scalars["String"]["output"]>;
    readonly itemList: ReadonlyArray<ConsumerTripContentItem>;
    readonly placeId?: Maybe<Scalars["String"]["output"]>;
    readonly systemTagIdList: ReadonlyArray<Scalars["ID"]["output"]>;
};

export type ConsumerTripContentItem = ConsumerTripContentItemProductList;

export type ConsumerTripContentItemProductList = {
    readonly __typename?: "ConsumerTripContentItemProductList";
    readonly filter: ConsumerTripContentItemProductListFilter;
    readonly layout: ConsumerTripContentItemProductListLayout;
    readonly type: ConsumerTripContentItemProductListType;
    readonly typeParams?: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
};

export type ConsumerTripContentItemProductListFilter = {
    readonly __typename?: "ConsumerTripContentItemProductListFilter";
    readonly duration?: Maybe<DurationRange>;
    readonly geoCircle?: Maybe<GeoCircle>;
    readonly placeId: Scalars["String"]["output"];
    readonly startTime?: Maybe<TimeRange>;
    readonly systemTag?: Maybe<ReadonlyArray<SystemTagFilter>>;
    readonly systemTagIdList?: Maybe<ReadonlyArray<Scalars["String"]["output"]>>;
};

export enum ConsumerTripContentItemProductListLayout {
    Slider = "SLIDER",
}

export enum ConsumerTripContentItemProductListType {
    AirportTransferAndLounge = "AIRPORT_TRANSFER_AND_LOUNGE",
    Business = "BUSINESS",
    FamilyFriendly = "FAMILY_FRIENDLY",
    HiddenGems = "HIDDEN_GEMS",
    LikedSystemTags = "LIKED_SYSTEM_TAGS",
    LikelyToSellOut = "LIKELY_TO_SELL_OUT",
    LongHaul = "LONG_HAUL",
    LongTrip = "LONG_TRIP",
    Nearby = "NEARBY",
    Pax_4OrMore = "PAX_4_OR_MORE",
    Personal = "PERSONAL",
    RainingDuringStay = "RAINING_DURING_STAY",
    RainingNextFewDays = "RAINING_NEXT_FEW_DAYS",
    RecentlyViewed = "RECENTLY_VIEWED",
    ShortTrip = "SHORT_TRIP",
    TopAttractions = "TOP_ATTRACTIONS",
}

export type ConsumerTripDestination = {
    readonly __typename?: "ConsumerTripDestination";
    readonly airportCode?: Maybe<Scalars["String"]["output"]>;
    readonly countryName?: Maybe<Scalars["String"]["output"]>;
    readonly image?: Maybe<ConsumerTripDestinationImage>;
    readonly name?: Maybe<Scalars["String"]["output"]>;
    readonly pagePath?: Maybe<Scalars["String"]["output"]>;
    readonly place?: Maybe<PlaceNew>;
    readonly placeId?: Maybe<Scalars["String"]["output"]>;
};

export type ConsumerTripDestinationImage = {
    readonly __typename?: "ConsumerTripDestinationImage";
    readonly heroImageAssetUrlList: ReadonlyArray<Asset>;
};

export type ConsumerTripEvent = {
    readonly __typename?: "ConsumerTripEvent";
    readonly consumerTripId: Scalars["String"]["output"];
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly meta?: Maybe<Scalars["JSON"]["output"]>;
    readonly type: ConsumerTripEventType;
};

export type ConsumerTripEventList = {
    readonly __typename?: "ConsumerTripEventList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<ConsumerTripEvent>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type ConsumerTripEventListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ConsumerTripEventListFilter = {
    readonly consumerTripId: Scalars["ID"]["input"];
};

export enum ConsumerTripEventType {
    AnalyticLinkOrigin = "ANALYTIC_LINK_ORIGIN",
    HbmlComponentShelfScrolled = "HBML_COMPONENT_SHELF_SCROLLED",
    HotjarSession = "HOTJAR_SESSION",
    LandingPageRedirect = "LANDING_PAGE_REDIRECT",
    WlBookingPaid = "WL_BOOKING_PAID",
    WlCheckAvailabilityClick = "WL_CHECK_AVAILABILITY_CLICK",
    WlConsumerTripQuestionnaireBackClicked = "WL_CONSUMER_TRIP_QUESTIONNAIRE_BACK_CLICKED",
    WlConsumerTripQuestionnaireChangeOfHeartClicked = "WL_CONSUMER_TRIP_QUESTIONNAIRE_CHANGE_OF_HEART_CLICKED",
    WlConsumerTripQuestionnaireClosed = "WL_CONSUMER_TRIP_QUESTIONNAIRE_CLOSED",
    WlConsumerTripQuestionnaireInterestSelected = "WL_CONSUMER_TRIP_QUESTIONNAIRE_INTEREST_SELECTED",
    WlConsumerTripQuestionnaireInterestUnselected = "WL_CONSUMER_TRIP_QUESTIONNAIRE_INTEREST_UNSELECTED",
    WlConsumerTripQuestionnaireNextClicked = "WL_CONSUMER_TRIP_QUESTIONNAIRE_NEXT_CLICKED",
    WlConsumerTripQuestionnaireOpened = "WL_CONSUMER_TRIP_QUESTIONNAIRE_OPENED",
    WlConsumerTripQuestionnaireSubmitted = "WL_CONSUMER_TRIP_QUESTIONNAIRE_SUBMITTED",
    WlConsumerTripStoryClicked = "WL_CONSUMER_TRIP_STORY_CLICKED",
    WlConsumerTripStoryDisplayed = "WL_CONSUMER_TRIP_STORY_DISPLAYED",
    WlConsumerTripStoryScrolled = "WL_CONSUMER_TRIP_STORY_SCROLLED",
    WlPageView = "WL_PAGE_VIEW",
    WlPersonalizationShelfDisplayed = "WL_PERSONALIZATION_SHELF_DISPLAYED",
    WlPersonalizationShelfScrolled = "WL_PERSONALIZATION_SHELF_SCROLLED",
    WlProductClick = "WL_PRODUCT_CLICK",
    WlSearch = "WL_SEARCH",
    WlSearchTriggered = "WL_SEARCH_TRIGGERED",
    WlSubscriptionModalClosed = "WL_SUBSCRIPTION_MODAL_CLOSED",
    WlSubscriptionOptIn = "WL_SUBSCRIPTION_OPT_IN",
    WlSubscriptionOptInModalDisplayed = "WL_SUBSCRIPTION_OPT_IN_MODAL_DISPLAYED",
    WlSubscriptionOptOut = "WL_SUBSCRIPTION_OPT_OUT",
}

export type ConsumerTripInput = {
    readonly accommodationAddress?: InputMaybe<Scalars["String"]["input"]>;
    readonly accommodationName?: InputMaybe<Scalars["String"]["input"]>;
    readonly arrivalAirportName?: InputMaybe<Scalars["String"]["input"]>;
    readonly arrivalDate?: InputMaybe<Scalars["DateTime"]["input"]>;
    readonly arrivalFlightNumber?: InputMaybe<Scalars["String"]["input"]>;
    readonly bookingDate?: InputMaybe<Scalars["DateTime"]["input"]>;
    readonly childCount?: InputMaybe<Scalars["NonNegativeInt"]["input"]>;
    readonly consumer?: InputMaybe<ConsumerInput>;
    readonly departureAirportCode?: InputMaybe<Scalars["String"]["input"]>;
    readonly departureAirportName?: InputMaybe<Scalars["String"]["input"]>;
    readonly departureCountryName?: InputMaybe<Scalars["String"]["input"]>;
    readonly departureDate?: InputMaybe<Scalars["DateTime"]["input"]>;
    readonly departureFlightClass?: InputMaybe<Flight_Class>;
    readonly departureFlightNumber?: InputMaybe<Scalars["String"]["input"]>;
    readonly destinationAirportCode?: InputMaybe<Scalars["String"]["input"]>;
    readonly destinationCountryName?: InputMaybe<Scalars["String"]["input"]>;
    readonly destinationName?: InputMaybe<Scalars["String"]["input"]>;
    readonly destinationPagePath?: InputMaybe<Scalars["String"]["input"]>;
    readonly externalProductName?: InputMaybe<Scalars["String"]["input"]>;
    readonly infantCount?: InputMaybe<Scalars["NonNegativeInt"]["input"]>;
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    readonly partnerConsumerExternalReference?: InputMaybe<Scalars["String"]["input"]>;
    readonly partnerExternalReference?: InputMaybe<Scalars["String"]["input"]>;
    readonly personCount?: InputMaybe<Scalars["NonNegativeInt"]["input"]>;
    readonly placeId?: InputMaybe<Scalars["String"]["input"]>;
    readonly selector: SelectorInput;
    readonly systemTagIdList?: InputMaybe<ReadonlyArray<Scalars["ID"]["input"]>>;
};

export type ConsumerTripList = {
    readonly __typename?: "ConsumerTripList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<ConsumerTrip>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type ConsumerTripListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ConsumerTripListFilter = {
    readonly consumerIdList?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    readonly tripWithinDateRange?: InputMaybe<DateTimeRangeInput>;
};

export type ConsumerTripListSort = {
    readonly createdAt?: InputMaybe<SortDirection>;
};

export type ConsumerTripSystemTag = {
    readonly __typename?: "ConsumerTripSystemTag";
    readonly name?: Maybe<Scalars["String"]["output"]>;
    readonly systemTagId: Scalars["ID"]["output"];
};

export type ConsumerTripSystemTagList = {
    readonly __typename?: "ConsumerTripSystemTagList";
    readonly nodes: ReadonlyArray<ConsumerTripSystemTag>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

/** This represents a consumer trip weather forecast data. */
export type ConsumerTripWeatherForecast = {
    readonly __typename?: "ConsumerTripWeatherForecast";
    readonly date?: Maybe<Scalars["Date"]["output"]>;
    readonly type?: Maybe<WeatherForecastTypes>;
};

export enum ConsumptionStateType {
    Full = "FULL",
    None = "NONE",
    Partial = "PARTIAL",
}

export type ContentComponent = {
    readonly __typename?: "ContentComponent";
    readonly createdAt: Scalars["Date"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly translatedValueList: LanguageTranslationJson;
    readonly type: ContentComponentType;
    readonly updatedAt?: Maybe<Scalars["Date"]["output"]>;
};

export type ContentComponentInput = {
    readonly id?: InputMaybe<Scalars["ID"]["input"]>;
    readonly name: Scalars["String"]["input"];
    readonly translatedValueList?: InputMaybe<LanguageTranslationJsonInput>;
    readonly type: ContentComponentType;
};

export type ContentComponentList = {
    readonly __typename?: "ContentComponentList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<ContentComponent>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type ContentComponentListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum ContentComponentType {
    Body = "BODY",
    Footer = "FOOTER",
    Header = "HEADER",
    Navigator = "NAVIGATOR",
}

/** This represents a curation */
export type Curation = {
    readonly __typename?: "Curation";
    /** Date curation was created in database */
    readonly createdAt?: Maybe<Scalars["Date"]["output"]>;
    /** List of associated curations */
    readonly curationAssociationList?: Maybe<CurationAssociationList>;
    /** Default path to curation */
    readonly defaultPath?: Maybe<ReadonlyArray<CurationPathItem>>;
    /** A brief description of the curation */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** A brief description of the curation in its original JSON format */
    readonly descriptionJson?: Maybe<Scalars["String"]["output"]>;
    readonly endContent?: Maybe<Hbml>;
    /** Details about the author */
    readonly endContentTranslationList?: Maybe<LanguageTranslationJson>;
    /** a JSON object containing a valid set of productList filter */
    readonly filterData?: Maybe<Scalars["JSON"]["output"]>;
    readonly heroImageAsset?: Maybe<Asset>;
    /** The image source of the curation */
    readonly heroImageSource?: Maybe<Scalars["String"]["output"]>;
    /** The heroImageVaultId of the curation */
    readonly heroImageVaultId?: Maybe<Scalars["String"]["output"]>;
    /** The product image url */
    readonly heroImageVaultUrl?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the curation */
    readonly id: Scalars["String"]["output"];
    /** The name of the curation */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** Original name of the curation */
    readonly originalName?: Maybe<Scalars["String"]["output"]>;
    /** The cityName of the curation */
    readonly placeName?: Maybe<Scalars["String"]["output"]>;
    /** The number of product associated with this curation */
    readonly productAssociationCount?: Maybe<Scalars["Int"]["output"]>;
    /** List of sibling curations in the same group */
    readonly siblingCurationsList?: Maybe<CurationAssociationList>;
    /** The type of the curation */
    readonly type: CurationType;
    /** Date curation was updated in database */
    readonly updatedAt?: Maybe<Scalars["Date"]["output"]>;
};

/** This represents a curation */
export type CurationDescriptionArgs = {
    contentType?: InputMaybe<DescriptionContentType>;
};

/** This represents a curation */
export type CurationSiblingCurationsListArgs = {
    parentCurationId: Scalars["ID"]["input"];
};

export type CurationAssociationList = {
    readonly __typename?: "CurationAssociationList";
    readonly nodes: ReadonlyArray<CurationAssociationListItem>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

/** This represents a curation association. */
export type CurationAssociationListItem = {
    readonly __typename?: "CurationAssociationListItem";
    /** The unique Holibob ID for the curation which is associated. */
    readonly curationId: Scalars["String"]["output"];
    /** Name of this curation. */
    readonly curationName: Scalars["String"]["output"];
    /** Group name of this curation. */
    readonly groupName?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the association. */
    readonly id: Scalars["String"]["output"];
    /** Image vaultId of this curation. */
    readonly imageSource?: Maybe<Scalars["String"]["output"]>;
    /** Image vaultId of this curation. */
    readonly imageVaultId?: Maybe<Scalars["String"]["output"]>;
    /** Image url of this curation. */
    readonly imageVaultUrl?: Maybe<Scalars["String"]["output"]>;
};

export type CurationBreadcrumb = {
    readonly __typename?: "CurationBreadcrumb";
    readonly id: Scalars["Hashids"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly slug: Scalars["String"]["output"];
};

export type CurationContent = {
    readonly __typename?: "CurationContent";
    readonly children: ReadonlyArray<CurationContentItemCuration>;
    readonly curation: CurationContentItemCuration;
    readonly curationParent?: Maybe<CurationContentItemCurationParent>;
    readonly groups: ReadonlyArray<CurationContentItemGroup>;
    readonly siblings: ReadonlyArray<CurationContentItemCuration>;
};

export type CurationContentItemCuration = {
    readonly __typename?: "CurationContentItemCuration";
    readonly description?: Maybe<Hbml>;
    readonly endContent?: Maybe<Hbml>;
    readonly heroImageAsset?: Maybe<Asset>;
    readonly hierarchyId: Scalars["String"]["output"];
    readonly id: Scalars["Hashids"]["output"];
    readonly metaDescription?: Maybe<Scalars["String"]["output"]>;
    readonly name: Scalars["String"]["output"];
    readonly productCount: Scalars["NonNegativeInt"]["output"];
    readonly slug: Scalars["String"]["output"];
};

export type CurationContentItemCurationParent = {
    readonly __typename?: "CurationContentItemCurationParent";
    readonly id: Scalars["Hashids"]["output"];
    readonly name?: Maybe<Scalars["String"]["output"]>;
};

export type CurationContentItemGroup = {
    readonly __typename?: "CurationContentItemGroup";
    readonly children: ReadonlyArray<CurationContentItemCuration>;
    readonly id: Scalars["String"]["output"];
    readonly name: Scalars["String"]["output"];
};

export type CurationListFilter = {
    /** Ids of curations */
    readonly ids?: InputMaybe<ReadonlyArray<Scalars["ID"]["input"]>>;
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** Filter by place id */
    readonly placeIdList?: InputMaybe<ReadonlyArray<Scalars["ID"]["input"]>>;
};

export type CurationListSort = {
    /** Sort by createdAt */
    readonly createdAt?: InputMaybe<SortDirection>;
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

export type CurationListType = {
    readonly __typename?: "CurationListType";
    readonly nodes: ReadonlyArray<Curation>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

/** This represents a curation path item */
export type CurationPathItem = {
    readonly __typename?: "CurationPathItem";
    /** The unique Holibob ID for the curation */
    readonly curationId: Scalars["ID"]["output"];
    /** The name of the curation */
    readonly label: Scalars["String"]["output"];
};

export enum CurationType {
    Filter = "FILTER",
    Rule = "RULE",
    Static = "STATIC",
}

export enum CurrencyCode {
    Aed = "AED",
    Aud = "AUD",
    Brl = "BRL",
    Cad = "CAD",
    Chf = "CHF",
    Dkk = "DKK",
    Egp = "EGP",
    Eur = "EUR",
    Fjd = "FJD",
    Gbp = "GBP",
    Inr = "INR",
    Mxn = "MXN",
    Nad = "NAD",
    Nok = "NOK",
    Npr = "NPR",
    Nzd = "NZD",
    Pen = "PEN",
    Qar = "QAR",
    Sar = "SAR",
    Sek = "SEK",
    Sgd = "SGD",
    Thb = "THB",
    Usd = "USD",
    Zar = "ZAR",
}

/** Entity Question Data Type */
export enum DataType {
    /** Data Type is BOOLEAN */
    Boolean = "BOOLEAN",
    /** Data Type is DATE */
    Date = "DATE",
    /** Data Type is OPTIONS */
    Options = "OPTIONS",
    /** Data Type is TEXT */
    Text = "TEXT",
}

export type DateTimeRangeInput = {
    readonly end?: InputMaybe<Scalars["DateTime"]["input"]>;
    readonly start?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export enum DescriptionContentType {
    Html = "HTML",
    JsonString = "JSON_STRING",
    Markdown = "MARKDOWN",
    ShortText = "SHORT_TEXT",
    Text = "TEXT",
}

export type DistributionChannel = {
    readonly __typename?: "DistributionChannel";
    readonly brandId: Scalars["String"]["output"];
    /** commissionShareRate */
    readonly commissionShareRate?: Maybe<Scalars["Float"]["output"]>;
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly curationHierarchyId?: Maybe<Scalars["String"]["output"]>;
    /** The currencyCodeData of this PartnerChannel. */
    readonly currencyCodeData?: Maybe<Scalars["JSON"]["output"]>;
    /** Brand Configuration List */
    readonly entitySystemConfigurationAssociationList?: Maybe<EntitySystemConfigurationAssociationList>;
    /** Returns Footer Translation HBML List from EntityValue */
    readonly footerTranslationList?: Maybe<LanguageTranslationHbml>;
    /** The ga4MeasurementId */
    readonly ga4MeasurementId?: Maybe<Scalars["String"]["output"]>;
    /** The ga4PropertyId */
    readonly ga4PropertyId?: Maybe<Scalars["String"]["output"]>;
    /** The ga4StreamId */
    readonly ga4StreamId?: Maybe<Scalars["String"]["output"]>;
    /** The googleTagManagerId */
    readonly googleTagManagerId?: Maybe<Scalars["String"]["output"]>;
    /** The google tracking id for the channel */
    readonly googleTrackingId?: Maybe<Scalars["String"]["output"]>;
    /** Returns if there are authors created for this DistributionChannel. */
    readonly hasDistributionChannelAuthors?: Maybe<Scalars["Boolean"]["output"]>;
    /** Returns if there are categories created for thid DistributionChannel. */
    readonly hasDistributionChannelCategories?: Maybe<Scalars["Boolean"]["output"]>;
    /** The shopping cart flag of the channel. */
    readonly hasShoppingCart?: Maybe<Scalars["Boolean"]["output"]>;
    readonly headerMenuHierarchyId?: Maybe<Scalars["String"]["output"]>;
    readonly homePageCurationId?: Maybe<Scalars["String"]["output"]>;
    /** Returns Home Page Translation HBML List from EntityValue */
    readonly homePageTranslationList?: Maybe<LanguageTranslationHbml>;
    readonly homePageType: DistributionChannelHomePageType;
    readonly hostName?: Maybe<Scalars["String"]["output"]>;
    readonly hostPrefix?: Maybe<Scalars["String"]["output"]>;
    readonly id: Scalars["String"]["output"];
    /** Is card payment required for the channel */
    readonly isCardPaymentRequired?: Maybe<Scalars["Boolean"]["output"]>;
    readonly metaDescription?: Maybe<Scalars["String"]["output"]>;
    readonly metaDescriptionTranslationList?: Maybe<LanguageTranslation>;
    readonly metaKeywords?: Maybe<Scalars["String"]["output"]>;
    readonly metaKeywordsTranslationList?: Maybe<LanguageTranslation>;
    readonly metaTitle?: Maybe<Scalars["String"]["output"]>;
    readonly metaTitleTranslationList?: Maybe<LanguageTranslation>;
    readonly name: Scalars["String"]["output"];
    /** ogImageVaultId */
    readonly ogImageVaultId?: Maybe<Scalars["String"]["output"]>;
    /** Resolved origin home URL based on hostName or hostPrefix */
    readonly originHomeUrl?: Maybe<Scalars["String"]["output"]>;
    readonly partnerId?: Maybe<Scalars["String"]["output"]>;
    /** Partner payment type */
    readonly paymentType?: Maybe<DistributionChannelPaymentType>;
    /** redirects */
    readonly redirectList?: Maybe<DistributionChannelRedirectList>;
    /** The sale currency of the channel */
    readonly saleCurrency?: Maybe<Scalars["String"]["output"]>;
    /** securityPermissionAssociation List */
    readonly securityPermissionAssociations?: Maybe<ReadonlyArray<SecurityPermission>>;
    /** securityRuleAssociations List */
    readonly securityRuleAssociations?: Maybe<ReadonlyArray<SecurityRulePrincipalAssociation>>;
    /** The stripeProductionCustomerKey. */
    readonly stripeProductionCustomerKey?: Maybe<Scalars["String"]["output"]>;
    /** The stripeSandboxCustomerKey. */
    readonly stripeSandboxCustomerKey?: Maybe<Scalars["String"]["output"]>;
    /** The suppress new search flag of the channel. */
    readonly suppressNewSearch?: Maybe<Scalars["Boolean"]["output"]>;
    readonly type: DistributionChannelTypeType;
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The email address that should appear on a voucher. */
    readonly voucherContactEmail?: Maybe<Scalars["String"]["output"]>;
    /** The company/contact name that should appear on a voucher. */
    readonly voucherContactName?: Maybe<Scalars["String"]["output"]>;
    /** The phone number name that should appear on a voucher. */
    readonly voucherContactPhone?: Maybe<Scalars["String"]["output"]>;
    /** Indicates that the summary should be included on the voucher. */
    readonly voucherIncludeSummary?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DistributionChannelAuthor = {
    readonly __typename?: "DistributionChannelAuthor";
    readonly avatarImageAsset?: Maybe<Asset>;
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly description?: Maybe<Hbml>;
    /** Details about the author */
    readonly descriptionList?: Maybe<LanguageTranslationJson>;
    /** distributionChannelId */
    readonly distributionChannelId: Scalars["String"]["output"];
    /** The ID of the author */
    readonly id: Scalars["String"]["output"];
    /** The name of the author */
    readonly name: Scalars["String"]["output"];
    readonly slug: Scalars["String"]["output"];
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type DistributionChannelAuthorInput = {
    /** Hero image AssetUrl */
    readonly avatarImageAsset?: InputMaybe<Scalars["AssetUrlInput"]["input"]>;
    readonly description?: InputMaybe<HbmlInput>;
    /** Details about the author */
    readonly descriptionList?: InputMaybe<LanguageTranslationJsonInput>;
    /** distributionChannelId */
    readonly distributionChannelId: Scalars["String"]["input"];
    /** The ID of the author */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The name of the author */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    readonly slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type DistributionChannelAuthorList = {
    readonly __typename?: "DistributionChannelAuthorList";
    readonly nodes: ReadonlyArray<DistributionChannelAuthor>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type DistributionChannelAuthorListFilter = {
    /** Filter by multiple DistributionChannel Author IDs. */
    readonly authorIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by DistributionChannel Id. */
    readonly distributionChannelId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by DistributionChannel Author Id. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** Filter by Author slug. */
    readonly slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type DistributionChannelAuthorListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

export type DistributionChannelBlogAuthorListFilter = {
    /** Filter by Author slug. */
    readonly slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type DistributionChannelBlogCategoryList = {
    readonly __typename?: "DistributionChannelBlogCategoryList";
    readonly nodes: ReadonlyArray<DistributionChannelPageCategory>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type DistributionChannelBlogCategoryListFilter = {
    readonly slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type DistributionChannelBlogPost = {
    readonly __typename?: "DistributionChannelBlogPost";
    readonly author: DistributionChannelAuthor;
    /** The page category associations. */
    readonly categoryAssociationList: DistributionChannelPageCategoryAssociationList;
    readonly createdAt: Scalars["DateTime"]["output"];
    /** The ID of the distributionChannelPage. */
    readonly id: Scalars["String"]["output"];
    readonly imageAsset?: Maybe<Asset>;
    readonly metaDescription?: Maybe<Scalars["String"]["output"]>;
    readonly metaTitle: Scalars["String"]["output"];
    /** path */
    readonly path: Scalars["String"]["output"];
    readonly summaryContentComponent: Hbml;
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly validFrom: Scalars["DateTime"]["output"];
};

export type DistributionChannelBlogPostList = {
    readonly __typename?: "DistributionChannelBlogPostList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<DistributionChannelBlogPost>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type DistributionChannelBlogPostListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DistributionChannelBlogPostListTotalArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DistributionChannelBlogPostListFilter = {
    readonly authorId?: InputMaybe<Scalars["ID"]["input"]>;
    readonly categoryId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type DistributionChannelComponent = {
    readonly __typename?: "DistributionChannelComponent";
    readonly content?: Maybe<Hbml>;
    /** contentComponentId */
    readonly contentComponentId: Scalars["String"]["output"];
    readonly createdAt: Scalars["DateTime"]["output"];
    /** distributionChannelId */
    readonly distributionChannelId: Scalars["String"]["output"];
    /** The ID of the distributionChannelComponent */
    readonly id: Scalars["String"]["output"];
    readonly type: DistributionChannelComponentTypeType;
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export enum DistributionChannelComponentTypeType {
    Footer = "FOOTER",
    Header = "HEADER",
    HeaderNavigator = "HEADER_NAVIGATOR",
    HomePage = "HOME_PAGE",
}

export type DistributionChannelContent = {
    readonly __typename?: "DistributionChannelContent";
    readonly footer?: Maybe<Hbml>;
    readonly headerMenu: ReadonlyArray<HeaderMenuEntry>;
    readonly headerNavigator?: Maybe<DistributionChannelComponent>;
    readonly homePage?: Maybe<DistributionChannelComponent>;
};

export enum DistributionChannelCreationType {
    BookingWeb = "BOOKING_WEB",
    Both = "BOTH",
    HubWeb = "HUB_WEB",
}

export type DistributionChannelHomePageContent =
    | DistributionChannelHomePageContentConsumerTrip
    | DistributionChannelHomePageContentCuration
    | DistributionChannelHomePageContentCustom
    | DistributionChannelHomePageContentFeaturedProducts;

export type DistributionChannelHomePageContentConsumerTrip = {
    readonly __typename?: "DistributionChannelHomePageContentConsumerTrip";
    readonly distributionChannelPagePath: Scalars["String"]["output"];
    readonly page: Hbml;
    readonly type: DistributionChannelHomePageContentType;
};

export type DistributionChannelHomePageContentCuration = {
    readonly __typename?: "DistributionChannelHomePageContentCuration";
    readonly curationId: Scalars["String"]["output"];
    readonly type: DistributionChannelHomePageContentType;
};

export type DistributionChannelHomePageContentCustom = {
    readonly __typename?: "DistributionChannelHomePageContentCustom";
    readonly page: Hbml;
    readonly type: DistributionChannelHomePageContentType;
};

export type DistributionChannelHomePageContentFeaturedProducts = {
    readonly __typename?: "DistributionChannelHomePageContentFeaturedProducts";
    readonly type: DistributionChannelHomePageContentType;
};

export enum DistributionChannelHomePageContentType {
    ConsumerTrip = "CONSUMER_TRIP",
    Curation = "CURATION",
    Custom = "CUSTOM",
    FeaturedProducts = "FEATURED_PRODUCTS",
}

export enum DistributionChannelHomePageType {
    ConsumerTrip = "CONSUMER_TRIP",
    Curation = "CURATION",
    Custom = "CUSTOM",
    FeaturedProducts = "FEATURED_PRODUCTS",
}

export type DistributionChannelInput = {
    readonly brandId?: InputMaybe<Scalars["String"]["input"]>;
    /** commissionShareRate */
    readonly commissionShareRate?: InputMaybe<Scalars["Float"]["input"]>;
    readonly createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
    readonly creationType?: InputMaybe<DistributionChannelCreationType>;
    readonly curationHierarchyId?: InputMaybe<Scalars["String"]["input"]>;
    /** The currencyCodeData of this PartnerChannel. */
    readonly currencyCodeData?: InputMaybe<Scalars["JSON"]["input"]>;
    /** Brand entityConfigurationList */
    readonly entitySystemConfigurationAssociationList?: InputMaybe<
        ReadonlyArray<EntitySystemConfigurationAssociationInput>
    >;
    /** Returns Footer Translation HBML List from EntityValue */
    readonly footerTranslationList?: InputMaybe<LanguageTranslationHbmlInput>;
    /** The ga4MeasurementId */
    readonly ga4MeasurementId?: InputMaybe<Scalars["String"]["input"]>;
    /** The ga4PropertyId */
    readonly ga4PropertyId?: InputMaybe<Scalars["String"]["input"]>;
    /** The ga4StreamId */
    readonly ga4StreamId?: InputMaybe<Scalars["String"]["input"]>;
    /** The googleTagManagerId */
    readonly googleTagManagerId?: InputMaybe<Scalars["String"]["input"]>;
    /** The google tracking id for the channel */
    readonly googleTrackingId?: InputMaybe<Scalars["String"]["input"]>;
    /** Returns if there are authors created for this DistributionChannel. */
    readonly hasDistributionChannelAuthors?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Returns if there are categories created for thid DistributionChannel. */
    readonly hasDistributionChannelCategories?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The shopping cart flag of the channel. */
    readonly hasShoppingCart?: InputMaybe<Scalars["Boolean"]["input"]>;
    readonly headerMenuHierarchyId?: InputMaybe<Scalars["String"]["input"]>;
    readonly homePageCurationId?: InputMaybe<Scalars["String"]["input"]>;
    /** Returns Home Page Translation HBML List from EntityValue */
    readonly homePageTranslationList?: InputMaybe<LanguageTranslationHbmlInput>;
    readonly homePageType?: InputMaybe<DistributionChannelHomePageType>;
    readonly hostName?: InputMaybe<Scalars["String"]["input"]>;
    readonly hostPrefix?: InputMaybe<Scalars["String"]["input"]>;
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** Is card payment required for the channel */
    readonly isCardPaymentRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
    readonly metaDescription?: InputMaybe<Scalars["String"]["input"]>;
    readonly metaDescriptionTranslationList?: InputMaybe<LanguageTranslationInput>;
    readonly metaKeywords?: InputMaybe<Scalars["String"]["input"]>;
    readonly metaKeywordsTranslationList?: InputMaybe<LanguageTranslationInput>;
    readonly metaTitle?: InputMaybe<Scalars["String"]["input"]>;
    readonly metaTitleTranslationList?: InputMaybe<LanguageTranslationInput>;
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** ogImageVaultId */
    readonly ogImageVaultId?: InputMaybe<Scalars["String"]["input"]>;
    /** Resolved origin home URL based on hostName or hostPrefix */
    readonly originHomeUrl?: InputMaybe<Scalars["String"]["input"]>;
    readonly partnerChannelId?: InputMaybe<Scalars["String"]["input"]>;
    readonly partnerId?: InputMaybe<Scalars["String"]["input"]>;
    /** Partner payment type */
    readonly paymentType?: InputMaybe<DistributionChannelPaymentType>;
    /** BrandChannel Redirect List */
    readonly redirectList?: InputMaybe<ReadonlyArray<DistributionChannelRedirectInput>>;
    /** The sale currency of the channel */
    readonly saleCurrency?: InputMaybe<Scalars["String"]["input"]>;
    /** security permission associations */
    readonly securityPermissionAssociations?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** securityRuleAssociations List */
    readonly securityRuleAssociations?: InputMaybe<ReadonlyArray<SecurityRulePrincipalAssociationInput>>;
    /** The stripeProductionCustomerKey. */
    readonly stripeProductionCustomerKey?: InputMaybe<Scalars["String"]["input"]>;
    /** The stripeSandboxCustomerKey. */
    readonly stripeSandboxCustomerKey?: InputMaybe<Scalars["String"]["input"]>;
    /** The suppress new search flag of the channel. */
    readonly suppressNewSearch?: InputMaybe<Scalars["Boolean"]["input"]>;
    readonly updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
    /** The email address that should appear on a voucher. */
    readonly voucherContactEmail?: InputMaybe<Scalars["String"]["input"]>;
    /** The company/contact name that should appear on a voucher. */
    readonly voucherContactName?: InputMaybe<Scalars["String"]["input"]>;
    /** The phone number name that should appear on a voucher. */
    readonly voucherContactPhone?: InputMaybe<Scalars["String"]["input"]>;
    /** Indicates that the summary should be included on the voucher. */
    readonly voucherIncludeSummary?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DistributionChannelList = {
    readonly __typename?: "DistributionChannelList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<DistributionChannel>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type DistributionChannelListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DistributionChannelListTotalArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DistributionChannelListFilter = {
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
};

export type DistributionChannelListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

export type DistributionChannelPage = {
    readonly __typename?: "DistributionChannelPage";
    readonly author?: Maybe<DistributionChannelAuthor>;
    /** bodyContentComponentId */
    readonly bodyContentComponentId: Scalars["String"]["output"];
    readonly bodyContentComponentValue: LanguageTranslationHbml;
    /** The page category associations. */
    readonly categoryAssociationList: DistributionChannelPageCategoryAssociationList;
    readonly createdAt: Scalars["DateTime"]["output"];
    /** distributionChannelId */
    readonly distributionChannelId: Scalars["String"]["output"];
    /** The ID of the distributionChannelPage. */
    readonly id: Scalars["String"]["output"];
    readonly imageAsset?: Maybe<Asset>;
    readonly metaDescription?: Maybe<Scalars["String"]["output"]>;
    readonly metaDescriptionTranslationList?: Maybe<LanguageTranslation>;
    readonly metaKeywords?: Maybe<Scalars["String"]["output"]>;
    readonly metaKeywordsTranslationList?: Maybe<LanguageTranslation>;
    readonly metaTitle: Scalars["String"]["output"];
    readonly metaTitleTranslationList?: Maybe<LanguageTranslation>;
    /**
     * The name of the distributionChannelPage.
     * @deprecated Use metaTitle instead.
     */
    readonly name: Scalars["String"]["output"];
    /** ogImageVaultId */
    readonly ogImageVaultId?: Maybe<Scalars["String"]["output"]>;
    /** path */
    readonly path: Scalars["String"]["output"];
    readonly placeId?: Maybe<Scalars["String"]["output"]>;
    readonly summaryContentComponent?: Maybe<Hbml>;
    /** summaryBodyContentComponentId */
    readonly summaryContentComponentId?: Maybe<Scalars["String"]["output"]>;
    readonly summaryContentComponentValue?: Maybe<LanguageTranslationHbml>;
    /** The type of the distributionChannelPage. */
    readonly type: DistributionChannelPageTypeType;
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly validFrom?: Maybe<Scalars["DateTime"]["output"]>;
    readonly validTo?: Maybe<Scalars["DateTime"]["output"]>;
};

export type DistributionChannelPageCategory = {
    readonly __typename?: "DistributionChannelPageCategory";
    readonly categoryImageAsset?: Maybe<Asset>;
    readonly createdAt: Scalars["DateTime"]["output"];
    /** The name of the page category */
    readonly defaultName: Scalars["String"]["output"];
    readonly description?: Maybe<Hbml>;
    /** Details about the category */
    readonly descriptionList?: Maybe<LanguageTranslationJson>;
    /** distributionChannelId */
    readonly distributionChannelId: Scalars["String"]["output"];
    /** The ID of the page category */
    readonly id: Scalars["String"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly nameList?: Maybe<LanguageTranslation>;
    readonly slug: Scalars["String"]["output"];
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type DistributionChannelPageCategoryAssociation = {
    readonly __typename?: "DistributionChannelPageCategoryAssociation";
    readonly categoryImageAsset?: Maybe<Asset>;
    readonly createdAt: Scalars["DateTime"]["output"];
    /** The name of the page category */
    readonly defaultName: Scalars["String"]["output"];
    readonly description?: Maybe<Hbml>;
    /** Details about the category */
    readonly descriptionList?: Maybe<LanguageTranslationJson>;
    /** distributionChannelId */
    readonly distributionChannelId: Scalars["String"]["output"];
    readonly distributionChannelPageId: Scalars["String"]["output"];
    /** The ID of the page category */
    readonly id: Scalars["String"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly nameList?: Maybe<LanguageTranslation>;
    readonly slug: Scalars["String"]["output"];
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type DistributionChannelPageCategoryAssociationInputList = {
    readonly nodes?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
};

export type DistributionChannelPageCategoryAssociationList = {
    readonly __typename?: "DistributionChannelPageCategoryAssociationList";
    readonly nodes: ReadonlyArray<DistributionChannelPageCategoryAssociation>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type DistributionChannelPageCategoryInput = {
    /** Hero image AssetUrl */
    readonly categoryImageAsset?: InputMaybe<Scalars["AssetUrlInput"]["input"]>;
    /** The name of the page category */
    readonly defaultName?: InputMaybe<Scalars["String"]["input"]>;
    /** Details about the category */
    readonly descriptionList?: InputMaybe<LanguageTranslationJsonInput>;
    /** distributionChannelId */
    readonly distributionChannelId: Scalars["String"]["input"];
    /** The ID of the page category */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    readonly nameList?: InputMaybe<LanguageTranslationInput>;
    readonly slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type DistributionChannelPageCategoryList = {
    readonly __typename?: "DistributionChannelPageCategoryList";
    readonly nodes: ReadonlyArray<DistributionChannelPageCategory>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type DistributionChannelPageCategoryListFilter = {
    /** Filter by multiple DistributionChannel Page Category IDs. */
    readonly categoryIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by DistributionChannel Id. */
    readonly distributionChannelId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by DistributionChannel Page Category Id. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** Filter by Page Category slug. */
    readonly slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type DistributionChannelPageCategoryListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

export type DistributionChannelPageContent = {
    readonly __typename?: "DistributionChannelPageContent";
    readonly author?: Maybe<DistributionChannelAuthor>;
    readonly body: Hbml;
    /** The page category associations. */
    readonly categoryAssociationList: DistributionChannelPageCategoryAssociationList;
    readonly imageAsset?: Maybe<Asset>;
    readonly metaDescription?: Maybe<Scalars["String"]["output"]>;
    readonly metaKeywords?: Maybe<Scalars["String"]["output"]>;
    readonly metaTitle?: Maybe<Scalars["String"]["output"]>;
    readonly placeId?: Maybe<Scalars["String"]["output"]>;
    readonly validFrom?: Maybe<Scalars["DateTime"]["output"]>;
};

export type DistributionChannelPageInput = {
    /** bodyContentComponentId */
    readonly bodyContentComponentId?: InputMaybe<Scalars["String"]["input"]>;
    readonly bodyContentComponentValue?: InputMaybe<LanguageTranslationHbmlInput>;
    readonly categoryAssociationList?: InputMaybe<DistributionChannelPageCategoryAssociationInputList>;
    /** The page author ID. */
    readonly distributionChannelAuthorId?: InputMaybe<Scalars["String"]["input"]>;
    /** distributionChannelId */
    readonly distributionChannelId: Scalars["String"]["input"];
    /** The page author ID. */
    readonly distributionChannelPageCategoryId?: InputMaybe<Scalars["String"]["input"]>;
    /** The ID of the distributionChannelPage. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** Hero image AssetUrl */
    readonly imageAsset?: InputMaybe<Scalars["AssetUrlInput"]["input"]>;
    readonly metaDescriptionTranslationList?: InputMaybe<LanguageTranslationInput>;
    readonly metaKeywords?: InputMaybe<Scalars["String"]["input"]>;
    readonly metaKeywordsTranslationList?: InputMaybe<LanguageTranslationInput>;
    readonly metaTitleTranslationList?: InputMaybe<LanguageTranslationInput>;
    /** The name of the distributionChannelPage. */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** ogImageVaultId */
    readonly ogImageVaultId?: InputMaybe<Scalars["String"]["input"]>;
    /** path */
    readonly path?: InputMaybe<Scalars["String"]["input"]>;
    readonly placeId?: InputMaybe<Scalars["String"]["input"]>;
    readonly summaryContentComponent?: InputMaybe<HbmlInput>;
    /** summaryBodyContentComponentId */
    readonly summaryContentComponentId?: InputMaybe<Scalars["String"]["input"]>;
    readonly summaryContentComponentValue?: InputMaybe<LanguageTranslationHbmlInput>;
    /** The type of the distributionChannelPage. */
    readonly type: DistributionChannelPageTypeType;
    readonly validFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
    readonly validTo?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DistributionChannelPageList = {
    readonly __typename?: "DistributionChannelPageList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<DistributionChannelPage>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type DistributionChannelPageListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DistributionChannelPageListTotalArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DistributionChannelPageListFilter = {
    /** Filter by DistributionChannel Id. */
    readonly distributionChannelId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by DistributionChannel Page Id. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** The type of the distributionChannelPage */
    readonly type?: InputMaybe<DistributionChannelPageTypeType>;
};

export type DistributionChannelPageListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
    readonly validFrom?: InputMaybe<SortDirection>;
};

export enum DistributionChannelPageTypeType {
    Blog = "BLOG",
    Page = "PAGE",
}

/** Type of payment for partner. */
export enum DistributionChannelPaymentType {
    /** Payment on account. */
    OnAccount = "ON_ACCOUNT",
    /** Take payment via stripe customer key. */
    OnRecord = "ON_RECORD",
    /** Card payment is required. */
    Required = "REQUIRED",
}

export type DistributionChannelRedirect = {
    readonly __typename?: "DistributionChannelRedirect";
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly distributionChannelId: Scalars["String"]["output"];
    readonly id: Scalars["String"]["output"];
    readonly type?: Maybe<DistributionChannelRedirectType>;
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly urlFrom: Scalars["String"]["output"];
    readonly urlTo: Scalars["String"]["output"];
};

export type DistributionChannelRedirectInput = {
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    readonly type?: InputMaybe<DistributionChannelRedirectType>;
    readonly urlFrom: Scalars["String"]["input"];
    readonly urlTo: Scalars["String"]["input"];
};

export type DistributionChannelRedirectList = {
    readonly __typename?: "DistributionChannelRedirectList";
    readonly nodes: ReadonlyArray<DistributionChannelRedirect>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export enum DistributionChannelRedirectType {
    Permanent = "PERMANENT",
    Temporary = "TEMPORARY",
}

export type DistributionChannelRedirectUrlPath = {
    readonly __typename?: "DistributionChannelRedirectUrlPath";
    readonly type: DistributionChannelRedirectType;
    readonly url: Scalars["String"]["output"];
};

export enum DistributionChannelTypeType {
    BookingWeb = "BOOKING_WEB",
    HubWeb = "HUB_WEB",
}

export type DurationRange = {
    readonly __typename?: "DurationRange";
    readonly end?: Maybe<Scalars["ISO8601Duration"]["output"]>;
    readonly start?: Maybe<Scalars["ISO8601Duration"]["output"]>;
};

export type DurationRangeInput = {
    readonly end?: InputMaybe<Scalars["ISO8601Duration"]["input"]>;
    readonly start?: InputMaybe<Scalars["ISO8601Duration"]["input"]>;
};

/** This represents an EntityAttachment. */
export type EntityAttachment = {
    readonly __typename?: "EntityAttachment";
    /** The id of the entity. */
    readonly entityId: Scalars["String"]["output"];
    /** The entityKey of the attachment allowing logical association to a group of attachments. */
    readonly entityKey?: Maybe<Scalars["String"]["output"]>;
    /** The type of the entity. */
    readonly entityType: Scalars["String"]["output"];
    /** The unique id for the EntityAttachment. */
    readonly id: Scalars["String"]["output"];
    /** The ordinal position of the attachment. */
    readonly mimeType?: Maybe<Scalars["String"]["output"]>;
    /** The ordinal position of the attachment. */
    readonly ordinalPosition: Scalars["String"]["output"];
};

/** This represents a list of EntityAttachment. */
export type EntityAttachmentList = {
    readonly __typename?: "EntityAttachmentList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes?: Maybe<ReadonlyArray<Maybe<EntityAttachment>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

/** This represents a EntityAttachment. */
export type EntityAttachmentUpsertInputType = {
    /** The type of the attachment allowing logical association to a group of attachments. */
    readonly entityKey: Scalars["String"]["input"];
    /** The unique id for the EntityAttachment. */
    readonly id: Scalars["String"]["input"];
    /** The ordinal position of the attachment. */
    readonly mimeType?: InputMaybe<Scalars["String"]["input"]>;
    /** The ordinal position of the attachment. */
    readonly ordinalPosition?: InputMaybe<Scalars["Int"]["input"]>;
};

/** This represents a EntityComment. */
export type EntityComment = {
    readonly __typename?: "EntityComment";
    /** Record access level. */
    readonly accessLevel: Scalars["Int"]["output"];
    /** The time at which the comment was created. */
    readonly createdAt: Scalars["DateTime"]["output"];
    /** Initials of the creator. */
    readonly createdSecurityPrincipalInitials?: Maybe<Scalars["String"]["output"]>;
    /** The avatar color of the creator. */
    readonly createdSecurityPrincipalUserAvatarColor?: Maybe<Scalars["String"]["output"]>;
    /** The avatar image of the creator. */
    readonly createdSecurityPrincipalUserAvatarVaultUrl?: Maybe<Scalars["String"]["output"]>;
    /** The name of the creator. */
    readonly createdSecurityPrincipalUserName?: Maybe<Scalars["String"]["output"]>;
    /** The comment. */
    readonly descriptionJson?: Maybe<Scalars["String"]["output"]>;
    /** The id of the entity that the comment is attached to. */
    readonly entityId: Scalars["String"]["output"];
    /** The name of the entity that the comment is attached to. */
    readonly entityName?: Maybe<Scalars["String"]["output"]>;
    /** The type of the entity that the comment is attached to. */
    readonly entityType: Scalars["String"]["output"];
    /** The unique id for the EntityComment. */
    readonly id: Scalars["String"]["output"];
    /** The time at which the comment was updated. */
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type EntityCommentList = {
    readonly __typename?: "EntityCommentList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<EntityComment>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type EntityCommentListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type EntityCommentListFilter = {
    /** filter by entityID. */
    readonly entityId?: InputMaybe<Scalars["String"]["input"]>;
};

export type EntityCommentUpsertInput = {
    /** The comment. */
    readonly descriptionJson?: InputMaybe<Scalars["String"]["input"]>;
    /** The Id of the entity that owns this commnet */
    readonly entityId?: InputMaybe<Scalars["String"]["input"]>;
    /** The Type of the entity that owns this commnet */
    readonly entityType?: InputMaybe<Scalars["String"]["input"]>;
    /** The ID of the EntityComment. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
};

export type EntityCommission = {
    readonly __typename?: "EntityCommission";
    readonly comment?: Maybe<Scalars["String"]["output"]>;
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly effectiveFrom: Scalars["Date"]["output"];
    readonly effectiveTo?: Maybe<Scalars["Date"]["output"]>;
    readonly entityId: Scalars["String"]["output"];
    readonly entityType: EntityCommissionTypeType;
    readonly id: Scalars["String"]["output"];
    readonly purchaseNetMarkupRate?: Maybe<Scalars["Float"]["output"]>;
    readonly rate: Scalars["Float"]["output"];
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type EntityCommissionDeleteInput = {
    /** The id of the entity commission */
    readonly id: Scalars["String"]["input"];
};

export type EntityCommissionInput = {
    readonly comment?: InputMaybe<Scalars["String"]["input"]>;
    readonly effectiveFrom?: InputMaybe<Scalars["Date"]["input"]>;
    readonly effectiveTo?: InputMaybe<Scalars["Date"]["input"]>;
    readonly entityId?: InputMaybe<Scalars["String"]["input"]>;
    readonly entityType?: InputMaybe<EntityCommissionTypeType>;
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    readonly purchaseNetMarkupRate?: InputMaybe<Scalars["Float"]["input"]>;
    readonly rate?: InputMaybe<Scalars["Float"]["input"]>;
};

export type EntityCommissionList = {
    readonly __typename?: "EntityCommissionList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<EntityCommission>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type EntityCommissionListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type EntityCommissionListFilter = {
    /** Filter by entityId */
    readonly entityId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by entityType */
    readonly entityType?: InputMaybe<Scalars["String"]["input"]>;
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
};

export type EntityCommissionListSort = {
    /** Sort by createdAt */
    readonly createdAt?: InputMaybe<SortDirection>;
    /** Sort by entityId */
    readonly entityId?: InputMaybe<SortDirection>;
};

export enum EntityCommissionTypeType {
    Actor = "ACTOR",
    Product = "PRODUCT",
    SourceInstance = "SOURCE_INSTANCE",
    Supplier = "SUPPLIER",
}

/** This represents a EntityQuestion */
export type EntityQuestion = {
    readonly __typename?: "EntityQuestion";
    /** question dataFormat */
    readonly dataFormat?: Maybe<Scalars["String"]["output"]>;
    /** question dataPattern */
    readonly dataPattern?: Maybe<Scalars["String"]["output"]>;
    /** question dataType */
    readonly dataType: DataType;
    /** question defaultValue */
    readonly defaultValue?: Maybe<Scalars["String"]["output"]>;
    /** The entityId. */
    readonly entityId?: Maybe<Scalars["String"]["output"]>;
    /** The entityType. */
    readonly entityType: EntityType;
    /** question flags */
    readonly flags?: Maybe<Scalars["String"]["output"]>;
    /** question helper */
    readonly helper?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the entity question */
    readonly id: Scalars["String"]["output"];
    /** isRequired */
    readonly isRequired: Scalars["Boolean"]["output"];
    /** isSelectMultiple */
    readonly isSelectMultiple: Scalars["Boolean"]["output"];
    /** isSuppressed */
    readonly isSuppressed: Scalars["Boolean"]["output"];
    /** The Name of the entity question */
    readonly name: Scalars["String"]["output"];
    /** The ordinalPosition for the Entity Question */
    readonly ordinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** question placeholder */
    readonly placeholder?: Maybe<Scalars["String"]["output"]>;
    /** The type for the  Entity Question */
    readonly type: Scalars["String"]["output"];
};

export type EntityQuestionAssociation = {
    readonly __typename?: "EntityQuestionAssociation";
    readonly id: Scalars["ID"]["output"];
    readonly isPerPerson: Scalars["Boolean"]["output"];
    readonly isRequired: Scalars["Boolean"]["output"];
    readonly question: Question;
    readonly questionId: Scalars["ID"]["output"];
};

export type EntityQuestionAssociationInput = {
    readonly id?: InputMaybe<Scalars["ID"]["input"]>;
    readonly isPerPerson: Scalars["Boolean"]["input"];
    readonly isRequired: Scalars["Boolean"]["input"];
    readonly questionId: Scalars["ID"]["input"];
};

export type EntityQuestionAssociationList = {
    readonly __typename?: "EntityQuestionAssociationList";
    readonly nodes: ReadonlyArray<EntityQuestionAssociation>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type EntityQuestionDeleteInput = {
    /** The unique Holibob ID for the entity question */
    readonly id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type EntityQuestionListFilter = {
    /** The entityId */
    readonly entityId?: InputMaybe<Scalars["String"]["input"]>;
    /** Entity type */
    readonly entityType?: InputMaybe<EntityType>;
    /** The id of the EntityQuestion */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents an EntityQuestion */
export type EntityQuestionListItem = {
    readonly __typename?: "EntityQuestionListItem";
    /** question dataFormat */
    readonly dataFormat?: Maybe<Scalars["String"]["output"]>;
    /** question dataPattern */
    readonly dataPattern?: Maybe<Scalars["String"]["output"]>;
    /** question dataType */
    readonly dataType: DataType;
    /** question defaultValue */
    readonly defaultValue?: Maybe<Scalars["String"]["output"]>;
    /** The entityId. */
    readonly entityId?: Maybe<Scalars["String"]["output"]>;
    /** The entityType. */
    readonly entityType: EntityType;
    /** question flags */
    readonly flags?: Maybe<Scalars["String"]["output"]>;
    /** question helper */
    readonly helper?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the entity question */
    readonly id: Scalars["String"]["output"];
    /** isRequired */
    readonly isRequired: Scalars["Boolean"]["output"];
    /** isSelectMultiple */
    readonly isSelectMultiple: Scalars["Boolean"]["output"];
    /** isSuppressed */
    readonly isSuppressed: Scalars["Boolean"]["output"];
    /** The Name of the entity question */
    readonly name: Scalars["String"]["output"];
    /** The ordinalPosition for the Entity Question */
    readonly ordinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** question placeholder */
    readonly placeholder?: Maybe<Scalars["String"]["output"]>;
    /** The type for the  Entity Question */
    readonly type: Scalars["String"]["output"];
};

/** This respresents the EntityQuestion list response type */
export type EntityQuestionListResponse = {
    readonly __typename?: "EntityQuestionListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** EntityQuestion list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<EntityQuestionListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type EntityQuestionListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

export type EntityQuestionUpsertInput = {
    /** question dataFormat */
    readonly dataFormat?: InputMaybe<Scalars["String"]["input"]>;
    /** question dataPattern */
    readonly dataPattern?: InputMaybe<Scalars["String"]["input"]>;
    /** question dataType */
    readonly dataType?: InputMaybe<DataType>;
    /** question defaultValue */
    readonly defaultValue?: InputMaybe<Scalars["String"]["input"]>;
    /** The entityId. */
    readonly entityId?: InputMaybe<Scalars["String"]["input"]>;
    /** The entityType. */
    readonly entityType?: InputMaybe<EntityType>;
    /** question flags */
    readonly flags?: InputMaybe<Scalars["String"]["input"]>;
    /** question helper */
    readonly helper?: InputMaybe<Scalars["String"]["input"]>;
    /** The unique Holibob ID for the entity question */
    readonly id?: InputMaybe<Scalars["ID"]["input"]>;
    /** isRequired */
    readonly isRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** isSelectMultiple */
    readonly isSelectMultiple?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** isSuppressed */
    readonly isSuppressed?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The Name of the entity question */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** The ordinalPosition for the Entity Question */
    readonly ordinalPosition?: InputMaybe<Scalars["Int"]["input"]>;
    /** question placeholder */
    readonly placeholder?: InputMaybe<Scalars["String"]["input"]>;
    /** The type for the  Entity Question */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
};

export type EntitySecret = EntitySecretBrazeDemo | EntitySecretBrazeWebhook;

export type EntitySecretBrazeDemo = {
    readonly __typename?: "EntitySecretBrazeDemo";
    readonly apiKey: Scalars["Secret"]["output"];
    readonly canvasId: Scalars["String"]["output"];
    readonly whatsAppSubscriptionGroupId: Scalars["String"]["output"];
};

export type EntitySecretBrazeWebhook = {
    readonly __typename?: "EntitySecretBrazeWebhook";
    readonly apiKey: Scalars["Secret"]["output"];
    readonly subscriptionGroupId: Scalars["String"]["output"];
};

export enum EntitySecretHierarchyLevel {
    DistributionChannel = "DistributionChannel",
    Partner = "Partner",
    PartnerChannel = "PartnerChannel",
}

export enum EntitySecretType {
    BrazeDemo = "brazeDemo",
    BrazeWebhook = "brazeWebhook",
}

/** This represents a EntityConfiguration. */
export type EntitySystemConfiguration = {
    readonly __typename?: "EntitySystemConfiguration";
    /** A flag indicating that the record can be inherited. */
    readonly allowInherit: Scalars["Boolean"]["output"];
    /** The id of the entity that this is attached to. */
    readonly entityId?: Maybe<Scalars["String"]["output"]>;
    /** The type of the entity that this is attached to. */
    readonly entityType?: Maybe<Scalars["String"]["output"]>;
    /** The unique id for the EntityConfiguration. */
    readonly id: Scalars["String"]["output"];
    /** The name. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** The scope. */
    readonly scope?: Maybe<Scalars["String"]["output"]>;
    /** The subType in the context of the type. */
    readonly subType?: Maybe<Scalars["String"]["output"]>;
    /** The type. */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** The value. */
    readonly value?: Maybe<Scalars["String"]["output"]>;
};

/** Represents an icon from SystemIcon */
export type EntitySystemConfigurationAssociationIcon = {
    readonly __typename?: "EntitySystemConfigurationAssociationIcon";
    /** Icon ID */
    readonly id: Scalars["String"]["output"];
    /** Icon name from Vault or Icon Description if SVG defined */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** Icon SVG */
    readonly svg?: Maybe<Scalars["String"]["output"]>;
};

export type EntitySystemConfigurationAssociationIconInput = {
    /** Icon ID */
    readonly id: Scalars["String"]["input"];
    /** Icon name from Vault or Icon Description if SVG defined */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** Icon SVG */
    readonly svg?: InputMaybe<Scalars["String"]["input"]>;
};

export type EntitySystemConfigurationAssociationInput = {
    readonly allowInherit?: InputMaybe<Scalars["Boolean"]["input"]>;
    readonly id?: InputMaybe<Scalars["ID"]["input"]>;
    readonly systemConfigurationId: Scalars["ID"]["input"];
    readonly systemIconId?: InputMaybe<Scalars["ID"]["input"]>;
    readonly translatedList?: InputMaybe<LanguageTranslationInput>;
    readonly value?: InputMaybe<Scalars["String"]["input"]>;
};

export type EntitySystemConfigurationAssociationList = {
    readonly __typename?: "EntitySystemConfigurationAssociationList";
    readonly nodes: ReadonlyArray<EntitySystemConfigurationAssociationListNode>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type EntitySystemConfigurationAssociationListInput = {
    readonly nodes: ReadonlyArray<EntitySystemConfigurationAssociationListNodeInput>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["input"];
};

export type EntitySystemConfigurationAssociationListNode = {
    readonly __typename?: "EntitySystemConfigurationAssociationListNode";
    /** A flag indicating that the record can have an icon. */
    readonly allowIcon: Scalars["Boolean"]["output"];
    /** A flag indicating that the record can have multiple values. */
    readonly allowMultiple: Scalars["Boolean"]["output"];
    /** The description associated with the configuration */
    readonly dataType: Scalars["String"]["output"];
    /** The description associated with the configuration */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** The unique SystemConfigurationId */
    readonly id: Scalars["String"]["output"];
    /** The value for the name associated with the configuration */
    readonly name: Scalars["String"]["output"];
    /** Values for the entity associated to the configuration */
    readonly valueList?: Maybe<EntitySystemConfigurationAssociationValueList>;
};

export type EntitySystemConfigurationAssociationListNodeInput = {
    /** A flag indicating that the record can have an icon. */
    readonly allowIcon: Scalars["Boolean"]["input"];
    /** A flag indicating that the record can have multiple values. */
    readonly allowMultiple: Scalars["Boolean"]["input"];
    /** The description associated with the configuration */
    readonly dataType: Scalars["String"]["input"];
    /** The description associated with the configuration */
    readonly description?: InputMaybe<Scalars["String"]["input"]>;
    /** The unique SystemConfigurationId */
    readonly id: Scalars["String"]["input"];
    /** The value for the name associated with the configuration */
    readonly name: Scalars["String"]["input"];
    /** Values for the entity associated to the configuration */
    readonly valueList?: InputMaybe<EntitySystemConfigurationAssociationValueListInput>;
};

export type EntitySystemConfigurationAssociationRefInput = {
    readonly id: Scalars["ID"]["input"];
    readonly type: EntitySystemConfigurationAssociationRefTypeTc;
};

export enum EntitySystemConfigurationAssociationRefTypeTc {
    Brand = "BRAND",
    Company = "COMPANY",
    DistributionChannel = "DISTRIBUTION_CHANNEL",
    Partner = "PARTNER",
    PartnerChannel = "PARTNER_CHANNEL",
    SecurityOrigin = "SECURITY_ORIGIN",
    Supplier = "SUPPLIER",
}

export type EntitySystemConfigurationAssociationUpsertInput = {
    /** The id of the entity the EntityConfiguration applies to or NULL to set it as a default. */
    readonly entityId?: InputMaybe<Scalars["String"]["input"]>;
    /** The type of entity to which the EntityConfiguration applied to. */
    readonly entityType?: InputMaybe<Scalars["String"]["input"]>;
    /** The ID of the EntityConfiguration. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The subType of the EntityConfiguration. */
    readonly subType?: InputMaybe<Scalars["String"]["input"]>;
    readonly translatedList?: InputMaybe<EntitySystemConfigurationAssociationListInput>;
    /** The type of the EntityConfiguration. */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
    /** The value of the EntityConfiguration. If set to FALSE this causes the configuration to not be eliminated for the given entity. */
    readonly value?: InputMaybe<Scalars["String"]["input"]>;
};

/** Represents a EntitySystemConfigurationAssociationEntityValue */
export type EntitySystemConfigurationAssociationValue = {
    readonly __typename?: "EntitySystemConfigurationAssociationValue";
    /** A flag indicating that the record can be inherited. */
    readonly allowInherit: Scalars["Boolean"]["output"];
    /** Values for the entity associated to the configuration */
    readonly icon?: Maybe<EntitySystemConfigurationAssociationIcon>;
    /** The unique SystemConfigurationAssociationId */
    readonly id: Scalars["String"]["output"];
    readonly translatedList?: Maybe<EntitySystemConfigurationAssociationValueTranslatedList>;
    /** The value associated with the configuration */
    readonly value?: Maybe<Scalars["String"]["output"]>;
};

export type EntitySystemConfigurationAssociationValueInput = {
    /** A flag indicating that the record can be inherited. */
    readonly allowInherit: Scalars["Boolean"]["input"];
    /** Values for the entity associated to the configuration */
    readonly icon?: InputMaybe<EntitySystemConfigurationAssociationIconInput>;
    /** The unique SystemConfigurationAssociationId */
    readonly id: Scalars["String"]["input"];
    readonly translatedList?: InputMaybe<EntitySystemConfigurationAssociationValueTranslatedListInput>;
    /** The value associated with the configuration */
    readonly value?: InputMaybe<Scalars["String"]["input"]>;
};

export type EntitySystemConfigurationAssociationValueList = {
    readonly __typename?: "EntitySystemConfigurationAssociationValueList";
    readonly nodes: ReadonlyArray<EntitySystemConfigurationAssociationValue>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type EntitySystemConfigurationAssociationValueListInput = {
    readonly nodes: ReadonlyArray<EntitySystemConfigurationAssociationValueInput>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["input"];
};

export type EntitySystemConfigurationAssociationValueTranslatedList = {
    readonly __typename?: "EntitySystemConfigurationAssociationValueTranslatedList";
    readonly nodes: ReadonlyArray<EntitySystemConfigurationValueTranslatedNode>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    readonly rootLanguage?: Maybe<LanguageCode>;
};

export type EntitySystemConfigurationAssociationValueTranslatedListInput = {
    readonly nodes: ReadonlyArray<EntitySystemConfigurationValueTranslatedNodeInput>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["input"];
    readonly rootLanguage?: InputMaybe<LanguageCode>;
};

export type EntitySystemConfigurationListUpsertInput = {
    readonly entity: EntitySystemConfigurationAssociationRefInput;
    /** entityConfigurationList */
    readonly entitySystemConfigurationAssociationList: ReadonlyArray<EntitySystemConfigurationAssociationInput>;
};

export type EntitySystemConfigurationValueTranslatedNode = {
    readonly __typename?: "EntitySystemConfigurationValueTranslatedNode";
    readonly language?: Maybe<LanguageCode>;
    readonly value: Scalars["String"]["output"];
};

export type EntitySystemConfigurationValueTranslatedNodeInput = {
    readonly language?: InputMaybe<LanguageCode>;
    readonly value: Scalars["String"]["input"];
};

/** This represents a SystemTag. */
export type EntitySystemTag = {
    readonly __typename?: "EntitySystemTag";
    /** Narrative generated by AI. */
    readonly aiNarrative?: Maybe<Scalars["String"]["output"]>;
    /** The type of the Tag. */
    readonly code: Scalars["String"]["output"];
    /** The description of the tag. */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** The id of the entity. */
    readonly entityId: Scalars["String"]["output"];
    /** The unique id for the SystemTag. */
    readonly id: Scalars["String"]["output"];
    readonly key?: Maybe<Scalars["String"]["output"]>;
    /** The hierarchical level */
    readonly level?: Maybe<Scalars["Int"]["output"]>;
    /** Maximum child tags allowed for this parent. */
    readonly maxChildren?: Maybe<Scalars["Int"]["output"]>;
    /** Minimum child tags required for this parent. */
    readonly minChildren?: Maybe<Scalars["Int"]["output"]>;
    /** The name of the Tag. */
    readonly name: Scalars["String"]["output"];
    /** The id of the parent. */
    readonly parentId?: Maybe<Scalars["String"]["output"]>;
    /** The key of the parent. */
    readonly parentKey?: Maybe<Scalars["String"]["output"]>;
    /** The root key of the tag's hierarchy. */
    readonly rootKey?: Maybe<Scalars["String"]["output"]>;
    /** The id of the SystemTag. */
    readonly systemTagId: Scalars["String"]["output"];
    /** The code of the Tag. */
    readonly type: Scalars["String"]["output"];
};

export type EntitySystemTagAssociationDeleteInput = {
    /** Entity ID */
    readonly entityId: Scalars["String"]["input"];
};

/** Entity Question type */
export enum EntityType {
    /** Entity Question is COMPANY */
    Company = "COMPANY",
    /** Entity Question is PARTNER */
    Partner = "PARTNER",
    /** Entity Question is voucher contact email */
    PartnerChannel = "PARTNER_CHANNEL",
    /** Entity Question is PRODUCT */
    Product = "PRODUCT",
    /** Entity Question is SOURCE */
    Source = "SOURCE",
    /** Entity Question is SUPPLIER */
    Supplier = "SUPPLIER",
}

/** This represents an EntityValue. */
export type EntityValue = {
    readonly __typename?: "EntityValue";
    /** The entityKey of the value allowing logical association to a group of values. */
    readonly entityKey?: Maybe<Scalars["String"]["output"]>;
    /** The unique id for the EntityValue. */
    readonly id: Scalars["String"]["output"];
    /** The ordinal position of the value. */
    readonly ordinalPosition: Scalars["String"]["output"];
    /** The type of the entity. */
    readonly value: Scalars["String"]["output"];
};

/** This represents a list of EntityValue. */
export type EntityValueList = {
    readonly __typename?: "EntityValueList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes?: Maybe<ReadonlyArray<Maybe<EntityValue>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

/** This represents a EntityAttachment. */
export type EntityValueUpsertInputType = {
    /** The type of the value allowing logical association to a group of values. */
    readonly entityKey: Scalars["String"]["input"];
    /** The unique id for the EntityValue. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The ordinal position of the attachment. */
    readonly value?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ExcludeDistrubtionChannelTypeList {
    Extranet = "EXTRANET",
    WhiteLabel = "WHITE_LABEL",
}

export type ExternalWidget =
    | ExternalWidgetBokun
    | ExternalWidgetBookingkit
    | ExternalWidgetFareharbor
    | ExternalWidgetPeek
    | ExternalWidgetRezdy
    | ExternalWidgetTicketinghub
    | ExternalWidgetTravelotopos
    | ExternalWidgetVentrata
    | ExternalWidgetXola;

export type ExternalWidgetBokun = {
    readonly __typename?: "ExternalWidgetBokun";
    readonly bookingChannelId: Scalars["String"]["output"];
    readonly widgetId: Scalars["String"]["output"];
};

export type ExternalWidgetBokunInput = {
    readonly bookingChannelId: Scalars["String"]["input"];
    readonly widgetId: Scalars["String"]["input"];
};

export type ExternalWidgetBookingkit = {
    readonly __typename?: "ExternalWidgetBookingkit";
    readonly subdomain?: Maybe<Scalars["String"]["output"]>;
    readonly widgetId: Scalars["String"]["output"];
};

export type ExternalWidgetBookingkitInput = {
    readonly subdomain?: InputMaybe<Scalars["String"]["input"]>;
    readonly widgetId: Scalars["String"]["input"];
};

export type ExternalWidgetFareharbor = {
    readonly __typename?: "ExternalWidgetFareharbor";
    readonly flowId: Scalars["PositiveInt"]["output"];
    readonly itemId: Scalars["PositiveInt"]["output"];
    readonly slug: Scalars["String"]["output"];
};

export type ExternalWidgetFareharborInput = {
    readonly flowId: Scalars["PositiveInt"]["input"];
    readonly itemId: Scalars["PositiveInt"]["input"];
};

export type ExternalWidgetInput = {
    readonly bokun?: InputMaybe<ExternalWidgetBokunInput>;
    readonly bookingkit?: InputMaybe<ExternalWidgetBookingkitInput>;
    readonly fareharbor?: InputMaybe<ExternalWidgetFareharborInput>;
    readonly peek?: InputMaybe<ExternalWidgetPeekInput>;
    readonly rezdy?: InputMaybe<ExternalWidgetRezdyInput>;
    readonly ticketinghub?: InputMaybe<ExternalWidgetTicketinghubInput>;
    readonly travelotopos?: InputMaybe<ExternalWidgetTravelotoposInput>;
    readonly ventrata?: InputMaybe<ExternalWidgetVentrataInput>;
    readonly xola?: InputMaybe<ExternalWidgetXolaInput>;
};

export type ExternalWidgetPeek = {
    readonly __typename?: "ExternalWidgetPeek";
    readonly keyId: Scalars["String"]["output"];
    readonly widgetId: Scalars["String"]["output"];
};

export type ExternalWidgetPeekInput = {
    readonly keyId: Scalars["String"]["input"];
    readonly widgetId: Scalars["String"]["input"];
};

export type ExternalWidgetRezdy = {
    readonly __typename?: "ExternalWidgetRezdy";
    readonly height?: Maybe<Scalars["PositiveInt"]["output"]>;
    readonly slug: Scalars["String"]["output"];
    readonly widgetId: Scalars["String"]["output"];
};

export type ExternalWidgetRezdyInput = {
    readonly height?: InputMaybe<Scalars["PositiveInt"]["input"]>;
    readonly slug: Scalars["String"]["input"];
    readonly widgetId: Scalars["String"]["input"];
};

export type ExternalWidgetTicketinghub = {
    readonly __typename?: "ExternalWidgetTicketinghub";
    readonly widgetId: Scalars["String"]["output"];
};

export type ExternalWidgetTicketinghubInput = {
    readonly widgetId: Scalars["String"]["input"];
};

export type ExternalWidgetTravelotopos = {
    readonly __typename?: "ExternalWidgetTravelotopos";
    readonly subdomain?: Maybe<Scalars["String"]["output"]>;
    readonly widgetId: Scalars["String"]["output"];
};

export type ExternalWidgetTravelotoposInput = {
    readonly subdomain?: InputMaybe<Scalars["String"]["input"]>;
    readonly widgetId: Scalars["String"]["input"];
};

export type ExternalWidgetVentrata = {
    readonly __typename?: "ExternalWidgetVentrata";
    readonly apiKey?: Maybe<Scalars["String"]["output"]>;
    readonly productId?: Maybe<Scalars["String"]["output"]>;
};

export type ExternalWidgetVentrataInput = {
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ExternalWidgetXola = {
    readonly __typename?: "ExternalWidgetXola";
    readonly experienceId: Scalars["String"]["output"];
    readonly sellerId: Scalars["String"]["output"];
};

export type ExternalWidgetXolaInput = {
    readonly experienceId: Scalars["String"]["input"];
    readonly sellerId: Scalars["String"]["input"];
};

/** This respresents the finance hedge response type */
export type FinanceHedgeList = {
    readonly __typename?: "FinanceHedgeList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** Finance Hedge list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<FinanceHedgeListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type FinanceHedgeListFilter = {
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    /** Search string */
    readonly unhedged?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Data required for FX hedging */
export type FinanceHedgeListItem = {
    readonly __typename?: "FinanceHedgeListItem";
    /** The amount to hedge */
    readonly amount?: Maybe<Scalars["String"]["output"]>;
    /** The sale currency */
    readonly counterCurrency?: Maybe<Scalars["String"]["output"]>;
    /** The origin currency */
    readonly currency?: Maybe<Scalars["String"]["output"]>;
    /** The entry date (booking date) */
    readonly entryDate?: Maybe<Scalars["DateTime"]["output"]>;
    /** Th FX rate at the time of booking */
    readonly entryRate?: Maybe<Scalars["String"]["output"]>;
    /** The currenct pair */
    readonly entryRatePair?: Maybe<Scalars["String"]["output"]>;
    /** The family name for this hedge */
    readonly externalRef?: Maybe<Scalars["String"]["output"]>;
    /** The code for this hedge */
    readonly hedgeAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The market direction (always Buy) */
    readonly marketDirection?: Maybe<Scalars["String"]["output"]>;
    /** Notes */
    readonly notes?: Maybe<Scalars["String"]["output"]>;
    /** The value date (supplier payout date */
    readonly valueDate?: Maybe<Scalars["DateTime"]["output"]>;
};

export type FinanceHedgeListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

export enum Flight_Class {
    Business = "BUSINESS",
    Economy = "ECONOMY",
    FirstClass = "FIRST_CLASS",
    PremiumEconomy = "PREMIUM_ECONOMY",
}

export type Font = FontCustom | FontExternal;

export type FontCustom = {
    readonly __typename?: "FontCustom";
    readonly bold?: Maybe<Asset>;
    readonly boldItalic?: Maybe<Asset>;
    readonly medium?: Maybe<Asset>;
    readonly mediumItalic?: Maybe<Asset>;
    readonly regular: Asset;
    readonly regularItalic?: Maybe<Asset>;
};

export type FontCustomInput = {
    readonly bold?: InputMaybe<Scalars["AssetUrlInput"]["input"]>;
    readonly boldItalic?: InputMaybe<Scalars["AssetUrlInput"]["input"]>;
    readonly medium?: InputMaybe<Scalars["AssetUrlInput"]["input"]>;
    readonly mediumItalic?: InputMaybe<Scalars["AssetUrlInput"]["input"]>;
    readonly regular: Scalars["AssetUrlInput"]["input"];
    readonly regularItalic?: InputMaybe<Scalars["AssetUrlInput"]["input"]>;
};

export type FontExternal = {
    readonly __typename?: "FontExternal";
    readonly name: Scalars["String"]["output"];
};

export type FontInput = {
    readonly definition?: InputMaybe<FontCustomInput>;
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
};

export type GenericBookingAvailabilitySignalInput = {
    readonly bookingAvailabilityId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GenericBookingSignalInput = {
    readonly bookingId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GenericSignalResponseType = {
    readonly __typename?: "GenericSignalResponseType";
    /** If the signal was emitted successfully */
    readonly sent?: Maybe<Scalars["Boolean"]["output"]>;
};

/** Represents filter by geo circle at given geo location and radius */
export type GeoCircle = {
    readonly __typename?: "GeoCircle";
    readonly accuracy?: Maybe<GeoCircleAccuracy>;
    readonly latitude: Scalars["Latitude"]["output"];
    readonly longitude: Scalars["Longitude"]["output"];
    readonly radius: GeoCircleRadius;
};

export enum GeoCircleAccuracy {
    Address = "ADDRESS",
    Any = "ANY",
}

export type GeoCircleInput = {
    readonly accuracy?: InputMaybe<GeoCircleAccuracy>;
    readonly latitude: Scalars["Latitude"]["input"];
    readonly longitude: Scalars["Longitude"]["input"];
    readonly radius: GeoCircleRadiusInput;
};

export type GeoCircleRadius = {
    readonly __typename?: "GeoCircleRadius";
    readonly unit: GeoCircleRadiusUnit;
    readonly value: Scalars["PositiveFloat"]["output"];
};

export type GeoCircleRadiusInput = {
    readonly unit: GeoCircleRadiusUnit;
    readonly value: Scalars["PositiveFloat"]["input"];
};

export enum GeoCircleRadiusUnit {
    Kilometer = "kilometer",
    Mile = "mile",
}

/** This represents a GeoCoordinate */
export type GeoCoordinate = {
    readonly __typename?: "GeoCoordinate";
    /** The accuracy of the geo coordinate */
    readonly accuracy: Scalars["String"]["output"];
    /** createdAt */
    readonly createdAt?: Maybe<Scalars["Date"]["output"]>;
    /** The unique Holibob ID for the geo coordinate */
    readonly id: Scalars["String"]["output"];
    /** The latitude of the product */
    readonly latitude: Scalars["Float"]["output"];
    /** The longitude of the product */
    readonly longitude: Scalars["Float"]["output"];
    /** updatedAt */
    readonly updatedAt?: Maybe<Scalars["Date"]["output"]>;
};

/** This represents an Geo Rectangle filter */
export type GeoRectangleFilterInput = {
    /** accuracy */
    readonly accuracy?: InputMaybe<GeoRectangleFilterInputAccuracyType>;
    /** topLeft */
    readonly bottomRight?: InputMaybe<GeoRectangleFilterInputPointType>;
    /** topLeft */
    readonly topLeft?: InputMaybe<GeoRectangleFilterInputPointType>;
};

/** This represents accuracy of the geo location */
export enum GeoRectangleFilterInputAccuracyType {
    Address = "ADDRESS",
    Any = "ANY",
}

/** This represents point type */
export type GeoRectangleFilterInputPointType = {
    /** latitude */
    readonly latitude: Scalars["Float"]["input"];
    /** longitude */
    readonly longitude: Scalars["Float"]["input"];
};

/** This represents a guide language. */
export type GuideLanguage = {
    readonly __typename?: "GuideLanguage";
    /** The ISO language code. */
    readonly code?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID for the language. */
    readonly id: Scalars["String"]["output"];
    /** The name of the language. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
};

/** This represents a list of guide languages. */
export type GuideLanguageList = {
    readonly __typename?: "GuideLanguageList";
    readonly nodes: ReadonlyArray<GuideLanguage>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type Hbml = {
    readonly __typename?: "HBML";
    /** Enhanced HBML content */
    readonly enhanced: Scalars["JSON"]["output"];
    /** As a html string */
    readonly html: Scalars["String"]["output"];
    /** As markdown */
    readonly markdown: Scalars["String"]["output"];
    /** Raw HBML content */
    readonly raw: Scalars["JSON"]["output"];
    /** As plain text */
    readonly text: Scalars["String"]["output"];
};

export type HbmlInput = {
    /** Enhanced HBML content */
    readonly enhanced: Scalars["JSON"]["input"];
    /** As a html string */
    readonly html: Scalars["String"]["input"];
    /** As markdown */
    readonly markdown: Scalars["String"]["input"];
    /** Raw HBML content */
    readonly raw: Scalars["JSON"]["input"];
    /** As plain text */
    readonly text: Scalars["String"]["input"];
};

export type HeaderMenuEntry = {
    readonly __typename?: "HeaderMenuEntry";
    readonly children: ReadonlyArray<HeaderMenuEntry>;
    readonly data?: Maybe<HeaderMenuEntryData>;
    readonly name: Scalars["String"]["output"];
    readonly openInNewTab: Scalars["Boolean"]["output"];
};

export type HeaderMenuEntryData =
    | HeaderMenuEntryDataCuration
    | HeaderMenuEntryDataDistributionChannelPage
    | HeaderMenuEntryDataPath;

export type HeaderMenuEntryDataCuration = {
    readonly __typename?: "HeaderMenuEntryDataCuration";
    readonly id: Scalars["ID"]["output"];
};

export type HeaderMenuEntryDataDistributionChannelPage = {
    readonly __typename?: "HeaderMenuEntryDataDistributionChannelPage";
    readonly id: Scalars["ID"]["output"];
    readonly path: Scalars["String"]["output"];
    readonly type?: Maybe<DistributionChannelPageTypeType>;
};

export type HeaderMenuEntryDataPath = {
    readonly __typename?: "HeaderMenuEntryDataPath";
    readonly path: Scalars["String"]["output"];
};

/** This represents a HierarchyCurationGrouopMeta. */
export type HierarchyCurationGrouopMeta = {
    readonly __typename?: "HierarchyCurationGrouopMeta";
    readonly id: Scalars["String"]["output"];
};

export type HierarchyCurationListFilterType = {
    readonly curationIdList?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
    readonly curationSlugIdList?: InputMaybe<ReadonlyArray<Scalars["Hashids"]["input"]>>;
    readonly parentId?: InputMaybe<Scalars["String"]["input"]>;
};

export type HierarchyCurationListType = {
    readonly __typename?: "HierarchyCurationListType";
    readonly nodes: ReadonlyArray<CurationContentItemCuration>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type HierarchyCurationMeta = {
    readonly __typename?: "HierarchyCurationMeta";
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly descriptionTranslationList?: Maybe<LanguageTranslationJson>;
    /** Details about the author */
    readonly endContentTranslationList?: Maybe<LanguageTranslationJson>;
    readonly heroImageAsset?: Maybe<Asset>;
    readonly hierarchyId: Scalars["String"]["output"];
    readonly id: Scalars["String"]["output"];
    readonly nameTranslationList?: Maybe<LanguageTranslation>;
    readonly typeSystemEnumerationKey: Scalars["String"]["output"];
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type HierarchyCurationMetaInput = {
    readonly descriptionTranslationList?: InputMaybe<LanguageTranslationJsonInput>;
    /** Details about the author */
    readonly endContentTranslationList?: InputMaybe<LanguageTranslationJsonInput>;
    /** Hero image AssetUrl */
    readonly heroImageAsset?: InputMaybe<Scalars["AssetUrlInput"]["input"]>;
    readonly hierarchyId?: InputMaybe<Scalars["String"]["input"]>;
    readonly nameTranslationList?: InputMaybe<LanguageTranslationInput>;
    readonly typeSystemEnumerationKey: Scalars["String"]["input"];
};

export type HierarchyCurationProductAssociationToggleInput = {
    /** The curationId */
    readonly hierarchyParentId: Scalars["String"]["input"];
    /** The productIds to be associated with the curation */
    readonly productId: Scalars["String"]["input"];
};

export type HierarchyCurationProductAssociationToggleType = {
    readonly __typename?: "HierarchyCurationProductAssociationToggleType";
    readonly isSelected?: Maybe<Scalars["Boolean"]["output"]>;
};

export type HierarchyCurationProductMeta = {
    readonly __typename?: "HierarchyCurationProductMeta";
    readonly bannerBackgroundColor?: Maybe<Scalars["String"]["output"]>;
    readonly bannerTextTranslationList?: Maybe<LanguageTranslation>;
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly hierarchyId: Scalars["String"]["output"];
    readonly id: Scalars["String"]["output"];
    readonly productId: Scalars["String"]["output"];
    readonly score?: Maybe<Scalars["Int"]["output"]>;
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type HierarchyCurationProductMetaInput = {
    readonly bannerBackgroundColor?: InputMaybe<Scalars["String"]["input"]>;
    readonly bannerTextTranslationList?: InputMaybe<LanguageTranslationInput>;
    readonly hierarchyId?: InputMaybe<Scalars["String"]["input"]>;
    readonly productId: Scalars["String"]["input"];
    readonly score?: InputMaybe<Scalars["Int"]["input"]>;
};

/** This represents a HierarchyHeaderMenuMeta. */
export type HierarchyHeaderMenuMeta = {
    readonly __typename?: "HierarchyHeaderMenuMeta";
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly entityId?: Maybe<Scalars["String"]["output"]>;
    readonly entityType?: Maybe<HierarchyHeaderMenuMetaEntityTypeType>;
    readonly hierarchyId: Scalars["String"]["output"];
    readonly id: Scalars["String"]["output"];
    readonly isOpenInNewTab?: Maybe<Scalars["Boolean"]["output"]>;
    /** Translated name values */
    readonly nameTranslationList?: Maybe<LanguageTranslation>;
    readonly path?: Maybe<Scalars["String"]["output"]>;
    readonly type: HierarchyHeaderMenuMetaTypeType;
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export enum HierarchyHeaderMenuMetaEntityTypeType {
    Curation = "CURATION",
    DistributionChannelPage = "DISTRIBUTION_CHANNEL_PAGE",
}

export type HierarchyHeaderMenuMetaRecordInput = {
    readonly createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
    readonly entityId?: InputMaybe<Scalars["String"]["input"]>;
    readonly entityType?: InputMaybe<HierarchyHeaderMenuMetaEntityTypeType>;
    readonly hierarchyId?: InputMaybe<Scalars["String"]["input"]>;
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    readonly isOpenInNewTab?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Translated name values */
    readonly nameTranslationList?: InputMaybe<LanguageTranslationInput>;
    readonly path?: InputMaybe<Scalars["String"]["input"]>;
    readonly type?: InputMaybe<HierarchyHeaderMenuMetaTypeType>;
    readonly updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export enum HierarchyHeaderMenuMetaTypeType {
    Entity = "ENTITY",
    Folder = "FOLDER",
    Path = "PATH",
}

export type HierarchyIsSelectedCriteriaType = {
    /** Hierarchy parent Node Id */
    readonly parentId?: InputMaybe<Scalars["String"]["input"]>;
};

export type HierarchyLinkAssociationToggleInput = {
    /** The hierarchy parent id where the link will be added to */
    readonly hierarchyParentId: Scalars["String"]["input"];
    /** The hierarchy node id to link with */
    readonly targetHierarchyId: Scalars["String"]["input"];
};

export type HierarchyLinkAssociationToggleType = {
    readonly __typename?: "HierarchyLinkAssociationToggleType";
    readonly isSelected?: Maybe<Scalars["Boolean"]["output"]>;
};

export type HierarchyList = {
    readonly __typename?: "HierarchyList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<Maybe<HierarchyNode>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    readonly parentNode?: Maybe<HierarchyNode>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type HierarchyListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type HierarchyListFilter = {
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    readonly ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    readonly parentId?: InputMaybe<Scalars["String"]["input"]>;
    readonly typeSystemEnumerationKey?: InputMaybe<HierarchyNodeTypeSystemEnumerationKey>;
    readonly typeSystemEnumerationKeyList?: InputMaybe<
        ReadonlyArray<InputMaybe<HierarchyNodeTypeSystemEnumerationKey>>
    >;
    /** If positive, the result will include link nodes that point to nodes with the provided filter typeSystemEnumerationKey(list) */
    readonly withLinksByTypeSystemEnumerationKey?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type HierarchyListSort = {
    /** Sort by type. */
    readonly ordinalPosition?: InputMaybe<SortDirection>;
};

/** This represents a HierarchyNode. */
export type HierarchyNode = {
    readonly __typename?: "HierarchyNode";
    readonly ancestorList?: Maybe<HierarchyNodeParentList>;
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly curationSlugId: Scalars["String"]["output"];
    readonly effectiveName: Scalars["String"]["output"];
    readonly id: Scalars["String"]["output"];
    /** Evaluate hierarchy node selection based on conditions. */
    readonly isSelected?: Maybe<Scalars["Boolean"]["output"]>;
    readonly isVirtualGroupNode?: Maybe<Scalars["Boolean"]["output"]>;
    /** Hierarchy node meta data. */
    readonly metaData?: Maybe<HierarchyNodeMetaData>;
    /** Translated name values. */
    readonly nameTranslationList?: Maybe<LanguageTranslation>;
    readonly ordinalPosition: Scalars["Int"]["output"];
    readonly parentId?: Maybe<Scalars["String"]["output"]>;
    readonly rootName: Scalars["String"]["output"];
    readonly typeName: Scalars["String"]["output"];
    readonly typeSystemEnumerationKey: HierarchyNodeTypeSystemEnumerationKey;
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly withVirtualGroups?: Maybe<Scalars["Boolean"]["output"]>;
};

/** This represents a HierarchyNode. */
export type HierarchyNodeIsSelectedArgs = {
    criteria?: InputMaybe<HierarchyIsSelectedCriteriaType>;
};

export type HierarchyNodeDeleteInput = {
    /** The hierarchy node id to be deleted */
    readonly id: Scalars["String"]["input"];
};

export type HierarchyNodeDeleteType = {
    readonly __typename?: "HierarchyNodeDeleteType";
    readonly deleted?: Maybe<Scalars["Boolean"]["output"]>;
};

export type HierarchyNodeInput = {
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    readonly nameTranslationList?: InputMaybe<LanguageTranslationInput>;
    readonly ordinalPosition?: InputMaybe<Scalars["Int"]["input"]>;
    readonly parentId?: InputMaybe<Scalars["String"]["input"]>;
    readonly typeSystemEnumerationKey?: InputMaybe<HierarchyNodeTypeSystemEnumerationKey>;
};

export type HierarchyNodeMetaData =
    | HierarchyCurationGrouopMeta
    | HierarchyCurationMeta
    | HierarchyCurationProductMeta
    | HierarchyHeaderMenuMeta;

/** This represents a HierarchyNode. */
export type HierarchyNodeParent = {
    readonly __typename?: "HierarchyNodeParent";
    readonly depth: Scalars["Int"]["output"];
    readonly effectiveName: Scalars["String"]["output"];
    readonly id: Scalars["String"]["output"];
};

export type HierarchyNodeParentList = {
    readonly __typename?: "HierarchyNodeParentList";
    readonly nodes: ReadonlyArray<HierarchyNodeParent>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export enum HierarchyNodeTypeSystemEnumerationKey {
    HierarchyNodeCuration = "HIERARCHY_NODE_CURATION",
    HierarchyNodeCurationGroup = "HIERARCHY_NODE_CURATION_GROUP",
    HierarchyNodeCurationProduct = "HIERARCHY_NODE_CURATION_PRODUCT",
    HierarchyNodeFolder = "HIERARCHY_NODE_FOLDER",
    HierarchyNodeGraphLink = "HIERARCHY_NODE_GRAPH_LINK",
    HierarchyNodeHeaderMenu = "HIERARCHY_NODE_HEADER_MENU",
    HierarchyNodeSystemTag = "HIERARCHY_NODE_SYSTEM_TAG",
    HierarchyNodeTaxonomyProductAttibute = "HIERARCHY_NODE_TAXONOMY_PRODUCT_ATTIBUTE",
    HierarchyNodeTaxonomyProductCategory = "HIERARCHY_NODE_TAXONOMY_PRODUCT_CATEGORY",
}

export type Icon = {
    readonly __typename?: "Icon";
    readonly definition: RawIconDefinition;
    readonly family: Scalars["String"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly style: Scalars["String"]["output"];
};

export type IconList = {
    readonly __typename?: "IconList";
    readonly nodes: ReadonlyArray<Icon>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type IconListFilter = {
    readonly first?: InputMaybe<Scalars["Int"]["input"]>;
    readonly search: Scalars["String"]["input"];
};

export type Language = {
    readonly __typename?: "Language";
    readonly code: Scalars["String"]["output"];
    readonly codeShort: Scalars["String"]["output"];
    readonly graphQLCode: Scalars["String"]["output"];
    readonly id: Scalars["String"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly urlCode: Scalars["String"]["output"];
};

export enum LanguageCode {
    ArAe = "ar-AE",
    BgBg = "bg-BG",
    BsLatn = "bs-Latn",
    CaEs = "ca-ES",
    CsCz = "cs-CZ",
    DaDk = "da-DK",
    DeDe = "de-DE",
    EnGb = "en-GB",
    EsEs = "es-ES",
    FiFi = "fi-FI",
    FrFr = "fr-FR",
    HeIl = "he-IL",
    HiIn = "hi-IN",
    HrHr = "hr-HR",
    HuHu = "hu-HU",
    IdId = "id-ID",
    IsIs = "is-IS",
    ItIt = "it-IT",
    JaJp = "ja-JP",
    KoKr = "ko-KR",
    LtLt = "lt-LT",
    NbNo = "nb-NO",
    NlNl = "nl-NL",
    NnNo = "nn-NO",
    NoNo = "no-NO",
    PlPl = "pl-PL",
    PtPt = "pt-PT",
    RuRu = "ru-RU",
    SkSk = "sk-SK",
    SvSe = "sv-SE",
    TaIn = "ta-IN",
    ThTh = "th-TH",
    TlTl = "tl-TL",
    TrTr = "tr-TR",
    ZhCn = "zh-CN",
}

/** LanguageStaticTranslation */
export type LanguageStaticTranslation = {
    readonly __typename?: "LanguageStaticTranslation";
    /** ar-AEanslation */
    readonly arAE?: Maybe<Scalars["String"]["output"]>;
    /** The date this item was created */
    readonly createdAt: Scalars["DateTime"]["output"];
    /** de-DEranslation */
    readonly deDE?: Maybe<Scalars["String"]["output"]>;
    /** en-GB Translation */
    readonly enGB?: Maybe<Scalars["String"]["output"]>;
    /** es-ESranslation */
    readonly esES?: Maybe<Scalars["String"]["output"]>;
    /** fr-FRranslation */
    readonly frFR?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID */
    readonly id: Scalars["String"]["output"];
    /** it-ITranslation */
    readonly itIT?: Maybe<Scalars["String"]["output"]>;
    /** Unique identifier within a given scope */
    readonly key: Scalars["String"]["output"];
    /** nl-NL Translation */
    readonly nlNL?: Maybe<Scalars["String"]["output"]>;
    /** pt-BR Translation */
    readonly ptBR?: Maybe<Scalars["String"]["output"]>;
    /** ru-RU Translation */
    readonly ruRU?: Maybe<Scalars["String"]["output"]>;
    /** The scope of the translation - which application does it apply to */
    readonly scope: Scalars["String"]["output"];
    /** The date this item was updated */
    readonly updatedAt: Scalars["DateTime"]["output"];
    /** JSON Array that lists translations that have been verified */
    readonly verifiedTranslation: ReadonlyArray<Maybe<Scalars["String"]["output"]>>;
};

export type LanguageStaticTranslationInputType = {
    /** ar-AEanslation */
    readonly arAE?: InputMaybe<Scalars["String"]["input"]>;
    /** de-DEranslation */
    readonly deDE?: InputMaybe<Scalars["String"]["input"]>;
    /** en-GB Translation */
    readonly enGB?: InputMaybe<Scalars["String"]["input"]>;
    /** es-ESranslation */
    readonly esES?: InputMaybe<Scalars["String"]["input"]>;
    /** fr-FRranslation */
    readonly frFR?: InputMaybe<Scalars["String"]["input"]>;
    /** The unique ID */
    readonly id: Scalars["String"]["input"];
    /** it-ITranslation */
    readonly itIT?: InputMaybe<Scalars["String"]["input"]>;
    /** Unique identifier within a given scope */
    readonly key: Scalars["String"]["input"];
    /** nl-NL Translation */
    readonly nlNL?: InputMaybe<Scalars["String"]["input"]>;
    /** pt-BR Translation */
    readonly ptBR?: InputMaybe<Scalars["String"]["input"]>;
    /** ru-RU Translation */
    readonly ruRU?: InputMaybe<Scalars["String"]["input"]>;
    /** The scope of the translation - which application does it apply to */
    readonly scope: Scalars["String"]["input"];
    /** JSON Array that lists translations that have been verified */
    readonly verifiedTranslation: ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>;
};

export type LanguageStaticTranslationListFilter = {
    readonly language?: InputMaybe<Scalars["String"]["input"]>;
    /** Use this filter to perform various types of search. */
    readonly match?: InputMaybe<MatchFilterInput>;
    readonly scope?: InputMaybe<Scalars["String"]["input"]>;
    readonly verified?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a LanguageStaticTranslation */
export type LanguageStaticTranslationListItem = {
    readonly __typename?: "LanguageStaticTranslationListItem";
    /** ar-AEanslation */
    readonly arAE?: Maybe<Scalars["String"]["output"]>;
    /** The date this item was created */
    readonly createdAt: Scalars["DateTime"]["output"];
    /** de-DEranslation */
    readonly deDE?: Maybe<Scalars["String"]["output"]>;
    /** en-GB Translation */
    readonly enGB?: Maybe<Scalars["String"]["output"]>;
    /** es-ESranslation */
    readonly esES?: Maybe<Scalars["String"]["output"]>;
    /** fr-FRranslation */
    readonly frFR?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID */
    readonly id: Scalars["String"]["output"];
    /** it-ITranslation */
    readonly itIT?: Maybe<Scalars["String"]["output"]>;
    /** Unique identifier within a given scope */
    readonly key: Scalars["String"]["output"];
    /** nl-NL Translation */
    readonly nlNL?: Maybe<Scalars["String"]["output"]>;
    /** pt-BR Translation */
    readonly ptBR?: Maybe<Scalars["String"]["output"]>;
    /** ru-RU Translation */
    readonly ruRU?: Maybe<Scalars["String"]["output"]>;
    /** The scope of the translation - which application does it apply to */
    readonly scope: Scalars["String"]["output"];
    /** The date this item was updated */
    readonly updatedAt: Scalars["DateTime"]["output"];
    /** JSON Array that lists translations that have been verified */
    readonly verifiedTranslation: ReadonlyArray<Maybe<Scalars["String"]["output"]>>;
};

/** This respresents the LanguageStaticTranslationList response type */
export type LanguageStaticTranslationListResponse = {
    readonly __typename?: "LanguageStaticTranslationListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** LanguageStaticTranslation list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<LanguageStaticTranslationListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type LanguageTranslation = {
    readonly __typename?: "LanguageTranslation";
    readonly nodes: ReadonlyArray<LanguageTranslationEntry>;
    readonly rootLanguage: LanguageCode;
};

export type LanguageTranslationEntry = {
    readonly __typename?: "LanguageTranslationEntry";
    readonly language: LanguageCode;
    readonly value: Scalars["String"]["output"];
};

export type LanguageTranslationEntryInput = {
    readonly language: LanguageCode;
    readonly value: Scalars["String"]["input"];
};

export type LanguageTranslationEntryJson = {
    readonly __typename?: "LanguageTranslationEntryJson";
    readonly language: LanguageCode;
    readonly valueJson: Scalars["JSON"]["output"];
};

export type LanguageTranslationEntryJsonInput = {
    readonly language: LanguageCode;
    readonly valueJson: Scalars["JSON"]["input"];
};

export type LanguageTranslationHbml = {
    readonly __typename?: "LanguageTranslationHBML";
    readonly nodes: ReadonlyArray<LanguageTranslationHbmlEntry>;
    readonly rootLanguage: LanguageCode;
};

export type LanguageTranslationHbmlEntry = {
    readonly __typename?: "LanguageTranslationHBMLEntry";
    readonly language: LanguageCode;
    readonly valueJson?: Maybe<Hbml>;
};

export type LanguageTranslationHbmlEntryInput = {
    readonly language: LanguageCode;
    readonly valueJson: Scalars["JSON"]["input"];
};

export type LanguageTranslationHbmlInput = {
    readonly nodes: ReadonlyArray<LanguageTranslationHbmlEntryInput>;
    readonly rootLanguage: LanguageCode;
};

export type LanguageTranslationInput = {
    readonly nodes: ReadonlyArray<LanguageTranslationEntryInput>;
    readonly rootLanguage: LanguageCode;
};

export type LanguageTranslationJson = {
    readonly __typename?: "LanguageTranslationJson";
    readonly nodes: ReadonlyArray<LanguageTranslationEntryJson>;
    readonly rootLanguage: LanguageCode;
};

export type LanguageTranslationJsonInput = {
    readonly nodes: ReadonlyArray<LanguageTranslationEntryJsonInput>;
    readonly rootLanguage: LanguageCode;
};

/** This represents a LegalEntity. */
export type LegalEntity = {
    readonly __typename?: "LegalEntity";
    /** The unique id for the legal entity. */
    readonly id?: Maybe<Scalars["String"]["output"]>;
    /** The main phone number of the legal entity. */
    readonly phoneMain?: Maybe<Scalars["String"]["output"]>;
};

export type LegalEntityInputType = {
    /** The unique id for the legal entity. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The main phone number of the legal entity. */
    readonly phoneMain?: InputMaybe<Scalars["String"]["input"]>;
};

export type LinkOAuthAccountInput = {
    readonly provider: Scalars["String"]["input"];
    readonly providerAccountId: Scalars["ID"]["input"];
    readonly userId: Scalars["ID"]["input"];
};

export type Mark = {
    readonly __typename?: "Mark";
    readonly label: Scalars["String"]["output"];
    readonly value: Scalars["Int"]["output"];
};

/** This represents an match filter */
export type MatchFilterInput = {
    /** match type */
    readonly type: MatchFilterInputType;
    /** value */
    readonly value: Scalars["String"]["input"];
};

/** This represents match type */
export enum MatchFilterInputType {
    Begins = "BEGINS",
    Contains = "CONTAINS",
    Exact = "EXACT",
}

export type MaxParticipantsDepends = {
    readonly __typename?: "MaxParticipantsDepends";
    readonly explanation?: Maybe<Scalars["String"]["output"]>;
    readonly multiplier?: Maybe<Scalars["String"]["output"]>;
    readonly pricingCategoryId?: Maybe<Scalars["String"]["output"]>;
};

/** This represents a Message. */
export type Message = {
    readonly __typename?: "Message";
    /** The message content. */
    readonly content: Scalars["String"]["output"];
    /** When the message was createdAt. */
    readonly createdAt: Scalars["DateTime"]["output"];
    /** The message direction. */
    readonly direction: Scalars["String"]["output"];
    /** The id. */
    readonly id: Scalars["String"]["output"];
    /** The message that this message was a response to */
    readonly replyToMessage?: Maybe<ResponseMessage>;
    /** The message status. */
    readonly status: Scalars["String"]["output"];
};

/** This represents a list of Message. */
export type MessageList = {
    readonly __typename?: "MessageList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<Message>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

/** This represents a list of Message. */
export type MessageListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** This represents a list of Message. */
export type MessageListTotalArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MessageListFilter = {
    /** filter by contactDetail. */
    readonly contactDetail?: InputMaybe<Scalars["String"]["input"]>;
    /** filter by type. */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type Mutations = {
    readonly __typename?: "Mutations";
    readonly analyticLinkOriginCreate: AnalyticLinkOrigin;
    readonly analyticLinkOriginDelete: Scalars["Boolean"]["output"];
    /** Upsert a article */
    readonly articleUpsert?: Maybe<Article>;
    readonly authUserLogin: AuthUserLoginResult;
    readonly authenticate: AuthenticateResult;
    /** Used to create a clone of a BookingAvailability's original availability */
    readonly availabilityFromBookingAvailability: Availability;
    /** Use to add a finalised availability (by id) to a booking, you must have first satisfied all questions on the availability and have created a booking. */
    readonly bookingAddAvailability?: Maybe<Booking>;
    /**
     * Amend the availability of a booking. This will create a new booking that has an origin booking availability of the supplied availabilityId, then signal the intent to amend the availability of the origin booking to the new booking.
     * @deprecated DEPRECATED - use bookingAddAvailability with originBookingAvailabilityId
     */
    readonly bookingAmendAvailabilityIntent?: Maybe<Booking>;
    /** Revert the pending amendment intent of the booking availability. */
    readonly bookingAvailabilityAmendmentIntentRevert: BookingAvailability;
    /** Use to mark BookingAvailabilities as consumed. This is used to indicate that the booking has been consumed by the customer. */
    readonly bookingAvailabilityConsume?: Maybe<BookingAvailabilityConsumptionType>;
    /** Use to cancel a booking. By doing so you are cancelling the booking with the supplier. */
    readonly bookingCancel?: Maybe<Booking>;
    /** Use to commit a booking. By doing so you are finalising the booking with the supplier and you become financially liable for the transaction. If you are operating against production in SANDBOX mode the commit will be simulated, no booking will actually be made with the supplier and there is no financial commitment. This mutation will return the final status of the booking and if COMPLETE or SANDBOX_COMPLETE you will be delivered the URL from which the voucher can be retrieved either immediately or at any time in the future. */
    readonly bookingCommit?: Maybe<Booking>;
    /** Use to create a new empty booking. Think of this much as an empty shopping cart. The next step will be to use the bookingAddAvailability to add one or more finalised availabilities to this booking before satisfying any required bookingQuestions and then calling the bookingCommit mutation. */
    readonly bookingCreate?: Maybe<Booking>;
    /** Use to delete a finalised availability (by id) to a booking, you must have first satisfied all questions on the availability and have created a booking. */
    readonly bookingDeleteAvailability: Booking;
    /** Edit a booking */
    readonly bookingEdit?: Maybe<Booking>;
    /** Use to cancel a booking payment intent. */
    readonly bookingPaymentIntentCancel?: Maybe<Scalars["Boolean"]["output"]>;
    readonly bookingQuestionUpload?: Maybe<BookingQuestionUpload>;
    /**
     * Replace the availability of a booking. This will delete the availability of the booking with the supplied availabilityId as long as it is in open state, then create a new booking with the supplied newAvailabilityId.
     * @deprecated DEPRECATED - use bookingAddAvailability with originBookingAvailabilityId
     */
    readonly bookingReplaceAvailability?: Maybe<Booking>;
    /**
     * Revert the amendment of the availability of a booking. This will delete the booking with the supplied availabilityId as long as it is in open state, then revert the availability of the origin booking to the availability of the booking.
     * @deprecated DEPRECATED - use bookingAvailabilityAmendmentIntentRevert
     */
    readonly bookingRevertAmendAvailabilityIntent: GenericSignalResponseType;
    readonly brandCreate: Brand;
    readonly brandDelete?: Maybe<Scalars["Boolean"]["output"]>;
    readonly brandUpdate?: Maybe<Brand>;
    /** Upsert an company entity system configuration association upsert. */
    readonly companyEntityConfigurationUpsert?: Maybe<EntitySystemConfigurationAssociationListNode>;
    readonly consumerCreate: ConsumerConcrete;
    readonly consumerTrip: ConsumerTrip;
    readonly consumerTripAssociateSystemTagList: Scalars["Boolean"]["output"];
    readonly consumerTripCreate: ConsumerTripConcrete;
    readonly consumerTripEventCreate: Scalars["Boolean"]["output"];
    readonly consumerTripUpdate: ConsumerTripConcrete;
    readonly consumerUpdate: ConsumerConcrete;
    /** Upsert the ContentComponent */
    readonly contentComponentUpsert?: Maybe<ContentComponent>;
    /** Creates/updates a new DistributionChannelAuthor */
    readonly distributionChannelAuthorUpsert?: Maybe<DistributionChannelAuthor>;
    /** Creates/updates a new DistributionChannelPageCategory */
    readonly distributionChannelPageCategoryUpsert?: Maybe<DistributionChannelPageCategory>;
    /** Creates/updates a new DistributionChannelPage */
    readonly distributionChannelPageUpsert?: Maybe<DistributionChannelPage>;
    readonly distributionChannelTranslationExport: TranslationExport;
    readonly distributionChannelTranslationImport: TranslationImport;
    /** Creates a new DistributionChannel */
    readonly distributionChannelUpsert?: Maybe<DistributionChannel>;
    /** Upsert the EntityComment */
    readonly entityCommentUpsert?: Maybe<EntityComment>;
    /** deletes a entity commission  */
    readonly entityCommissionDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert a entityCommission */
    readonly entityCommissionUpsert?: Maybe<EntityCommission>;
    /** Upsert the EntityConfiguration */
    readonly entityConfigurationUpsert?: Maybe<EntitySystemConfiguration>;
    /** Delete a entity question record. */
    readonly entityQuestionDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert a entity question record. */
    readonly entityQuestionUpsert?: Maybe<EntityQuestion>;
    readonly entitySecretDelete?: Maybe<Scalars["Void"]["output"]>;
    readonly entitySecretSave?: Maybe<Scalars["Void"]["output"]>;
    /** Upsert a list of entity system configuration associations. */
    readonly entitySystemConfigurationListUpsert: ReadonlyArray<EntitySystemConfigurationAssociationListNode>;
    /** Delete a entity system tag asssociation records */
    readonly entitySystemTagAssociationDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Add/Delete user's favourite product. */
    readonly favouriteProduct?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert a new HierarchyCurationMeta */
    readonly hierarchyCurationMetaUpsert?: Maybe<HierarchyCurationMeta>;
    /** Toggle a curation product association */
    readonly hierarchyCurationProductAssociationToggle?: Maybe<HierarchyCurationProductAssociationToggleType>;
    /** Upsert a new HierarchyCurationProductMeta */
    readonly hierarchyCurationProductMetaUpsert?: Maybe<HierarchyCurationProductMeta>;
    /** Upsert a new HierarchyHeaderMenuMeta */
    readonly hierarchyHeaderMenuMetaUpsert?: Maybe<HierarchyHeaderMenuMeta>;
    /** Toggle a hierarchy link association */
    readonly hierarchyLinkAssociationToggle?: Maybe<HierarchyLinkAssociationToggleType>;
    /** Delete a Hierarchy Node */
    readonly hierarchyNodeDelete?: Maybe<HierarchyNodeDeleteType>;
    /** Upsert a new Hierarchy node */
    readonly hierarchyNodeUpsert?: Maybe<HierarchyNode>;
    /** Update a LanguageStaticTranslation */
    readonly languageStaticTranslationUpsert?: Maybe<LanguageStaticTranslation>;
    /** Mutation to send whatsapp message using braze canvas */
    readonly launchBrazeCanvas?: Maybe<Scalars["Boolean"]["output"]>;
    readonly linkOAuthAccount: AuthAccount;
    /** Accept or reject an onboarding request */
    readonly onboardRequestApproval?: Maybe<OnboardRequestType>;
    /** Upsert an onboard request */
    readonly onboardRequestUpsert?: Maybe<OnboardRequestType>;
    /** Delete a Operator */
    readonly operatorDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Delete a OperatorGroup */
    readonly operatorGroupDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert a operatorGroup record. */
    readonly operatorGroupUpsert?: Maybe<OperatorGroup>;
    /** Upsert a operator record. */
    readonly operatorUpsert?: Maybe<Operator>;
    /** deletes a partnerChannelBookingQuestion  */
    readonly partnerChannelBookingQuestionDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert a partner channel booking question */
    readonly partnerChannelBookingQuestionUpsert?: Maybe<PartnerChannelBookingQuestion>;
    /** Upsert a partnerChannel */
    readonly partnerChannelUpsert?: Maybe<PartnerChannel>;
    /** Update a partner */
    readonly partnerUpdate?: Maybe<Partner>;
    /** Update a placeAlias */
    readonly placeAliasUpdate?: Maybe<PlaceAlias>;
    /** Creates a place */
    readonly placeFromAliasCreate?: Maybe<PlaceAlias>;
    /** Upsert a place */
    readonly placeUpsert?: Maybe<Place>;
    /** Delete the PlaygroundQuery. */
    readonly playgroundQueryDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert the PlaygroundQuery */
    readonly playgroundQueryUpsert?: Maybe<PlaygroundQuery>;
    /** Delete the ProductAttachment. */
    readonly productAttachmentDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert the ProductAttachment */
    readonly productAttachmentUpsert?: Maybe<ProductAttachment>;
    /** Delete the ProductAttributeAssociation. */
    readonly productAttributeAssociationDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert the ProductAttributeAssociation */
    readonly productAttributeAssociationUpsert?: Maybe<ProductAttributeAssociation>;
    /** Delete the ProductAttribute. */
    readonly productAttributeDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert the ProductAttribute */
    readonly productAttributeUpsert?: Maybe<ProductAttribute>;
    /** Clone a productAvailabilityPattern */
    readonly productAvailabilityPatternClone?: Maybe<ProductAvailabilityPattern>;
    /** Delete a ProductAvailabilityPattern */
    readonly productAvailabilityPatternDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert a availability pattern */
    readonly productAvailabilityPatternUpsert?: Maybe<ProductAvailabilityPattern>;
    /** Delete the ProductCancellationPenalty. */
    readonly productCancellationPenaltyDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert the ProductCancellationPenalty */
    readonly productCancellationPenaltyUpsert?: Maybe<ProductCancellationPenalty>;
    /** Delete a ProductCategoryAssociation */
    readonly productCategoryAssociationDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Toggle a category product association */
    readonly productCategoryAssociationToggle?: Maybe<ProductCategoryAssociationToggleType>;
    /** Upsert a ProductCategoryAssociation */
    readonly productCategoryAssociationUpsert?: Maybe<ProductCategoryAssociation>;
    /** Upsert a productCategory */
    readonly productCategoryUpsert?: Maybe<ProductCategory>;
    /** Associate a system tag id to a product chat GPT prompt response tag */
    readonly productChatGPTPromptResponseTagAssociate: ProductChatGptPromptResponseTag;
    /** Clone a product */
    readonly productClone?: Maybe<Product>;
    /** Delete a ProductContent */
    readonly productContentDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert a product content */
    readonly productContentUpsert?: Maybe<ProductContent>;
    /** Delete the Product. */
    readonly productDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Delete a ProductImage */
    readonly productImageDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert a productImage */
    readonly productImageUpsert?: Maybe<ProductImage>;
    /** Delete a ProductInventory */
    readonly productInventoryDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert a availability pattern */
    readonly productInventoryUpsert?: Maybe<ProductInventory>;
    readonly productListAiAnalyze?: Maybe<Scalars["Boolean"]["output"]>;
    /** Update a product meta */
    readonly productMetaUpdate?: Maybe<ProductMeta>;
    /** deletes a productReview  */
    readonly productReviewDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert a product review */
    readonly productReviewUpsert?: Maybe<ProductReview>;
    /** Toggle a productSaleRestriction. */
    readonly productSaleRestrictionToggle?: Maybe<ProductSaleRestrictionToggleType>;
    /** Set a productSaleRestriction. */
    readonly productSaleRestrictionUpsert?: Maybe<ProductSaleRestrictionUpsertType>;
    /** Delete a ProductStartTime */
    readonly productStartTimeDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert a product content */
    readonly productStartTimeUpsert?: Maybe<ProductStartTime>;
    /** Update a product translation */
    readonly productTranslationUpdate?: Maybe<ProductTranslation>;
    /** Update a product */
    readonly productUpsert?: Maybe<Product>;
    /** Delete the Provider. */
    readonly providerDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert the Provider */
    readonly providerUpsert?: Maybe<Provider>;
    readonly questionCreate: Question;
    readonly questionUpdate: Question;
    /** Create db recrds bese on a template */
    readonly recordsFromTemplateUpsert?: Maybe<RecordsFromTemplate>;
    /** Refresh productList cache. */
    readonly refreshCache?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert Restaurant product associations. */
    readonly restaurantProductAssociationDelete?: Maybe<RestaurantProductAssociationDelete>;
    /** Upsert Restaurant product associations. */
    readonly restaurantProductAssociationUpsert?: Maybe<RestaurantProductAssociationUpsert>;
    /** Creates a new sale agency */
    readonly saleAgencyCreate: SaleAgency;
    /** deletes a user  */
    readonly saleAgencyDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Update a Sale Agency */
    readonly saleAgencyUpdate?: Maybe<SaleAgency>;
    /** Change a securityPrincipal parentId */
    readonly securityPrincipalChangeParent?: Maybe<Scalars["Boolean"]["output"]>;
    readonly securityPrincipalPermissionListUpsert?: Maybe<Scalars["Boolean"]["output"]>;
    /** Update a securityPrincipal */
    readonly securityPrincipalUpdate?: Maybe<SecurityPrincipal>;
    /** creates a new securityPrincipalUser  */
    readonly securityPrincipalUserCreate: SecurityPrincipalUser;
    /** deletes a user  */
    readonly securityPrincipalUserDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Migrate a securityPrincipalUser */
    readonly securityPrincipalUserMigrate?: Maybe<Scalars["Boolean"]["output"]>;
    /** Resets the password of a securityPrincipalUser  */
    readonly securityPrincipalUserResetPassword: Scalars["Boolean"]["output"];
    /** Requests a reset of securityPrincipalUser's password */
    readonly securityPrincipalUserResetPasswordRequest?: Maybe<Scalars["Boolean"]["output"]>;
    /** Claims a password reset request */
    readonly securityPrincipalUserResetPasswordRequestClaim?: Maybe<SecurityPrincipalUserResetPasswordRequestClaimResponse>;
    /** Signup a securityPrincipalUser */
    readonly securityPrincipalUserSignup?: Maybe<SecurityPrincipalUserSignupResponse>;
    /** Update a securityPrincipalUser */
    readonly securityPrincipalUserUpdate?: Maybe<SecurityPrincipalUser>;
    /** Delete the SecurityRule. */
    readonly securityRuleDelete?: Maybe<Scalars["Boolean"]["output"]>;
    readonly securityRulePrincipalAssociationListUpsert?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert the SecurityRule */
    readonly securityRuleUpsert?: Maybe<SecurityRule>;
    readonly signalBookingAcknowledgementEmail: GenericSignalResponseType;
    readonly signalBookingAvailabilityAccept?: Maybe<SignalBookingAvailabilityAcceptResponse>;
    readonly signalBookingAvailabilityAdditionalData: GenericSignalResponseType;
    /** @deprecated DEPRECATED - use bookingAddAvailability with originBookingAvailabilityId. Sending amendment intent without replacement booking availability id does nothing. */
    readonly signalBookingAvailabilityAmendmentIntent: GenericSignalResponseType;
    readonly signalBookingAvailabilityCancellationAccept?: Maybe<SignalBookingAvailabilityAcceptResponse>;
    readonly signalBookingAvailabilityCancellationEmail: GenericSignalResponseType;
    readonly signalBookingAvailabilityCancellationIntent: GenericSignalResponseType;
    readonly signalBookingAvailabilityCancellationReject?: Maybe<SignalBookingAvailabilityAcceptResponse>;
    readonly signalBookingAvailabilityCancellationRevert: GenericSignalResponseType;
    readonly signalBookingAvailabilityConfirm: GenericSignalResponseType;
    readonly signalBookingAvailabilityConfirmState: GenericSignalResponseType;
    readonly signalBookingAvailabilityConfirmationEmail: GenericSignalResponseType;
    readonly signalBookingAvailabilityForcedCancellationIntent: GenericSignalResponseType;
    readonly signalBookingAvailabilityGenerateTicket: GenericSignalResponseType;
    readonly signalBookingAvailabilityInitialise: GenericSignalResponseType;
    /** Use to signal an intent to cancel a list of bookingAvailabilities */
    readonly signalBookingAvailabilityListCancellationIntent: GenericSignalResponseType;
    /** Use to signal an intent to force cancel a list of bookingAvailabilities */
    readonly signalBookingAvailabilityListForceCancellationIntent: GenericSignalResponseType;
    readonly signalBookingAvailabilityPreArrivalInstructionsEmail: GenericSignalResponseType;
    readonly signalBookingAvailabilityReject?: Maybe<SignalBookingAvailabilityAcceptResponse>;
    readonly signalBookingAvailabilityReminderEmail: GenericSignalResponseType;
    readonly signalBookingAvailabilityRequestCancellationEmail: GenericSignalResponseType;
    readonly signalBookingAvailabilityRequestConfirmationEmail: GenericSignalResponseType;
    readonly signalBookingAvailabilityReviewEmail: GenericSignalResponseType;
    readonly signalBookingAvailabilitySourceTicket: GenericSignalResponseType;
    readonly signalBookingAvailabilitySupplierCancellationAccept?: Maybe<SignalBookingAvailabilityAcceptResponse>;
    readonly signalBookingAvailabilitySupplierCancellationReject?: Maybe<SignalBookingAvailabilityAcceptResponse>;
    readonly signalBookingAvailabilitySupplierCommitAccept?: Maybe<SignalBookingAvailabilityAcceptResponse>;
    readonly signalBookingAvailabilitySupplierCommitReject?: Maybe<SignalBookingAvailabilityAcceptResponse>;
    readonly signalBookingBookingAvailabilityListCommit: GenericSignalResponseType;
    readonly signalBookingBookingAvailabilityListConfirm: GenericSignalResponseType;
    readonly signalBookingBookingAvailabilityListNotificationEmail: GenericSignalResponseType;
    readonly signalBookingGenerateVoucher: GenericSignalResponseType;
    readonly signalBookingInitialise: GenericSignalResponseType;
    readonly signalBookingProcessPayment: GenericSignalResponseType;
    readonly signalBookingProcessPaymentIntent: GenericSignalResponseType;
    readonly signalBookingSummaryEmail: GenericSignalResponseType;
    /** Delete the SupplierFinance. */
    readonly supplierFinanceDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert a supplierFinance */
    readonly supplierFinanceUpsert?: Maybe<SupplierFinance>;
    /** Onboard a Suppplier */
    readonly supplierOnboard?: Maybe<SupplierOnboardResponse>;
    /** Sets supplier survey data */
    readonly supplierProductSurvey?: Maybe<Scalars["Boolean"]["output"]>;
    /** Sets supplier survey data */
    readonly supplierSurvey?: Maybe<Scalars["Boolean"]["output"]>;
    /** Update a supplierSurvey */
    readonly supplierSurveyUpdate?: Maybe<SupplierSurvey>;
    /** Upsert a supplier record. */
    readonly supplierUpsert?: Maybe<Supplier>;
    /** Creates a system page state */
    readonly systemPageStateCreate?: Maybe<Scalars["Boolean"]["output"]>;
    /** Deletes a system page state */
    readonly systemPageStateDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Delete the SystemTemplate. */
    readonly systemTemplateDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert the SystemTemplate */
    readonly systemTemplateUpsert?: Maybe<SystemTemplate>;
    /** deletes a test booking  */
    readonly testBookingDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert a test booking */
    readonly testBookingUpsert?: Maybe<TestBooking>;
    /** deletes a test product  */
    readonly testProductDelete?: Maybe<Scalars["Boolean"]["output"]>;
    /** Upsert a test product */
    readonly testProductUpsert?: Maybe<TestProduct>;
    /** Resets the viewer's password */
    readonly viewerResetPassword: Scalars["Boolean"]["output"];
    /** Update a Workstream */
    readonly workstreamUpsert?: Maybe<Workstream>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsAnalyticLinkOriginCreateArgs = {
    input: AnalyticLinkOriginCreateInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsAnalyticLinkOriginDeleteArgs = {
    input: AnalyticLinkOriginDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsArticleUpsertArgs = {
    input?: InputMaybe<ArticleUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsAuthUserLoginArgs = {
    password: Scalars["String"]["input"];
    username: Scalars["String"]["input"];
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsAvailabilityFromBookingAvailabilityArgs = {
    input?: InputMaybe<AvailabilityFromBookingAvailabilityInputType>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsBookingAddAvailabilityArgs = {
    input?: InputMaybe<BookingAddAvailabilityInputType>;
    saleCurrency?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsBookingAmendAvailabilityIntentArgs = {
    availabilityId: Scalars["String"]["input"];
    newAvailabilityId: Scalars["String"]["input"];
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsBookingAvailabilityAmendmentIntentRevertArgs = {
    bookingAvailabilityId: Scalars["String"]["input"];
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsBookingAvailabilityConsumeArgs = {
    input: BookingAvailabilityConsumeMutationInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsBookingCancelArgs = {
    input?: InputMaybe<BookingCancelInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsBookingCommitArgs = {
    bookingSelector: BookingSelector;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsBookingCreateArgs = {
    input?: InputMaybe<BookingCreateInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsBookingDeleteAvailabilityArgs = {
    input?: InputMaybe<BookingDeleteAvailabilityInputType>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsBookingEditArgs = {
    input?: InputMaybe<BookingEditInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsBookingPaymentIntentCancelArgs = {
    input?: InputMaybe<BookingPaymentIntentCancelInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsBookingQuestionUploadArgs = {
    bookingId: Scalars["ID"]["input"];
    bookingQuestionId: Scalars["ID"]["input"];
    contentType: Scalars["String"]["input"];
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsBookingReplaceAvailabilityArgs = {
    availabilityId: Scalars["String"]["input"];
    newAvailabilityId: Scalars["String"]["input"];
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsBookingRevertAmendAvailabilityIntentArgs = {
    availabilityId: Scalars["String"]["input"];
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsBrandCreateArgs = {
    input: BrandCreateInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsBrandDeleteArgs = {
    input?: InputMaybe<BrandDeleteInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsBrandUpdateArgs = {
    input: BrandUpdateInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsCompanyEntityConfigurationUpsertArgs = {
    input?: InputMaybe<CompanyEntityConfigurationInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsConsumerCreateArgs = {
    input: ConsumerConcreteCreateInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsConsumerTripArgs = {
    input: ConsumerTripInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsConsumerTripAssociateSystemTagListArgs = {
    consumerTripId: Scalars["String"]["input"];
    systemTagIdList: ReadonlyArray<Scalars["String"]["input"]>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsConsumerTripCreateArgs = {
    input: ConsumerTripConcreteCreateInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsConsumerTripEventCreateArgs = {
    consumerTripId: Scalars["String"]["input"];
    meta: Scalars["JSON"]["input"];
    type: Scalars["String"]["input"];
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsConsumerTripUpdateArgs = {
    input: ConsumerTripConcreteUpdateInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsConsumerUpdateArgs = {
    input: ConsumerConcreteUpdateInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsContentComponentUpsertArgs = {
    input: ContentComponentInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsDistributionChannelAuthorUpsertArgs = {
    input: DistributionChannelAuthorInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsDistributionChannelPageCategoryUpsertArgs = {
    input: DistributionChannelPageCategoryInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsDistributionChannelPageUpsertArgs = {
    input: DistributionChannelPageInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsDistributionChannelTranslationExportArgs = {
    distributionChannelId: Scalars["ID"]["input"];
    languageList: ReadonlyArray<LanguageCode>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsDistributionChannelTranslationImportArgs = {
    asset: Scalars["AssetUrlInput"]["input"];
    distributionChannelId: Scalars["ID"]["input"];
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsDistributionChannelUpsertArgs = {
    input: DistributionChannelInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsEntityCommentUpsertArgs = {
    input?: InputMaybe<EntityCommentUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsEntityCommissionDeleteArgs = {
    input?: InputMaybe<EntityCommissionDeleteInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsEntityCommissionUpsertArgs = {
    input: EntityCommissionInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsEntityConfigurationUpsertArgs = {
    input: EntitySystemConfigurationAssociationUpsertInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsEntityQuestionDeleteArgs = {
    input: EntityQuestionDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsEntityQuestionUpsertArgs = {
    input: EntityQuestionUpsertInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsEntitySecretDeleteArgs = {
    hierarchyLevel: EntitySecretHierarchyLevel;
    id: Scalars["ID"]["input"];
    secretType: EntitySecretType;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsEntitySecretSaveArgs = {
    hierarchyLevel: EntitySecretHierarchyLevel;
    id: Scalars["ID"]["input"];
    secret: Scalars["JSON"]["input"];
    secretType: EntitySecretType;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsEntitySystemConfigurationListUpsertArgs = {
    input?: InputMaybe<EntitySystemConfigurationListUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsEntitySystemTagAssociationDeleteArgs = {
    input: EntitySystemTagAssociationDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsFavouriteProductArgs = {
    isFavourite: Scalars["Boolean"]["input"];
    productId: Scalars["String"]["input"];
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsHierarchyCurationMetaUpsertArgs = {
    input: HierarchyCurationMetaInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsHierarchyCurationProductAssociationToggleArgs = {
    input?: InputMaybe<HierarchyCurationProductAssociationToggleInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsHierarchyCurationProductMetaUpsertArgs = {
    input: HierarchyCurationProductMetaInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsHierarchyHeaderMenuMetaUpsertArgs = {
    input: HierarchyHeaderMenuMetaRecordInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsHierarchyLinkAssociationToggleArgs = {
    input?: InputMaybe<HierarchyLinkAssociationToggleInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsHierarchyNodeDeleteArgs = {
    input?: InputMaybe<HierarchyNodeDeleteInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsHierarchyNodeUpsertArgs = {
    input: HierarchyNodeInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsLanguageStaticTranslationUpsertArgs = {
    input?: InputMaybe<LanguageStaticTranslationInputType>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsLaunchBrazeCanvasArgs = {
    consumerTripId: Scalars["String"]["input"];
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsLinkOAuthAccountArgs = {
    input: LinkOAuthAccountInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsOnboardRequestApprovalArgs = {
    input?: InputMaybe<OnboardRequestApprovalInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsOnboardRequestUpsertArgs = {
    input?: InputMaybe<OnboardRequestUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsOperatorDeleteArgs = {
    input: OperatorDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsOperatorGroupDeleteArgs = {
    input: OperatorGroupDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsOperatorGroupUpsertArgs = {
    input?: InputMaybe<OperatorGroupUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsOperatorUpsertArgs = {
    input?: InputMaybe<OperatorUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsPartnerChannelBookingQuestionDeleteArgs = {
    input?: InputMaybe<PartnerChannelBookingQuestionDeleteInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsPartnerChannelBookingQuestionUpsertArgs = {
    input?: InputMaybe<PartnerChannelBookingQuestionUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsPartnerChannelUpsertArgs = {
    input?: InputMaybe<PartnerChannelUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsPartnerUpdateArgs = {
    input?: InputMaybe<PartnerUpdateInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsPlaceAliasUpdateArgs = {
    input?: InputMaybe<PlaceAliasUpdateInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsPlaceFromAliasCreateArgs = {
    input?: InputMaybe<PlaceFromAliasCreateInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsPlaceUpsertArgs = {
    input?: InputMaybe<PlaceUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsPlaygroundQueryDeleteArgs = {
    input: PlaygroundQueryDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsPlaygroundQueryUpsertArgs = {
    input?: InputMaybe<PlaygroundQueryUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductAttachmentDeleteArgs = {
    input: ProductAttachmentDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductAttachmentUpsertArgs = {
    input?: InputMaybe<ProductAttachmentUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductAttributeAssociationDeleteArgs = {
    input: ProductAttributeAssociationDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductAttributeAssociationUpsertArgs = {
    input?: InputMaybe<ProductAttributeAssociationUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductAttributeDeleteArgs = {
    input: ProductAttributeDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductAttributeUpsertArgs = {
    input?: InputMaybe<ProductAttributeUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductAvailabilityPatternCloneArgs = {
    input?: InputMaybe<ProductAvailabilityPatternCloneInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductAvailabilityPatternDeleteArgs = {
    input: ProductAvailabilityPatternDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductAvailabilityPatternUpsertArgs = {
    input?: InputMaybe<ProductAvailabilityPatternUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductCancellationPenaltyDeleteArgs = {
    input: ProductCancellationPenaltyDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductCancellationPenaltyUpsertArgs = {
    input?: InputMaybe<ProductCancellationPenaltyUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductCategoryAssociationDeleteArgs = {
    input: ProductCategoryAssociationDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductCategoryAssociationToggleArgs = {
    input?: InputMaybe<ProductCategoryAssociationToggleInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductCategoryAssociationUpsertArgs = {
    input?: InputMaybe<ProductCategoryAssociationUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductCategoryUpsertArgs = {
    input?: InputMaybe<ProductCategoryUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductChatGptPromptResponseTagAssociateArgs = {
    id: Scalars["ID"]["input"];
    systemTagId: Scalars["ID"]["input"];
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductCloneArgs = {
    input?: InputMaybe<ProductCloneInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductContentDeleteArgs = {
    input: ProductContentDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductContentUpsertArgs = {
    input?: InputMaybe<ProductContentUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductDeleteArgs = {
    input: ProductDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductImageDeleteArgs = {
    input: ProductImageDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductImageUpsertArgs = {
    input?: InputMaybe<ProductImageUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductInventoryDeleteArgs = {
    input: ProductInventoryDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductInventoryUpsertArgs = {
    input?: InputMaybe<ProductInventoryUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductListAiAnalyzeArgs = {
    productIdList?: InputMaybe<ReadonlyArray<Scalars["ID"]["input"]>>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductMetaUpdateArgs = {
    input?: InputMaybe<ProductMetaUpdateInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductReviewDeleteArgs = {
    input?: InputMaybe<ProductReviewDeleteInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductReviewUpsertArgs = {
    input?: InputMaybe<ProductReviewUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductSaleRestrictionToggleArgs = {
    input?: InputMaybe<ProductSaleRestrictionToggleInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductSaleRestrictionUpsertArgs = {
    input?: InputMaybe<ProductSaleRestrictionUpsertInputType>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductStartTimeDeleteArgs = {
    input: ProductStartTimeDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductStartTimeUpsertArgs = {
    input?: InputMaybe<ProductStartTimeUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductTranslationUpdateArgs = {
    input?: InputMaybe<ProductTranslationUpdateInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProductUpsertArgs = {
    input?: InputMaybe<ProductUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProviderDeleteArgs = {
    input: ProviderDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsProviderUpsertArgs = {
    input?: InputMaybe<ProviderUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsQuestionCreateArgs = {
    input: QuestionCreateInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsQuestionUpdateArgs = {
    input: QuestionUpdateInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsRecordsFromTemplateUpsertArgs = {
    input?: InputMaybe<RecordsFromTemplateInputType>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsRefreshCacheArgs = {
    input: RefreshCacheInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsRestaurantProductAssociationDeleteArgs = {
    input: RestaurantProductAssociationDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsRestaurantProductAssociationUpsertArgs = {
    input: RestaurantProductAssociationUpsertInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSaleAgencyCreateArgs = {
    input: SaleAgencyCreateInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSaleAgencyDeleteArgs = {
    input: SaleAgencyDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSaleAgencyUpdateArgs = {
    input: SaleAgencyUpdateInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSecurityPrincipalChangeParentArgs = {
    input: SecurityPrincipalChangeParentInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSecurityPrincipalPermissionListUpsertArgs = {
    input?: InputMaybe<SecurityPrincipalPermissionUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSecurityPrincipalUpdateArgs = {
    input?: InputMaybe<SecurityPrincipalUpdateInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSecurityPrincipalUserCreateArgs = {
    input: SecurityPrincipalUserCreateInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSecurityPrincipalUserDeleteArgs = {
    input: SecurityPrincipalUserDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSecurityPrincipalUserMigrateArgs = {
    input: SecurityPrincipalUserMigrateInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSecurityPrincipalUserResetPasswordArgs = {
    input: SecurityPrincipalUserResetPasswordInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSecurityPrincipalUserResetPasswordRequestArgs = {
    input?: InputMaybe<SecurityPrincipalUserResetPasswordRequestInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSecurityPrincipalUserResetPasswordRequestClaimArgs = {
    input: SecurityPrincipalUserResetPasswordRequestClaimInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSecurityPrincipalUserSignupArgs = {
    input: SecurityPrincipalUserSignupInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSecurityPrincipalUserUpdateArgs = {
    input: SecurityPrincipalUserUpdateInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSecurityRuleDeleteArgs = {
    input: SecurityRuleDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSecurityRulePrincipalAssociationListUpsertArgs = {
    input: SecurityRulePrincipalAssociationListUpsertInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSecurityRuleUpsertArgs = {
    input?: InputMaybe<SecurityRuleUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAcknowledgementEmailArgs = {
    input: GenericBookingSignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityAcceptArgs = {
    input: SignalBookingAvailabilityAcceptInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityAdditionalDataArgs = {
    input: GenericBookingAvailabilitySignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityAmendmentIntentArgs = {
    input: GenericBookingAvailabilitySignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityCancellationAcceptArgs = {
    input: SignalBookingAvailabilityAcceptInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityCancellationEmailArgs = {
    input: GenericBookingAvailabilitySignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityCancellationIntentArgs = {
    input: SignalBookingAvailabilityCancellationIntentInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityCancellationRejectArgs = {
    input: SignalBookingAvailabilityAcceptInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityCancellationRevertArgs = {
    input: GenericBookingAvailabilitySignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityConfirmArgs = {
    input: GenericBookingAvailabilitySignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityConfirmStateArgs = {
    input: GenericBookingAvailabilitySignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityConfirmationEmailArgs = {
    input: GenericBookingAvailabilitySignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityForcedCancellationIntentArgs = {
    input: SignalBookingAvailabilityCancellationIntentInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityGenerateTicketArgs = {
    input: GenericBookingAvailabilitySignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityInitialiseArgs = {
    input: GenericBookingAvailabilitySignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityListCancellationIntentArgs = {
    input: SignalBookingAvailabilityListCancellationIntentInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityListForceCancellationIntentArgs = {
    input: SignalBookingAvailabilityListCancellationIntentInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityPreArrivalInstructionsEmailArgs = {
    input: GenericBookingAvailabilitySignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityRejectArgs = {
    input: SignalBookingAvailabilityAcceptInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityReminderEmailArgs = {
    input: GenericBookingAvailabilitySignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityRequestCancellationEmailArgs = {
    input: GenericBookingAvailabilitySignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityRequestConfirmationEmailArgs = {
    input: GenericBookingAvailabilitySignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilityReviewEmailArgs = {
    input: GenericBookingAvailabilitySignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilitySourceTicketArgs = {
    input: GenericBookingAvailabilitySignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilitySupplierCancellationAcceptArgs = {
    input: SignalBookingAvailabilityAcceptInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilitySupplierCancellationRejectArgs = {
    input: SignalBookingAvailabilityAcceptInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilitySupplierCommitAcceptArgs = {
    input: SignalBookingAvailabilityAcceptInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingAvailabilitySupplierCommitRejectArgs = {
    input: SignalBookingAvailabilityAcceptInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingBookingAvailabilityListCommitArgs = {
    input: GenericBookingSignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingBookingAvailabilityListConfirmArgs = {
    input: GenericBookingSignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingBookingAvailabilityListNotificationEmailArgs = {
    input: GenericBookingSignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingGenerateVoucherArgs = {
    input: GenericBookingSignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingInitialiseArgs = {
    input: GenericBookingSignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingProcessPaymentArgs = {
    input: GenericBookingSignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingProcessPaymentIntentArgs = {
    input: GenericBookingSignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSignalBookingSummaryEmailArgs = {
    input: GenericBookingSignalInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSupplierFinanceDeleteArgs = {
    input: SupplierFinanceDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSupplierFinanceUpsertArgs = {
    input?: InputMaybe<SupplierFinanceUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSupplierOnboardArgs = {
    input: SupplierOnboardInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSupplierProductSurveyArgs = {
    productId: Scalars["String"]["input"];
    survey: Scalars["JSON"]["input"];
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSupplierSurveyArgs = {
    supplierId: Scalars["String"]["input"];
    survey: Scalars["JSON"]["input"];
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSupplierSurveyUpdateArgs = {
    input?: InputMaybe<SupplierSurveyUpdateInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSupplierUpsertArgs = {
    input?: InputMaybe<SupplierUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSystemPageStateCreateArgs = {
    input: SystemPageStateCreateInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSystemPageStateDeleteArgs = {
    input: SystemPageStateDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSystemTemplateDeleteArgs = {
    input: SystemTemplateDeleteInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsSystemTemplateUpsertArgs = {
    input?: InputMaybe<SystemTemplateUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsTestBookingDeleteArgs = {
    input?: InputMaybe<TestBookingDeleteInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsTestBookingUpsertArgs = {
    input?: InputMaybe<TestBookingUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsTestProductDeleteArgs = {
    input?: InputMaybe<TestProductDeleteInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsTestProductUpsertArgs = {
    input?: InputMaybe<TestProductUpsertInput>;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsViewerResetPasswordArgs = {
    input: ViewerResetPasswordInput;
};

/** Mutations are used to send data to the API Generally, mutations take an input that contains all of the data that you need to pass to the systems.  Mutations are also capable of returning data and as such, like queries, lake a list of nodes that you want returned. If you do not requre any data to be returned after the mutation completes it is acceptable to have zero nodes in the list. You may of course find it helpful to at least return an ID in particular where the mutation is expected to create a new record. */
export type MutationsWorkstreamUpsertArgs = {
    input?: InputMaybe<WorkstreamUpsertInput>;
};

export type OnboardRequestApprovalInput = {
    /** The unique Holibob ID for onboard request */
    readonly id: Scalars["String"]["input"];
    /** Onboard request acept or reject */
    readonly status: Scalars["String"]["input"];
};

/** This respresents the Onboarding request response type */
export type OnboardRequestListResponse = {
    readonly __typename?: "OnboardRequestListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** Onboarding request list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<OnboardRequestType>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

/** This represents an onboarding */
export type OnboardRequestType = {
    readonly __typename?: "OnboardRequestType";
    /** date the origin was last updated */
    readonly acceptedAt?: Maybe<Scalars["Date"]["output"]>;
    /** The address string */
    readonly address: Scalars["String"]["output"];
    /** date the origin was created */
    readonly createdAt: Scalars["Date"]["output"];
    /** The name string */
    readonly email: Scalars["String"]["output"];
    /** The firstName string */
    readonly firstName: Scalars["String"]["output"];
    /** The unique Holibob ID for the origin */
    readonly id: Scalars["String"]["output"];
    /** The lastName string */
    readonly lastName: Scalars["String"]["output"];
    /** The name string */
    readonly name: Scalars["String"]["output"];
    /** date the origin was created */
    readonly rejectedAt?: Maybe<Scalars["Date"]["output"]>;
    /** The current state of the request */
    readonly signupState?: Maybe<Scalars["String"]["output"]>;
    /** The Signup Url for the registration */
    readonly signupUrl?: Maybe<Scalars["String"]["output"]>;
    /** The current state of the request */
    readonly state?: Maybe<Scalars["String"]["output"]>;
    /** The type string */
    readonly type: Scalars["String"]["output"];
    /** date the origin was last updated */
    readonly updatedAt?: Maybe<Scalars["Date"]["output"]>;
};

export type OnboardRequestUpsertInput = {
    /** Line 1 of the address */
    readonly address: Scalars["String"]["input"];
    /** The name string */
    readonly email: Scalars["String"]["input"];
    /** The firstName string */
    readonly firstName: Scalars["String"]["input"];
    /** The lastName string */
    readonly lastName: Scalars["String"]["input"];
    /** The partner name */
    readonly name: Scalars["String"]["input"];
    /** The recaptcha token */
    readonly token: Scalars["String"]["input"];
    /** The name string */
    readonly type: Scalars["String"]["input"];
};

/** This represents a Operator. */
export type Operator = {
    readonly __typename?: "Operator";
    /** The unique id for the Operator. */
    readonly id: Scalars["String"]["output"];
    /** The legal entity fields for this operator. */
    readonly legalEntity?: Maybe<LegalEntity>;
    /** The name of the operator. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
};

/** This represents a OperatorAssociation. */
export type OperatorAssociation = {
    readonly __typename?: "OperatorAssociation";
    /** The id of the association. */
    readonly id?: Maybe<Scalars["String"]["output"]>;
    /** The ID of the operator. */
    readonly operatorId?: Maybe<Scalars["String"]["output"]>;
    /** The duration after which we try to request this operator. */
    readonly requestDelayDuration?: Maybe<Scalars["String"]["output"]>;
};

export type OperatorAssociationInput = {
    /** The id of the association. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The ID of the operator. */
    readonly operatorId?: InputMaybe<Scalars["String"]["input"]>;
    /** The duration after which we try to request this operator. */
    readonly requestDelayDuration?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a list of OperatorAssociation. */
export type OperatorAssociationList = {
    readonly __typename?: "OperatorAssociationList";
    readonly nodes: ReadonlyArray<OperatorAssociation>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type OperatorDeleteInput = {
    /** The ID */
    readonly id: Scalars["String"]["input"];
};

/** This represents a OperatorGroup. */
export type OperatorGroup = {
    readonly __typename?: "OperatorGroup";
    /** The unique id for the OperatorGroup. */
    readonly id: Scalars["String"]["output"];
    /** The name of the operatorGroup. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** The list of operators in this group. */
    readonly operatorAssociationList?: Maybe<OperatorAssociationList>;
};

export type OperatorGroupDeleteInput = {
    /** The ID */
    readonly id: Scalars["String"]["input"];
};

/** This represents a list of OperatorGroup. */
export type OperatorGroupList = {
    readonly __typename?: "OperatorGroupList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<OperatorGroup>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

/** This represents a list of OperatorGroup. */
export type OperatorGroupListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** This represents a list of OperatorGroup. */
export type OperatorGroupListTotalArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OperatorGroupListFilter = {
    /** filter by name. */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
};

export type OperatorGroupListSort = {
    /** Sort by name. */
    readonly name?: InputMaybe<SortDirection>;
};

export type OperatorGroupUpsertInput = {
    /** The id of the operatorGroup. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The name of the operatorGroup. */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** The associated operator ids. */
    readonly operatorAssociationList?: InputMaybe<ReadonlyArray<InputMaybe<OperatorAssociationInput>>>;
};

/** This represents a list of Operator. */
export type OperatorList = {
    readonly __typename?: "OperatorList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<Operator>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

/** This represents a list of Operator. */
export type OperatorListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** This represents a list of Operator. */
export type OperatorListTotalArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OperatorListFilter = {
    /** filter by name. */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
};

export type OperatorListSort = {
    /** Sort by name. */
    readonly name?: InputMaybe<SortDirection>;
};

export type OperatorUpsertInput = {
    /** The id of the operator. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The legal entity fields for this operator. */
    readonly legalEntity?: InputMaybe<LegalEntityInputType>;
    /** The name of the operator. */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents an option */
export type Option = {
    readonly __typename?: "Option";
    /** code */
    readonly code?: Maybe<Scalars["String"]["output"]>;
    /** description */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID for the industry */
    readonly id: Scalars["String"]["output"];
    /** Descendent level */
    readonly level?: Maybe<Scalars["Int"]["output"]>;
    /** parentId */
    readonly parentId?: Maybe<Scalars["String"]["output"]>;
    /** type */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** value */
    readonly value: Scalars["String"]["output"];
};

/** Filter options. */
export type OptionsFilter = {
    readonly distributionChannelId?: InputMaybe<Scalars["String"]["input"]>;
    readonly distributionChannelIdList?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
    readonly domain?: InputMaybe<Scalars["String"]["input"]>;
    readonly parentKey?: InputMaybe<Scalars["String"]["input"]>;
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    readonly securityPrincipalId?: InputMaybe<Scalars["String"]["input"]>;
    readonly supplierId?: InputMaybe<Scalars["String"]["input"]>;
    readonly type?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** This represents a partner */
export type Partner = {
    readonly __typename?: "Partner";
    /** Commission Rate for this partner */
    readonly commissionRate?: Maybe<Scalars["String"]["output"]>;
    /** A brief description of the partner */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** Partner Configuration List */
    readonly entitySystemConfigurationAssociationList: EntitySystemConfigurationAssociationList;
    /** The unique Holibob ID for the partner */
    readonly id: Scalars["String"]["output"];
    /** The Name of the partner */
    readonly name: Scalars["String"]["output"];
    /** Partner Channel List */
    readonly partnerChannelList?: Maybe<PartnerChannelListResponse>;
    /** The permissions of this partnerChannel */
    readonly permissions?: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
    /** securityPermissions */
    readonly securityPermissions: ReadonlyArray<SecurityPermission>;
    readonly securityPrincipalId: Scalars["String"]["output"];
    /** securityRuleAssociations List */
    readonly securityRuleAssociations: ReadonlyArray<SecurityRulePrincipalAssociation>;
    /** Settings for this partner */
    readonly settings?: Maybe<Scalars["JSON"]["output"]>;
};

/** This represents a partner */
export type PartnerPartnerChannelListArgs = {
    filter?: InputMaybe<PartnerChannelListFilter>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<PartnerChannelListSort>;
};

/** This represents a partner */
export type PartnerBusinessPrincipal = {
    readonly __typename?: "PartnerBusinessPrincipal";
    /** Commission Rate for this partner */
    readonly commissionRate?: Maybe<Scalars["String"]["output"]>;
    /** A brief description of the partner */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** Partner Configuration List */
    readonly entitySystemConfigurationAssociationList: EntitySystemConfigurationAssociationList;
    readonly hierarchyNodeType: SecurityPrincipalHiearchyNodeType;
    /** The unique Holibob ID for the partner */
    readonly id: Scalars["String"]["output"];
    /** The Name of the partner */
    readonly name: Scalars["String"]["output"];
    readonly parentSecurityPrincipalId: Scalars["String"]["output"];
    readonly parentSecurityPrincipalType?: Maybe<SecurityPrincipalTypeType>;
    /** Partner Channel List */
    readonly partnerChannelList?: Maybe<PartnerChannelListResponse>;
    /** The permissions of this partnerChannel */
    readonly permissions?: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
    readonly securityPermissionList: SecurityPermissionList;
    /** securityPermissions */
    readonly securityPermissions: ReadonlyArray<SecurityPermission>;
    readonly securityPrincipalId: Scalars["String"]["output"];
    readonly securityPrincipalType: SecurityPrincipalTypeType;
    /** securityRuleAssociations List */
    readonly securityRuleAssociations: ReadonlyArray<SecurityRulePrincipalAssociation>;
    /** Settings for this partner */
    readonly settings?: Maybe<Scalars["JSON"]["output"]>;
};

/** This represents a partner */
export type PartnerBusinessPrincipalPartnerChannelListArgs = {
    filter?: InputMaybe<PartnerChannelListFilter>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<PartnerChannelListSort>;
};

/** This represents a partnerChannel */
export type PartnerChannel = {
    readonly __typename?: "PartnerChannel";
    /** The brand id of the PartnerChannel. */
    readonly brandId?: Maybe<Scalars["String"]["output"]>;
    /** The currency associated with PartnerChannel. */
    readonly currency?: Maybe<Scalars["String"]["output"]>;
    /** Partner Channel Configuration List */
    readonly entitySystemConfigurationAssociationList: EntitySystemConfigurationAssociationList;
    readonly extranetDistributionChannelId?: Maybe<Scalars["String"]["output"]>;
    readonly hostName?: Maybe<Scalars["String"]["output"]>;
    readonly hostPrefix?: Maybe<Scalars["String"]["output"]>;
    /** The unique id for the PartnerChannel. */
    readonly id: Scalars["String"]["output"];
    /** The consumer facing flag of the PartnerChannel. */
    readonly isConsumerFacing?: Maybe<Scalars["Boolean"]["output"]>;
    /** Does the partner have permission to make live bookings. */
    readonly isSandboxed?: Maybe<Scalars["Boolean"]["output"]>;
    readonly name: Scalars["String"]["output"];
    /** partnerChannelSecurityRuleAssociations List */
    readonly partnerChannelSecurityRuleAssociations: ReadonlyArray<SecurityRulePrincipalAssociation>;
    /** The unique id for the PartnerChannel's partner record. */
    readonly partnerId: Scalars["String"]["output"];
    /** Partner payment type. */
    readonly paymentType?: Maybe<PartnerChannelPaymentType>;
    /** The url template for (must include {productId}) product management associated with PartnerChannel. */
    readonly productManagementUrlTemplate?: Maybe<Scalars["String"]["output"]>;
    /** The sale currency associated with PartnerChannel. */
    readonly saleCurrency?: Maybe<Scalars["String"]["output"]>;
    /** securityPermissions */
    readonly securityPermissions: ReadonlyArray<SecurityPermission>;
    readonly securityPrincipalId: Scalars["String"]["output"];
    /** Security Principal User List */
    readonly securityPrincipalUserList?: Maybe<SecurityPrincipalUserListResponse>;
    /** The type from the product rule associated with PartnerChannel. */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    readonly whitelabelDistributionChannelId?: Maybe<Scalars["String"]["output"]>;
};

/** This represents a partnerChannel */
export type PartnerChannelSecurityPrincipalUserListArgs = {
    filter?: InputMaybe<SecurityPrincipalUserListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<SecurityPrincipalUserListSort>;
};

/** This represents a question */
export type PartnerChannelBookingQuestion = {
    readonly __typename?: "PartnerChannelBookingQuestion";
    /** The value that may be applied to the autocomplete attribute of a form input control */
    readonly autoCompleteValue?: Maybe<Scalars["String"]["output"]>;
    /** availableOptions */
    readonly availableOptions: ReadonlyArray<Maybe<AvailableOptions>>;
    /** question dataFormat */
    readonly dataFormat?: Maybe<Scalars["String"]["output"]>;
    /** question dataPattern */
    readonly dataPattern?: Maybe<Scalars["String"]["output"]>;
    /** question dataType */
    readonly dataType?: Maybe<Scalars["String"]["output"]>;
    /** question defaultValue */
    readonly defaultValue?: Maybe<Scalars["String"]["output"]>;
    /** question flags */
    readonly flags?: Maybe<Scalars["String"]["output"]>;
    /** question helper */
    readonly helper?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID for the  Question */
    readonly id: Scalars["String"]["output"];
    /** isFromOptions */
    readonly isFromOptions: Scalars["Boolean"]["output"];
    /** isRequired */
    readonly isRequired: Scalars["Boolean"]["output"];
    /** isSelectMultiple */
    readonly isSelectMultiple: Scalars["Boolean"]["output"];
    /** Question Label. */
    readonly label?: Maybe<Scalars["String"]["output"]>;
    /** The ordinalPosition for the  Question */
    readonly ordinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** The partnerChannelId for the  Question */
    readonly partnerChannelId?: Maybe<Scalars["String"]["output"]>;
    /** The partnerChannelName for the  Question */
    readonly partnerChannelName?: Maybe<Scalars["String"]["output"]>;
    /** question placeholder */
    readonly placeholder?: Maybe<Scalars["String"]["output"]>;
    readonly synchronisationQuestionId?: Maybe<Scalars["String"]["output"]>;
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The type for the  Question */
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

export type PartnerChannelBookingQuestionDeleteInput = {
    /** The id of the partnerChannelBookingQuestion */
    readonly id: Scalars["String"]["input"];
};

export type PartnerChannelBookingQuestionListFilter = {
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** Find Partner Channel Booking Question by partnerChannelId */
    readonly partnerChannelId?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents an partner channel booking question */
export type PartnerChannelBookingQuestionListItem = {
    readonly __typename?: "PartnerChannelBookingQuestionListItem";
    /** The value that may be applied to the autocomplete attribute of a form input control */
    readonly autoCompleteValue?: Maybe<Scalars["String"]["output"]>;
    /** availableOptions */
    readonly availableOptions: ReadonlyArray<Maybe<AvailableOptions>>;
    /** question dataFormat */
    readonly dataFormat?: Maybe<Scalars["String"]["output"]>;
    /** question dataPattern */
    readonly dataPattern?: Maybe<Scalars["String"]["output"]>;
    /** question dataType */
    readonly dataType?: Maybe<Scalars["String"]["output"]>;
    /** question defaultValue */
    readonly defaultValue?: Maybe<Scalars["String"]["output"]>;
    /** question flags */
    readonly flags?: Maybe<Scalars["String"]["output"]>;
    /** question helper */
    readonly helper?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID for the  Question */
    readonly id: Scalars["String"]["output"];
    /** isFromOptions */
    readonly isFromOptions: Scalars["Boolean"]["output"];
    /** isRequired */
    readonly isRequired: Scalars["Boolean"]["output"];
    /** isSelectMultiple */
    readonly isSelectMultiple: Scalars["Boolean"]["output"];
    /** Question Label. */
    readonly label?: Maybe<Scalars["String"]["output"]>;
    /** The ordinalPosition for the  Question */
    readonly ordinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** The partnerChannelId for the  Question */
    readonly partnerChannelId?: Maybe<Scalars["String"]["output"]>;
    /** The partnerChannelName for the  Question */
    readonly partnerChannelName?: Maybe<Scalars["String"]["output"]>;
    /** question placeholder */
    readonly placeholder?: Maybe<Scalars["String"]["output"]>;
    readonly synchronisationQuestionId?: Maybe<Scalars["String"]["output"]>;
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The type for the  Question */
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

/** This respresents the PartnerChannelBookingQuestionList response type */
export type PartnerChannelBookingQuestionListResponse = {
    readonly __typename?: "PartnerChannelBookingQuestionListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** Partner Channel Booking Question list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<PartnerChannelBookingQuestionListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type PartnerChannelBookingQuestionListSort = {
    /** Sort by type */
    readonly type?: InputMaybe<SortDirection>;
};

export type PartnerChannelBookingQuestionUpsertInput = {
    /** question dataFormat */
    readonly dataFormat?: InputMaybe<Scalars["String"]["input"]>;
    /** question dataPattern */
    readonly dataPattern?: InputMaybe<Scalars["String"]["input"]>;
    /** question dataType */
    readonly dataType: Scalars["String"]["input"];
    /** question defaultValue */
    readonly defaultValue?: InputMaybe<Scalars["String"]["input"]>;
    /** question flags */
    readonly flags?: InputMaybe<Scalars["String"]["input"]>;
    /** question helper */
    readonly helper?: InputMaybe<Scalars["String"]["input"]>;
    /** The unique ID for the  Question */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** isFromOptions */
    readonly isFromOptions?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** isRequired */
    readonly isRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** isSelectMultiple */
    readonly isSelectMultiple?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** question label */
    readonly label: Scalars["String"]["input"];
    /** The ordinalPosition for the  Question */
    readonly ordinalPosition?: InputMaybe<Scalars["Int"]["input"]>;
    /** The partnerChannelId for the  Question */
    readonly partnerChannelId: Scalars["String"]["input"];
    /** question placeholder */
    readonly placeholder?: InputMaybe<Scalars["String"]["input"]>;
    /** The type for the  Question */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a partnerChannel */
export type PartnerChannelBusinessPrincipal = {
    readonly __typename?: "PartnerChannelBusinessPrincipal";
    /** The HMAC key. */
    readonly apiKey?: Maybe<Scalars["String"]["output"]>;
    /** The HMAC secret. */
    readonly apiSecret?: Maybe<Scalars["String"]["output"]>;
    /** The brand id of the PartnerChannel. */
    readonly brandId?: Maybe<Scalars["String"]["output"]>;
    /** The commission rate from the partner. */
    readonly commissionShareRate?: Maybe<Scalars["Float"]["output"]>;
    /** The date on which the PartnerChannel was created. */
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The currency associated with PartnerChannel. */
    readonly currency?: Maybe<Scalars["String"]["output"]>;
    /** The currencyCodeData of this PartnerChannel. */
    readonly currencyCodeData?: Maybe<Scalars["JSON"]["output"]>;
    /** Partner Channel Configuration List */
    readonly entitySystemConfigurationAssociationList: EntitySystemConfigurationAssociationList;
    readonly extranetDistributionChannelId?: Maybe<Scalars["String"]["output"]>;
    /** The google tracking Id that will be applied to the white label site. */
    readonly googleTrackingId?: Maybe<Scalars["String"]["output"]>;
    /** Does the PartnerChannel require multiple availabilities booked to one booking. */
    readonly hasShoppingCart?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hierarchyNodeType: SecurityPrincipalHiearchyNodeType;
    readonly hostName?: Maybe<Scalars["String"]["output"]>;
    readonly hostPrefix?: Maybe<Scalars["String"]["output"]>;
    /** The unique id for the PartnerChannel. */
    readonly id: Scalars["String"]["output"];
    /**
     * DEPRECATED A Json object containing the credentials required to comunicate with partner servers.
     * @deprecated refactored to notificationConfiguration
     */
    readonly integrationCredentials?: Maybe<Scalars["JSON"]["output"]>;
    /** The internalSynchronisationSourceId that will be used if the authenticated principal creates new records. */
    readonly internalSynchronisationSourceId?: Maybe<Scalars["String"]["output"]>;
    /** Indicates that the consumer will be required to pay by card. */
    readonly isCardPaymentRequired?: Maybe<Scalars["Boolean"]["output"]>;
    /** The consumer facing flag of the PartnerChannel. */
    readonly isConsumerFacing?: Maybe<Scalars["Boolean"]["output"]>;
    /** Does the partner have permission to make live bookings. */
    readonly isSandboxed?: Maybe<Scalars["Boolean"]["output"]>;
    /** The markup rate of the PartnerChannel. */
    readonly marginRate?: Maybe<Scalars["Float"]["output"]>;
    readonly name: Scalars["String"]["output"];
    /** A Json object containing the credentials required to comunicate with partner servers. */
    readonly notificationConfiguration?: Maybe<Scalars["JSON"]["output"]>;
    readonly parentSecurityPrincipalId: Scalars["String"]["output"];
    readonly parentSecurityPrincipalType?: Maybe<SecurityPrincipalTypeType>;
    /** partnerChannelSecurityRuleAssociations List */
    readonly partnerChannelSecurityRuleAssociations: ReadonlyArray<SecurityRulePrincipalAssociation>;
    /** The unique id for the PartnerChannel's partner record. */
    readonly partnerId: Scalars["String"]["output"];
    /** The name of the partner that owns the PartnerChannel. */
    readonly partnerName?: Maybe<Scalars["String"]["output"]>;
    /** Partner payment type. */
    readonly paymentType?: Maybe<PartnerChannelPaymentType>;
    /** The permissions of this PartnerChannel. */
    readonly permissions?: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
    /** The url template for (must include {productId}) product management associated with PartnerChannel. */
    readonly productManagementUrlTemplate?: Maybe<Scalars["String"]["output"]>;
    /** The sale currency associated with PartnerChannel. */
    readonly saleCurrency?: Maybe<Scalars["String"]["output"]>;
    readonly securityPermissionList: SecurityPermissionList;
    /** securityPermissions */
    readonly securityPermissions: ReadonlyArray<SecurityPermission>;
    readonly securityPrincipalId: Scalars["String"]["output"];
    readonly securityPrincipalType: SecurityPrincipalTypeType;
    /** Security Principal User List */
    readonly securityPrincipalUserList?: Maybe<SecurityPrincipalUserListResponse>;
    /** The stripeProductionCustomerKey. */
    readonly stripeProductionCustomerKey?: Maybe<Scalars["String"]["output"]>;
    /** The stripeSandboxCustomerKey. */
    readonly stripeSandboxCustomerKey?: Maybe<Scalars["String"]["output"]>;
    /** Indicates that the search bar should be included on the partner PartnerChannel. */
    readonly suppressNewSearch?: Maybe<Scalars["Boolean"]["output"]>;
    /** The fee charged to the partner per transaction. */
    readonly switchTransactionFee?: Maybe<Scalars["Float"]["output"]>;
    /** The Rate at which switch transactions are charged. */
    readonly switchTransactionRate?: Maybe<Scalars["Float"]["output"]>;
    /** The type from the product rule associated with PartnerChannel. */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** The address that should appear on a voucher. */
    readonly voucherContactAddress?: Maybe<Scalars["String"]["output"]>;
    /** The email address that should appear on a voucher. */
    readonly voucherContactEmail?: Maybe<Scalars["String"]["output"]>;
    /** The company/contact name that should appear on a voucher. */
    readonly voucherContactName?: Maybe<Scalars["String"]["output"]>;
    /** The phone number name that should appear on a voucher. */
    readonly voucherContactPhone?: Maybe<Scalars["String"]["output"]>;
    /** Indicates that the summary should be included on the voucher. */
    readonly voucherIncludeSummary?: Maybe<Scalars["Boolean"]["output"]>;
    /** When not null causes the commit service to send all vouchers to this address. Otherwise the voucher will be sent directly to the email provided during checkout. */
    readonly voucherTransmissionEmail?: Maybe<Scalars["String"]["output"]>;
    readonly whitelabelDistributionChannelId?: Maybe<Scalars["String"]["output"]>;
};

/** This represents a partnerChannel */
export type PartnerChannelBusinessPrincipalSecurityPrincipalUserListArgs = {
    filter?: InputMaybe<SecurityPrincipalUserListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<SecurityPrincipalUserListSort>;
};

export type PartnerChannelListFilter = {
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** Filter by parentSecurityPrincipalId */
    readonly parentSecurityPrincipalId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by partnerId */
    readonly partnerId?: InputMaybe<Scalars["String"]["input"]>;
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a partnerChannel */
export type PartnerChannelListItem = {
    readonly __typename?: "PartnerChannelListItem";
    /** The HMAC key. */
    readonly apiKey?: Maybe<Scalars["String"]["output"]>;
    /** The HMAC secret. */
    readonly apiSecret?: Maybe<Scalars["String"]["output"]>;
    /** The brand id of the PartnerChannel. */
    readonly brandId?: Maybe<Scalars["String"]["output"]>;
    /** The commission rate from the partner. */
    readonly commissionShareRate?: Maybe<Scalars["Float"]["output"]>;
    /** The date on which the PartnerChannel was created. */
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The currency associated with PartnerChannel. */
    readonly currency?: Maybe<Scalars["String"]["output"]>;
    /** The currencyCodeData of this PartnerChannel. */
    readonly currencyCodeData?: Maybe<Scalars["JSON"]["output"]>;
    /** Partner Channel Configuration List */
    readonly entitySystemConfigurationAssociationList: EntitySystemConfigurationAssociationList;
    readonly extranetDistributionChannelId?: Maybe<Scalars["String"]["output"]>;
    /** The google tracking Id that will be applied to the white label site. */
    readonly googleTrackingId?: Maybe<Scalars["String"]["output"]>;
    /** Does the PartnerChannel require multiple availabilities booked to one booking. */
    readonly hasShoppingCart?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hostName?: Maybe<Scalars["String"]["output"]>;
    readonly hostPrefix?: Maybe<Scalars["String"]["output"]>;
    /** The unique id for the PartnerChannel. */
    readonly id: Scalars["String"]["output"];
    /**
     * DEPRECATED A Json object containing the credentials required to comunicate with partner servers.
     * @deprecated refactored to notificationConfiguration
     */
    readonly integrationCredentials?: Maybe<Scalars["JSON"]["output"]>;
    /** The internalSynchronisationSourceId that will be used if the authenticated principal creates new records. */
    readonly internalSynchronisationSourceId?: Maybe<Scalars["String"]["output"]>;
    /** Indicates that the consumer will be required to pay by card. */
    readonly isCardPaymentRequired?: Maybe<Scalars["Boolean"]["output"]>;
    /** The consumer facing flag of the PartnerChannel. */
    readonly isConsumerFacing?: Maybe<Scalars["Boolean"]["output"]>;
    /** Does the partner have permission to make live bookings. */
    readonly isSandboxed?: Maybe<Scalars["Boolean"]["output"]>;
    /** The markup rate of the PartnerChannel. */
    readonly marginRate?: Maybe<Scalars["Float"]["output"]>;
    readonly name: Scalars["String"]["output"];
    /** A Json object containing the credentials required to comunicate with partner servers. */
    readonly notificationConfiguration?: Maybe<Scalars["JSON"]["output"]>;
    /** partnerChannelSecurityRuleAssociations List */
    readonly partnerChannelSecurityRuleAssociations: ReadonlyArray<SecurityRulePrincipalAssociation>;
    /** The unique id for the PartnerChannel's partner record. */
    readonly partnerId: Scalars["String"]["output"];
    /** The name of the partner that owns the PartnerChannel. */
    readonly partnerName?: Maybe<Scalars["String"]["output"]>;
    /** Partner payment type. */
    readonly paymentType?: Maybe<PartnerChannelPaymentType>;
    /** The permissions of this PartnerChannel. */
    readonly permissions?: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
    /** The url template for (must include {productId}) product management associated with PartnerChannel. */
    readonly productManagementUrlTemplate?: Maybe<Scalars["String"]["output"]>;
    /** The sale currency associated with PartnerChannel. */
    readonly saleCurrency?: Maybe<Scalars["String"]["output"]>;
    /** securityPermissions */
    readonly securityPermissions: ReadonlyArray<SecurityPermission>;
    readonly securityPrincipalId: Scalars["String"]["output"];
    /** Security Principal User List */
    readonly securityPrincipalUserList?: Maybe<SecurityPrincipalUserListResponse>;
    /** The stripeProductionCustomerKey. */
    readonly stripeProductionCustomerKey?: Maybe<Scalars["String"]["output"]>;
    /** The stripeSandboxCustomerKey. */
    readonly stripeSandboxCustomerKey?: Maybe<Scalars["String"]["output"]>;
    /** Indicates that the search bar should be included on the partner PartnerChannel. */
    readonly suppressNewSearch?: Maybe<Scalars["Boolean"]["output"]>;
    /** The fee charged to the partner per transaction. */
    readonly switchTransactionFee?: Maybe<Scalars["Float"]["output"]>;
    /** The Rate at which switch transactions are charged. */
    readonly switchTransactionRate?: Maybe<Scalars["Float"]["output"]>;
    /** The type from the product rule associated with PartnerChannel. */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** The address that should appear on a voucher. */
    readonly voucherContactAddress?: Maybe<Scalars["String"]["output"]>;
    /** The email address that should appear on a voucher. */
    readonly voucherContactEmail?: Maybe<Scalars["String"]["output"]>;
    /** The company/contact name that should appear on a voucher. */
    readonly voucherContactName?: Maybe<Scalars["String"]["output"]>;
    /** The phone number name that should appear on a voucher. */
    readonly voucherContactPhone?: Maybe<Scalars["String"]["output"]>;
    /** Indicates that the summary should be included on the voucher. */
    readonly voucherIncludeSummary?: Maybe<Scalars["Boolean"]["output"]>;
    /** When not null causes the commit service to send all vouchers to this address. Otherwise the voucher will be sent directly to the email provided during checkout. */
    readonly voucherTransmissionEmail?: Maybe<Scalars["String"]["output"]>;
    readonly whitelabelDistributionChannelId?: Maybe<Scalars["String"]["output"]>;
};

/** This represents a partnerChannel */
export type PartnerChannelListItemSecurityPrincipalUserListArgs = {
    filter?: InputMaybe<SecurityPrincipalUserListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<SecurityPrincipalUserListSort>;
};

export type PartnerChannelListResponse = {
    readonly __typename?: "PartnerChannelListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<PartnerChannelListItem>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type PartnerChannelListResponseRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PartnerChannelListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

/** Filter options for partner channels */
export type PartnerChannelOptionsFilter = {
    readonly distributionChannelId?: InputMaybe<Scalars["String"]["input"]>;
    readonly distributionChannelIdList?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
    readonly domain?: InputMaybe<Scalars["String"]["input"]>;
    readonly excludeDistrubtionChannelTypeList?: InputMaybe<
        ReadonlyArray<InputMaybe<ExcludeDistrubtionChannelTypeList>>
    >;
    readonly parentKey?: InputMaybe<Scalars["String"]["input"]>;
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    readonly securityPrincipalId?: InputMaybe<Scalars["String"]["input"]>;
    readonly supplierId?: InputMaybe<Scalars["String"]["input"]>;
    readonly type?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Type of payment for partner. */
export enum PartnerChannelPaymentType {
    /** Payment on account. */
    OnAccount = "ON_ACCOUNT",
    /** Take payment via stripe customer key. */
    OnRecord = "ON_RECORD",
    /** Card payment is required. */
    Required = "REQUIRED",
}

/** This represents a partnerChannel */
export type PartnerChannelPrivate = {
    readonly __typename?: "PartnerChannelPrivate";
    /** The HMAC key. */
    readonly apiKey?: Maybe<Scalars["String"]["output"]>;
    /** The HMAC secret. */
    readonly apiSecret?: Maybe<Scalars["String"]["output"]>;
    /** The brand id of the PartnerChannel. */
    readonly brandId?: Maybe<Scalars["String"]["output"]>;
    /** The commission rate from the partner. */
    readonly commissionShareRate?: Maybe<Scalars["Float"]["output"]>;
    /** The date on which the PartnerChannel was created. */
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The currency associated with PartnerChannel. */
    readonly currency?: Maybe<Scalars["String"]["output"]>;
    /** The currencyCodeData of this PartnerChannel. */
    readonly currencyCodeData?: Maybe<Scalars["JSON"]["output"]>;
    /** Partner Channel Configuration List */
    readonly entitySystemConfigurationAssociationList: EntitySystemConfigurationAssociationList;
    readonly extranetDistributionChannelId?: Maybe<Scalars["String"]["output"]>;
    /** The google tracking Id that will be applied to the white label site. */
    readonly googleTrackingId?: Maybe<Scalars["String"]["output"]>;
    /** Does the PartnerChannel require multiple availabilities booked to one booking. */
    readonly hasShoppingCart?: Maybe<Scalars["Boolean"]["output"]>;
    readonly hostName?: Maybe<Scalars["String"]["output"]>;
    readonly hostPrefix?: Maybe<Scalars["String"]["output"]>;
    /** The unique id for the PartnerChannel. */
    readonly id: Scalars["String"]["output"];
    /**
     * DEPRECATED A Json object containing the credentials required to comunicate with partner servers.
     * @deprecated refactored to notificationConfiguration
     */
    readonly integrationCredentials?: Maybe<Scalars["JSON"]["output"]>;
    /** The internalSynchronisationSourceId that will be used if the authenticated principal creates new records. */
    readonly internalSynchronisationSourceId?: Maybe<Scalars["String"]["output"]>;
    /** Indicates that the consumer will be required to pay by card. */
    readonly isCardPaymentRequired?: Maybe<Scalars["Boolean"]["output"]>;
    /** The consumer facing flag of the PartnerChannel. */
    readonly isConsumerFacing?: Maybe<Scalars["Boolean"]["output"]>;
    /** Does the partner have permission to make live bookings. */
    readonly isSandboxed?: Maybe<Scalars["Boolean"]["output"]>;
    /** The markup rate of the PartnerChannel. */
    readonly marginRate?: Maybe<Scalars["Float"]["output"]>;
    readonly name: Scalars["String"]["output"];
    /** A Json object containing the credentials required to comunicate with partner servers. */
    readonly notificationConfiguration?: Maybe<Scalars["JSON"]["output"]>;
    /** partnerChannelSecurityRuleAssociations List */
    readonly partnerChannelSecurityRuleAssociations: ReadonlyArray<SecurityRulePrincipalAssociation>;
    /** The unique id for the PartnerChannel's partner record. */
    readonly partnerId: Scalars["String"]["output"];
    /** The name of the partner that owns the PartnerChannel. */
    readonly partnerName?: Maybe<Scalars["String"]["output"]>;
    /** Partner payment type. */
    readonly paymentType?: Maybe<PartnerChannelPaymentType>;
    /** The permissions of this PartnerChannel. */
    readonly permissions?: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
    /** The url template for (must include {productId}) product management associated with PartnerChannel. */
    readonly productManagementUrlTemplate?: Maybe<Scalars["String"]["output"]>;
    /** The sale currency associated with PartnerChannel. */
    readonly saleCurrency?: Maybe<Scalars["String"]["output"]>;
    /** securityPermissions */
    readonly securityPermissions: ReadonlyArray<SecurityPermission>;
    readonly securityPrincipalId: Scalars["String"]["output"];
    /** Security Principal User List */
    readonly securityPrincipalUserList?: Maybe<SecurityPrincipalUserListResponse>;
    /** The stripeProductionCustomerKey. */
    readonly stripeProductionCustomerKey?: Maybe<Scalars["String"]["output"]>;
    /** The stripeSandboxCustomerKey. */
    readonly stripeSandboxCustomerKey?: Maybe<Scalars["String"]["output"]>;
    /** Indicates that the search bar should be included on the partner PartnerChannel. */
    readonly suppressNewSearch?: Maybe<Scalars["Boolean"]["output"]>;
    /** The fee charged to the partner per transaction. */
    readonly switchTransactionFee?: Maybe<Scalars["Float"]["output"]>;
    /** The Rate at which switch transactions are charged. */
    readonly switchTransactionRate?: Maybe<Scalars["Float"]["output"]>;
    /** The type from the product rule associated with PartnerChannel. */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** The address that should appear on a voucher. */
    readonly voucherContactAddress?: Maybe<Scalars["String"]["output"]>;
    /** The email address that should appear on a voucher. */
    readonly voucherContactEmail?: Maybe<Scalars["String"]["output"]>;
    /** The company/contact name that should appear on a voucher. */
    readonly voucherContactName?: Maybe<Scalars["String"]["output"]>;
    /** The phone number name that should appear on a voucher. */
    readonly voucherContactPhone?: Maybe<Scalars["String"]["output"]>;
    /** Indicates that the summary should be included on the voucher. */
    readonly voucherIncludeSummary?: Maybe<Scalars["Boolean"]["output"]>;
    /** When not null causes the commit service to send all vouchers to this address. Otherwise the voucher will be sent directly to the email provided during checkout. */
    readonly voucherTransmissionEmail?: Maybe<Scalars["String"]["output"]>;
    readonly whitelabelDistributionChannelId?: Maybe<Scalars["String"]["output"]>;
};

/** This represents a partnerChannel */
export type PartnerChannelPrivateSecurityPrincipalUserListArgs = {
    filter?: InputMaybe<SecurityPrincipalUserListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<SecurityPrincipalUserListSort>;
};

/** This represents a product curation. */
export type PartnerChannelProductPromotion = {
    readonly __typename?: "PartnerChannelProductPromotion";
    /** Unique Holibob Id for this product curation. */
    readonly id?: Maybe<Scalars["String"]["output"]>;
    /** The label for this curation. */
    readonly label?: Maybe<Scalars["String"]["output"]>;
    /** Score of this curation type. */
    readonly score?: Maybe<Scalars["Int"]["output"]>;
    /** Type of curation. */
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

/** This represents a product curation list. */
export type PartnerChannelProductPromotionList = {
    readonly __typename?: "PartnerChannelProductPromotionList";
    readonly nodes?: Maybe<ReadonlyArray<Maybe<PartnerChannelProductPromotion>>>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type PartnerChannelUpsertInput = {
    /** The brand Id */
    readonly brandId?: InputMaybe<Scalars["String"]["input"]>;
    /** The commission rate from the partner */
    readonly commissionShareRate?: InputMaybe<Scalars["Float"]["input"]>;
    /** The created at date of the channel  */
    readonly createdAt?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The currency of the channel */
    readonly currency?: InputMaybe<Scalars["String"]["input"]>;
    /** currencyCodeData (json array) */
    readonly currencyCodeData?: InputMaybe<Scalars["JSON"]["input"]>;
    /** Partner Channel entityConfigurationList */
    readonly entitySystemConfigurationAssociationList?: InputMaybe<
        ReadonlyArray<EntitySystemConfigurationAssociationInput>
    >;
    /** The google tracking id for the channel */
    readonly googleTrackingId?: InputMaybe<Scalars["String"]["input"]>;
    /** The shopping cart flag of the channel. */
    readonly hasShoppingCart?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** partnerChannel id */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The integration credentials for the channel */
    readonly integrationCredentials?: InputMaybe<Scalars["JSON"]["input"]>;
    /** The internalSynchronisationSourceId that will be used if the authenticated principal creates new records */
    readonly internalSynchronisationSourceId?: InputMaybe<Scalars["JSON"]["input"]>;
    /** The card payment required flag of the channel. */
    readonly isCardPaymentRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The consumer facing flag of the channel. */
    readonly isConsumerFacing?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The consumer facing flag of the channel. */
    readonly isSandboxed?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The markup rate of the channel */
    readonly marginRate?: InputMaybe<Scalars["Float"]["input"]>;
    /** The name of the partner channel */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** The unique Holibob id for the channel's partner record */
    readonly partnerId?: InputMaybe<Scalars["String"]["input"]>;
    /** Partner payment type */
    readonly paymentType?: InputMaybe<PartnerChannelPaymentType>;
    /** permissions */
    readonly permissions?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** The url template for (must include {productId}) product management */
    readonly productManagementUrlTemplate?: InputMaybe<Scalars["String"]["input"]>;
    /** The sale currency of the channel */
    readonly saleCurrency?: InputMaybe<Scalars["String"]["input"]>;
    /** security rule associations */
    readonly securityRuleAssociations?: InputMaybe<ReadonlyArray<InputMaybe<SecurityRuleAssociations>>>;
    /** stripeProductionCustomerKey */
    readonly stripeProductionCustomerKey?: InputMaybe<Scalars["String"]["input"]>;
    /** stripeSandboxCustomerKey */
    readonly stripeSandboxCustomerKey?: InputMaybe<Scalars["String"]["input"]>;
    /** The suppress new search flag of the channel. */
    readonly suppressNewSearch?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The switch transaction fee of the channel */
    readonly switchTransactionFee?: InputMaybe<Scalars["Float"]["input"]>;
    /** The switch transaction rate of the channel */
    readonly switchTransactionRate?: InputMaybe<Scalars["Float"]["input"]>;
    /** The email to be included on a voucher for the channel */
    readonly voucherContactEmail?: InputMaybe<Scalars["String"]["input"]>;
    /** The company/contact name to be included on a voucher for the channel */
    readonly voucherContactName?: InputMaybe<Scalars["String"]["input"]>;
    /** The phone number to be included on a voucher for the channel */
    readonly voucherContactPhone?: InputMaybe<Scalars["String"]["input"]>;
    /** Indicates that the summary should be included on a voucher */
    readonly voucherIncludeSummary?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The Transmission email to be included on a voucher for the channel */
    readonly voucherTransmissionEmail?: InputMaybe<Scalars["String"]["input"]>;
};

export type PartnerList = {
    readonly __typename?: "PartnerList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<PartnerListItem>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type PartnerListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PartnerListFilter = {
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    readonly parentSecurityPrincipalId?: InputMaybe<Scalars["String"]["input"]>;
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a partner */
export type PartnerListItem = {
    readonly __typename?: "PartnerListItem";
    /** Commission Rate for this partner */
    readonly commissionRate?: Maybe<Scalars["String"]["output"]>;
    /** A brief description of the partner */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the partner */
    readonly id: Scalars["String"]["output"];
    /** The Name of the partner */
    readonly name: Scalars["String"]["output"];
    /** The permissions of this partnerChannel */
    readonly permissions?: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
    readonly securityPrincipalId: Scalars["String"]["output"];
    /** Settings for this partner */
    readonly settings?: Maybe<Scalars["JSON"]["output"]>;
};

export type PartnerListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

export type PartnerUpdateInput = {
    /** Partner commission rate */
    readonly commissionRate?: InputMaybe<Scalars["Int"]["input"]>;
    /** Partner description */
    readonly description?: InputMaybe<Scalars["String"]["input"]>;
    /** entityConfigurationList */
    readonly entitySystemConfigurationAssociationList?: InputMaybe<
        ReadonlyArray<EntitySystemConfigurationAssociationInput>
    >;
    /** The unique Holibob ID for the partner */
    readonly id: Scalars["String"]["input"];
    /** Partner name */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** permissions */
    readonly permissions?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** security rule associations */
    readonly securityRuleAssociations?: InputMaybe<ReadonlyArray<InputMaybe<SecurityRuleAssociations>>>;
    /** Settings for partner */
    readonly settings?: InputMaybe<Scalars["String"]["input"]>;
};

export type PaxConsumeInputList = {
    readonly id: Scalars["ID"]["input"];
    readonly isConsumed: Scalars["Boolean"]["input"];
};

/** This represents a PaymentOnboard. */
export type PaymentOnboarding = {
    readonly __typename?: "PaymentOnboarding";
    /** The redirect URL where the user will finalise their onloarding */
    readonly redirectUrl: Scalars["String"]["output"];
};

/** This represents Pexels photo */
export type PexelsPhoto = {
    readonly __typename?: "PexelsPhoto";
    readonly alt: Scalars["String"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly originalHeight: Scalars["Int"]["output"];
    readonly originalWidth: Scalars["Int"]["output"];
    readonly src: PexelsPhotoSrc;
};

/** This represents Pexels photo list */
export type PexelsPhotoList = {
    readonly __typename?: "PexelsPhotoList";
    readonly nodes: ReadonlyArray<PexelsPhoto>;
    readonly page: Scalars["Int"]["output"];
    readonly perPage: Scalars["Int"]["output"];
    readonly totalResults: Scalars["Int"]["output"];
};

export type PexelsPhotoListFilter = {
    readonly orientation?: InputMaybe<PexelsPhotoOrientation>;
    readonly page?: InputMaybe<Scalars["Int"]["input"]>;
    readonly perPage?: InputMaybe<Scalars["Int"]["input"]>;
    readonly query: Scalars["String"]["input"];
};

export enum PexelsPhotoOrientation {
    Landscape = "landscape",
    Portrait = "portrait",
    Square = "square",
}

export type PexelsPhotoSrc = {
    readonly __typename?: "PexelsPhotoSrc";
    readonly large: Scalars["String"]["output"];
    readonly medium: Scalars["String"]["output"];
    readonly original: Scalars["String"]["output"];
    readonly small: Scalars["String"]["output"];
};

export type PhraseHighlightRange = {
    readonly __typename?: "PhraseHighlightRange";
    readonly end: Scalars["Int"]["output"];
    readonly start: Scalars["Int"]["output"];
};

/** This represents a place */
export type Place = {
    readonly __typename?: "Place";
    /** Partner Partner Channel List */
    readonly aliasList?: Maybe<ReadonlyArray<Maybe<PlaceAlias>>>;
    /** The place associations for this place */
    readonly associationList?: Maybe<ReadonlyArray<Maybe<PlaceAssociation>>>;
    /** The country in which the place exists */
    readonly countryName?: Maybe<Scalars["String"]["output"]>;
    /** The date the place was created at */
    readonly createdAt: Scalars["DateTime"]["output"];
    /** The unique Holibob ID for the place */
    readonly id: Scalars["String"]["output"];
    /** Indicates that the place is primary */
    readonly isPrimary?: Maybe<Scalars["Boolean"]["output"]>;
    /** Indicates that the place is unverified */
    readonly isUnverified?: Maybe<Scalars["Boolean"]["output"]>;
    /** The updatedAt */
    readonly iso2?: Maybe<Scalars["String"]["output"]>;
    /** Indicates that the place is unverified */
    readonly iso3?: Maybe<Scalars["String"]["output"]>;
    /** The  subType of the place */
    readonly lat?: Maybe<Scalars["Float"]["output"]>;
    /** The name of the place */
    readonly lng?: Maybe<Scalars["Float"]["output"]>;
    /** The name of the place */
    readonly name: Scalars["String"]["output"];
    /** The date the place was created at */
    readonly nameLocale?: Maybe<Scalars["String"]["output"]>;
    /** Name translation List */
    readonly nameTranslationList?: Maybe<TranslationList>;
    /** Indicates that the place is unverified */
    readonly population?: Maybe<Scalars["Int"]["output"]>;
    /** The  subType of the place */
    readonly subType?: Maybe<Scalars["String"]["output"]>;
    /** The type of the place */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** The updatedAt */
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

/** This represents a placeAlias */
export type PlaceAlias = {
    readonly __typename?: "PlaceAlias";
    /** The createdAt of the place alias */
    readonly createdAt?: Maybe<Scalars["String"]["output"]>;
    /** The hint of the place alias */
    readonly hint?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID for the place alias association for this place */
    readonly id: Scalars["String"]["output"];
    /** The name of the place alias */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** The placeId of the place alias */
    readonly placeId?: Maybe<Scalars["String"]["output"]>;
    /** The placeId of the place alias */
    readonly placeName?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationId of the place alias */
    readonly synchronisationId?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationSource of the place alias */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisedAt of the place alias */
    readonly synchronisedAt?: Maybe<Scalars["String"]["output"]>;
    /** The type of the place alias */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** The updçatedAt of the place alias */
    readonly updatedAt?: Maybe<Scalars["String"]["output"]>;
};

export type PlaceAliasListFilter = {
    /** Filter by placeId */
    readonly placeId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by an array of placeId */
    readonly placeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by placeId */
    readonly requiresMatching?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by a synchronisationSource */
    readonly synchronisationSource?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by an array of synchronisationSource */
    readonly synchronisationSources?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** This represents an PlaceAlias */
export type PlaceAliasListItem = {
    readonly __typename?: "PlaceAliasListItem";
    /** The createdAt of the place alias */
    readonly createdAt?: Maybe<Scalars["String"]["output"]>;
    /** The hint of the place alias */
    readonly hint?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID for the place alias association for this place */
    readonly id: Scalars["String"]["output"];
    /** The name of the place alias */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** The placeId of the place alias */
    readonly placeId?: Maybe<Scalars["String"]["output"]>;
    /** The placeId of the place alias */
    readonly placeName?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationId of the place alias */
    readonly synchronisationId?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationSource of the place alias */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisedAt of the place alias */
    readonly synchronisedAt?: Maybe<Scalars["String"]["output"]>;
    /** The type of the place alias */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** The updçatedAt of the place alias */
    readonly updatedAt?: Maybe<Scalars["String"]["output"]>;
};

/** This respresents the PlaceAlias List response type */
export type PlaceAliasListResponse = {
    readonly __typename?: "PlaceAliasListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** PlaceAlias list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<PlaceAliasListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type PlaceAliasListSort = {
    /** Sort by createdAt */
    readonly createdAt?: InputMaybe<SortDirection>;
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

export type PlaceAliasUpdateInput = {
    /** The unique Holibob ID for the partner */
    readonly id: Scalars["String"]["input"];
    /** Place Alias name */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** Place alias placeId */
    readonly placeId?: InputMaybe<Scalars["String"]["input"]>;
    /** SynchronisationId for Place Alias */
    readonly synchronisationId?: InputMaybe<Scalars["String"]["input"]>;
    /** Place alias type */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a place associated with another place. */
export type PlaceAssociation = {
    readonly __typename?: "PlaceAssociation";
    /** The unique ID for the partner responsible for this channel */
    readonly id: Scalars["String"]["output"];
    /** The name rate from the place association */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** The relationship rate from the place association */
    readonly relationship?: Maybe<Scalars["String"]["output"]>;
    /** The target place id from the place association */
    readonly targetPlaceId?: Maybe<Scalars["String"]["output"]>;
    /** The tpye of the place association */
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

/** This represents a list of place associations. */
export type PlaceAssociationList = {
    readonly __typename?: "PlaceAssociationList";
    readonly nodes?: Maybe<ReadonlyArray<Maybe<PlaceAssociation>>>;
    readonly total?: Maybe<Scalars["Int"]["output"]>;
};

export type PlaceFromAliasCreateInput = {
    /** The placeId of the placeAlias */
    readonly countryId: Scalars["String"]["input"];
    /** The  id of the placeAlias */
    readonly id: Scalars["String"]["input"];
    /** The placeId of the placeAlias */
    readonly name: Scalars["String"]["input"];
    /** The  type of the placeAlias */
    readonly type: Scalars["String"]["input"];
};

export type PlaceListFilter = {
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** Filter by subType */
    readonly subType?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by subType */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents an Place */
export type PlaceListItem = {
    readonly __typename?: "PlaceListItem";
    /** The country in which the place exists */
    readonly countryName?: Maybe<Scalars["String"]["output"]>;
    /** The date the place was created at */
    readonly createdAt: Scalars["DateTime"]["output"];
    /** The unique Holibob ID for the place */
    readonly id: Scalars["String"]["output"];
    /** Indicates that the place is primary */
    readonly isPrimary?: Maybe<Scalars["Boolean"]["output"]>;
    /** Indicates that the place is unverified */
    readonly isUnverified?: Maybe<Scalars["Boolean"]["output"]>;
    /** The updatedAt */
    readonly iso2?: Maybe<Scalars["String"]["output"]>;
    /** Indicates that the place is unverified */
    readonly iso3?: Maybe<Scalars["String"]["output"]>;
    /** The  subType of the place */
    readonly lat?: Maybe<Scalars["Float"]["output"]>;
    /** The name of the place */
    readonly lng?: Maybe<Scalars["Float"]["output"]>;
    /** The name of the place */
    readonly name: Scalars["String"]["output"];
    /** The date the place was created at */
    readonly nameLocale?: Maybe<Scalars["String"]["output"]>;
    /** Indicates that the place is unverified */
    readonly population?: Maybe<Scalars["Int"]["output"]>;
    /** The  subType of the place */
    readonly subType?: Maybe<Scalars["String"]["output"]>;
    /** The type of the place */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** The updatedAt */
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

/** This respresents the Place List response type */
export type PlaceListResponse = {
    readonly __typename?: "PlaceListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** Place list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<PlaceListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type PlaceListSort = {
    /** Sort by createdAt */
    readonly createdAt?: InputMaybe<SortDirection>;
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

/** This represents the place. */
export type PlaceNew = {
    readonly __typename?: "PlaceNew";
    readonly formattedAddress: Scalars["String"]["output"];
    readonly geoCoordinate: PlaceNewGeoCoordinate;
    readonly googlePlaceId: Scalars["String"]["output"];
    readonly id: Scalars["String"]["output"];
    readonly mapImageUrl: Scalars["String"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly timeZone?: Maybe<Scalars["String"]["output"]>;
};

export type PlaceNewGeoCoordinate = {
    readonly __typename?: "PlaceNewGeoCoordinate";
    readonly latitude: Scalars["Float"]["output"];
    readonly longitude: Scalars["Float"]["output"];
};

export type PlaceUpsertInput = {
    /** The unique Holibob ID for the place */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** Indicates that the place is primary */
    readonly isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Indicates that the place is unverified */
    readonly isUnverified?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The updatedAt */
    readonly iso2?: InputMaybe<Scalars["String"]["input"]>;
    /** Indicates that the place is unverified */
    readonly iso3?: InputMaybe<Scalars["String"]["input"]>;
    /** The  subType of the place */
    readonly lat?: InputMaybe<Scalars["Float"]["input"]>;
    /** The name of the place */
    readonly lng?: InputMaybe<Scalars["Float"]["input"]>;
    /** The name of the place */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** The date the place was created at */
    readonly nameLocale?: InputMaybe<Scalars["String"]["input"]>;
    /** The name of the place */
    readonly nameTranslations?: InputMaybe<ReadonlyArray<InputMaybe<TranslationInput>>>;
    /** Indicates that the place is unverified */
    readonly population?: InputMaybe<Scalars["Int"]["input"]>;
    /** The  subType of the place */
    readonly subType?: InputMaybe<Scalars["String"]["input"]>;
    /** The type of the place */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a PlaygroundQuery. */
export type PlaygroundQuery = {
    readonly __typename?: "PlaygroundQuery";
    /** Can poll this query. */
    readonly canPoll?: Maybe<Scalars["Boolean"]["output"]>;
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The description of the playground query. */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** The name of the group (folder) that contains the query. */
    readonly groupName?: Maybe<Scalars["String"]["output"]>;
    /** The unique id for the PlaygroundQuery. */
    readonly id: Scalars["String"]["output"];
    /** The name. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** The position of the query with respect to the groupName. */
    readonly ordinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** The query. */
    readonly query?: Maybe<Scalars["String"]["output"]>;
    /** The responseMappingData. */
    readonly responseMappingData?: Maybe<Scalars["String"]["output"]>;
    /** The type of query. */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

/** This represents a PlaygroundQuery. */
export type PlaygroundQueryDescriptionArgs = {
    contentType?: InputMaybe<DescriptionContentType>;
};

export type PlaygroundQueryDeleteInput = {
    /** The ID of the PlaygroundQuery to be deleted. */
    readonly id: Scalars["String"]["input"];
};

/** This represents a list of PlaygroundQuery. */
export type PlaygroundQueryList = {
    readonly __typename?: "PlaygroundQueryList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes?: Maybe<ReadonlyArray<Maybe<PlaygroundQuery>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type PlaygroundQueryListFilter = {
    /** filter by groupName. */
    readonly groupName?: InputMaybe<Scalars["String"]["input"]>;
    /** filter by name. */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    /** filter by type. */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
};

export type PlaygroundQueryUpsertInput = {
    /** Can poll. */
    readonly canPoll?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The descriptionJson of the PlaygroundQuery. */
    readonly descriptionJson?: InputMaybe<Scalars["String"]["input"]>;
    /** The group name of the PlaygroundQuery. */
    readonly groupName?: InputMaybe<Scalars["String"]["input"]>;
    /** The ID of the PlaygroundQuery. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The name of the PlaygroundQuery. */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** The ordinal position of the PlaygroundQuery. */
    readonly ordinalPosition?: InputMaybe<Scalars["String"]["input"]>;
    /** The query of the PlaygroundQuery. */
    readonly query?: InputMaybe<Scalars["String"]["input"]>;
    /** The responseMappingData of the PlaygroundQuery. */
    readonly responseMappingData?: InputMaybe<Scalars["String"]["input"]>;
    /** The type of the PlaygroundQuery. */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a price. */
export type Price = {
    readonly __typename?: "Price";
    /** The commission. */
    readonly commission: Scalars["Float"]["output"];
    /** The commission as formatted text. */
    readonly commissionFormattedText: Scalars["String"]["output"];
    /** The currency. */
    readonly currency: Scalars["String"]["output"];
    /** The gross price. */
    readonly gross: Scalars["Float"]["output"];
    /** The gross price as formatted text. */
    readonly grossFormattedText: Scalars["String"]["output"];
    /** The net price. */
    readonly net: Scalars["Float"]["output"];
    /** The net price as formatted text. */
    readonly netFormattedText: Scalars["String"]["output"];
    /** All pricing data, for use by holibob admin. */
    readonly pricingData?: Maybe<Scalars["JSON"]["output"]>;
    /**
     * The price that is paid to the supplier of this product.
     * @deprecated This field is deprecated
     */
    readonly supplier: BasicPrice;
};

export type PriceTree = {
    readonly __typename?: "PriceTree";
    readonly currency: CurrencyCode;
    readonly histogram: ReadonlyArray<Scalars["Int"]["output"]>;
    readonly markList: ReadonlyArray<Mark>;
    readonly maxPrice: Scalars["Int"]["output"];
    readonly minPrice: Scalars["Int"]["output"];
};

export type PricingCategory = {
    readonly __typename?: "PricingCategory";
    readonly availabilityId: Scalars["String"]["output"];
    /** @deprecated Use unitPrice instead. */
    readonly currency: Scalars["String"]["output"];
    readonly description?: Maybe<Scalars["String"]["output"]>;
    readonly discountsAvailable: Scalars["Boolean"]["output"];
    readonly errors?: Maybe<ReadonlyArray<Maybe<PricingCategoryError>>>;
    readonly id: Scalars["String"]["output"];
    readonly isDiscounted: Scalars["Boolean"]["output"];
    readonly isValid: Scalars["Boolean"]["output"];
    readonly key?: Maybe<Scalars["String"]["output"]>;
    readonly label: Scalars["String"]["output"];
    readonly maxAge?: Maybe<Scalars["Int"]["output"]>;
    readonly maxParticipants?: Maybe<Scalars["Int"]["output"]>;
    readonly maxParticipantsDepends?: Maybe<ReadonlyArray<Maybe<MaxParticipantsDepends>>>;
    readonly minAge?: Maybe<Scalars["Int"]["output"]>;
    readonly minParticipants?: Maybe<Scalars["Int"]["output"]>;
    /** @deprecated Use unitPrice instead. */
    readonly price: Scalars["Float"]["output"];
    /** @deprecated Use totalDiscountApplied instead. */
    readonly priceDiscountTotalFormattedText: Scalars["String"]["output"];
    /** @deprecated Use unitPrice instead. */
    readonly priceFormattedText: Scalars["String"]["output"];
    /** @deprecated Use totalPrice instead. */
    readonly priceTotal: Scalars["Float"]["output"];
    /** @deprecated Use totalPrice instead. */
    readonly priceTotalFormattedText: Scalars["String"]["output"];
    /** @deprecated Use units instead. */
    readonly requestedValue: Scalars["Int"]["output"];
    readonly room?: Maybe<PricingCategoryRoom>;
    /** This is the total discount which has already been applied to totalPrice. This discount is due to the existence of group rates. */
    readonly totalDiscountApplied: Price;
    /** The current total price for this pricingCategory = unitPrice x units. */
    readonly totalPrice: Price;
    /** The price to book a single unit of this pricing category. */
    readonly unitPrice: Price;
    readonly units: Scalars["Int"]["output"];
    /** @deprecated Use units instead. */
    readonly value: Scalars["Int"]["output"];
};

/** Type of availability */
export enum PricingCategoryError {
    /** The maximum number of participants has been exceed */
    MaximumConstraintViolated = "MAXIMUM_CONSTRAINT_VIOLATED",
    /** The minimum number of participants has been exceed */
    MinimumConstraintViolated = "MINIMUM_CONSTRAINT_VIOLATED",
}

/** This represents a list pricing categories */
export type PricingCategoryList = {
    readonly __typename?: "PricingCategoryList";
    /** @deprecated Please use totalPrice instead. */
    readonly currency?: Maybe<Scalars["String"]["output"]>;
    readonly errors?: Maybe<ReadonlyArray<Maybe<PricingCategoryListError>>>;
    readonly nodes: ReadonlyArray<Maybe<PricingCategory>>;
    /** @deprecated Please use totalPrice instead. */
    readonly priceTotal?: Maybe<Scalars["Float"]["output"]>;
    /** @deprecated Please use totalPrice instead. */
    readonly priceTotalFormattedText?: Maybe<Scalars["String"]["output"]>;
    readonly recordCount: Scalars["Int"]["output"];
    /** @deprecated Please use record count in place */
    readonly total: Scalars["Int"]["output"];
    readonly totalPrice?: Maybe<Price>;
};

/** Type of availability */
export enum PricingCategoryListError {
    /** The maximum number of participants has been exceed */
    MaximumConstraintViolated = "MAXIMUM_CONSTRAINT_VIOLATED",
    /** The minimum number of participants has been exceed */
    MinimumConstraintViolated = "MINIMUM_CONSTRAINT_VIOLATED",
}

export type PricingCategoryRoom = {
    readonly __typename?: "PricingCategoryRoom";
    readonly area?: Maybe<ProductRoomArea>;
    readonly availableRoomCount: Scalars["Int"]["output"];
    readonly bedGroup?: Maybe<Scalars["String"]["output"]>;
    readonly cancellationPenaltyList: CancellationPenaltyList;
    readonly id: Scalars["String"]["output"];
    readonly imageList: ProductRoomImageList;
    readonly maxAdultCount: Scalars["Int"]["output"];
    readonly maxChildCount: Scalars["Int"]["output"];
    readonly maxPersonCount: Scalars["Int"]["output"];
    readonly occupancy: Scalars["String"]["output"];
    readonly pricingBreakdown: PricingCategoryRoomPricingBreakdown;
    readonly rateAmenityList: ProductRoomAmenityList;
    readonly roomAmenityList: ProductRoomAmenityList;
    readonly viewList: ProductRoomViewList;
};

export type PricingCategoryRoomInclusiveItem = {
    readonly __typename?: "PricingCategoryRoomInclusiveItem";
    readonly currency: Scalars["String"]["output"];
    readonly type: Scalars["String"]["output"];
    readonly value: Scalars["Float"]["output"];
};

export type PricingCategoryRoomInclusiveItemList = {
    readonly __typename?: "PricingCategoryRoomInclusiveItemList";
    readonly nodes: ReadonlyArray<PricingCategoryRoomInclusiveItem>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type PricingCategoryRoomPayableItem = {
    readonly __typename?: "PricingCategoryRoomPayableItem";
    readonly billableCurrency?: Maybe<PricingCategoryRoomPayableItemCurrencyItem>;
    readonly requestCurrency?: Maybe<PricingCategoryRoomPayableItemCurrencyItem>;
    readonly type: Scalars["String"]["output"];
};

export type PricingCategoryRoomPayableItemCurrencyItem = {
    readonly __typename?: "PricingCategoryRoomPayableItemCurrencyItem";
    readonly currency: Scalars["String"]["output"];
    readonly value: Scalars["Float"]["output"];
};

export type PricingCategoryRoomPayableItemList = {
    readonly __typename?: "PricingCategoryRoomPayableItemList";
    readonly nodes: ReadonlyArray<PricingCategoryRoomPayableItem>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type PricingCategoryRoomPricingBreakdown = {
    readonly __typename?: "PricingCategoryRoomPricingBreakdown";
    readonly exclusiveNightlyBaseRateList?: Maybe<PricingCategoryRoomInclusiveItemList>;
    readonly inclusiveFeeList?: Maybe<PricingCategoryRoomInclusiveItemList>;
    /** Charges payable at the property */
    readonly payAtPropertyFeeList: PricingCategoryRoomPayableItemList;
};

/** Private Query root */
export type PrivateQuery = {
    readonly __typename?: "PrivateQuery";
    /** List articles */
    readonly articleList?: Maybe<ArticleListResponse>;
    readonly booking?: Maybe<BookingPrivate>;
    /** Used for checking db diagnostics */
    readonly diagnostic?: Maybe<Scalars["String"]["output"]>;
    /** Finance hedge list */
    readonly financeHedgeList?: Maybe<FinanceHedgeList>;
    /** Partner */
    readonly partner?: Maybe<Partner>;
    /** Use to fetch the extended data for a single PartnerChannel. The criteria includes a required partnerChannelId. */
    readonly partnerChannel?: Maybe<PartnerChannelPrivate>;
    /** PartnerChannel List */
    readonly partnerChannelList?: Maybe<PartnerChannelListResponse>;
    /** Used to fetch a list of partner channels including an id, value and description. */
    readonly partnerChannelOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Partner List */
    readonly partnerList?: Maybe<PartnerList>;
    /** Used to fetcha list of partners including an id, value and description. */
    readonly partnerOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Product */
    readonly product?: Maybe<ProductPrivate>;
    /** Use to fetch the extended data for a single ProductCategory. The criteria includes a required productCategoryId. */
    readonly productCategory?: Maybe<ProductCategoryPrivate>;
    /** ProductCategory List */
    readonly productCategoryList?: Maybe<ProductCategoryListResponse>;
    /** Use to fetch the extended data for a single ProductContent. The criteria includes a required productContentId. */
    readonly productContent?: Maybe<ProductContentPrivate>;
    /** Use to fetch a list of product. The query support pagination and will accept a wide range of filters from a simple search to more complex filters covering locations, types and attributes. */
    readonly productList?: Maybe<ProductListPrivate>;
    /** Use to fetch the extended data for a single ProductSaleRestrictionSummaryList. The criteria includes a required countryId. */
    readonly productSaleRestrictionSummaryList?: Maybe<ProductSaleRestrictionSummaryListResponse>;
    /** Used to fetch a list of ProductSaleRestriction type including an id, value and description. */
    readonly productSaleRestrictionTypeOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch a list of securityPrincipal including an id, value and description. */
    readonly securityPrincipalOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch the extended data for a single SecurityPrincipalUser. The criteria includes a required securityPrincipalUserId. */
    readonly securityPrincipalUser?: Maybe<SecurityPrincipalUser>;
    /** SecurityPrincipalUser List */
    readonly securityPrincipalUserList?: Maybe<SecurityPrincipalUserListResponse>;
    /** Use to fetch the extended data for a single SupplierFinance. The criteria includes a required supplierFinanceId. */
    readonly supplierFinance?: Maybe<SupplierFinance>;
    /** SupplierFinance list. */
    readonly supplierFinanceList?: Maybe<SupplierFinanceList>;
    /** Used to fetcha list of suppliers including an id, value and description. */
    readonly supplierOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** SupplierSurvey */
    readonly supplierSurvey?: Maybe<SupplierSurvey>;
    /** Used to fetcha list of synchronisation sources including an id, value and description. */
    readonly synchronisationSourceOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch the extended data for a single test booking. The criteria includes a required testBooking Id. */
    readonly testBooking?: Maybe<TestBooking>;
    /** Test Booking List */
    readonly testBookingList?: Maybe<TestBookingListResponse>;
    /** Use to fetch the extended data for a single test product. The criteria includes a required testProduct Id. */
    readonly testProduct?: Maybe<TestProduct>;
    /** Test Product List */
    readonly testProductList?: Maybe<TestProductListResponse>;
    /** Used to fetcha list of users including an id, value and description. */
    readonly userOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
};

/** Private Query root */
export type PrivateQueryArticleListArgs = {
    filter?: InputMaybe<ArticleListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ArticleListSort>;
};

/** Private Query root */
export type PrivateQueryBookingArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
    input?: InputMaybe<BookingInput>;
};

/** Private Query root */
export type PrivateQueryFinanceHedgeListArgs = {
    filter?: InputMaybe<FinanceHedgeListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<FinanceHedgeListSort>;
};

/** Private Query root */
export type PrivateQueryPartnerArgs = {
    id: Scalars["String"]["input"];
};

/** Private Query root */
export type PrivateQueryPartnerChannelArgs = {
    id: Scalars["String"]["input"];
};

/** Private Query root */
export type PrivateQueryPartnerChannelListArgs = {
    filter?: InputMaybe<PartnerChannelListFilter>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<PartnerChannelListSort>;
};

/** Private Query root */
export type PrivateQueryPartnerChannelOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<PartnerChannelOptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Private Query root */
export type PrivateQueryPartnerListArgs = {
    filter?: InputMaybe<PartnerListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<PartnerListSort>;
};

/** Private Query root */
export type PrivateQueryPartnerOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Private Query root */
export type PrivateQueryProductArgs = {
    id: Scalars["String"]["input"];
};

/** Private Query root */
export type PrivateQueryProductCategoryArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Private Query root */
export type PrivateQueryProductCategoryListArgs = {
    filter?: InputMaybe<ProductCategoryListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ProductCategoryListSort>;
};

/** Private Query root */
export type PrivateQueryProductContentArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Private Query root */
export type PrivateQueryProductListArgs = {
    filter?: InputMaybe<ProductListFilterPrivate>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ProductListSort>;
};

/** Private Query root */
export type PrivateQueryProductSaleRestrictionSummaryListArgs = {
    filter?: InputMaybe<ProductSaleRestrictionSummaryListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ProductSaleRestrictionSummaryListSort>;
};

/** Private Query root */
export type PrivateQueryProductSaleRestrictionTypeOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Private Query root */
export type PrivateQuerySecurityPrincipalOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Private Query root */
export type PrivateQuerySecurityPrincipalUserArgs = {
    id: Scalars["String"]["input"];
};

/** Private Query root */
export type PrivateQuerySecurityPrincipalUserListArgs = {
    filter?: InputMaybe<SecurityPrincipalUserListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<SecurityPrincipalUserListSort>;
};

/** Private Query root */
export type PrivateQuerySupplierFinanceArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Private Query root */
export type PrivateQuerySupplierFinanceListArgs = {
    filter?: InputMaybe<SupplierFinanceListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<SupplierFinanceListSort>;
};

/** Private Query root */
export type PrivateQuerySupplierOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Private Query root */
export type PrivateQuerySupplierSurveyArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Private Query root */
export type PrivateQuerySynchronisationSourceOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Private Query root */
export type PrivateQueryTestBookingArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Private Query root */
export type PrivateQueryTestBookingListArgs = {
    filter?: InputMaybe<TestBookingListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<TestBookingListSort>;
};

/** Private Query root */
export type PrivateQueryTestProductArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Private Query root */
export type PrivateQueryTestProductListArgs = {
    filter?: InputMaybe<TestProductListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<TestProductListSort>;
};

/** Private Query root */
export type PrivateQueryUserOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Product = {
    readonly __typename?: "Product";
    /** The abstract (brief description) of the product. */
    readonly abstract?: Maybe<Scalars["String"]["output"]>;
    /** The bookable additions for this product. */
    readonly additionList: ProductAdditionList;
    /**
     * DEPRECATED The bookable additions.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use additionList in its place
     */
    readonly additions?: Maybe<ReadonlyArray<Maybe<ProductAddition>>>;
    /** administrationNote */
    readonly administrationNote?: Maybe<Scalars["String"]["output"]>;
    /** The duration prio to a product start in which you must arrive. */
    readonly advanceArrivalDuration?: Maybe<Scalars["String"]["output"]>;
    /** allContactFields */
    readonly allContactFields?: Maybe<Scalars["JSON"]["output"]>;
    readonly allowZeroPricing?: Maybe<Scalars["Boolean"]["output"]>;
    readonly associatedProductList: ProductAssociatedListType;
    /** The attributes association for which this product is available. */
    readonly attributeAssociationList?: Maybe<ProductAttributeAssociationList>;
    /** The list of attributes for the product. */
    readonly attributeList: ProductAttributeList;
    /**
     * DEPRECATED The attributes
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use attributeList in its place
     */
    readonly attributes?: Maybe<ReadonlyArray<Maybe<ProductAttribute>>>;
    /** Product Availability */
    readonly availabilities?: Maybe<AvailabilityListResponse>;
    /**
     * Count of Availability Records for this product
     * @deprecated This is no longer supported.
     */
    readonly availabilityCount?: Maybe<Scalars["Int"]["output"]>;
    /** The availability patterns for which this product is available. */
    readonly availabilityPatternList?: Maybe<ProductAvailabilityPatternList>;
    /** Type of availability associated with this product. */
    readonly availabilityType: AvailabilityType;
    /** Banner to display over product image */
    readonly banner?: Maybe<BannerType>;
    /** The best time to experience this product. */
    readonly bestTimeToVisit?: Maybe<Scalars["String"]["output"]>;
    /** The duration prior to a product start in which no booking can be made. */
    readonly bookingCutoffDuration?: Maybe<Scalars["String"]["output"]>;
    /** bookingQuestions */
    readonly bookingQuestions?: Maybe<Scalars["JSON"]["output"]>;
    /** url to the booking page for this product */
    readonly bookingUrl?: Maybe<Scalars["String"]["output"]>;
    /** The cancellation policy for the product. */
    readonly cancellationPolicy: ProductCancellationPolicy;
    /** The category association for which this product is available. */
    readonly categoryAssociationList?: Maybe<ProductCategoryAssociationList>;
    /** The list of categories for the product. */
    readonly categoryList?: Maybe<ProductProductCategoryListType>;
    /** The nearest city for the end location */
    readonly cityEnd?: Maybe<Place>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead. */
    readonly cityEndId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead. */
    readonly cityEndName?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead. */
    readonly cityId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead. */
    readonly cityName?: Maybe<Scalars["String"]["output"]>;
    /** The nearest city for the start location */
    readonly cityStart?: Maybe<Place>;
    /**
     * DEPRECATED - Please use cityId in place
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead.
     */
    readonly cityStartId?: Maybe<Scalars["String"]["output"]>;
    /**
     * DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead.
     */
    readonly cityStartName?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob code for the product. */
    readonly code?: Maybe<Scalars["String"]["output"]>;
    /** Pricing for contact only products */
    readonly contactPricingList?: Maybe<ProductContactPricingList>;
    /** The list of content for the product. */
    readonly contentList: ProductContentListResponse;
    /** All product content in all languages */
    readonly contentTranslatedList: ProductContentTranslatedList;
    /**
     * The country id for the end location
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryEndId?: Maybe<Scalars["String"]["output"]>;
    /**
     * The country name for the end location
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryEndName?: Maybe<Scalars["String"]["output"]>;
    /**
     * The country id for the activity location
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryId?: Maybe<Scalars["String"]["output"]>;
    /**
     * The country name for the activity location
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryName?: Maybe<Scalars["String"]["output"]>;
    /**
     * DEPRECATED - Please use countryId in place
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryStartId?: Maybe<Scalars["String"]["output"]>;
    /**
     * DEPRECATED - Please use countryName in place
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryStartName?: Maybe<Scalars["String"]["output"]>;
    /** createdAt */
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The culture shock level of the product. */
    readonly cultureShockLevel?: Maybe<Scalars["String"]["output"]>;
    /**
     * The list of curations for the product.
     * @deprecated DEPRECATED: This field returns obsolete data. For current Curation data please use the hierarchyCurationList field.
     */
    readonly curationList?: Maybe<CurationListType>;
    /** customFields */
    readonly customFields?: Maybe<Scalars["JSON"]["output"]>;
    /**
     * Default path to curation
     * @deprecated DEPRECATED: this field returns obsolete data
     */
    readonly defaultCurationPath?: Maybe<ReadonlyArray<CurationPathItem>>;
    /** defaultImageId */
    readonly defaultImageId?: Maybe<Scalars["String"]["output"]>;
    /** The language code that the defaultName has been returned in if available. */
    readonly defaultLanguageCode?: Maybe<Scalars["String"]["output"]>;
    /** The name of the product in the fallback language (typically EN). */
    readonly defaultName?: Maybe<Scalars["String"]["output"]>;
    /** The description of the product in the requested or default language. */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /**
     * An HTML description of the product.
     * @deprecated Deprecated: please use description with a content type of html
     */
    readonly descriptionHtml?: Maybe<Scalars["String"]["output"]>;
    readonly descriptionJson?: Maybe<Scalars["JSON"]["output"]>;
    /** The difficulty level of the product. */
    readonly difficultyLevel?: Maybe<Scalars["String"]["output"]>;
    /** Dress advice for the product. */
    readonly dressAdvice?: Maybe<Scalars["String"]["output"]>;
    /** effectiveCommissionRate */
    readonly effectiveCommissionRate?: Maybe<Scalars["Float"]["output"]>;
    /** effectiveCommissionType */
    readonly effectiveCommissionType?: Maybe<Scalars["String"]["output"]>;
    /** The language code that the name has been returned in. */
    readonly effectiveLanguageCode?: Maybe<Scalars["String"]["output"]>;
    /** The ending place for the product. */
    readonly endPlace?: Maybe<PlaceNew>;
    /**
     * DEPRECATED The exclusions
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use contentList in its place
     */
    readonly exclusions?: Maybe<ReadonlyArray<Maybe<ProductContent>>>;
    readonly externalWidgetData?: Maybe<ExternalWidget>;
    readonly factorTagList?: Maybe<SystemTagFactorList>;
    /** Product Geo Coordinate */
    readonly geoCoordinate?: Maybe<GeoCoordinate>;
    /** The id of the product geo coordinate. */
    readonly geoCoordinateId?: Maybe<Scalars["String"]["output"]>;
    /** The geo name of the product. */
    readonly geoName?: Maybe<Scalars["String"]["output"]>;
    /** The list of guide languages in which this product is available. */
    readonly guideLanguageList: GuideLanguageList;
    /**
     * The guide price for the product.
     * @deprecated This field is deprecated, please use guidePriceAmount in its place.
     */
    readonly guidePrice?: Maybe<Scalars["Float"]["output"]>;
    /** The guide price amount for the product. */
    readonly guidePriceAmount?: Maybe<Scalars["Float"]["output"]>;
    /** The guide price currency for the product. */
    readonly guidePriceCurrency?: Maybe<Scalars["String"]["output"]>;
    /** The guide price for the product as formatted text with currency symbol. */
    readonly guidePriceFormattedText?: Maybe<Scalars["String"]["output"]>;
    /** The guide price type */
    readonly guidePriceType?: Maybe<ProductGuidePriceType>;
    /**
     * Does product have an active commission
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use offSaleReasonList instead
     */
    readonly hasActiveCommission?: Maybe<Scalars["Boolean"]["output"]>;
    /** hasHtmlParseErrors */
    readonly hasHtmlParseErrors?: Maybe<Scalars["Boolean"]["output"]>;
    /** Whether this product has a start time. */
    readonly hasStartTime?: Maybe<Scalars["Boolean"]["output"]>;
    /** The list of curations for the product. */
    readonly hierarchyCurationList?: Maybe<ProductCurationListType>;
    /**
     * DEPRECATED The highlights
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use contentList in its place
     */
    readonly highlights?: Maybe<ReadonlyArray<Maybe<ProductContent>>>;
    /** The guide price for the product using price type. */
    readonly holibobGuidePrice?: Maybe<Price>;
    /** htmlParseErrors */
    readonly htmlParseErrors?: Maybe<Scalars["JSON"]["output"]>;
    /** The unique Holibob Id for the product. */
    readonly id: Scalars["String"]["output"];
    /** Product Images */
    readonly imageList: ReadonlyArray<ProductImage>;
    /**
     * DEPRECATED Product Images
     * @deprecated DEPRECATED - Please use imageList instead.
     */
    readonly images: ReadonlyArray<ProductImage>;
    /**
     * includeInDemo
     * @deprecated Deprecated: no longer used
     */
    readonly includeInDemo?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * includeInDemoAsFeatured
     * @deprecated Deprecated: no longer used
     */
    readonly includeInDemoAsFeatured?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * DEPRECATED The inclusions
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use contentList in its place
     */
    readonly inclusions?: Maybe<ReadonlyArray<Maybe<ProductContent>>>;
    /** If viewer's favourite product */
    readonly isFavourite?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is this product featured. */
    readonly isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is this product offsale. */
    readonly isOffSale?: Maybe<Scalars["Boolean"]["output"]>;
    /** A flag indicating if the product requires manual approval by the supplier. Only relevant for internal products. */
    readonly isOnRequest: Scalars["Boolean"]["output"];
    /**
     * DEPRECATED Is this product activated.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use offSaleReasonList instead
     */
    readonly isPartnerActivated?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is this product featured. */
    readonly isPromoted?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * Is this product the subject of one or more sale restrictions.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use offSaleReasonList instead
     */
    readonly isSaleRestricted?: Maybe<Scalars["Boolean"]["output"]>;
    /** Evaluate product selection based on conditions. */
    readonly isSelected?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * DEPRECATED Is this product activated.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use offSaleReasonList instead
     */
    readonly isSupplierActivated?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * isTest
     * @deprecated Deprecated: no longer used
     */
    readonly isTest?: Maybe<Scalars["Boolean"]["output"]>;
    readonly itinerary: ProductItinerary;
    /**
     * The guide languages in which this product is available.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use guideLanguageList in its place
     */
    readonly languages?: Maybe<ReadonlyArray<Maybe<GuideLanguage>>>;
    /** mainContactFields */
    readonly mainContactFields?: Maybe<Scalars["JSON"]["output"]>;
    /** The maximum duration of the product. */
    readonly maxDuration?: Maybe<Scalars["String"]["output"]>;
    /** Maximum group size for the product. */
    readonly maxGroupSize?: Maybe<Scalars["String"]["output"]>;
    /** The list of translations for the product. */
    readonly metaList: ProductMetaListResponse;
    /** The minimum duration of the product. */
    readonly minDuration?: Maybe<Scalars["String"]["output"]>;
    /** The name of the product in the requested language where this is available and in a fallback language (typically EN) where the requested language is not available. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /**
     * The date on which the product is next available.
     * @deprecated This is not longer supported.
     */
    readonly nextAvailabilityAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** Product Sales restrictions */
    readonly offSaleReasonList: ReadonlyArray<Maybe<ProductOffSaleReason>>;
    /** originGuidePrice */
    readonly originGuidePrice?: Maybe<Scalars["String"]["output"]>;
    /** originGuidePriceCurrency */
    readonly originGuidePriceCurrency?: Maybe<Scalars["String"]["output"]>;
    /** originMaxRetailPrice */
    readonly originMaxRetailPrice?: Maybe<Scalars["String"]["output"]>;
    /**
     * overrideName
     * @deprecated Deprecated: no longer used
     */
    readonly overrideName?: Maybe<Scalars["String"]["output"]>;
    /** passengerFields */
    readonly passengerFields?: Maybe<Scalars["JSON"]["output"]>;
    /** The permissions that the current viewer has with this record. */
    readonly permissions?: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
    /** The starting place for the product. */
    readonly place?: Maybe<ProductPlace>;
    /**
     * The ending place for the product.
     * @deprecated DEPRECATED - Use endPlace
     */
    readonly placeEnd?: Maybe<ProductPlace>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead. */
    readonly placeId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead. */
    readonly placeName?: Maybe<Scalars["String"]["output"]>;
    /** The number of hours before the activity start time when the pre-arrival instructions email is sent. */
    readonly preArrivalInstructionsEmailWindow?: Maybe<Scalars["Int"]["output"]>;
    /** A preview image for this product. */
    readonly previewImage?: Maybe<ProductImage>;
    /** The channel specific promotions of the product. */
    readonly promotionList?: Maybe<PartnerChannelProductPromotionList>;
    /** The enumerated type attributed to the promotion of the product. */
    readonly promotionType?: Maybe<Scalars["String"]["output"]>;
    /** Product Provider */
    readonly provider?: Maybe<Provider>;
    /** The id of the product provider. */
    readonly providerId?: Maybe<Scalars["String"]["output"]>;
    /** Product quality score */
    readonly qualityMatchScore?: Maybe<Scalars["Float"]["output"]>;
    readonly questionList: EntityQuestionAssociationList;
    /** the total numbers of reviews */
    readonly reviewCount?: Maybe<Scalars["Int"]["output"]>;
    /** reviews rating score */
    readonly reviewRating?: Maybe<Scalars["Float"]["output"]>;
    /** The roolm list associated with this product. */
    readonly roomList: ProductRoomList;
    /**
     * The language code that the rootName has been returned in if available.
     * @deprecated Deprecated: please use defaultLanguageCode
     */
    readonly rootLanguageCode?: Maybe<Scalars["String"]["output"]>;
    /**
     * The name of the product in the fallback language (typically EN).
     * @deprecated Deprecated: please use defaultName
     */
    readonly rootName?: Maybe<Scalars["String"]["output"]>;
    readonly slug?: Maybe<Scalars["String"]["output"]>;
    /** Source Instance */
    readonly sourceInstance?: Maybe<SynchronisationSource>;
    /** The starting place for the product. */
    readonly startPlace?: Maybe<PlaceNew>;
    /** Count of start times availabile with this product. */
    readonly startTimeCount?: Maybe<Scalars["Int"]["output"]>;
    /** The start times for which this product is available. */
    readonly startTimeList: ProductStartTimeList;
    /**
     * The start times for which this product is available.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use startTimesList in its place
     */
    readonly startTimes?: Maybe<ReadonlyArray<Maybe<ProductStartTime>>>;
    /** Product Supplier */
    readonly supplier?: Maybe<ProductSupplier>;
    /** supplierId */
    readonly supplierId?: Maybe<Scalars["String"]["output"]>;
    /** The name of the product supplier. */
    readonly supplierName?: Maybe<Scalars["String"]["output"]>;
    /** supplierSurvey */
    readonly supplierSurvey?: Maybe<Scalars["JSON"]["output"]>;
    /** synchronisationDeletedAt */
    readonly synchronisationDeletedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** synchronisationId */
    readonly synchronisationId?: Maybe<Scalars["String"]["output"]>;
    /** synchronisationKey */
    readonly synchronisationKey?: Maybe<Scalars["String"]["output"]>;
    /** synchronisationSource */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** synchronisedAt */
    readonly synchronisedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** synchronisedAvailabilityAt */
    readonly synchronisedAvailabilityAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The list of SystemTags with this Provider. */
    readonly tagAssociationList?: Maybe<SystemTagList>;
    /** Tipping advice for the product. */
    readonly tippingAdvice?: Maybe<Scalars["String"]["output"]>;
    /** The list of translations for the product. */
    readonly translationList?: Maybe<ProductTranslationListResponse>;
    readonly type: ProductTypeType;
    /** updatedAt */
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** Defines the mode in which this product can be edited. */
    readonly upsertMode?: Maybe<Scalars["String"]["output"]>;
    /** The websiteUrl for the product. */
    readonly websiteUrl?: Maybe<Scalars["String"]["output"]>;
};

export type ProductAssociatedProductListArgs = {
    filter?: InputMaybe<AssociatedProductFilter>;
};

export type ProductAvailabilitiesArgs = {
    autoFillOptions?: InputMaybe<Scalars["Boolean"]["input"]>;
    filter?: InputMaybe<AvailabilityListFilter>;
    optionList?: InputMaybe<ReadonlyArray<InputMaybe<AvailabilityListOptionListItemInput>>>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sessionId?: InputMaybe<Scalars["ID"]["input"]>;
    sort?: InputMaybe<AvailabilityListSort>;
};

export type ProductDescriptionArgs = {
    contentType?: InputMaybe<DescriptionContentType>;
};

export type ProductIsSelectedArgs = {
    criteria?: InputMaybe<ProductIsSelectedCriteriaType>;
};

/** This represents a product addition */
export type ProductAddition = {
    readonly __typename?: "ProductAddition";
    /** description */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** id */
    readonly id?: Maybe<Scalars["String"]["output"]>;
    /** name */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Deprecated. */
    readonly saleCurrency?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Deprecated. */
    readonly saleGrossPrice?: Maybe<Scalars["Float"]["output"]>;
    /** @deprecated Please use unitPrice instead. */
    readonly saleGrossPriceFormattedText?: Maybe<Scalars["String"]["output"]>;
    /** type */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** Guide price for this addition */
    readonly unitPrice?: Maybe<Price>;
};

/** This represents a list of product additions. */
export type ProductAdditionList = {
    readonly __typename?: "ProductAdditionList";
    readonly nodes: ReadonlyArray<ProductAddition>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type ProductAssociated = {
    readonly __typename?: "ProductAssociated";
    readonly product: ProductTypeBase;
    readonly type: ProductAssociationType;
};

export type ProductAssociatedListType = {
    readonly __typename?: "ProductAssociatedListType";
    readonly nodes: ReadonlyArray<ProductAssociated>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type ProductAssociationInput = {
    readonly associatedProductId: Scalars["String"]["input"];
    readonly type?: InputMaybe<ProductAssociationType>;
};

export enum ProductAssociationType {
    Mandated = "MANDATED",
    Suggested = "SUGGESTED",
}

/** This represents a product attachment */
export type ProductAttachment = {
    readonly __typename?: "ProductAttachment";
    /** The unique Holibob ID for the product attachment */
    readonly id: Scalars["String"]["output"];
    /** The unique Holibob ID for the product */
    readonly productId: Scalars["String"]["output"];
    /** The product name for the product */
    readonly productName?: Maybe<Scalars["String"]["output"]>;
    /** The  product attachment type */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** The product attachment vaultId */
    readonly vaultId?: Maybe<Scalars["String"]["output"]>;
};

export type ProductAttachmentDeleteInput = {
    /** The ID of the ProductAttachment to be deleted. */
    readonly id: Scalars["String"]["input"];
};

export type ProductAttachmentListFilter = {
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** productId */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents an productImage */
export type ProductAttachmentListItem = {
    readonly __typename?: "ProductAttachmentListItem";
    /** The unique Holibob ID for the product attachment */
    readonly id: Scalars["String"]["output"];
    /** The unique Holibob ID for the product */
    readonly productId: Scalars["String"]["output"];
    /** The product name for the product */
    readonly productName?: Maybe<Scalars["String"]["output"]>;
    /** The  product attachment type */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** The product attachment vaultId */
    readonly vaultId?: Maybe<Scalars["String"]["output"]>;
};

/** This respresents the ProductAttachmentList response type */
export type ProductAttachmentListResponse = {
    readonly __typename?: "ProductAttachmentListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** ProductAttachment list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<ProductAttachmentListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type ProductAttachmentListSort = {
    /** Sort by id */
    readonly id?: InputMaybe<SortDirection>;
};

export type ProductAttachmentUpsertInput = {
    /** The ID of the ProductAttachment. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The productId of the ProductAttachment. */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    /** The Type of the ProductAttachment. */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
    /** The vaultId of the ProductAttachment. */
    readonly vaultId?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a ProductAttribute. */
export type ProductAttribute = {
    readonly __typename?: "ProductAttribute";
    /** The createdAt. */
    readonly createdAt?: Maybe<Scalars["String"]["output"]>;
    /** The description. */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** The unique id for the ProductAttribute. */
    readonly id: Scalars["String"]["output"];
    /** The includedProductCount. */
    readonly includedProductCount?: Maybe<Scalars["String"]["output"]>;
    /** The isDisplay. */
    readonly isDisplay?: Maybe<Scalars["String"]["output"]>;
    /** The level1. */
    readonly level1?: Maybe<Scalars["String"]["output"]>;
    /** Name translation List */
    readonly level1TranslationList?: Maybe<TranslationList>;
    /** The mattchedProductCount. */
    readonly matchedProductCount?: Maybe<Scalars["String"]["output"]>;
    /** The name. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** The nameMapping. */
    readonly nameMapping?: Maybe<Scalars["String"]["output"]>;
    /** Name translation List */
    readonly nameTranslationList?: Maybe<TranslationList>;
    /** The orriginalMatchId. */
    readonly originalMatchId?: Maybe<Scalars["String"]["output"]>;
    /** The score. */
    readonly score?: Maybe<Scalars["String"]["output"]>;
    /** The source. */
    readonly source?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationSource. */
    readonly synchronisationId?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisedAt. */
    readonly synchronisedAt?: Maybe<Scalars["String"]["output"]>;
    /** The totalProductCount. */
    readonly totalProductCount?: Maybe<Scalars["String"]["output"]>;
    /** The type. */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** The updatedAt. */
    readonly updatedAt?: Maybe<Scalars["String"]["output"]>;
};

/** This represents a ProductAttributeAssociation. */
export type ProductAttributeAssociation = {
    readonly __typename?: "ProductAttributeAssociation";
    /** The unique id for the ProductAttributeAssociation. */
    readonly id: Scalars["String"]["output"];
    /** The id of the associated ProductAttribute. */
    readonly productAttributeId?: Maybe<Scalars["String"]["output"]>;
    /** The name of the associated ProductAttribute. */
    readonly productAttributeName?: Maybe<Scalars["String"]["output"]>;
    /** The id of the associated Product. */
    readonly productId?: Maybe<Scalars["String"]["output"]>;
};

export type ProductAttributeAssociationDeleteInput = {
    /** The ID of the ProductAttributeAssociation to be deleted. */
    readonly id: Scalars["String"]["input"];
};

/** This represents a list of ProductAttributeAssociation. */
export type ProductAttributeAssociationList = {
    readonly __typename?: "ProductAttributeAssociationList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes?: Maybe<ReadonlyArray<Maybe<ProductAttributeAssociation>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type ProductAttributeAssociationListFilter = {
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** filter by productId */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductAttributeAssociationUpsertInput = {
    /** The ID of the ProductAttributeAssociation. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The productAttributeId of the ProductAttribute to associate. */
    readonly productAttributeId: Scalars["String"]["input"];
    /** The ProductId the  of the ProductAttribute to associate. */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductAttributeDeleteInput = {
    /** The ID of the ProductAttribute to be deleted. */
    readonly id: Scalars["String"]["input"];
};

/** This represents a list of ProductAttribute. */
export type ProductAttributeList = {
    readonly __typename?: "ProductAttributeList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<ProductAttribute>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type ProductAttributeListFilter = {
    /** Search by Name. */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductAttributeUpsertInput = {
    /** The Description of the ProductAttribute. */
    readonly description?: InputMaybe<Scalars["String"]["input"]>;
    /** The ID of the ProductAttribute. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The IsDisplay of the ProductAttribute. */
    readonly isDisplay?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The Level1 of the ProductAttribute. */
    readonly level1?: InputMaybe<Scalars["String"]["input"]>;
    /** The level1 of the productAttribute */
    readonly level1Translations?: InputMaybe<ReadonlyArray<InputMaybe<TranslationInput>>>;
    /** The Name of the ProductAttribute. */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** The NameMapping of the ProductAttribute. */
    readonly nameMapping?: InputMaybe<Scalars["String"]["input"]>;
    /** The name of the productAttribute */
    readonly nameTranslations?: InputMaybe<ReadonlyArray<InputMaybe<TranslationInput>>>;
    /** The Score of the ProductAttribute. */
    readonly score?: InputMaybe<Scalars["String"]["input"]>;
    /** The Type of the ProductAttribute. */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents an item of product content. */
export type ProductAvailabilityPattern = {
    readonly __typename?: "ProductAvailabilityPattern";
    /** Capacity of the availability. */
    readonly capacity?: Maybe<Scalars["Int"]["output"]>;
    /** Capacity threshold of the availability. */
    readonly capacityThreshold?: Maybe<Scalars["Int"]["output"]>;
    /** The capacityType of the availability */
    readonly capacityType?: Maybe<CapacityConsumptionTypeType>;
    /** Duration of the availability. */
    readonly duration?: Maybe<Scalars["String"]["output"]>;
    /** The datetime the availability is valid from. */
    readonly effectiveFrom?: Maybe<Scalars["DateTime"]["output"]>;
    /** The datetime the availability is valid to. */
    readonly effectiveTo?: Maybe<Scalars["DateTime"]["output"]>;
    /** Unique Holibob Id for the availability. */
    readonly id?: Maybe<Scalars["String"]["output"]>;
    /** maxParticipantsPerBookingAvailability of the availability. */
    readonly maxParticipantsPerBookingAvailability?: Maybe<Scalars["Int"]["output"]>;
    /** Name of the availability. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** The pricing categories for this availability. */
    readonly pricingCategoryList?: Maybe<ProductAvailabilityPatternPricingCategoryList>;
    /** Product id. */
    readonly productId?: Maybe<Scalars["String"]["output"]>;
    /** Rrule for this availability. */
    readonly rRule?: Maybe<Scalars["String"]["output"]>;
    /** The start times for this availability. */
    readonly startTimeList?: Maybe<ProductAvailabilityPatternStartTimeList>;
    /** The unitPrice of the availability */
    readonly unitPrice?: Maybe<Scalars["Float"]["output"]>;
};

export type ProductAvailabilityPatternCloneInput = {
    readonly id: Scalars["String"]["input"];
};

export type ProductAvailabilityPatternDeleteInput = {
    /** The ID */
    readonly id: Scalars["String"]["input"];
};

/** This respresents the ProductAvailabilityPatternList type. */
export type ProductAvailabilityPatternList = {
    readonly __typename?: "ProductAvailabilityPatternList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** ProductAvailabilityPattern list. */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<ProductAvailabilityPattern>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type ProductAvailabilityPatternListFilter = {
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** Filter by list of ids. */
    readonly productAvailabilityPatternIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** filter by productId. */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
};

/** This respresents the ProductAvailabilityPatternPricingCategory type. */
export type ProductAvailabilityPatternPricingCategory = {
    readonly __typename?: "ProductAvailabilityPatternPricingCategory";
    /** The gross price for this pricing category. */
    readonly grossPrice?: Maybe<Scalars["Float"]["output"]>;
    /** Unique Holibob Id for the pricing category. */
    readonly id?: Maybe<Scalars["String"]["output"]>;
    /** The minimum age for this pricing category. */
    readonly maxAge?: Maybe<Scalars["Int"]["output"]>;
    /** The minimum age for this pricing category. */
    readonly minAge?: Maybe<Scalars["Int"]["output"]>;
    /** The name for this pricing category. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** The net price for this pricing category. */
    readonly netPrice?: Maybe<Scalars["Float"]["output"]>;
    /** @deprecated DEPRECATED - please use grossPrice instead */
    readonly price?: Maybe<Scalars["Float"]["output"]>;
};

export type ProductAvailabilityPatternPricingCategoryInput = {
    /** The gross price for this pricing category. */
    readonly grossPrice?: InputMaybe<Scalars["Float"]["input"]>;
    /** Unique Holibob Id for the pricing category. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The minimum age for this pricing category. */
    readonly maxAge?: InputMaybe<Scalars["Int"]["input"]>;
    /** The minimum age for this pricing category. */
    readonly minAge?: InputMaybe<Scalars["Int"]["input"]>;
    /** The name for this pricing category. */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** The net price for this pricing category. */
    readonly netPrice?: InputMaybe<Scalars["Float"]["input"]>;
    /** @deprecated DEPRECATED - please use grossPrice instead */
    readonly price?: InputMaybe<Scalars["Float"]["input"]>;
};

/** This respresents the ProductAvailabilityPatternPricingCategoryList type. */
export type ProductAvailabilityPatternPricingCategoryList = {
    readonly __typename?: "ProductAvailabilityPatternPricingCategoryList";
    /** ProductAvailabilityPatternPricingCategory list. */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<ProductAvailabilityPatternPricingCategory>>>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

/** This respresents the ProductAvailabilityPatternStartTime type. */
export type ProductAvailabilityPatternStartTime = {
    readonly __typename?: "ProductAvailabilityPatternStartTime";
    /** Unique Holibob Id for the start time. */
    readonly id: Scalars["String"]["output"];
    /** The time for this startTime. */
    readonly time?: Maybe<Scalars["String"]["output"]>;
};

export type ProductAvailabilityPatternStartTimeInput = {
    /** Unique Holibob Id for the start time. */
    readonly id: Scalars["String"]["input"];
    /** The time for this startTime. */
    readonly time?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a ProductAvailabilityPatternStartTimeInstance. */
export type ProductAvailabilityPatternStartTimeInstance = {
    readonly __typename?: "ProductAvailabilityPatternStartTimeInstance";
    /** The capacityThreshold. */
    readonly capacityThreshold?: Maybe<Scalars["Int"]["output"]>;
    /** The capacityType. */
    readonly capacityType?: Maybe<Scalars["String"]["output"]>;
    /** The consumption ledger for this instance. */
    readonly consumptionLedgerList?: Maybe<ProductAvailabilityPatternStartTimeInstanceConsumptionLedgerListType>;
    /** The date. */
    readonly date?: Maybe<Scalars["Date"]["output"]>;
    /** The id. */
    readonly id: Scalars["String"]["output"];
    /** The initial capacity. */
    readonly initialCapacity?: Maybe<Scalars["Int"]["output"]>;
    /** The inventoryList for this instance. */
    readonly inventoryList?: Maybe<ProductAvailabilityPatternStartTimeInstanceInventoryListType>;
    /** The name. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** If this record does not exist then it was generated from rRules */
    readonly recordExists?: Maybe<Scalars["Boolean"]["output"]>;
    /** The remaining capacity. */
    readonly remainingCapacity?: Maybe<Scalars["Int"]["output"]>;
    /** The status. */
    readonly status?: Maybe<Scalars["String"]["output"]>;
    /** The time. */
    readonly time?: Maybe<Scalars["String"]["output"]>;
};

/** This respresents the ProductAvailabilityPatternStartTime type. */
export type ProductAvailabilityPatternStartTimeInstanceConsumptionLedger = {
    readonly __typename?: "ProductAvailabilityPatternStartTimeInstanceConsumptionLedger";
    /** The bookingCode. */
    readonly bookingCode?: Maybe<Scalars["String"]["output"]>;
    /** The bookingId. */
    readonly bookingId?: Maybe<Scalars["String"]["output"]>;
    /** The comment. */
    readonly comment?: Maybe<Scalars["String"]["output"]>;
    /** Unique Holibob Id for the instance consumption ledger record. */
    readonly id: Scalars["String"]["output"];
    /** The type - CREDIT or DEBIT. */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** The units. */
    readonly units?: Maybe<Scalars["Int"]["output"]>;
};

/** This respresents the ProductAvailabilityPatternStartTimeInstanceConsumptionLedgerListType type. */
export type ProductAvailabilityPatternStartTimeInstanceConsumptionLedgerListType = {
    readonly __typename?: "ProductAvailabilityPatternStartTimeInstanceConsumptionLedgerListType";
    /** ProductAvailabilityPatternStartTimeInstanceConsumptionLedger list. */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<ProductAvailabilityPatternStartTimeInstanceConsumptionLedger>>>;
    /** Total number of records matching query */
    readonly total?: Maybe<Scalars["Int"]["output"]>;
};

/** This respresents the ProductAvailabilityPatternStartTimeInstanceInventory type. */
export type ProductAvailabilityPatternStartTimeInstanceInventory = {
    readonly __typename?: "ProductAvailabilityPatternStartTimeInstanceInventory";
    /** Unique Holibob Id for the instance inventory record. */
    readonly id: Scalars["String"]["output"];
    /** The name. */
    readonly name: Scalars["String"]["output"];
    /** The operatorList for this instance. */
    readonly operatorList?: Maybe<ProductAvailabilityPatternStartTimeInstanceInventoryOperatorListType>;
};

/** This respresents the ProductAvailabilityPatternStartTimeInstanceInventoryListType type. */
export type ProductAvailabilityPatternStartTimeInstanceInventoryListType = {
    readonly __typename?: "ProductAvailabilityPatternStartTimeInstanceInventoryListType";
    /** ProductAvailabilityPatternStartTimeInstanceInventory list. */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<ProductAvailabilityPatternStartTimeInstanceInventory>>>;
    /** Total number of records matching query */
    readonly total?: Maybe<Scalars["Int"]["output"]>;
};

/** This respresents the ProductAvailabilityPatternStartTimeInstanceInventoryOperator type. */
export type ProductAvailabilityPatternStartTimeInstanceInventoryOperator = {
    readonly __typename?: "ProductAvailabilityPatternStartTimeInstanceInventoryOperator";
    /** Unique Holibob Id for the instance inventoryOperator record. */
    readonly id: Scalars["String"]["output"];
    /** The name. */
    readonly name: Scalars["String"]["output"];
    /** The phoneNumber. */
    readonly phoneNumber: Scalars["String"]["output"];
    /** The requestDelayDuration. */
    readonly requestDelayDuration: Scalars["String"]["output"];
    /** The requestSentAt. */
    readonly requestSentAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The status. */
    readonly requestStatus: Scalars["String"]["output"];
};

/** This respresents the ProductAvailabilityPatternStartTimeInstanceInventoryOperatorListType type. */
export type ProductAvailabilityPatternStartTimeInstanceInventoryOperatorListType = {
    readonly __typename?: "ProductAvailabilityPatternStartTimeInstanceInventoryOperatorListType";
    /** ProductAvailabilityPatternStartTimeInstanceInventoryOperator list. */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<ProductAvailabilityPatternStartTimeInstanceInventoryOperator>>>;
    /** Total number of records matching query */
    readonly total?: Maybe<Scalars["Int"]["output"]>;
};

/** This represents a list of ProductAvailabilityPatternStartTimeInstance. */
export type ProductAvailabilityPatternStartTimeInstanceList = {
    readonly __typename?: "ProductAvailabilityPatternStartTimeInstanceList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes?: Maybe<ReadonlyArray<Maybe<ProductAvailabilityPatternStartTimeInstance>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type ProductAvailabilityPatternStartTimeInstanceListFilter = {
    /** filter by id. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** filter by Product Id. */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
};

/** This respresents the ProductAvailabilityPatternStartTimeList type. */
export type ProductAvailabilityPatternStartTimeList = {
    readonly __typename?: "ProductAvailabilityPatternStartTimeList";
    /** ProductAvailabilityPatternStartTime list. */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<ProductAvailabilityPatternStartTime>>>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type ProductAvailabilityPatternUpsertInput = {
    /** The capacity of the availability */
    readonly capacity?: InputMaybe<Scalars["Int"]["input"]>;
    /** The capacityThreshold of the availability */
    readonly capacityThreshold?: InputMaybe<Scalars["Int"]["input"]>;
    /** The capacityType of the availability */
    readonly capacityType?: InputMaybe<CapacityConsumptionTypeType>;
    /** The duration of the availability */
    readonly duration?: InputMaybe<Scalars["String"]["input"]>;
    /** The datetime the availability is valid from. */
    readonly effectiveFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
    /** The datetime the availability is valid to. */
    readonly effectiveTo?: InputMaybe<Scalars["DateTime"]["input"]>;
    /** availability id */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The maxParticipantsPerBookingAvailability of the availability */
    readonly maxParticipantsPerBookingAvailability?: InputMaybe<Scalars["Int"]["input"]>;
    /** The name of the availability */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** Pricing categories for the availability */
    readonly pricingCategoryList?: InputMaybe<
        ReadonlyArray<InputMaybe<ProductAvailabilityPatternPricingCategoryInput>>
    >;
    /** product id */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    /** The rrule for the availability */
    readonly rRule?: InputMaybe<Scalars["String"]["input"]>;
    /** Start times for the availability */
    readonly startTimeList?: InputMaybe<ReadonlyArray<InputMaybe<ProductAvailabilityPatternStartTimeInput>>>;
    /** The price for the availability */
    readonly unitPrice?: InputMaybe<Scalars["Float"]["input"]>;
};

/** This represents a CancellationPenalty. */
export type ProductCancellationPenalty = {
    readonly __typename?: "ProductCancellationPenalty";
    readonly amount?: Maybe<Scalars["Float"]["output"]>;
    readonly amountCurrency?: Maybe<Scalars["String"]["output"]>;
    readonly amountType?: Maybe<Scalars["String"]["output"]>;
    /** The createdAt. */
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly duration?: Maybe<Scalars["String"]["output"]>;
    readonly formattedText?: Maybe<Scalars["String"]["output"]>;
    /** The unique id for the ProductCancellationPenalty. */
    readonly id: Scalars["String"]["output"];
    readonly ordinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** The productId. */
    readonly productId?: Maybe<Scalars["String"]["output"]>;
    readonly refundPercentage?: Maybe<Scalars["Float"]["output"]>;
    readonly relativeTo: CancellationPenaltyRelativeToType;
    /** The synchronisationDeletedAt. */
    readonly synchronisationDeletedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The synchronisationId. */
    readonly synchronisationId?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationKey. */
    readonly synchronisationKey?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisedAt. */
    readonly synchronisedAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly type: CancellationPenaltyType;
    /** The updatedAt. */
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ProductCancellationPenaltyDeleteInput = {
    /** The ID of the ProductCancellationPenalty to be deleted. */
    readonly id: Scalars["String"]["input"];
};

/** This represents a list of ProductCancellationPenalty. */
export type ProductCancellationPenaltyList = {
    readonly __typename?: "ProductCancellationPenaltyList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<ProductCancellationPenalty>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

/** This represents a list of ProductCancellationPenalty. */
export type ProductCancellationPenaltyListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ProductCancellationPenaltyListFilter = {
    /** filter by productId */
    readonly productId: Scalars["String"]["input"];
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductCancellationPenaltyUpsertInput = {
    /** The amount of the ProductCancellationPenalty. */
    readonly amount?: InputMaybe<Scalars["Float"]["input"]>;
    /** The amountCurrency of the ProductCancellationPenalty. */
    readonly amountCurrency?: InputMaybe<Scalars["String"]["input"]>;
    /** The amountType of the ProductCancellationPenalty. */
    readonly amountType?: InputMaybe<Scalars["String"]["input"]>;
    /** The duration of the ProductCancellationPenalty. */
    readonly duration?: InputMaybe<Scalars["String"]["input"]>;
    /** The ID of the ProductCancellationPenalty. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The productId of the ProductCancellationPenalty. */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    /** The refundPercentage of the ProductCancellationPenalty. */
    readonly refundPercentage?: InputMaybe<Scalars["Float"]["input"]>;
    /** The relativeTo of the ProductCancellationPenalty. */
    readonly relativeTo?: InputMaybe<Scalars["String"]["input"]>;
    /** The type of the ProductCancellationPenalty. */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a cancellation policy */
export type ProductCancellationPolicy = {
    readonly __typename?: "ProductCancellationPolicy";
    /** Whether this product has free cancellation. */
    readonly hasFreeCancellation?: Maybe<Scalars["Boolean"]["output"]>;
    /** Whether it is possible to cancel this product. */
    readonly isCancellable?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * DEPRECATED: list of penalties.
     * @deprecated This will be deprecated. Please use penaltyList in its place.
     */
    readonly penalties: ReadonlyArray<ProductCancellationPenalty>;
    /** Penalty list. */
    readonly penaltyList: ProductCancellationPolicyPenaltyList;
};

/** This represents a cancellation policy */
export type ProductCancellationPolicyPenaltyList = {
    readonly __typename?: "ProductCancellationPolicyPenaltyList";
    readonly nodes: ReadonlyArray<ProductCancellationPenalty>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

/** This represents a productCategory */
export type ProductCategory = {
    readonly __typename?: "ProductCategory";
    /** The ID */
    readonly id: Scalars["String"]["output"];
    /** The name of the product category */
    readonly name: Scalars["String"]["output"];
    /** Name translation List */
    readonly nameTranslationList: TranslationList;
};

/** This represents a category associated with a product */
export type ProductCategoryAssociation = {
    readonly __typename?: "ProductCategoryAssociation";
    /** The unique Id for the record. */
    readonly id: Scalars["String"]["output"];
    /** The productCategoryId. */
    readonly productCategoryId?: Maybe<Scalars["String"]["output"]>;
    /** The name of the ProductCategory. */
    readonly productCategoryName?: Maybe<Scalars["String"]["output"]>;
    /** The productId. */
    readonly productId?: Maybe<Scalars["String"]["output"]>;
    /** The name of the ProductCategory. */
    readonly productName?: Maybe<Scalars["String"]["output"]>;
};

export type ProductCategoryAssociationDeleteInput = {
    /** The id */
    readonly id: Scalars["String"]["input"];
};

/** This represents a list of category for the product. */
export type ProductCategoryAssociationList = {
    readonly __typename?: "ProductCategoryAssociationList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes?: Maybe<ReadonlyArray<Maybe<ProductCategoryAssociation>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type ProductCategoryAssociationListFilter = {
    /** filter by productCategoryId */
    readonly productCategoryId?: InputMaybe<Scalars["String"]["input"]>;
    /** filter by productId */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    /** filter by search */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductCategoryAssociationToggleInput = {
    /** The categoryId */
    readonly categoryId: Scalars["String"]["input"];
    /** The productIds to be associated with the category */
    readonly productId: Scalars["String"]["input"];
};

export type ProductCategoryAssociationToggleType = {
    readonly __typename?: "ProductCategoryAssociationToggleType";
    readonly isSelected?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ProductCategoryAssociationUpsertInput = {
    /** The id of the association */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The id of the ProductCategory */
    readonly productCategoryId?: InputMaybe<Scalars["String"]["input"]>;
    /** The id of the Product */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductCategoryListFilter = {
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents an productCategory */
export type ProductCategoryListItem = {
    readonly __typename?: "ProductCategoryListItem";
    /** The number of product matched indirectly by inclusions */
    readonly countIncludedProduct?: Maybe<Scalars["String"]["output"]>;
    /** The number of product matched directly */
    readonly countMatchedProduct?: Maybe<Scalars["String"]["output"]>;
    /** The total number of product matched */
    readonly countTotalProduct?: Maybe<Scalars["String"]["output"]>;
    /** The ID */
    readonly id: Scalars["String"]["output"];
    /** The first level of categorisation */
    readonly level1?: Maybe<Scalars["String"]["output"]>;
    /** Level 1 translation List */
    readonly level1TranslationList?: Maybe<TranslationList>;
    /** The second level of categorisation */
    readonly level2?: Maybe<Scalars["String"]["output"]>;
    /** Level 2 translation List */
    readonly level2TranslationList?: Maybe<TranslationList>;
    /** The matchID */
    readonly matchId?: Maybe<Scalars["String"]["output"]>;
    /** The name of the product category */
    readonly name: Scalars["String"]["output"];
    /** A JSON object that defines the match terms */
    readonly nameMapping?: Maybe<Scalars["String"]["output"]>;
    /** Name translation List */
    readonly nameTranslationList: TranslationList;
};

/** This respresents the ProductCategoryList response type */
export type ProductCategoryListResponse = {
    readonly __typename?: "ProductCategoryListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** ProductCategory list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<ProductCategoryListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type ProductCategoryListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

/** This represents a productCategory */
export type ProductCategoryPrivate = {
    readonly __typename?: "ProductCategoryPrivate";
    /** The number of product matched indirectly by inclusions */
    readonly countIncludedProduct?: Maybe<Scalars["String"]["output"]>;
    /** The number of product matched directly */
    readonly countMatchedProduct?: Maybe<Scalars["String"]["output"]>;
    /** The total number of product matched */
    readonly countTotalProduct?: Maybe<Scalars["String"]["output"]>;
    /** The ID */
    readonly id: Scalars["String"]["output"];
    /** The first level of categorisation */
    readonly level1?: Maybe<Scalars["String"]["output"]>;
    /** Level 1 translation List */
    readonly level1TranslationList?: Maybe<TranslationList>;
    /** The second level of categorisation */
    readonly level2?: Maybe<Scalars["String"]["output"]>;
    /** Level 2 translation List */
    readonly level2TranslationList?: Maybe<TranslationList>;
    /** The matchID */
    readonly matchId?: Maybe<Scalars["String"]["output"]>;
    /** The name of the product category */
    readonly name: Scalars["String"]["output"];
    /** A JSON object that defines the match terms */
    readonly nameMapping?: Maybe<Scalars["String"]["output"]>;
    /** Name translation List */
    readonly nameTranslationList: TranslationList;
};

export type ProductCategoryUpsertInput = {
    /** productCategory id */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The level1 of the productCategory */
    readonly level1?: InputMaybe<Scalars["String"]["input"]>;
    /** The level1 of the productCategory */
    readonly level1Translations?: InputMaybe<ReadonlyArray<InputMaybe<TranslationInput>>>;
    /** The level2 of the productCategory */
    readonly level2?: InputMaybe<Scalars["String"]["input"]>;
    /** The level2 of the productCategory */
    readonly level2Translations?: InputMaybe<ReadonlyArray<InputMaybe<TranslationInput>>>;
    /** The matchId of the productCategory */
    readonly matchId?: InputMaybe<Scalars["String"]["input"]>;
    /** The name of the productCategory */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** The nameMapping of the productCategory */
    readonly nameMapping?: InputMaybe<Scalars["JSON"]["input"]>;
    /** The name of the productCategory */
    readonly nameTranslations?: InputMaybe<ReadonlyArray<InputMaybe<TranslationInput>>>;
};

export type ProductChatGptPromptResponseTag = {
    readonly __typename?: "ProductChatGPTPromptResponseTag";
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly productChatGPTPromptId: Scalars["ID"]["output"];
    readonly systemTagId?: Maybe<Scalars["ID"]["output"]>;
    readonly tag: Scalars["String"]["output"];
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ProductCloneInput = {
    readonly id: Scalars["String"]["input"];
    readonly name: Scalars["String"]["input"];
    readonly supplierId: Scalars["String"]["input"];
};

export type ProductContactPricing = {
    readonly __typename?: "ProductContactPricing";
    readonly priceList: ProductContactPricingPriceList;
    readonly type: ProductContactPricingTypeEnum;
};

export type ProductContactPricingList = {
    readonly __typename?: "ProductContactPricingList";
    readonly nodes: ReadonlyArray<ProductContactPricing>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type ProductContactPricingPrice = {
    readonly __typename?: "ProductContactPricingPrice";
    readonly amount: Scalars["Float"]["output"];
    readonly capacity?: Maybe<Scalars["Float"]["output"]>;
    readonly currency: Scalars["String"]["output"];
    readonly maxAge?: Maybe<Scalars["Int"]["output"]>;
    readonly minAge?: Maybe<Scalars["Int"]["output"]>;
    readonly name: Scalars["String"]["output"];
};

export type ProductContactPricingPriceList = {
    readonly __typename?: "ProductContactPricingPriceList";
    readonly nodes: ReadonlyArray<ProductContactPricingPrice>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export enum ProductContactPricingTypeEnum {
    PerGroup = "PER_GROUP",
    PerPricingCategory = "PER_PRICING_CATEGORY",
}

/** This represents an productContent */
export type ProductContent = {
    readonly __typename?: "ProductContent";
    /** Description of the content. */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /**
     * Description of the content.
     * @deprecated Deprecated: use description with content type of HTML instead.
     */
    readonly descriptionHtml?: Maybe<Scalars["String"]["output"]>;
    /** descriptionJson. */
    readonly descriptionJson?: Maybe<Scalars["JSON"]["output"]>;
    /** Unique Holibob Id for the content. */
    readonly id: Scalars["ID"]["output"];
    /** Has this content record been machine translated. */
    readonly isMachineTranslated?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is this content is invalid. */
    readonly isValid?: Maybe<Scalars["Boolean"]["output"]>;
    /** Content language */
    readonly language?: Maybe<LanguageCode>;
    /** Language id that this content is in. */
    readonly languageId?: Maybe<Scalars["String"]["output"]>;
    /** Language that this content is in. */
    readonly languageName?: Maybe<Scalars["String"]["output"]>;
    /** Name of the content. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** Ordinal position relative to content of the same type. */
    readonly ordinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** Description of the content. */
    readonly productId: Scalars["String"]["output"];
    /** Description of the content. */
    readonly synchronisationId?: Maybe<Scalars["String"]["output"]>;
    /** Description of the content. */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** Type of content. */
    readonly type: ProductContentType;
    /** translated type of content. */
    readonly typeLabel?: Maybe<Scalars["String"]["output"]>;
    /** Validation comment. */
    readonly validationComment?: Maybe<Scalars["String"]["output"]>;
};

/** This represents an productContent */
export type ProductContentDescriptionArgs = {
    contentType?: InputMaybe<DescriptionContentType>;
};

export type ProductContentDeleteInput = {
    /** The id */
    readonly id: Scalars["String"]["input"];
};

export type ProductContentListFilter = {
    /** Include invalid content. */
    readonly includeInvalid?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Content is invalid. */
    readonly isInvalid?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Language Id. */
    readonly languageId?: InputMaybe<Scalars["String"]["input"]>;
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** filter by productId */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    /** filter by supplierId */
    readonly supplierId?: InputMaybe<Scalars["String"]["input"]>;
    /** Content type. */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents the ProductContentList response type */
export type ProductContentListResponse = {
    readonly __typename?: "ProductContentListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** ProductContent list */
    readonly nodes: ReadonlyArray<ProductContent>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type ProductContentListSort = {
    /** Sort by value */
    readonly value?: InputMaybe<SortDirection>;
};

/** This represents an productContent */
export type ProductContentPrivate = {
    readonly __typename?: "ProductContentPrivate";
    /** Description of the content. */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /**
     * Description of the content.
     * @deprecated Deprecated: use description with content type of HTML instead.
     */
    readonly descriptionHtml?: Maybe<Scalars["String"]["output"]>;
    /** descriptionJson. */
    readonly descriptionJson?: Maybe<Scalars["JSON"]["output"]>;
    /** Unique Holibob Id for the content. */
    readonly id: Scalars["ID"]["output"];
    /** Has this content record been machine translated. */
    readonly isMachineTranslated?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is this content is invalid. */
    readonly isValid?: Maybe<Scalars["Boolean"]["output"]>;
    /** Content language */
    readonly language?: Maybe<LanguageCode>;
    /** Language id that this content is in. */
    readonly languageId?: Maybe<Scalars["String"]["output"]>;
    /** Language that this content is in. */
    readonly languageName?: Maybe<Scalars["String"]["output"]>;
    /** name. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** Ordinal position relative to content of the same type. */
    readonly ordinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** Description of the content. */
    readonly productId: Scalars["String"]["output"];
    /** sourceDescription of the content. */
    readonly sourceDescription?: Maybe<Scalars["String"]["output"]>;
    /** sourceDescriptionJson. */
    readonly sourceDescriptionJson?: Maybe<Scalars["JSON"]["output"]>;
    /** sourceName. */
    readonly sourceName?: Maybe<Scalars["String"]["output"]>;
    /** Description of the content. */
    readonly synchronisationId?: Maybe<Scalars["String"]["output"]>;
    /** Description of the content. */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** Type of content. */
    readonly type: ProductContentType;
    /** translated type of content. */
    readonly typeLabel?: Maybe<Scalars["String"]["output"]>;
    /** Validation comment. */
    readonly validationComment?: Maybe<Scalars["String"]["output"]>;
};

/** This represents an productContent */
export type ProductContentPrivateDescriptionArgs = {
    contentType?: InputMaybe<DescriptionContentType>;
};

/** This represents an productContent */
export type ProductContentPrivateSourceDescriptionArgs = {
    contentType?: InputMaybe<DescriptionContentType>;
};

export type ProductContentTranslatedList = {
    readonly __typename?: "ProductContentTranslatedList";
    readonly nodes: ReadonlyArray<ProductContentTranslatedListItem>;
};

/** This represents an productContent */
export type ProductContentTranslatedListItem = {
    readonly __typename?: "ProductContentTranslatedListItem";
    /** descriptionJson. */
    readonly descriptionJson?: Maybe<Scalars["JSON"]["output"]>;
    /** Unique Holibob Id for the content. */
    readonly id: Scalars["ID"]["output"];
    /** Has this content record been machine translated. */
    readonly isMachineTranslated?: Maybe<Scalars["Boolean"]["output"]>;
    /** Content language */
    readonly language?: Maybe<LanguageCode>;
    /** Name of the content. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** Type of content. */
    readonly type: ProductContentType;
};

export type ProductContentTranslatedListItemInput = {
    /** descriptionJson. */
    readonly descriptionJson?: InputMaybe<Scalars["JSON"]["input"]>;
    /** Unique Holibob Id for the content. */
    readonly id?: InputMaybe<Scalars["ID"]["input"]>;
    /** Has this content record been machine translated. */
    readonly isMachineTranslated?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Content language */
    readonly language?: InputMaybe<LanguageCode>;
    /** Name of the content. */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** Type of content. */
    readonly type: ProductContentType;
};

export enum ProductContentType {
    AvailableDay = "AVAILABLE_DAY",
    ContactPricingDescription = "CONTACT_PRICING_DESCRIPTION",
    Exclusion = "EXCLUSION",
    Exhibition = "EXHIBITION",
    FinishingPoint = "FINISHING_POINT",
    Highlight = "HIGHLIGHT",
    HotelCheckin = "HOTEL_CHECKIN",
    HotelCheckout = "HOTEL_CHECKOUT",
    HotelFees = "HOTEL_FEES",
    HotelInstructions = "HOTEL_INSTRUCTIONS",
    HotelPolicies = "HOTEL_POLICIES",
    HotelSpecialInstructions = "HOTEL_SPECIAL_INSTRUCTIONS",
    Inclusion = "INCLUSION",
    Itinerary = "ITINERARY",
    KnowBeforeYouGo = "KNOW_BEFORE_YOU_GO",
    L1Attribute_1Zian2 = "L1_ATTRIBUTE_1ZIAN2",
    L1AttributeDca7Vg = "L1_ATTRIBUTE_DCA7VG",
    L1AttributeGk883J = "L1_ATTRIBUTE_GK883J",
    L1AttributeGlbjge = "L1_ATTRIBUTE_GLBJGE",
    MeetingPoint = "MEETING_POINT",
    Note = "NOTE",
    OpeningHours = "OPENING_HOURS",
    PreArrivalInstructions = "PRE_ARRIVAL_INSTRUCTIONS",
    Requirement = "REQUIREMENT",
    Root = "ROOT",
    SafetyMeasure = "SAFETY_MEASURE",
    StartingPoint = "STARTING_POINT",
    TimingNote = "TIMING_NOTE",
    Venue = "VENUE",
    VoucherNote = "VOUCHER_NOTE",
}

export type ProductContentUpsertInput = {
    /** The descriptionJson of the product content */
    readonly descriptionJson?: InputMaybe<Scalars["String"]["input"]>;
    /** The ID */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** Is this content valid. */
    readonly isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Language id of product content */
    readonly languageId?: InputMaybe<Scalars["String"]["input"]>;
    /** The name of the product content */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** The productId of the product content */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    /** The synchronisationSource of the product content */
    readonly synchronisationSource?: InputMaybe<Scalars["String"]["input"]>;
    /** The type of the product content */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductCurationListType = {
    readonly __typename?: "ProductCurationListType";
    readonly nodes: ReadonlyArray<CurationContentItemCuration>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type ProductDeleteInput = {
    /** The ID of the Product to be deleted. */
    readonly id: Scalars["String"]["input"];
};

export enum ProductGuidePriceType {
    PerGroup = "PER_GROUP",
    PerPerson = "PER_PERSON",
}

/** This represents a product image */
export type ProductImage = {
    readonly __typename?: "ProductImage";
    /** The product image asset. */
    readonly asset?: Maybe<Asset>;
    /** The product image filename */
    readonly filename?: Maybe<Scalars["String"]["output"]>;
    /** The product image host */
    readonly host?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the product image */
    readonly id: Scalars["String"]["output"];
    /** The product image isRecognised */
    readonly isRecognised?: Maybe<Scalars["Boolean"]["output"]>;
    /** The product image height */
    readonly maxHeight?: Maybe<Scalars["Int"]["output"]>;
    /** The product image width */
    readonly maxWidth?: Maybe<Scalars["Int"]["output"]>;
    /** The product image path */
    readonly path?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the product */
    readonly productId: Scalars["String"]["output"];
    /** The product name for the product */
    readonly productName?: Maybe<Scalars["String"]["output"]>;
    /** The product image synchronisationSource */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The  product image api key  */
    readonly type?: Maybe<ProductImageType>;
    /** The product image url */
    readonly url: Scalars["String"]["output"];
    /** The product image url large */
    readonly urlLarge: Scalars["String"]["output"];
    /** The product image url medium */
    readonly urlMedium: Scalars["String"]["output"];
    /** The product image url small */
    readonly urlSmall: Scalars["String"]["output"];
    /** The product image url tiny */
    readonly urlTiny: Scalars["String"]["output"];
    readonly vaultUploadUrl?: Maybe<Scalars["String"]["output"]>;
    readonly vaultUrl?: Maybe<Scalars["String"]["output"]>;
};

/** This represents a product image */
export type ProductImageUrlArgs = {
    height?: InputMaybe<Scalars["Float"]["input"]>;
    width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ProductImageDeleteInput = {
    /** The id */
    readonly id: Scalars["String"]["input"];
};

export type ProductImageListFilter = {
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** productId */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents an productImage */
export type ProductImageListItem = {
    readonly __typename?: "ProductImageListItem";
    /** The product image filename */
    readonly filename?: Maybe<Scalars["String"]["output"]>;
    /** The product image host */
    readonly host?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the product image */
    readonly id: Scalars["String"]["output"];
    /** The product image isRecognised */
    readonly isRecognised?: Maybe<Scalars["Boolean"]["output"]>;
    /** The product image height */
    readonly maxHeight?: Maybe<Scalars["Int"]["output"]>;
    /** The product image width */
    readonly maxWidth?: Maybe<Scalars["Int"]["output"]>;
    /** The product image path */
    readonly path?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the product */
    readonly productId: Scalars["String"]["output"];
    /** The product name for the product */
    readonly productName?: Maybe<Scalars["String"]["output"]>;
    /** The product image synchronisationSource */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The  product image api key  */
    readonly type?: Maybe<ProductImageType>;
    /** The product image url */
    readonly url: Scalars["String"]["output"];
    readonly vaultUploadUrl?: Maybe<Scalars["String"]["output"]>;
    readonly vaultUrl?: Maybe<Scalars["String"]["output"]>;
};

/** This represents an productImage */
export type ProductImageListItemUrlArgs = {
    height?: InputMaybe<Scalars["Float"]["input"]>;
    width?: InputMaybe<Scalars["Float"]["input"]>;
};

/** This respresents the ProductImageList response type */
export type ProductImageListResponse = {
    readonly __typename?: "ProductImageListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** ProductImage list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<ProductImageListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type ProductImageListSort = {
    /** Sort by id */
    readonly id?: InputMaybe<SortDirection>;
};

export enum ProductImageType {
    Gallery = "GALLERY",
    Primary = "PRIMARY",
}

export type ProductImageUpsertInput = {
    /** productImage id */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The type of the productImage */
    readonly ordinalPosition: Scalars["Int"]["input"];
    /** The productId of the productImage */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents an item of product content. */
export type ProductInventory = {
    readonly __typename?: "ProductInventory";
    /** Unique Holibob Id for the inventory. */
    readonly id?: Maybe<Scalars["String"]["output"]>;
    /** Name of the inventory. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** The associated operator group id. */
    readonly operatorGroupId?: Maybe<Scalars["String"]["output"]>;
    /** ProductId of the inventory. */
    readonly productId?: Maybe<Scalars["String"]["output"]>;
};

export type ProductInventoryDeleteInput = {
    /** The ID */
    readonly id: Scalars["String"]["input"];
};

/** This respresents the ProductInventoryList type. */
export type ProductInventoryList = {
    readonly __typename?: "ProductInventoryList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** ProductInventory list. */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<ProductInventory>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type ProductInventoryListFilter = {
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** filter by productId. */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductInventoryUpsertInput = {
    /** Unique Holibob Id for the inventory. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** Name of the inventory. */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** The associated operator group id. */
    readonly operatorGroupId?: InputMaybe<Scalars["String"]["input"]>;
    /** ProductId of the inventory. */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductIsSelectedCriteriaType = {
    /** categoryId */
    readonly categoryId?: InputMaybe<Scalars["String"]["input"]>;
    /** curationId */
    readonly curationId?: InputMaybe<Scalars["String"]["input"]>;
    /** categoryId */
    readonly productSaleRestrictionType?: InputMaybe<Scalars["String"]["input"]>;
    readonly withHierarchy?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ProductItinerary = {
    readonly __typename?: "ProductItinerary";
    readonly itemList: ProductItineraryItemList;
};

export type ProductItineraryItem = {
    readonly __typename?: "ProductItineraryItem";
    readonly description?: Maybe<Hbml>;
    readonly duration?: Maybe<Scalars["Duration"]["output"]>;
    readonly extraInformation: ProductItineraryItemExtraInformation;
    readonly id: Scalars["String"]["output"];
    readonly name?: Maybe<Scalars["String"]["output"]>;
    readonly place?: Maybe<PlaceNew>;
};

export type ProductItineraryItemExtraInformation = {
    readonly __typename?: "ProductItineraryItemExtraInformation";
    readonly day?: Maybe<Scalars["Int"]["output"]>;
    readonly isAdmissionIncluded?: Maybe<Scalars["Boolean"]["output"]>;
    readonly passByWithoutStopping?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ProductItineraryItemList = {
    readonly __typename?: "ProductItineraryItemList";
    readonly nodes: ReadonlyArray<ProductItineraryItem>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

/** This represents an Product */
export type ProductList = {
    readonly __typename?: "ProductList";
    /** Product attribute tree */
    readonly attributeTree: Scalars["JSON"]["output"];
    /** Product category tree */
    readonly categoryTree: Scalars["JSON"]["output"];
    /** Product city tree */
    readonly cityTree: Scalars["JSON"]["output"];
    /**
     * Maximum price in product list
     * @deprecated This field is deprecated, please do not use.
     */
    readonly maxPrice: Scalars["Int"]["output"];
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<ProductListItem>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Product count tree */
    readonly priceTree?: Maybe<PriceTree>;
    /** Product provider tree */
    readonly providerTree?: Maybe<ProviderTreeList>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    readonly reportUrl?: Maybe<Scalars["String"]["output"]>;
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
    /**
     * Product type tree
     * @deprecated DEPRECATED - use attributeTree instead
     */
    readonly typeTree: Scalars["JSON"]["output"];
    /** The number of records that would have been returned if filters were not applied. */
    readonly unfilteredRecordCount: Scalars["Int"]["output"];
};

/** This represents an Product */
export type ProductListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** This represents an Product */
export type ProductListTotalArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Use to add one or more filter to the list. In addition to passing search terms to the Match filter, you can add filters for Place, Price, Availability, StartTimes and more. */
export type ProductListFilter = {
    /** Filter by a single productAttribute id */
    readonly attributeId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by list of productAttribute ids */
    readonly attributeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by a single productAttribute name */
    readonly attributeName?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by list of productAttribute names */
    readonly attributeNames?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Search availability type */
    readonly availabilityType?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by a single productCategory id */
    readonly categoryId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by a list of productCategory ids */
    readonly categoryIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by a single productCategory name */
    readonly categoryName?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by a list of productCategory names */
    readonly categoryNames?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by legacy curationId */
    readonly curationId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by curationId */
    readonly curationSlugId?: InputMaybe<Scalars["Hashids"]["input"]>;
    readonly duration?: InputMaybe<DurationRangeInput>;
    /** Filter all products inside a given radius of a given point */
    readonly geoCircle?: InputMaybe<GeoCircleInput>;
    /** Filter all products inside a given rectangle */
    readonly geoRectangle?: InputMaybe<GeoRectangleFilterInput>;
    /** Only products with start times */
    readonly hasStartTime?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Will include product that have been deleted. */
    readonly includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Include products where commision rates are inactive */
    readonly includeInactiveCommission?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Will include product that have an inactive supplier. */
    readonly includeInactiveSupplier?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Will include product that has no valid root content. */
    readonly includeInvalidRootContent?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Will include product that have one or more sale restriction. */
    readonly includeSaleRestricted?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Only featured */
    readonly isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Only new */
    readonly isNew?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Only private experiences */
    readonly isPrivate?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Use this filter to match a search term to any product. */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** Will only return product that are viewer's 'favourite'. */
    readonly onlyFavourites?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Only return the partner's internal product. */
    readonly onlyInternal?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Will only return product that the viewer 'owns'. */
    readonly onlyMyProduct?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Only return product that have one or more sale restriction. */
    readonly onlySaleRestricted?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Filter by a single id of a Town, City, Country or Region */
    readonly placeId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by a list of id of a Town, City, Country or Region */
    readonly placeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by a single name of a Town, City, Country or Region */
    readonly placeName?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by a list of the name of a Town, City, Country or Region */
    readonly placeNames?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by highest price */
    readonly priceEnd?: InputMaybe<Scalars["Int"]["input"]>;
    /** Filter by lowest price */
    readonly priceStart?: InputMaybe<Scalars["Int"]["input"]>;
    /** Filter by a sigle productId */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by a list of productId */
    readonly productIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** filter by a single providerId - The provider of the product is the entity that operates the product on the ground and likely the entity a consumer would need to contact in the event of an issue in-destination. In some cases the supplier and provider will be the same entity. */
    readonly providerId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by by a list of providerId - The provider of the product is the entity that operates the product on the ground and likely the entity a consumer would need to contact in the event of an issue in-destination. In some cases the supplier and provider will be the same entity. */
    readonly providerIds?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
    /** Filter by requires commission rate updated */
    readonly requiresCommissionRate?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Filter by ProductSaleRestrictionType */
    readonly restrictionTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Use to pass a simple search term that will be used in natural language more to match product where the term exists in the name, description, place or certain extended fields. */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    readonly searchInDescription?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Use to pass a simple search term to make a wildcard search against product name. */
    readonly searchText?: InputMaybe<Scalars["String"]["input"]>;
    readonly startTime?: InputMaybe<TimeRangeInput>;
    /** Filter by a single supplierId - See also providerId */
    readonly supplierId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by a list of supplierId - See also providerIds */
    readonly supplierIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by source */
    readonly synchronisationSource?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by one or more synchronisation source */
    readonly synchronisationSources?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    readonly systemTag?: InputMaybe<ReadonlyArray<SystemTagFilterInput>>;
    /** Filter by a list of tags */
    readonly systemTagIdList?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by ACTIVITY, HOTEL or ALL */
    readonly type?: InputMaybe<ProductType>;
};

/** Use to add one or more filter to the list. In addition to passing search terms to the Match filter, you can add filters for Place, Price, Availability, StartTimes and more. */
export type ProductListFilterPrivate = {
    /** Filter by a single productAttribute id */
    readonly attributeId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by list of productAttribute ids */
    readonly attributeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by a single productAttribute name */
    readonly attributeName?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by list of productAttribute names */
    readonly attributeNames?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Search availability type */
    readonly availabilityType?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by a single productCategory id */
    readonly categoryId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by a list of productCategory ids */
    readonly categoryIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by a single productCategory name */
    readonly categoryName?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by a list of productCategory names */
    readonly categoryNames?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by legacy curationId */
    readonly curationId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by curationId */
    readonly curationSlugId?: InputMaybe<Scalars["Hashids"]["input"]>;
    readonly duration?: InputMaybe<DurationRangeInput>;
    /** Filter all products inside a given radius of a given point */
    readonly geoCircle?: InputMaybe<GeoCircleInput>;
    /** Filter all products inside a given rectangle */
    readonly geoRectangle?: InputMaybe<GeoRectangleFilterInput>;
    /** Only products with start times */
    readonly hasStartTime?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Will include product that have been deleted. */
    readonly includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Include products where commision rates are inactive */
    readonly includeInactiveCommission?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Will include product that have an inactive supplier. */
    readonly includeInactiveSupplier?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Will include product that has no valid root content. */
    readonly includeInvalidRootContent?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Will include product that have one or more sale restriction. */
    readonly includeSaleRestricted?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Only featured */
    readonly isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Only new */
    readonly isNew?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Only private experiences */
    readonly isPrivate?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Use this filter to match a search term to any product. */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** Will only return product that are viewer's 'favourite'. */
    readonly onlyFavourites?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Only return the partner's internal product. */
    readonly onlyInternal?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Will only return product that the viewer 'owns'. */
    readonly onlyMyProduct?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Only return product that have one or more sale restriction. */
    readonly onlySaleRestricted?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Filter by a single id of a Town, City, Country or Region */
    readonly placeId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by a list of id of a Town, City, Country or Region */
    readonly placeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by a single name of a Town, City, Country or Region */
    readonly placeName?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by a list of the name of a Town, City, Country or Region */
    readonly placeNames?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by highest price */
    readonly priceEnd?: InputMaybe<Scalars["Int"]["input"]>;
    /** Filter by lowest price */
    readonly priceStart?: InputMaybe<Scalars["Int"]["input"]>;
    /** Filter by a sigle productId */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by a list of productId */
    readonly productIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** filter by a single providerId - The provider of the product is the entity that operates the product on the ground and likely the entity a consumer would need to contact in the event of an issue in-destination. In some cases the supplier and provider will be the same entity. */
    readonly providerId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by by a list of providerId - The provider of the product is the entity that operates the product on the ground and likely the entity a consumer would need to contact in the event of an issue in-destination. In some cases the supplier and provider will be the same entity. */
    readonly providerIds?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
    /** Filter by requires commission rate updated */
    readonly requiresCommissionRate?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Filter by ProductSaleRestrictionType */
    readonly restrictionTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Use to pass a simple search term that will be used in natural language more to match product where the term exists in the name, description, place or certain extended fields. */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    readonly searchInDescription?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Use to pass a simple search term to make a wildcard search against product name. */
    readonly searchText?: InputMaybe<Scalars["String"]["input"]>;
    readonly startTime?: InputMaybe<TimeRangeInput>;
    /** Filter by a single supplierId - See also providerId */
    readonly supplierId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by a list of supplierId - See also providerIds */
    readonly supplierIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by source */
    readonly synchronisationSource?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by one or more synchronisation source */
    readonly synchronisationSources?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    readonly systemTag?: InputMaybe<ReadonlyArray<SystemTagFilterInput>>;
    /** Filter by a list of tags */
    readonly systemTagIdList?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by ACTIVITY, HOTEL or ALL */
    readonly type?: InputMaybe<ProductType>;
};

export type ProductListItem = {
    readonly __typename?: "ProductListItem";
    /** The abstract (brief description) of the product. */
    readonly abstract?: Maybe<Scalars["String"]["output"]>;
    /** The bookable additions for this product. */
    readonly additionList: ProductAdditionList;
    /**
     * DEPRECATED The bookable additions.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use additionList in its place
     */
    readonly additions?: Maybe<ReadonlyArray<Maybe<ProductAddition>>>;
    /** administrationNote */
    readonly administrationNote?: Maybe<Scalars["String"]["output"]>;
    /** The duration prio to a product start in which you must arrive. */
    readonly advanceArrivalDuration?: Maybe<Scalars["String"]["output"]>;
    /** allContactFields */
    readonly allContactFields?: Maybe<Scalars["JSON"]["output"]>;
    readonly allowZeroPricing?: Maybe<Scalars["Boolean"]["output"]>;
    readonly associatedProductList: ProductAssociatedListType;
    /** The attributes association for which this product is available. */
    readonly attributeAssociationList?: Maybe<ProductAttributeAssociationList>;
    /** The list of attributes for the product. */
    readonly attributeList: ProductAttributeList;
    /**
     * DEPRECATED The attributes
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use attributeList in its place
     */
    readonly attributes?: Maybe<ReadonlyArray<Maybe<ProductAttribute>>>;
    /** Product Availability */
    readonly availabilities?: Maybe<AvailabilityListResponse>;
    /**
     * Count of Availability Records for this product
     * @deprecated This is no longer supported.
     */
    readonly availabilityCount?: Maybe<Scalars["Int"]["output"]>;
    /** The availability patterns for which this product is available. */
    readonly availabilityPatternList?: Maybe<ProductAvailabilityPatternList>;
    /** Type of availability associated with this product. */
    readonly availabilityType: AvailabilityType;
    /** Banner to display over product image */
    readonly banner?: Maybe<BannerType>;
    /** The best time to experience this product. */
    readonly bestTimeToVisit?: Maybe<Scalars["String"]["output"]>;
    /** The duration prior to a product start in which no booking can be made. */
    readonly bookingCutoffDuration?: Maybe<Scalars["String"]["output"]>;
    /** bookingQuestions */
    readonly bookingQuestions?: Maybe<Scalars["JSON"]["output"]>;
    /** url to the booking page for this product */
    readonly bookingUrl?: Maybe<Scalars["String"]["output"]>;
    /** The cancellation policy for the product. */
    readonly cancellationPolicy: ProductCancellationPolicy;
    /** The category association for which this product is available. */
    readonly categoryAssociationList?: Maybe<ProductCategoryAssociationList>;
    /** The list of categories for the product. */
    readonly categoryList?: Maybe<ProductProductCategoryListType>;
    /** The nearest city for the end location */
    readonly cityEnd?: Maybe<Place>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead. */
    readonly cityEndId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead. */
    readonly cityEndName?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead. */
    readonly cityId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead. */
    readonly cityName?: Maybe<Scalars["String"]["output"]>;
    /** The nearest city for the start location */
    readonly cityStart?: Maybe<Place>;
    /**
     * DEPRECATED - Please use cityId in place
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead.
     */
    readonly cityStartId?: Maybe<Scalars["String"]["output"]>;
    /**
     * DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead.
     */
    readonly cityStartName?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob code for the product. */
    readonly code?: Maybe<Scalars["String"]["output"]>;
    /** Pricing for contact only products */
    readonly contactPricingList?: Maybe<ProductContactPricingList>;
    /** The list of content for the product. */
    readonly contentList: ProductContentListResponse;
    /** All product content in all languages */
    readonly contentTranslatedList: ProductContentTranslatedList;
    /**
     * The country id for the end location
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryEndId?: Maybe<Scalars["String"]["output"]>;
    /**
     * The country name for the end location
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryEndName?: Maybe<Scalars["String"]["output"]>;
    /**
     * The country id for the activity location
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryId?: Maybe<Scalars["String"]["output"]>;
    /**
     * The country name for the activity location
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryName?: Maybe<Scalars["String"]["output"]>;
    /**
     * DEPRECATED - Please use countryId in place
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryStartId?: Maybe<Scalars["String"]["output"]>;
    /**
     * DEPRECATED - Please use countryName in place
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryStartName?: Maybe<Scalars["String"]["output"]>;
    /** createdAt */
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The culture shock level of the product. */
    readonly cultureShockLevel?: Maybe<Scalars["String"]["output"]>;
    /**
     * The list of curations for the product.
     * @deprecated DEPRECATED: This field returns obsolete data. For current Curation data please use the hierarchyCurationList field.
     */
    readonly curationList?: Maybe<CurationListType>;
    /** customFields */
    readonly customFields?: Maybe<Scalars["JSON"]["output"]>;
    /**
     * Default path to curation
     * @deprecated DEPRECATED: this field returns obsolete data
     */
    readonly defaultCurationPath?: Maybe<ReadonlyArray<CurationPathItem>>;
    /** defaultImageId */
    readonly defaultImageId?: Maybe<Scalars["String"]["output"]>;
    /** The language code that the defaultName has been returned in if available. */
    readonly defaultLanguageCode?: Maybe<Scalars["String"]["output"]>;
    /** The name of the product in the fallback language (typically EN). */
    readonly defaultName?: Maybe<Scalars["String"]["output"]>;
    /** The description of the product in the requested or default language. */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /**
     * An HTML description of the product.
     * @deprecated Deprecated: please use description with a content type of html
     */
    readonly descriptionHtml?: Maybe<Scalars["String"]["output"]>;
    readonly descriptionJson?: Maybe<Scalars["JSON"]["output"]>;
    /** The difficulty level of the product. */
    readonly difficultyLevel?: Maybe<Scalars["String"]["output"]>;
    /** Dress advice for the product. */
    readonly dressAdvice?: Maybe<Scalars["String"]["output"]>;
    /** effectiveCommissionRate */
    readonly effectiveCommissionRate?: Maybe<Scalars["Float"]["output"]>;
    /** effectiveCommissionType */
    readonly effectiveCommissionType?: Maybe<Scalars["String"]["output"]>;
    /** The language code that the name has been returned in. */
    readonly effectiveLanguageCode?: Maybe<Scalars["String"]["output"]>;
    /** The ending place for the product. */
    readonly endPlace?: Maybe<PlaceNew>;
    /**
     * DEPRECATED The exclusions
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use contentList in its place
     */
    readonly exclusions?: Maybe<ReadonlyArray<Maybe<ProductContent>>>;
    readonly externalWidgetData?: Maybe<ExternalWidget>;
    readonly factorTagList?: Maybe<SystemTagFactorList>;
    /** Product Geo Coordinate */
    readonly geoCoordinate?: Maybe<GeoCoordinate>;
    /** The id of the product geo coordinate. */
    readonly geoCoordinateId?: Maybe<Scalars["String"]["output"]>;
    /** The geo name of the product. */
    readonly geoName?: Maybe<Scalars["String"]["output"]>;
    /** The list of guide languages in which this product is available. */
    readonly guideLanguageList: GuideLanguageList;
    /**
     * The guide price for the product.
     * @deprecated This field is deprecated, please use guidePriceAmount in its place.
     */
    readonly guidePrice?: Maybe<Scalars["Float"]["output"]>;
    /** The guide price amount for the product. */
    readonly guidePriceAmount?: Maybe<Scalars["Float"]["output"]>;
    /** The guide price currency for the product. */
    readonly guidePriceCurrency?: Maybe<Scalars["String"]["output"]>;
    /** The guide price for the product as formatted text with currency symbol. */
    readonly guidePriceFormattedText?: Maybe<Scalars["String"]["output"]>;
    /** The guide price type */
    readonly guidePriceType?: Maybe<ProductGuidePriceType>;
    /**
     * Does product have an active commission
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use offSaleReasonList instead
     */
    readonly hasActiveCommission?: Maybe<Scalars["Boolean"]["output"]>;
    /** hasHtmlParseErrors */
    readonly hasHtmlParseErrors?: Maybe<Scalars["Boolean"]["output"]>;
    /** Whether this product has a start time. */
    readonly hasStartTime?: Maybe<Scalars["Boolean"]["output"]>;
    /** The list of curations for the product. */
    readonly hierarchyCurationList?: Maybe<ProductCurationListType>;
    /**
     * DEPRECATED The highlights
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use contentList in its place
     */
    readonly highlights?: Maybe<ReadonlyArray<Maybe<ProductContent>>>;
    /** The guide price for the product using price type. */
    readonly holibobGuidePrice?: Maybe<Price>;
    /** htmlParseErrors */
    readonly htmlParseErrors?: Maybe<Scalars["JSON"]["output"]>;
    /** The unique Holibob Id for the product. */
    readonly id: Scalars["String"]["output"];
    /** Product Images */
    readonly imageList: ReadonlyArray<ProductImage>;
    /**
     * DEPRECATED Product Images
     * @deprecated DEPRECATED - Please use imageList instead.
     */
    readonly images: ReadonlyArray<ProductImage>;
    /**
     * includeInDemo
     * @deprecated Deprecated: no longer used
     */
    readonly includeInDemo?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * includeInDemoAsFeatured
     * @deprecated Deprecated: no longer used
     */
    readonly includeInDemoAsFeatured?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * DEPRECATED The inclusions
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use contentList in its place
     */
    readonly inclusions?: Maybe<ReadonlyArray<Maybe<ProductContent>>>;
    /** If viewer's favourite product */
    readonly isFavourite?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is this product featured. */
    readonly isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is this product offsale. */
    readonly isOffSale?: Maybe<Scalars["Boolean"]["output"]>;
    /** A flag indicating if the product requires manual approval by the supplier. Only relevant for internal products. */
    readonly isOnRequest: Scalars["Boolean"]["output"];
    /**
     * DEPRECATED Is this product activated.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use offSaleReasonList instead
     */
    readonly isPartnerActivated?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is this product featured. */
    readonly isPromoted?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * Is this product the subject of one or more sale restrictions.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use offSaleReasonList instead
     */
    readonly isSaleRestricted?: Maybe<Scalars["Boolean"]["output"]>;
    /** Evaluate product selection based on conditions. */
    readonly isSelected?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * DEPRECATED Is this product activated.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use offSaleReasonList instead
     */
    readonly isSupplierActivated?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * isTest
     * @deprecated Deprecated: no longer used
     */
    readonly isTest?: Maybe<Scalars["Boolean"]["output"]>;
    readonly itinerary: ProductItinerary;
    /**
     * The guide languages in which this product is available.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use guideLanguageList in its place
     */
    readonly languages?: Maybe<ReadonlyArray<Maybe<GuideLanguage>>>;
    /** mainContactFields */
    readonly mainContactFields?: Maybe<Scalars["JSON"]["output"]>;
    /** The maximum duration of the product. */
    readonly maxDuration?: Maybe<Scalars["String"]["output"]>;
    /** Maximum group size for the product. */
    readonly maxGroupSize?: Maybe<Scalars["String"]["output"]>;
    /** The list of translations for the product. */
    readonly metaList: ProductMetaListResponse;
    /** The minimum duration of the product. */
    readonly minDuration?: Maybe<Scalars["String"]["output"]>;
    /** The name of the product in the requested language where this is available and in a fallback language (typically EN) where the requested language is not available. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /**
     * The date on which the product is next available.
     * @deprecated This is not longer supported.
     */
    readonly nextAvailabilityAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** Product Sales restrictions */
    readonly offSaleReasonList: ReadonlyArray<Maybe<ProductOffSaleReason>>;
    /** originGuidePrice */
    readonly originGuidePrice?: Maybe<Scalars["String"]["output"]>;
    /** originGuidePriceCurrency */
    readonly originGuidePriceCurrency?: Maybe<Scalars["String"]["output"]>;
    /** originMaxRetailPrice */
    readonly originMaxRetailPrice?: Maybe<Scalars["String"]["output"]>;
    /**
     * overrideName
     * @deprecated Deprecated: no longer used
     */
    readonly overrideName?: Maybe<Scalars["String"]["output"]>;
    /** passengerFields */
    readonly passengerFields?: Maybe<Scalars["JSON"]["output"]>;
    /** The permissions that the current viewer has with this record. */
    readonly permissions?: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
    /** The starting place for the product. */
    readonly place?: Maybe<ProductPlace>;
    /**
     * The ending place for the product.
     * @deprecated DEPRECATED - Use endPlace
     */
    readonly placeEnd?: Maybe<ProductPlace>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead. */
    readonly placeId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead. */
    readonly placeName?: Maybe<Scalars["String"]["output"]>;
    /** The number of hours before the activity start time when the pre-arrival instructions email is sent. */
    readonly preArrivalInstructionsEmailWindow?: Maybe<Scalars["Int"]["output"]>;
    /** A preview image for this product. */
    readonly previewImage?: Maybe<ProductImage>;
    /** The channel specific promotions of the product. */
    readonly promotionList?: Maybe<PartnerChannelProductPromotionList>;
    /** The enumerated type attributed to the promotion of the product. */
    readonly promotionType?: Maybe<Scalars["String"]["output"]>;
    /** Product Provider */
    readonly provider?: Maybe<Provider>;
    /** The id of the product provider. */
    readonly providerId?: Maybe<Scalars["String"]["output"]>;
    /** Product quality score */
    readonly qualityMatchScore?: Maybe<Scalars["Float"]["output"]>;
    readonly questionList: EntityQuestionAssociationList;
    /** the total numbers of reviews */
    readonly reviewCount?: Maybe<Scalars["Int"]["output"]>;
    /** reviews rating score */
    readonly reviewRating?: Maybe<Scalars["Float"]["output"]>;
    /** The roolm list associated with this product. */
    readonly roomList: ProductRoomList;
    /**
     * The language code that the rootName has been returned in if available.
     * @deprecated Deprecated: please use defaultLanguageCode
     */
    readonly rootLanguageCode?: Maybe<Scalars["String"]["output"]>;
    /**
     * The name of the product in the fallback language (typically EN).
     * @deprecated Deprecated: please use defaultName
     */
    readonly rootName?: Maybe<Scalars["String"]["output"]>;
    readonly slug?: Maybe<Scalars["String"]["output"]>;
    /** Source Instance */
    readonly sourceInstance?: Maybe<SynchronisationSource>;
    /** The starting place for the product. */
    readonly startPlace?: Maybe<PlaceNew>;
    /** Count of start times availabile with this product. */
    readonly startTimeCount?: Maybe<Scalars["Int"]["output"]>;
    /** The start times for which this product is available. */
    readonly startTimeList: ProductStartTimeList;
    /**
     * The start times for which this product is available.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use startTimesList in its place
     */
    readonly startTimes?: Maybe<ReadonlyArray<Maybe<ProductStartTime>>>;
    /** Product Supplier */
    readonly supplier?: Maybe<ProductSupplier>;
    /** supplierId */
    readonly supplierId?: Maybe<Scalars["String"]["output"]>;
    /** The name of the product supplier. */
    readonly supplierName?: Maybe<Scalars["String"]["output"]>;
    /** supplierSurvey */
    readonly supplierSurvey?: Maybe<Scalars["JSON"]["output"]>;
    /** synchronisationDeletedAt */
    readonly synchronisationDeletedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** synchronisationId */
    readonly synchronisationId?: Maybe<Scalars["String"]["output"]>;
    /** synchronisationKey */
    readonly synchronisationKey?: Maybe<Scalars["String"]["output"]>;
    /** synchronisationSource */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** synchronisedAt */
    readonly synchronisedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** synchronisedAvailabilityAt */
    readonly synchronisedAvailabilityAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The list of SystemTags with this Provider. */
    readonly tagAssociationList?: Maybe<SystemTagList>;
    /** Tipping advice for the product. */
    readonly tippingAdvice?: Maybe<Scalars["String"]["output"]>;
    /** The list of translations for the product. */
    readonly translationList?: Maybe<ProductTranslationListResponse>;
    readonly type: ProductTypeType;
    /** updatedAt */
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** Defines the mode in which this product can be edited. */
    readonly upsertMode?: Maybe<Scalars["String"]["output"]>;
    /** The websiteUrl for the product. */
    readonly websiteUrl?: Maybe<Scalars["String"]["output"]>;
};

export type ProductListItemAssociatedProductListArgs = {
    filter?: InputMaybe<AssociatedProductFilter>;
};

export type ProductListItemAvailabilitiesArgs = {
    autoFillOptions?: InputMaybe<Scalars["Boolean"]["input"]>;
    filter?: InputMaybe<AvailabilityListFilter>;
    optionList?: InputMaybe<ReadonlyArray<InputMaybe<AvailabilityListOptionListItemInput>>>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sessionId?: InputMaybe<Scalars["ID"]["input"]>;
    sort?: InputMaybe<AvailabilityListSort>;
};

export type ProductListItemDescriptionArgs = {
    contentType?: InputMaybe<DescriptionContentType>;
};

export type ProductListItemIsSelectedArgs = {
    criteria?: InputMaybe<ProductIsSelectedCriteriaType>;
};

/** This represents an Product */
export type ProductListPrivate = {
    readonly __typename?: "ProductListPrivate";
    /** Product attribute tree */
    readonly attributeTree: Scalars["JSON"]["output"];
    /** Product category tree */
    readonly categoryTree: Scalars["JSON"]["output"];
    /** Product city tree */
    readonly cityTree: Scalars["JSON"]["output"];
    /**
     * Maximum price in product list
     * @deprecated This field is deprecated, please do not use.
     */
    readonly maxPrice: Scalars["Int"]["output"];
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<ProductListItem>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Product count tree */
    readonly priceTree?: Maybe<PriceTree>;
    /** Product provider tree */
    readonly providerTree?: Maybe<ProviderTreeList>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    readonly reportUrl?: Maybe<Scalars["String"]["output"]>;
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
    /**
     * Product type tree
     * @deprecated DEPRECATED - use attributeTree instead
     */
    readonly typeTree: Scalars["JSON"]["output"];
    /** The number of records that would have been returned if filters were not applied. */
    readonly unfilteredRecordCount: Scalars["Int"]["output"];
};

/** This represents an Product */
export type ProductListPrivateRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** This represents an Product */
export type ProductListPrivateTotalArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ProductListSort = {
    /**
     * Sort by availabilityCount
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release
     */
    readonly availabilityCount?: InputMaybe<SortDirection>;
    /** Sort by guidePrice */
    readonly guidePrice?: InputMaybe<SortDirection>;
    /** Sort by isRecommended */
    readonly isRecommended?: InputMaybe<SortDirection>;
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
    /** Sort by qualityMatchScore */
    readonly qualityMatchScore?: InputMaybe<SortDirection>;
    /** Sort by region */
    readonly region?: InputMaybe<SortDirection>;
    /** Sort by supplierName */
    readonly supplierName?: InputMaybe<SortDirection>;
    /** Sort by type */
    readonly type?: InputMaybe<SortDirection>;
};

/** This represents a product meta */
export type ProductMeta = {
    readonly __typename?: "ProductMeta";
    /** The createdAt of the product meta */
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The ID */
    readonly id: Scalars["String"]["output"];
    /** The productId of the product meta */
    readonly productId?: Maybe<Scalars["String"]["output"]>;
    /** The productName of the product meta */
    readonly productName?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationAt of the product meta */
    readonly synchronisationAt?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationDeletedAt of the product meta */
    readonly synchronisationDeletedAt?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationId of the product meta */
    readonly synchronisationId?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationSource of the product meta */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisedAt of the product meta */
    readonly synchronisedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The type of the product meta */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** The updatedAt of the product meta */
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The value of the product meta */
    readonly value?: Maybe<Scalars["String"]["output"]>;
};

export type ProductMetaListFilter = {
    /** filter by productId */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents an productMeta */
export type ProductMetaListItem = {
    readonly __typename?: "ProductMetaListItem";
    /** The createdAt of the product meta */
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The ID */
    readonly id: Scalars["String"]["output"];
    /** The productId of the product meta */
    readonly productId?: Maybe<Scalars["String"]["output"]>;
    /** The productName of the product meta */
    readonly productName?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationAt of the product meta */
    readonly synchronisationAt?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationDeletedAt of the product meta */
    readonly synchronisationDeletedAt?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationId of the product meta */
    readonly synchronisationId?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationSource of the product meta */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisedAt of the product meta */
    readonly synchronisedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The type of the product meta */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** The updatedAt of the product meta */
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The value of the product meta */
    readonly value?: Maybe<Scalars["String"]["output"]>;
};

/** This respresents the ProductMetaList response type */
export type ProductMetaListResponse = {
    readonly __typename?: "ProductMetaListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** ProductMeta list */
    readonly nodes: ReadonlyArray<ProductMetaListItem>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type ProductMetaListSort = {
    /** Sort by value */
    readonly value?: InputMaybe<SortDirection>;
};

export type ProductMetaUpdateInput = {
    /** The ID */
    readonly id: Scalars["String"]["input"];
    /** The productId of the product meta */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    /** The name of the product meta */
    readonly synchronisationId?: InputMaybe<Scalars["String"]["input"]>;
    /** The synchronisationSource of the product meta */
    readonly synchronisationSource?: InputMaybe<Scalars["String"]["input"]>;
    /** The type of the product meta */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
    /** The value of the product meta */
    readonly value?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductOffSaleReason = {
    readonly __typename?: "ProductOffSaleReason";
    readonly description: Scalars["String"]["output"];
    readonly label: Scalars["String"]["output"];
    readonly type: Scalars["String"]["output"];
};

/** This represents the place of the product. */
export type ProductPlace = {
    readonly __typename?: "ProductPlace";
    readonly associationList?: Maybe<PlaceAssociationList>;
    /** Unique Holibob Id for the place's city. */
    readonly cityId?: Maybe<Scalars["String"]["output"]>;
    /** Name of the place's city. */
    readonly cityName?: Maybe<Scalars["String"]["output"]>;
    /** Unique Holibob Id for the place's country. */
    readonly countryId?: Maybe<Scalars["String"]["output"]>;
    /** Name of the place's country. */
    readonly countryName?: Maybe<Scalars["String"]["output"]>;
};

export type ProductPrivate = {
    readonly __typename?: "ProductPrivate";
    /** The abstract (brief description) of the product. */
    readonly abstract?: Maybe<Scalars["String"]["output"]>;
    /** The bookable additions for this product. */
    readonly additionList: ProductAdditionList;
    /**
     * DEPRECATED The bookable additions.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use additionList in its place
     */
    readonly additions?: Maybe<ReadonlyArray<Maybe<ProductAddition>>>;
    /** administrationNote */
    readonly administrationNote?: Maybe<Scalars["String"]["output"]>;
    /** The duration prio to a product start in which you must arrive. */
    readonly advanceArrivalDuration?: Maybe<Scalars["String"]["output"]>;
    /** allContactFields */
    readonly allContactFields?: Maybe<Scalars["JSON"]["output"]>;
    readonly allowZeroPricing?: Maybe<Scalars["Boolean"]["output"]>;
    readonly associatedProductList: ProductAssociatedListType;
    /** The attributes association for which this product is available. */
    readonly attributeAssociationList?: Maybe<ProductAttributeAssociationList>;
    /** The list of attributes for the product. */
    readonly attributeList: ProductAttributeList;
    /**
     * DEPRECATED The attributes
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use attributeList in its place
     */
    readonly attributes?: Maybe<ReadonlyArray<Maybe<ProductAttribute>>>;
    /** Product Availability */
    readonly availabilities?: Maybe<AvailabilityListResponse>;
    /**
     * Count of Availability Records for this product
     * @deprecated This is no longer supported.
     */
    readonly availabilityCount?: Maybe<Scalars["Int"]["output"]>;
    /** The availability patterns for which this product is available. */
    readonly availabilityPatternList?: Maybe<ProductAvailabilityPatternList>;
    /** Type of availability associated with this product. */
    readonly availabilityType: AvailabilityType;
    /** Banner to display over product image */
    readonly banner?: Maybe<BannerType>;
    /** The best time to experience this product. */
    readonly bestTimeToVisit?: Maybe<Scalars["String"]["output"]>;
    /** The duration prior to a product start in which no booking can be made. */
    readonly bookingCutoffDuration?: Maybe<Scalars["String"]["output"]>;
    /** bookingQuestions */
    readonly bookingQuestions?: Maybe<Scalars["JSON"]["output"]>;
    /** url to the booking page for this product */
    readonly bookingUrl?: Maybe<Scalars["String"]["output"]>;
    /** The cancellation policy for the product. */
    readonly cancellationPolicy: ProductCancellationPolicy;
    /** The category association for which this product is available. */
    readonly categoryAssociationList?: Maybe<ProductCategoryAssociationList>;
    /** The list of categories for the product. */
    readonly categoryList?: Maybe<ProductProductCategoryListType>;
    /** The nearest city for the end location */
    readonly cityEnd?: Maybe<Place>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead. */
    readonly cityEndId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead. */
    readonly cityEndName?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead. */
    readonly cityId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead. */
    readonly cityName?: Maybe<Scalars["String"]["output"]>;
    /** The nearest city for the start location */
    readonly cityStart?: Maybe<Place>;
    /**
     * DEPRECATED - Please use cityId in place
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead.
     */
    readonly cityStartId?: Maybe<Scalars["String"]["output"]>;
    /**
     * DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead.
     */
    readonly cityStartName?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob code for the product. */
    readonly code?: Maybe<Scalars["String"]["output"]>;
    /** Pricing for contact only products */
    readonly contactPricingList?: Maybe<ProductContactPricingList>;
    /** The list of content for the product. */
    readonly contentList: ProductContentListResponse;
    /** All product content in all languages */
    readonly contentTranslatedList: ProductContentTranslatedList;
    /**
     * The country id for the end location
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryEndId?: Maybe<Scalars["String"]["output"]>;
    /**
     * The country name for the end location
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryEndName?: Maybe<Scalars["String"]["output"]>;
    /**
     * The country id for the activity location
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryId?: Maybe<Scalars["String"]["output"]>;
    /**
     * The country name for the activity location
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryName?: Maybe<Scalars["String"]["output"]>;
    /**
     * DEPRECATED - Please use countryId in place
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryStartId?: Maybe<Scalars["String"]["output"]>;
    /**
     * DEPRECATED - Please use countryName in place
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryStartName?: Maybe<Scalars["String"]["output"]>;
    /** createdAt */
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The culture shock level of the product. */
    readonly cultureShockLevel?: Maybe<Scalars["String"]["output"]>;
    /**
     * The list of curations for the product.
     * @deprecated DEPRECATED: This field returns obsolete data. For current Curation data please use the hierarchyCurationList field.
     */
    readonly curationList?: Maybe<CurationListType>;
    /** customFields */
    readonly customFields?: Maybe<Scalars["JSON"]["output"]>;
    /**
     * Default path to curation
     * @deprecated DEPRECATED: this field returns obsolete data
     */
    readonly defaultCurationPath?: Maybe<ReadonlyArray<CurationPathItem>>;
    /** defaultImageId */
    readonly defaultImageId?: Maybe<Scalars["String"]["output"]>;
    /** The language code that the defaultName has been returned in if available. */
    readonly defaultLanguageCode?: Maybe<Scalars["String"]["output"]>;
    /** The name of the product in the fallback language (typically EN). */
    readonly defaultName?: Maybe<Scalars["String"]["output"]>;
    /** The description of the product in the requested or default language. */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /**
     * An HTML description of the product.
     * @deprecated Deprecated: please use description with a content type of html
     */
    readonly descriptionHtml?: Maybe<Scalars["String"]["output"]>;
    readonly descriptionJson?: Maybe<Scalars["JSON"]["output"]>;
    /** The difficulty level of the product. */
    readonly difficultyLevel?: Maybe<Scalars["String"]["output"]>;
    /** Dress advice for the product. */
    readonly dressAdvice?: Maybe<Scalars["String"]["output"]>;
    /** effectiveCommissionRate */
    readonly effectiveCommissionRate?: Maybe<Scalars["Float"]["output"]>;
    /** effectiveCommissionType */
    readonly effectiveCommissionType?: Maybe<Scalars["String"]["output"]>;
    /** The language code that the name has been returned in. */
    readonly effectiveLanguageCode?: Maybe<Scalars["String"]["output"]>;
    /** The ending place for the product. */
    readonly endPlace?: Maybe<PlaceNew>;
    /**
     * DEPRECATED The exclusions
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use contentList in its place
     */
    readonly exclusions?: Maybe<ReadonlyArray<Maybe<ProductContent>>>;
    readonly externalWidgetData?: Maybe<ExternalWidget>;
    readonly factorTagList?: Maybe<SystemTagFactorList>;
    /** Product Geo Coordinate */
    readonly geoCoordinate?: Maybe<GeoCoordinate>;
    /** The id of the product geo coordinate. */
    readonly geoCoordinateId?: Maybe<Scalars["String"]["output"]>;
    /** The geo name of the product. */
    readonly geoName?: Maybe<Scalars["String"]["output"]>;
    /** The list of guide languages in which this product is available. */
    readonly guideLanguageList: GuideLanguageList;
    /**
     * The guide price for the product.
     * @deprecated This field is deprecated, please use guidePriceAmount in its place.
     */
    readonly guidePrice?: Maybe<Scalars["Float"]["output"]>;
    /** The guide price amount for the product. */
    readonly guidePriceAmount?: Maybe<Scalars["Float"]["output"]>;
    /** The guide price currency for the product. */
    readonly guidePriceCurrency?: Maybe<Scalars["String"]["output"]>;
    /** The guide price for the product as formatted text with currency symbol. */
    readonly guidePriceFormattedText?: Maybe<Scalars["String"]["output"]>;
    /** The guide price type */
    readonly guidePriceType?: Maybe<ProductGuidePriceType>;
    /**
     * Does product have an active commission
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use offSaleReasonList instead
     */
    readonly hasActiveCommission?: Maybe<Scalars["Boolean"]["output"]>;
    /** hasHtmlParseErrors */
    readonly hasHtmlParseErrors?: Maybe<Scalars["Boolean"]["output"]>;
    /** Whether this product has a start time. */
    readonly hasStartTime?: Maybe<Scalars["Boolean"]["output"]>;
    /** The list of curations for the product. */
    readonly hierarchyCurationList?: Maybe<ProductCurationListType>;
    /**
     * DEPRECATED The highlights
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use contentList in its place
     */
    readonly highlights?: Maybe<ReadonlyArray<Maybe<ProductContent>>>;
    /** The guide price for the product using price type. */
    readonly holibobGuidePrice?: Maybe<Price>;
    /** htmlParseErrors */
    readonly htmlParseErrors?: Maybe<Scalars["JSON"]["output"]>;
    /** The unique Holibob Id for the product. */
    readonly id: Scalars["String"]["output"];
    /** Product Images */
    readonly imageList: ReadonlyArray<ProductImage>;
    /**
     * DEPRECATED Product Images
     * @deprecated DEPRECATED - Please use imageList instead.
     */
    readonly images: ReadonlyArray<ProductImage>;
    /**
     * includeInDemo
     * @deprecated Deprecated: no longer used
     */
    readonly includeInDemo?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * includeInDemoAsFeatured
     * @deprecated Deprecated: no longer used
     */
    readonly includeInDemoAsFeatured?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * DEPRECATED The inclusions
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use contentList in its place
     */
    readonly inclusions?: Maybe<ReadonlyArray<Maybe<ProductContent>>>;
    /** If viewer's favourite product */
    readonly isFavourite?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is this product featured. */
    readonly isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is this product offsale. */
    readonly isOffSale?: Maybe<Scalars["Boolean"]["output"]>;
    /** A flag indicating if the product requires manual approval by the supplier. Only relevant for internal products. */
    readonly isOnRequest: Scalars["Boolean"]["output"];
    /**
     * DEPRECATED Is this product activated.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use offSaleReasonList instead
     */
    readonly isPartnerActivated?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is this product featured. */
    readonly isPromoted?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * Is this product the subject of one or more sale restrictions.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use offSaleReasonList instead
     */
    readonly isSaleRestricted?: Maybe<Scalars["Boolean"]["output"]>;
    /** Evaluate product selection based on conditions. */
    readonly isSelected?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * DEPRECATED Is this product activated.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use offSaleReasonList instead
     */
    readonly isSupplierActivated?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * isTest
     * @deprecated Deprecated: no longer used
     */
    readonly isTest?: Maybe<Scalars["Boolean"]["output"]>;
    readonly itinerary: ProductItinerary;
    /**
     * The guide languages in which this product is available.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use guideLanguageList in its place
     */
    readonly languages?: Maybe<ReadonlyArray<Maybe<GuideLanguage>>>;
    /** mainContactFields */
    readonly mainContactFields?: Maybe<Scalars["JSON"]["output"]>;
    /** The maximum duration of the product. */
    readonly maxDuration?: Maybe<Scalars["String"]["output"]>;
    /** Maximum group size for the product. */
    readonly maxGroupSize?: Maybe<Scalars["String"]["output"]>;
    /** The list of translations for the product. */
    readonly metaList: ProductMetaListResponse;
    /** The minimum duration of the product. */
    readonly minDuration?: Maybe<Scalars["String"]["output"]>;
    /** The name of the product in the requested language where this is available and in a fallback language (typically EN) where the requested language is not available. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /**
     * The date on which the product is next available.
     * @deprecated This is not longer supported.
     */
    readonly nextAvailabilityAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** Product Sales restrictions */
    readonly offSaleReasonList: ReadonlyArray<Maybe<ProductOffSaleReason>>;
    /** originGuidePrice */
    readonly originGuidePrice?: Maybe<Scalars["String"]["output"]>;
    /** originGuidePriceCurrency */
    readonly originGuidePriceCurrency?: Maybe<Scalars["String"]["output"]>;
    /** originMaxRetailPrice */
    readonly originMaxRetailPrice?: Maybe<Scalars["String"]["output"]>;
    /**
     * overrideName
     * @deprecated Deprecated: no longer used
     */
    readonly overrideName?: Maybe<Scalars["String"]["output"]>;
    /** passengerFields */
    readonly passengerFields?: Maybe<Scalars["JSON"]["output"]>;
    /** The permissions that the current viewer has with this record. */
    readonly permissions?: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
    /** The starting place for the product. */
    readonly place?: Maybe<ProductPlace>;
    /**
     * The ending place for the product.
     * @deprecated DEPRECATED - Use endPlace
     */
    readonly placeEnd?: Maybe<ProductPlace>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead. */
    readonly placeId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead. */
    readonly placeName?: Maybe<Scalars["String"]["output"]>;
    /** The number of hours before the activity start time when the pre-arrival instructions email is sent. */
    readonly preArrivalInstructionsEmailWindow?: Maybe<Scalars["Int"]["output"]>;
    /** A preview image for this product. */
    readonly previewImage?: Maybe<ProductImage>;
    /** The channel specific promotions of the product. */
    readonly promotionList?: Maybe<PartnerChannelProductPromotionList>;
    /** The enumerated type attributed to the promotion of the product. */
    readonly promotionType?: Maybe<Scalars["String"]["output"]>;
    /** Product Provider */
    readonly provider?: Maybe<Provider>;
    /** The id of the product provider. */
    readonly providerId?: Maybe<Scalars["String"]["output"]>;
    /** Product quality score */
    readonly qualityMatchScore?: Maybe<Scalars["Float"]["output"]>;
    readonly questionList: EntityQuestionAssociationList;
    /** the total numbers of reviews */
    readonly reviewCount?: Maybe<Scalars["Int"]["output"]>;
    /** reviews rating score */
    readonly reviewRating?: Maybe<Scalars["Float"]["output"]>;
    /** The roolm list associated with this product. */
    readonly roomList: ProductRoomList;
    /**
     * The language code that the rootName has been returned in if available.
     * @deprecated Deprecated: please use defaultLanguageCode
     */
    readonly rootLanguageCode?: Maybe<Scalars["String"]["output"]>;
    /**
     * The name of the product in the fallback language (typically EN).
     * @deprecated Deprecated: please use defaultName
     */
    readonly rootName?: Maybe<Scalars["String"]["output"]>;
    readonly slug?: Maybe<Scalars["String"]["output"]>;
    /** Source Instance */
    readonly sourceInstance?: Maybe<SynchronisationSource>;
    /** The starting place for the product. */
    readonly startPlace?: Maybe<PlaceNew>;
    /** Count of start times availabile with this product. */
    readonly startTimeCount?: Maybe<Scalars["Int"]["output"]>;
    /** The start times for which this product is available. */
    readonly startTimeList: ProductStartTimeList;
    /**
     * The start times for which this product is available.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use startTimesList in its place
     */
    readonly startTimes?: Maybe<ReadonlyArray<Maybe<ProductStartTime>>>;
    /** Product Supplier */
    readonly supplier?: Maybe<ProductSupplier>;
    /** supplierId */
    readonly supplierId?: Maybe<Scalars["String"]["output"]>;
    /** The name of the product supplier. */
    readonly supplierName?: Maybe<Scalars["String"]["output"]>;
    /** supplierSurvey */
    readonly supplierSurvey?: Maybe<Scalars["JSON"]["output"]>;
    /** synchronisationDeletedAt */
    readonly synchronisationDeletedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** synchronisationId */
    readonly synchronisationId?: Maybe<Scalars["String"]["output"]>;
    /** synchronisationKey */
    readonly synchronisationKey?: Maybe<Scalars["String"]["output"]>;
    /** synchronisationSource */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** synchronisedAt */
    readonly synchronisedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** synchronisedAvailabilityAt */
    readonly synchronisedAvailabilityAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The list of SystemTags with this Provider. */
    readonly tagAssociationList?: Maybe<SystemTagList>;
    /** Tipping advice for the product. */
    readonly tippingAdvice?: Maybe<Scalars["String"]["output"]>;
    /** The list of translations for the product. */
    readonly translationList?: Maybe<ProductTranslationListResponse>;
    readonly type: ProductTypeType;
    /** updatedAt */
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** Defines the mode in which this product can be edited. */
    readonly upsertMode?: Maybe<Scalars["String"]["output"]>;
    /** The websiteUrl for the product. */
    readonly websiteUrl?: Maybe<Scalars["String"]["output"]>;
};

export type ProductPrivateAssociatedProductListArgs = {
    filter?: InputMaybe<AssociatedProductFilter>;
};

export type ProductPrivateAvailabilitiesArgs = {
    autoFillOptions?: InputMaybe<Scalars["Boolean"]["input"]>;
    filter?: InputMaybe<AvailabilityListFilter>;
    optionList?: InputMaybe<ReadonlyArray<InputMaybe<AvailabilityListOptionListItemInput>>>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sessionId?: InputMaybe<Scalars["ID"]["input"]>;
    sort?: InputMaybe<AvailabilityListSort>;
};

export type ProductPrivateDescriptionArgs = {
    contentType?: InputMaybe<DescriptionContentType>;
};

export type ProductPrivateIsSelectedArgs = {
    criteria?: InputMaybe<ProductIsSelectedCriteriaType>;
};

/** This respresents the product category type. */
export type ProductProductCategoryListType = {
    readonly __typename?: "ProductProductCategoryListType";
    /** ProductCategory list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<ProductCategoryListItem>>>;
    readonly total?: Maybe<Scalars["Int"]["output"]>;
};

/** Workstream project prefix */
export enum ProductQualityProjectPrefix {
    Pq = "PQ",
}

/** This represents a product review */
export type ProductReview = {
    readonly __typename?: "ProductReview";
    /** The bookingId of the review. */
    readonly bookingId?: Maybe<Scalars["String"]["output"]>;
    /** The description of the product. */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the review */
    readonly id: Scalars["String"]["output"];
    /** The productId of the review. */
    readonly productId?: Maybe<Scalars["String"]["output"]>;
    /** The rating of the review. */
    readonly rating?: Maybe<Scalars["Int"]["output"]>;
    /** The securityPrincipalUserId of the review. */
    readonly securityPrincipalUserId?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUser name of the review. */
    readonly securityPrincipalUserName?: Maybe<Scalars["String"]["output"]>;
};

/** This represents a product review */
export type ProductReviewDescriptionArgs = {
    contentType?: InputMaybe<DescriptionContentType>;
};

export type ProductReviewDeleteInput = {
    /** The id of the productReview */
    readonly id: Scalars["String"]["input"];
};

export type ProductReviewListFilter = {
    /** Sort by bookingId */
    readonly bookingId?: InputMaybe<Scalars["String"]["input"]>;
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** Sort by productId */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    /** Sort by securityPrincipalUserId */
    readonly securityPrincipalUserId?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents an ProductReview */
export type ProductReviewListItem = {
    readonly __typename?: "ProductReviewListItem";
    /** The bookingId of the review. */
    readonly bookingId?: Maybe<Scalars["String"]["output"]>;
    /** The description of the product. */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the review */
    readonly id: Scalars["String"]["output"];
    /** The productId of the review. */
    readonly productId?: Maybe<Scalars["String"]["output"]>;
    /** The rating of the review. */
    readonly rating?: Maybe<Scalars["Int"]["output"]>;
    /** The securityPrincipalUserId of the review. */
    readonly securityPrincipalUserId?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUser name of the review. */
    readonly securityPrincipalUserName?: Maybe<Scalars["String"]["output"]>;
};

/** This represents an ProductReview */
export type ProductReviewListItemDescriptionArgs = {
    contentType?: InputMaybe<DescriptionContentType>;
};

export type ProductReviewListSort = {
    /** Sort by bookingId */
    readonly bookingId?: InputMaybe<SortDirection>;
    /** Sort by createdAt */
    readonly createdAt?: InputMaybe<SortDirection>;
    /** Sort by productId */
    readonly productId?: InputMaybe<SortDirection>;
};

/** This respresents the ProductReview List response type */
export type ProductReviewListType = {
    readonly __typename?: "ProductReviewListType";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** ProductReview list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<ProductReviewListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type ProductReviewUpsertInput = {
    /** The bookingId of the review. */
    readonly bookingId?: InputMaybe<Scalars["String"]["input"]>;
    /** A  description of the review. */
    readonly description?: InputMaybe<Scalars["JSON"]["input"]>;
    /** The unique Holibob ID for the review */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The productId of the review. */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    /** The rating of the review. */
    readonly rating?: InputMaybe<Scalars["Int"]["input"]>;
    /** The securityPrincipalUserId of the review. */
    readonly securityPrincipalUserId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Represent the room of HOTEL product availability type */
export type ProductRoom = {
    readonly __typename?: "ProductRoom";
    readonly area?: Maybe<ProductRoomArea>;
    readonly description: Scalars["String"]["output"];
    readonly id: Scalars["String"]["output"];
    readonly imageList: ProductRoomImageList;
    readonly maxAdultCount: Scalars["Int"]["output"];
    readonly maxChildCount: Scalars["Int"]["output"];
    readonly maxPersonCount: Scalars["Int"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly productId: Scalars["String"]["output"];
    readonly roomAmenityList: ProductRoomAmenityList;
    readonly synchronisationKey?: Maybe<Scalars["String"]["output"]>;
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    readonly synchronisedAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly type: ProductRoomType;
    readonly viewList: ProductRoomViewList;
};

export type ProductRoomAmenityItem = {
    readonly __typename?: "ProductRoomAmenityItem";
    readonly id: Scalars["String"]["output"];
    readonly name: Scalars["String"]["output"];
};

export type ProductRoomAmenityList = {
    readonly __typename?: "ProductRoomAmenityList";
    readonly nodes: ReadonlyArray<ProductRoomAmenityItem>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type ProductRoomArea = {
    readonly __typename?: "ProductRoomArea";
    readonly squareFeet: Scalars["Float"]["output"];
    readonly squareMeters: Scalars["Float"]["output"];
};

export type ProductRoomImageList = {
    readonly __typename?: "ProductRoomImageList";
    readonly nodes: ReadonlyArray<ProductRoomImageListItem>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type ProductRoomImageListItem = {
    readonly __typename?: "ProductRoomImageListItem";
    readonly asset?: Maybe<Asset>;
    readonly heroImage: Scalars["Boolean"]["output"];
    readonly id: Scalars["String"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly type: Scalars["String"]["output"];
};

export type ProductRoomList = {
    readonly __typename?: "ProductRoomList";
    readonly nodes: ReadonlyArray<ProductRoom>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export enum ProductRoomType {
    Hotel = "HOTEL",
}

export type ProductRoomViewList = {
    readonly __typename?: "ProductRoomViewList";
    readonly nodes: ReadonlyArray<ProductViewTypeItem>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

/** This represents a ProductSaleRestriction */
export type ProductSaleRestriction = {
    readonly __typename?: "ProductSaleRestriction";
    /** comment for the productSaleRestriction */
    readonly comment?: Maybe<Scalars["String"]["output"]>;
    /** The id of the productSaleRestriction */
    readonly id?: Maybe<Scalars["String"]["output"]>;
    /** The isActive of the productSaleRestriction */
    readonly isActive?: Maybe<Scalars["Boolean"]["output"]>;
    /** The productId of the productSaleRestriction */
    readonly productId?: Maybe<Scalars["String"]["output"]>;
    /** The productName of the productSaleRestriction */
    readonly productName?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisedKey for the product */
    readonly productSynchronisationKey?: Maybe<Scalars["String"]["output"]>;
    /** The supplierId of the productSaleRestriction */
    readonly supplierId?: Maybe<Scalars["String"]["output"]>;
    /** The supplierName of the product */
    readonly supplierName?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisedKey for the supplier */
    readonly supplierSynchronisationKey?: Maybe<Scalars["String"]["output"]>;
    /** The sychronisationId of the productSaleRestriction */
    readonly synchronisationId?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisedKey for this productSaleRestriction */
    readonly synchronisationKey?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationSource of the productSaleRestriction */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisedAt for this productSaleRestriction */
    readonly synchronisedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The type for the productSaleRestriction */
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

export type ProductSaleRestrictionListFilter = {
    /** Filter by productId */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    /** filter by types */
    readonly restrictionTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by supplierId */
    readonly supplierId?: InputMaybe<Scalars["String"]["input"]>;
    /** filter by synchronisation sources */
    readonly synchronisationSources?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** This represents an ProductSaleRestriction */
export type ProductSaleRestrictionListItem = {
    readonly __typename?: "ProductSaleRestrictionListItem";
    /** comment for the productSaleRestriction */
    readonly comment?: Maybe<Scalars["String"]["output"]>;
    /** The id of the productSaleRestriction */
    readonly id?: Maybe<Scalars["String"]["output"]>;
    /** The isActive of the productSaleRestriction */
    readonly isActive?: Maybe<Scalars["Boolean"]["output"]>;
    /** The productId of the productSaleRestriction */
    readonly productId?: Maybe<Scalars["String"]["output"]>;
    /** The productName of the productSaleRestriction */
    readonly productName?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisedKey for the product */
    readonly productSynchronisationKey?: Maybe<Scalars["String"]["output"]>;
    /** The supplierId of the productSaleRestriction */
    readonly supplierId?: Maybe<Scalars["String"]["output"]>;
    /** The supplierName of the product */
    readonly supplierName?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisedKey for the supplier */
    readonly supplierSynchronisationKey?: Maybe<Scalars["String"]["output"]>;
    /** The sychronisationId of the productSaleRestriction */
    readonly synchronisationId?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisedKey for this productSaleRestriction */
    readonly synchronisationKey?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationSource of the productSaleRestriction */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisedAt for this productSaleRestriction */
    readonly synchronisedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The type for the productSaleRestriction */
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

/** This respresents the ProductSaleRestriction List response type */
export type ProductSaleRestrictionListResponse = {
    readonly __typename?: "ProductSaleRestrictionListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<ProductSaleRestrictionListItem>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    readonly reportUrl?: Maybe<Scalars["String"]["output"]>;
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

/** This respresents the ProductSaleRestriction List response type */
export type ProductSaleRestrictionListResponseRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** This respresents the ProductSaleRestriction List response type */
export type ProductSaleRestrictionListResponseTotalArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ProductSaleRestrictionListSort = {
    /** Sort by id */
    readonly id?: InputMaybe<SortDirection>;
};

export type ProductSaleRestrictionSummaryListFilter = {
    /** filter by types */
    readonly restrictionTypes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    /** filter by synchronisation sources */
    readonly synchronisationSources?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** This represents an ProductSaleRestrictionSummary */
export type ProductSaleRestrictionSummaryListItem = {
    readonly __typename?: "ProductSaleRestrictionSummaryListItem";
    /** The id of the productSaleRestriction */
    readonly id?: Maybe<Scalars["String"]["output"]>;
    /** The reason for the productSaleRestriction */
    readonly reason?: Maybe<Scalars["JSON"]["output"]>;
    /** Settings for this partner */
    readonly restrictedProductCount?: Maybe<Scalars["Int"]["output"]>;
    /** The synchronisationSource of the productSaleRestriction */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The type of the productSaleRestriction */
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

/** This respresents the ProductSaleRestrictionSummaryList response type */
export type ProductSaleRestrictionSummaryListResponse = {
    readonly __typename?: "ProductSaleRestrictionSummaryListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** ProductSaleRestrictionSummary list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<ProductSaleRestrictionSummaryListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type ProductSaleRestrictionSummaryListSort = {
    /** Sort by type */
    readonly type?: InputMaybe<SortDirection>;
};

export type ProductSaleRestrictionToggleInput = {
    /** The productId to toggle the sale restriction on */
    readonly productId: Scalars["String"]["input"];
    /** The productSaleRestrictionType. */
    readonly productSaleRestrictionType: Scalars["String"]["input"];
};

export type ProductSaleRestrictionToggleType = {
    readonly __typename?: "ProductSaleRestrictionToggleType";
    readonly isSelected?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ProductSaleRestrictionUpsertInputType = {
    /** Additional comment for this action */
    readonly comment?: InputMaybe<Scalars["String"]["input"]>;
    /** The productId for restriction */
    readonly productId: Scalars["String"]["input"];
    /** The productSaleRestrictionType */
    readonly type: Scalars["String"]["input"];
};

export type ProductSaleRestrictionUpsertType = {
    readonly __typename?: "ProductSaleRestrictionUpsertType";
    readonly comment?: Maybe<Scalars["String"]["output"]>;
    readonly id: Scalars["String"]["output"];
    readonly productId: Scalars["String"]["output"];
    readonly type: Scalars["String"]["output"];
};

/** This represents a start time associated with a product */
export type ProductStartTime = {
    readonly __typename?: "ProductStartTime";
    /** The prodct duration associated with this start time. */
    readonly duration?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob Id for the start time. */
    readonly id: Scalars["String"]["output"];
    /** The pick up time associated with this start time. */
    readonly pickupTime?: Maybe<Scalars["String"]["output"]>;
    /** The productId. */
    readonly productId?: Maybe<Scalars["String"]["output"]>;
    /** The start time. */
    readonly startTime?: Maybe<Scalars["String"]["output"]>;
};

export type ProductStartTimeDeleteInput = {
    /** The ID */
    readonly id: Scalars["String"]["input"];
};

/** This represents a list of start times for the product. */
export type ProductStartTimeList = {
    readonly __typename?: "ProductStartTimeList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<ProductStartTime>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type ProductStartTimeListFilter = {
    /** filter by productId */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductStartTimeUpsertInput = {
    /** The duration of the product content */
    readonly duration?: InputMaybe<Scalars["String"]["input"]>;
    /** The ID */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The pickup time of the product content */
    readonly pickupTime?: InputMaybe<Scalars["String"]["input"]>;
    /** The productId of the product content */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    /** The start time of the product content */
    readonly startTime?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductSupplier = {
    readonly __typename?: "ProductSupplier";
    /** Commission rate */
    readonly commissionRate?: Maybe<Scalars["Float"]["output"]>;
    /** The AddressCountry of the supplier. */
    readonly companyAddressCountry?: Maybe<Scalars["String"]["output"]>;
    /** The AddressCountryCode of the supplier. */
    readonly companyAddressCountryCode?: Maybe<Scalars["String"]["output"]>;
    readonly companyAddressLine1?: Maybe<Scalars["String"]["output"]>;
    /** The AddressLine2 of the supplier. */
    readonly companyAddressLine2?: Maybe<Scalars["String"]["output"]>;
    /** The AdressPostalcode of the supplier. */
    readonly companyAddressPostalcode?: Maybe<Scalars["String"]["output"]>;
    /** The AdressRegion of the supplier. */
    readonly companyAddressRegion?: Maybe<Scalars["String"]["output"]>;
    /** The AddressTown of the supplier. */
    readonly companyAddressTown?: Maybe<Scalars["String"]["output"]>;
    /** The ContactPhone of the supplier. */
    readonly companyContactPhone?: Maybe<Scalars["String"]["output"]>;
    /** The ContactPrimaryFamilyName of the supplier. */
    readonly companyContactPrimaryFamilyName?: Maybe<Scalars["String"]["output"]>;
    /** The ContactPrimaryGivenName of the supplier. */
    readonly companyContactPrimaryGivenName?: Maybe<Scalars["String"]["output"]>;
    readonly companyContactPrimaryRole?: Maybe<Scalars["String"]["output"]>;
    /** The PrimaryEmail of the supplier. */
    readonly companyEmailPrimary?: Maybe<Scalars["String"]["output"]>;
    /** The EmergancyPhone of the supplier . */
    readonly companyPhoneEmergency?: Maybe<Scalars["String"]["output"]>;
    readonly companyPhoneMain?: Maybe<Scalars["String"]["output"]>;
    /** The Mobile of the supplier. */
    readonly companyPhoneMobile?: Maybe<Scalars["String"]["output"]>;
    /** The Contract of the supplier. */
    readonly contract?: Maybe<Scalars["String"]["output"]>;
    /** createdAt */
    readonly createdAt?: Maybe<Scalars["Date"]["output"]>;
    /** The DefaultCurrency of the supplier. */
    readonly defaultCurrency?: Maybe<Scalars["String"]["output"]>;
    /** Brand Configuration List */
    readonly entitySystemConfigurationAssociationList: EntitySystemConfigurationAssociationList;
    /** The full address as returned from the GEO Tables. */
    readonly fullAddress?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the supplier */
    readonly id: Scalars["String"]["output"];
    /** Is supplier active. */
    readonly isActive?: Maybe<Scalars["Boolean"]["output"]>;
    /** The languages spoken by the supplier. */
    readonly languages?: Maybe<Scalars["String"]["output"]>;
    /** The legalJurisdiction of the supplier. */
    readonly legalJurisdiction?: Maybe<Scalars["String"]["output"]>;
    /** The MainContactEmail of the supplier. */
    readonly mainContactEmail?: Maybe<Scalars["String"]["output"]>;
    /** The MainContactEmailOverride of the supplier. */
    readonly mainContactEmailOverride?: Maybe<Scalars["String"]["output"]>;
    /** The MainContactFullName of the supplier. */
    readonly mainContactFullName?: Maybe<Scalars["String"]["output"]>;
    /** The Name of the supplier */
    readonly name: Scalars["String"]["output"];
    /** The Note. */
    readonly note?: Maybe<Scalars["String"]["output"]>;
    /** The times at which the supplier is open. */
    readonly openingTimes?: Maybe<Scalars["String"]["output"]>;
    /** The OutReachComment. */
    readonly outReachComment?: Maybe<Scalars["String"]["output"]>;
    /** The OutReachDate of the supplier. */
    readonly outReachDate?: Maybe<Scalars["Date"]["output"]>;
    /** The outReachComment of the supplier. */
    readonly outReachStatus?: Maybe<Scalars["String"]["output"]>;
    /** The PartnerId for the supplier */
    readonly partnerId?: Maybe<Scalars["String"]["output"]>;
    /** Use to fetch the state of the supplier payment onboaring process */
    readonly paymentAccount?: Maybe<SupplierPricingCategory>;
    /** Is payment split active or not. */
    readonly paymentSplit?: Maybe<Scalars["Boolean"]["output"]>;
    /** The permissions that the current viewer has with this record. */
    readonly permissions?: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
    /** The TOTAL count of Product Records for this supplier */
    readonly productCount: Scalars["Int"]["output"];
    /** The count of Product attached to this supplier that have one or more ProductSaleRestriction applied. */
    readonly restrictedProductCount: Scalars["Int"]["output"];
    /** securityPermissions */
    readonly securityPermissions: ReadonlyArray<SecurityPermission>;
    readonly securityPrincipalId?: Maybe<Scalars["String"]["output"]>;
    /** securityRuleAssociations List */
    readonly securityRuleAssociations: ReadonlyArray<SecurityRulePrincipalAssociation>;
    /** The business segment the supplier operates in. */
    readonly segment?: Maybe<Scalars["String"]["output"]>;
    /** The SocialFacebookUrl of the supplier. */
    readonly socialFacebookUrl?: Maybe<Scalars["String"]["output"]>;
    /** The SocialInstagramURL. */
    readonly socialInstagramUrl?: Maybe<Scalars["String"]["output"]>;
    /** The SocialTwitterUrl of the supplier. */
    readonly socialTwitterUrl?: Maybe<Scalars["String"]["output"]>;
    /** Source name */
    readonly sourceName?: Maybe<Scalars["String"]["output"]>;
    /** Survey json */
    readonly survey?: Maybe<Scalars["JSON"]["output"]>;
    readonly synchronisationKey?: Maybe<Scalars["String"]["output"]>;
    /** Synchronisation source */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** Last Synchronisation */
    readonly synchronisedAt?: Maybe<Scalars["Date"]["output"]>;
    /** The TOTAL count of Product Records for this supplier. */
    readonly totalProductCount: Scalars["Int"]["output"];
    /** The TripAdvisorRating of the supplier. */
    readonly tripAdvisorRating?: Maybe<Scalars["String"]["output"]>;
    /** The TripAdvisorUrl of the supplier. */
    readonly tripAdvisorUrl?: Maybe<Scalars["String"]["output"]>;
    /** updatedAt */
    readonly updatedAt?: Maybe<Scalars["Date"]["output"]>;
    /** The VoucherContactEmail of the supplier. */
    readonly voucherContactEmail?: Maybe<Scalars["String"]["output"]>;
    /** The VoucherContactName of the supplier. */
    readonly voucherContactName?: Maybe<Scalars["String"]["output"]>;
    /** The Main VoucherContactPhone of the supplier. */
    readonly voucherContactPhoneMain?: Maybe<Scalars["String"]["output"]>;
    /** The Mobile VoucherContactPhone of the supplier. */
    readonly voucherContactPhoneMobile?: Maybe<Scalars["String"]["output"]>;
    /** The WebsiteURL of the supplier. */
    readonly websiteUrl?: Maybe<Scalars["String"]["output"]>;
};

/** This represents a product translation */
export type ProductTranslation = {
    readonly __typename?: "ProductTranslation";
    /** The code of the product translation */
    readonly code?: Maybe<Scalars["String"]["output"]>;
    /** The language id for product content */
    readonly id: Scalars["String"]["output"];
    /** The name of the product translation */
    readonly name?: Maybe<Scalars["String"]["output"]>;
};

export type ProductTranslationListFilter = {
    /** filter by languageId */
    readonly languageId?: InputMaybe<Scalars["String"]["input"]>;
    /** filter by productId */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents an productTranslation */
export type ProductTranslationListItem = {
    readonly __typename?: "ProductTranslationListItem";
    /** The code of the product translation */
    readonly code?: Maybe<Scalars["String"]["output"]>;
    /** The language id for product content */
    readonly id: Scalars["String"]["output"];
    /** The name of the product translation */
    readonly name?: Maybe<Scalars["String"]["output"]>;
};

/** This respresents the ProductTranslationList response type */
export type ProductTranslationListResponse = {
    readonly __typename?: "ProductTranslationListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** ProductTranslation list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<ProductTranslationListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type ProductTranslationListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

export type ProductTranslationUpdateInput = {
    /** The productId of the product translation */
    readonly abstract?: InputMaybe<Scalars["String"]["input"]>;
    /** The description of the product translation */
    readonly description?: InputMaybe<Scalars["String"]["input"]>;
    /** The name of the product translation */
    readonly descriptionHtml?: InputMaybe<Scalars["String"]["input"]>;
    /** The ID */
    readonly id: Scalars["String"]["input"];
    /** The languageId of the product translation */
    readonly languageId?: InputMaybe<Scalars["String"]["input"]>;
    /** The name of the product translation */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** The productId of the product translation */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    /** The name of the product translation */
    readonly synchronisationId?: InputMaybe<Scalars["String"]["input"]>;
    /** The synchronisationSource of the product translation */
    readonly synchronisationSource?: InputMaybe<Scalars["String"]["input"]>;
    /** The productId of the product translation */
    readonly synchronisedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

/** Product Type Data Type */
export enum ProductType {
    /** Product is of type ACTIVITY */
    Activity = "ACTIVITY",
    /** Product type is not filtered on */
    All = "ALL",
    /** Product is of type HOTEL */
    Hotel = "HOTEL",
    /** Product is of type RESTAURANT */
    Restaurant = "RESTAURANT",
}

/** This represents an Product */
export type ProductTypeBase = {
    readonly __typename?: "ProductTypeBase";
    /** The abstract (brief description) of the product. */
    readonly abstract?: Maybe<Scalars["String"]["output"]>;
    /** The bookable additions for this product. */
    readonly additionList: ProductAdditionList;
    /**
     * DEPRECATED The bookable additions.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use additionList in its place
     */
    readonly additions?: Maybe<ReadonlyArray<Maybe<ProductAddition>>>;
    /** administrationNote */
    readonly administrationNote?: Maybe<Scalars["String"]["output"]>;
    /** The duration prio to a product start in which you must arrive. */
    readonly advanceArrivalDuration?: Maybe<Scalars["String"]["output"]>;
    /** allContactFields */
    readonly allContactFields?: Maybe<Scalars["JSON"]["output"]>;
    readonly allowZeroPricing?: Maybe<Scalars["Boolean"]["output"]>;
    /** The attributes association for which this product is available. */
    readonly attributeAssociationList?: Maybe<ProductAttributeAssociationList>;
    /** The list of attributes for the product. */
    readonly attributeList: ProductAttributeList;
    /**
     * DEPRECATED The attributes
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use attributeList in its place
     */
    readonly attributes?: Maybe<ReadonlyArray<Maybe<ProductAttribute>>>;
    /** Product Availability */
    readonly availabilities?: Maybe<AvailabilityListResponse>;
    /**
     * Count of Availability Records for this product
     * @deprecated This is no longer supported.
     */
    readonly availabilityCount?: Maybe<Scalars["Int"]["output"]>;
    /** The availability patterns for which this product is available. */
    readonly availabilityPatternList?: Maybe<ProductAvailabilityPatternList>;
    /** Type of availability associated with this product. */
    readonly availabilityType: AvailabilityType;
    /** Banner to display over product image */
    readonly banner?: Maybe<BannerType>;
    /** The best time to experience this product. */
    readonly bestTimeToVisit?: Maybe<Scalars["String"]["output"]>;
    /** The duration prior to a product start in which no booking can be made. */
    readonly bookingCutoffDuration?: Maybe<Scalars["String"]["output"]>;
    /** bookingQuestions */
    readonly bookingQuestions?: Maybe<Scalars["JSON"]["output"]>;
    /** url to the booking page for this product */
    readonly bookingUrl?: Maybe<Scalars["String"]["output"]>;
    /** The cancellation policy for the product. */
    readonly cancellationPolicy: ProductCancellationPolicy;
    /** The category association for which this product is available. */
    readonly categoryAssociationList?: Maybe<ProductCategoryAssociationList>;
    /** The list of categories for the product. */
    readonly categoryList?: Maybe<ProductProductCategoryListType>;
    /** The nearest city for the end location */
    readonly cityEnd?: Maybe<Place>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead. */
    readonly cityEndId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead. */
    readonly cityEndName?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead. */
    readonly cityId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead. */
    readonly cityName?: Maybe<Scalars["String"]["output"]>;
    /** The nearest city for the start location */
    readonly cityStart?: Maybe<Place>;
    /**
     * DEPRECATED - Please use cityId in place
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead.
     */
    readonly cityStartId?: Maybe<Scalars["String"]["output"]>;
    /**
     * DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead.
     */
    readonly cityStartName?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob code for the product. */
    readonly code?: Maybe<Scalars["String"]["output"]>;
    /** Pricing for contact only products */
    readonly contactPricingList?: Maybe<ProductContactPricingList>;
    /** The list of content for the product. */
    readonly contentList: ProductContentListResponse;
    /** All product content in all languages */
    readonly contentTranslatedList: ProductContentTranslatedList;
    /**
     * The country id for the end location
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryEndId?: Maybe<Scalars["String"]["output"]>;
    /**
     * The country name for the end location
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryEndName?: Maybe<Scalars["String"]["output"]>;
    /**
     * The country id for the activity location
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryId?: Maybe<Scalars["String"]["output"]>;
    /**
     * The country name for the activity location
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryName?: Maybe<Scalars["String"]["output"]>;
    /**
     * DEPRECATED - Please use countryId in place
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryStartId?: Maybe<Scalars["String"]["output"]>;
    /**
     * DEPRECATED - Please use countryName in place
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use placeEnd instead.
     */
    readonly countryStartName?: Maybe<Scalars["String"]["output"]>;
    /** createdAt */
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The culture shock level of the product. */
    readonly cultureShockLevel?: Maybe<Scalars["String"]["output"]>;
    /**
     * The list of curations for the product.
     * @deprecated DEPRECATED: This field returns obsolete data. For current Curation data please use the hierarchyCurationList field.
     */
    readonly curationList?: Maybe<CurationListType>;
    /** customFields */
    readonly customFields?: Maybe<Scalars["JSON"]["output"]>;
    /**
     * Default path to curation
     * @deprecated DEPRECATED: this field returns obsolete data
     */
    readonly defaultCurationPath?: Maybe<ReadonlyArray<CurationPathItem>>;
    /** defaultImageId */
    readonly defaultImageId?: Maybe<Scalars["String"]["output"]>;
    /** The language code that the defaultName has been returned in if available. */
    readonly defaultLanguageCode?: Maybe<Scalars["String"]["output"]>;
    /** The name of the product in the fallback language (typically EN). */
    readonly defaultName?: Maybe<Scalars["String"]["output"]>;
    /** The description of the product in the requested or default language. */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /**
     * An HTML description of the product.
     * @deprecated Deprecated: please use description with a content type of html
     */
    readonly descriptionHtml?: Maybe<Scalars["String"]["output"]>;
    readonly descriptionJson?: Maybe<Scalars["JSON"]["output"]>;
    /** The difficulty level of the product. */
    readonly difficultyLevel?: Maybe<Scalars["String"]["output"]>;
    /** Dress advice for the product. */
    readonly dressAdvice?: Maybe<Scalars["String"]["output"]>;
    /** effectiveCommissionRate */
    readonly effectiveCommissionRate?: Maybe<Scalars["Float"]["output"]>;
    /** effectiveCommissionType */
    readonly effectiveCommissionType?: Maybe<Scalars["String"]["output"]>;
    /** The language code that the name has been returned in. */
    readonly effectiveLanguageCode?: Maybe<Scalars["String"]["output"]>;
    /** The ending place for the product. */
    readonly endPlace?: Maybe<PlaceNew>;
    /**
     * DEPRECATED The exclusions
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use contentList in its place
     */
    readonly exclusions?: Maybe<ReadonlyArray<Maybe<ProductContent>>>;
    readonly externalWidgetData?: Maybe<ExternalWidget>;
    readonly factorTagList?: Maybe<SystemTagFactorList>;
    /** Product Geo Coordinate */
    readonly geoCoordinate?: Maybe<GeoCoordinate>;
    /** The id of the product geo coordinate. */
    readonly geoCoordinateId?: Maybe<Scalars["String"]["output"]>;
    /** The geo name of the product. */
    readonly geoName?: Maybe<Scalars["String"]["output"]>;
    /** The list of guide languages in which this product is available. */
    readonly guideLanguageList: GuideLanguageList;
    /**
     * The guide price for the product.
     * @deprecated This field is deprecated, please use guidePriceAmount in its place.
     */
    readonly guidePrice?: Maybe<Scalars["Float"]["output"]>;
    /** The guide price amount for the product. */
    readonly guidePriceAmount?: Maybe<Scalars["Float"]["output"]>;
    /** The guide price currency for the product. */
    readonly guidePriceCurrency?: Maybe<Scalars["String"]["output"]>;
    /** The guide price for the product as formatted text with currency symbol. */
    readonly guidePriceFormattedText?: Maybe<Scalars["String"]["output"]>;
    /** The guide price type */
    readonly guidePriceType?: Maybe<ProductGuidePriceType>;
    /**
     * Does product have an active commission
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use offSaleReasonList instead
     */
    readonly hasActiveCommission?: Maybe<Scalars["Boolean"]["output"]>;
    /** hasHtmlParseErrors */
    readonly hasHtmlParseErrors?: Maybe<Scalars["Boolean"]["output"]>;
    /** Whether this product has a start time. */
    readonly hasStartTime?: Maybe<Scalars["Boolean"]["output"]>;
    /** The list of curations for the product. */
    readonly hierarchyCurationList?: Maybe<ProductCurationListType>;
    /**
     * DEPRECATED The highlights
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use contentList in its place
     */
    readonly highlights?: Maybe<ReadonlyArray<Maybe<ProductContent>>>;
    /** The guide price for the product using price type. */
    readonly holibobGuidePrice?: Maybe<Price>;
    /** htmlParseErrors */
    readonly htmlParseErrors?: Maybe<Scalars["JSON"]["output"]>;
    /** The unique Holibob Id for the product. */
    readonly id: Scalars["String"]["output"];
    /** Product Images */
    readonly imageList: ReadonlyArray<ProductImage>;
    /**
     * DEPRECATED Product Images
     * @deprecated DEPRECATED - Please use imageList instead.
     */
    readonly images: ReadonlyArray<ProductImage>;
    /**
     * includeInDemo
     * @deprecated Deprecated: no longer used
     */
    readonly includeInDemo?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * includeInDemoAsFeatured
     * @deprecated Deprecated: no longer used
     */
    readonly includeInDemoAsFeatured?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * DEPRECATED The inclusions
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use contentList in its place
     */
    readonly inclusions?: Maybe<ReadonlyArray<Maybe<ProductContent>>>;
    /** If viewer's favourite product */
    readonly isFavourite?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is this product featured. */
    readonly isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is this product offsale. */
    readonly isOffSale?: Maybe<Scalars["Boolean"]["output"]>;
    /** A flag indicating if the product requires manual approval by the supplier. Only relevant for internal products. */
    readonly isOnRequest: Scalars["Boolean"]["output"];
    /**
     * DEPRECATED Is this product activated.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use offSaleReasonList instead
     */
    readonly isPartnerActivated?: Maybe<Scalars["Boolean"]["output"]>;
    /** Is this product featured. */
    readonly isPromoted?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * Is this product the subject of one or more sale restrictions.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use offSaleReasonList instead
     */
    readonly isSaleRestricted?: Maybe<Scalars["Boolean"]["output"]>;
    /** Evaluate product selection based on conditions. */
    readonly isSelected?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * DEPRECATED Is this product activated.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use offSaleReasonList instead
     */
    readonly isSupplierActivated?: Maybe<Scalars["Boolean"]["output"]>;
    /**
     * isTest
     * @deprecated Deprecated: no longer used
     */
    readonly isTest?: Maybe<Scalars["Boolean"]["output"]>;
    readonly itinerary: ProductItinerary;
    /**
     * The guide languages in which this product is available.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use guideLanguageList in its place
     */
    readonly languages?: Maybe<ReadonlyArray<Maybe<GuideLanguage>>>;
    /** mainContactFields */
    readonly mainContactFields?: Maybe<Scalars["JSON"]["output"]>;
    /** The maximum duration of the product. */
    readonly maxDuration?: Maybe<Scalars["String"]["output"]>;
    /** Maximum group size for the product. */
    readonly maxGroupSize?: Maybe<Scalars["String"]["output"]>;
    /** The list of translations for the product. */
    readonly metaList: ProductMetaListResponse;
    /** The minimum duration of the product. */
    readonly minDuration?: Maybe<Scalars["String"]["output"]>;
    /** The name of the product in the requested language where this is available and in a fallback language (typically EN) where the requested language is not available. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /**
     * The date on which the product is next available.
     * @deprecated This is not longer supported.
     */
    readonly nextAvailabilityAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** Product Sales restrictions */
    readonly offSaleReasonList: ReadonlyArray<Maybe<ProductOffSaleReason>>;
    /** originGuidePrice */
    readonly originGuidePrice?: Maybe<Scalars["String"]["output"]>;
    /** originGuidePriceCurrency */
    readonly originGuidePriceCurrency?: Maybe<Scalars["String"]["output"]>;
    /** originMaxRetailPrice */
    readonly originMaxRetailPrice?: Maybe<Scalars["String"]["output"]>;
    /**
     * overrideName
     * @deprecated Deprecated: no longer used
     */
    readonly overrideName?: Maybe<Scalars["String"]["output"]>;
    /** passengerFields */
    readonly passengerFields?: Maybe<Scalars["JSON"]["output"]>;
    /** The permissions that the current viewer has with this record. */
    readonly permissions?: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
    /** The starting place for the product. */
    readonly place?: Maybe<ProductPlace>;
    /**
     * The ending place for the product.
     * @deprecated DEPRECATED - Use endPlace
     */
    readonly placeEnd?: Maybe<ProductPlace>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead. */
    readonly placeId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use place instead. */
    readonly placeName?: Maybe<Scalars["String"]["output"]>;
    /** The number of hours before the activity start time when the pre-arrival instructions email is sent. */
    readonly preArrivalInstructionsEmailWindow?: Maybe<Scalars["Int"]["output"]>;
    /** A preview image for this product. */
    readonly previewImage?: Maybe<ProductImage>;
    /** The channel specific promotions of the product. */
    readonly promotionList?: Maybe<PartnerChannelProductPromotionList>;
    /** The enumerated type attributed to the promotion of the product. */
    readonly promotionType?: Maybe<Scalars["String"]["output"]>;
    /** Product Provider */
    readonly provider?: Maybe<Provider>;
    /** The id of the product provider. */
    readonly providerId?: Maybe<Scalars["String"]["output"]>;
    /** Product quality score */
    readonly qualityMatchScore?: Maybe<Scalars["Float"]["output"]>;
    readonly questionList: EntityQuestionAssociationList;
    /** the total numbers of reviews */
    readonly reviewCount?: Maybe<Scalars["Int"]["output"]>;
    /** reviews rating score */
    readonly reviewRating?: Maybe<Scalars["Float"]["output"]>;
    /** The roolm list associated with this product. */
    readonly roomList: ProductRoomList;
    /**
     * The language code that the rootName has been returned in if available.
     * @deprecated Deprecated: please use defaultLanguageCode
     */
    readonly rootLanguageCode?: Maybe<Scalars["String"]["output"]>;
    /**
     * The name of the product in the fallback language (typically EN).
     * @deprecated Deprecated: please use defaultName
     */
    readonly rootName?: Maybe<Scalars["String"]["output"]>;
    readonly slug?: Maybe<Scalars["String"]["output"]>;
    /** Source Instance */
    readonly sourceInstance?: Maybe<SynchronisationSource>;
    /** The starting place for the product. */
    readonly startPlace?: Maybe<PlaceNew>;
    /** Count of start times availabile with this product. */
    readonly startTimeCount?: Maybe<Scalars["Int"]["output"]>;
    /** The start times for which this product is available. */
    readonly startTimeList: ProductStartTimeList;
    /**
     * The start times for which this product is available.
     * @deprecated DEPRECATED - This field is no longer supported and will be removed in a future release. Please use startTimesList in its place
     */
    readonly startTimes?: Maybe<ReadonlyArray<Maybe<ProductStartTime>>>;
    /** Product Supplier */
    readonly supplier?: Maybe<ProductSupplier>;
    /** supplierId */
    readonly supplierId?: Maybe<Scalars["String"]["output"]>;
    /** The name of the product supplier. */
    readonly supplierName?: Maybe<Scalars["String"]["output"]>;
    /** supplierSurvey */
    readonly supplierSurvey?: Maybe<Scalars["JSON"]["output"]>;
    /** synchronisationDeletedAt */
    readonly synchronisationDeletedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** synchronisationId */
    readonly synchronisationId?: Maybe<Scalars["String"]["output"]>;
    /** synchronisationKey */
    readonly synchronisationKey?: Maybe<Scalars["String"]["output"]>;
    /** synchronisationSource */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** synchronisedAt */
    readonly synchronisedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** synchronisedAvailabilityAt */
    readonly synchronisedAvailabilityAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The list of SystemTags with this Provider. */
    readonly tagAssociationList?: Maybe<SystemTagList>;
    /** Tipping advice for the product. */
    readonly tippingAdvice?: Maybe<Scalars["String"]["output"]>;
    /** The list of translations for the product. */
    readonly translationList?: Maybe<ProductTranslationListResponse>;
    readonly type: ProductTypeType;
    /** updatedAt */
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** Defines the mode in which this product can be edited. */
    readonly upsertMode?: Maybe<Scalars["String"]["output"]>;
    /** The websiteUrl for the product. */
    readonly websiteUrl?: Maybe<Scalars["String"]["output"]>;
};

/** This represents an Product */
export type ProductTypeBaseAvailabilitiesArgs = {
    autoFillOptions?: InputMaybe<Scalars["Boolean"]["input"]>;
    filter?: InputMaybe<AvailabilityListFilter>;
    optionList?: InputMaybe<ReadonlyArray<InputMaybe<AvailabilityListOptionListItemInput>>>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sessionId?: InputMaybe<Scalars["ID"]["input"]>;
    sort?: InputMaybe<AvailabilityListSort>;
};

/** This represents an Product */
export type ProductTypeBaseDescriptionArgs = {
    contentType?: InputMaybe<DescriptionContentType>;
};

/** This represents an Product */
export type ProductTypeBaseIsSelectedArgs = {
    criteria?: InputMaybe<ProductIsSelectedCriteriaType>;
};

export enum ProductTypeType {
    Activity = "ACTIVITY",
    Golf = "GOLF",
    Hotel = "HOTEL",
    Restaurant = "RESTAURANT",
}

export type ProductUpsertInput = {
    /** When set will allow consumers to add an availability even if the total price is zero. This is typically used for reservation products only. */
    readonly allowZeroPricing?: InputMaybe<Scalars["Boolean"]["input"]>;
    readonly associatedProductList?: InputMaybe<ReadonlyArray<ProductAssociationInput>>;
    /** Category association list. */
    readonly attributeAssociationList?: InputMaybe<ReadonlyArray<InputMaybe<ProductAttributeAssociationUpsertInput>>>;
    /** Availability pattern list. */
    readonly availabilityPatternList?: InputMaybe<ReadonlyArray<InputMaybe<ProductAvailabilityPatternUpsertInput>>>;
    /** The availability type for the product */
    readonly availabilityType?: InputMaybe<Scalars["String"]["input"]>;
    /** The bookingCutoffDuration for the product */
    readonly bookingCutoffDuration?: InputMaybe<Scalars["String"]["input"]>;
    /** Availability pattern list. */
    readonly cancellationPenaltyList?: InputMaybe<ReadonlyArray<InputMaybe<ProductCancellationPenaltyUpsertInput>>>;
    /** Category association list. */
    readonly categoryAssociationList?: InputMaybe<ReadonlyArray<InputMaybe<ProductCategoryAssociationUpsertInput>>>;
    /** The cityEndId for the product */
    readonly cityEndId?: InputMaybe<Scalars["String"]["input"]>;
    /** The cityId for the product */
    readonly cityId?: InputMaybe<Scalars["String"]["input"]>;
    /** All product content entries for each language */
    readonly contentTranslatedList?: InputMaybe<ReadonlyArray<InputMaybe<ProductContentTranslatedListItemInput>>>;
    /** The curationIds for the product */
    readonly curationIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** External availability widget. Pick one of properties only */
    readonly externalWidgetData?: InputMaybe<ExternalWidgetInput>;
    /** The geo name for the product */
    readonly geoName?: InputMaybe<Scalars["String"]["input"]>;
    /** The unique ID for the product */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** Image list. */
    readonly imageList?: InputMaybe<ReadonlyArray<InputMaybe<ProductImageUpsertInput>>>;
    /** Is this product activated. */
    readonly isActivated?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** On Request product adds extra booking lifecycle manager step, which requires the Supplier to manually accept/reject booking confirmation or booking cancellation. */
    readonly isOnRequest?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Is this product activated by the Partner. */
    readonly isPartnerActivated?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Is this product activated by the Supplier. */
    readonly isSupplierActivated?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The product originGuidePrice */
    readonly originGuidePrice?: InputMaybe<Scalars["Float"]["input"]>;
    readonly originGuidePriceCurrency?: InputMaybe<Scalars["String"]["input"]>;
    /** Controls the number of hours the pre-arrival email is sent before the activity start time. */
    readonly preArrivalInstructionsEmailWindow?: InputMaybe<Scalars["Int"]["input"]>;
    readonly questionList?: InputMaybe<ReadonlyArray<EntityQuestionAssociationInput>>;
    /** the total numbers of reviews */
    readonly reviewCount?: InputMaybe<Scalars["Int"]["input"]>;
    /** reviews rating score */
    readonly reviewRating?: InputMaybe<Scalars["Float"]["input"]>;
    /** The supplierId for the product */
    readonly supplierId?: InputMaybe<Scalars["String"]["input"]>;
    /** The synchronisationSource  for the product */
    readonly synchronisationSource?: InputMaybe<Scalars["String"]["input"]>;
    /** System tag association list. */
    readonly tagList?: InputMaybe<ReadonlyArray<InputMaybe<SystemTagAssociationUpsertInput>>>;
    /** The upsertMode for the product */
    readonly upsertMode?: InputMaybe<Scalars["String"]["input"]>;
    /** The website Url for the product */
    readonly websiteUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductViewTypeItem = {
    readonly __typename?: "ProductViewTypeItem";
    readonly id: Scalars["String"]["output"];
    readonly name: Scalars["String"]["output"];
};

/** This represents a Provider. */
export type Provider = {
    readonly __typename?: "Provider";
    /** The list of Actors with this Provider. */
    readonly actorList?: Maybe<ActorAssociationList>;
    /**
     * The Town.
     * @deprecated This field would no longer be populated
     */
    readonly addressCity?: Maybe<Scalars["String"]["output"]>;
    /**
     * The Country.
     * @deprecated This field would no longer be populated
     */
    readonly addressCountry?: Maybe<Scalars["String"]["output"]>;
    /**
     * The Address Line1.
     * @deprecated This field would no longer be populated
     */
    readonly addressLine1?: Maybe<Scalars["String"]["output"]>;
    /**
     * The Address Line 2.
     * @deprecated This field would no longer be populated
     */
    readonly addressLine2?: Maybe<Scalars["String"]["output"]>;
    /**
     * The Address Line 3.
     * @deprecated This field would no longer be populated
     */
    readonly addressLine3?: Maybe<Scalars["String"]["output"]>;
    /**
     * The Post Code.
     * @deprecated This field would no longer be populated
     */
    readonly addressPostalCode?: Maybe<Scalars["String"]["output"]>;
    /**
     * The Region.
     * @deprecated This field would no longer be populated
     */
    readonly addressRegion?: Maybe<Scalars["String"]["output"]>;
    /**
     * The Town.
     * @deprecated Deprecated in favour of 'addressCity'
     */
    readonly addressTown?: Maybe<Scalars["String"]["output"]>;
    /** The list of EntityAttachment with this Provider. */
    readonly attachmentList?: Maybe<EntityAttachmentList>;
    /** The district of the Provider. */
    readonly district?: Maybe<Scalars["String"]["output"]>;
    /**
     * The email.
     * @deprecated This data will be available under 'valueList'-prop
     */
    readonly emailMain?: Maybe<Scalars["String"]["output"]>;
    /** The full address as returned from the GEO Tables. */
    readonly geoLocationId?: Maybe<Scalars["String"]["output"]>;
    /** The unique id for the Provider. */
    readonly id: Scalars["String"]["output"];
    /** The name. */
    readonly name: Scalars["String"]["output"];
    /**
     * The phone.
     * @deprecated This data will be available under 'valueList'-prop
     */
    readonly phoneMain?: Maybe<Scalars["String"]["output"]>;
    /** The list of SystemTags with this Provider. */
    readonly tagList?: Maybe<SystemTagList>;
    /** The type of the Provider. */
    readonly type: ProviderTypeType;
    /** The list of EntityValue with this Provider. */
    readonly valueList?: Maybe<EntityValueList>;
};

export type ProviderDeleteInput = {
    /** The ID of the Provider to be deleted. */
    readonly id: Scalars["String"]["input"];
};

export type ProviderList = {
    readonly __typename?: "ProviderList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<Provider>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /** Generates a report of the provider list and returns the URL to download it */
    readonly reportUrl?: Maybe<Scalars["String"]["output"]>;
};

export type ProviderListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ProviderListFilter = {
    /** Filter by a list of Districts */
    readonly districtList?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
    /** Filter by a list of id of a Town, City, Country or Region */
    readonly placeList?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
    /** Filter by Product Id. */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by a list of region id tags */
    readonly regionTagIdList?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
    /** Filter by name. */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by a list of Supplier Security Principal ids */
    readonly supplierSecurityPrincipalIdList?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
    /** Filter by a list of id tags */
    readonly tagList?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
    /** Provider type */
    readonly type?: InputMaybe<ProviderTypeType>;
    readonly typeList?: InputMaybe<ReadonlyArray<InputMaybe<ProviderTypeType>>>;
};

export type ProviderListSort = {
    /** Sort by name. */
    readonly name?: InputMaybe<SortDirection>;
};

export type ProviderTree = {
    readonly __typename?: "ProviderTree";
    readonly count: Scalars["Int"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly isSelected: Scalars["Boolean"]["output"];
    readonly label: Scalars["String"]["output"];
    readonly type: Scalars["String"]["output"];
};

export type ProviderTreeList = {
    readonly __typename?: "ProviderTreeList";
    readonly nodes: ReadonlyArray<ProviderTree>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export enum ProviderTypeType {
    Accommodation = "ACCOMMODATION",
    Activity = "ACTIVITY",
    Event = "EVENT",
    Experience = "EXPERIENCE",
    General = "GENERAL",
    Restaurant = "RESTAURANT",
    Spa = "SPA",
}

export type ProviderUpsertInput = {
    /** System tag association list. */
    readonly attachmentList?: InputMaybe<ReadonlyArray<InputMaybe<EntityAttachmentUpsertInputType>>>;
    /** The district of the Provider. */
    readonly district?: InputMaybe<Scalars["String"]["input"]>;
    /** The full address as returned from the GEO Tables. */
    readonly geoLocationId?: InputMaybe<Scalars["String"]["input"]>;
    /** The ID of the Provider. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The name of the Provider. */
    readonly name: Scalars["String"]["input"];
    /** The primary contact Actor details */
    readonly primaryContact?: InputMaybe<ActorAssociationUpsertInputType>;
    /** System tag association list. */
    readonly tagList?: InputMaybe<ReadonlyArray<InputMaybe<SystemTagAssociationUpsertInput>>>;
    /** The type of the Provider. */
    readonly type: Scalars["String"]["input"];
    /** EntityValue list. */
    readonly valueList?: InputMaybe<ReadonlyArray<InputMaybe<EntityValueUpsertInputType>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type Queries = {
    readonly __typename?: "Queries";
    /** Used to fetchhigh level statistics relating to the business systems. */
    readonly adminStats?: Maybe<AdminStats>;
    readonly analyticLinkOriginList: AnalyticLinkOriginList;
    /** Used to fetch the API Documentation. */
    readonly apiDocumentationList?: Maybe<ArticleListResponse>;
    /** Article */
    readonly article?: Maybe<Article>;
    /** List articles */
    readonly articleList?: Maybe<ArticleListResponse>;
    /** Used to fetcha list of articles including an id and value. */
    readonly articleTypeOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    readonly assetUpload: AssetUpload;
    readonly authUserByAccount?: Maybe<AuthUser>;
    readonly authUserByEmail?: Maybe<AuthUser>;
    readonly authUserById?: Maybe<AuthUser>;
    /** Use to fetch full details of a given availability. The query is able to return options and availability and you should ALWAYS request both.  If options exist for the availability and have not yet been answered then the availability node will be null.  Once you have provided answers to any options returned (using the appropriate mutation) you must again call for the options and availability and iterate the process until all options have been answered. */
    readonly availability?: Maybe<Availability>;
    /** Use to fetch a list of availabilities for a given product. The query takes a productID and a filter including the start and end dates for the range of availability that is required. */
    readonly availabilityList?: Maybe<AvailabilityListResponse>;
    /** Use to fetch the details of a booking. The input includes a bookingSelector that can take a bookingID or the booking's code. */
    readonly booking?: Maybe<Booking>;
    /** Use to fetch the details of a bookingAvailability */
    readonly bookingAvailability?: Maybe<BookingAvailability>;
    /** Use to fetch the QR consumption details of a bookingAvailability */
    readonly bookingAvailabilityConsumption?: Maybe<BookingAvailabilityConsumptionType>;
    /** Used to fetch the private and extended details of a bookingAvailability. */
    readonly bookingAvailabilityExtended?: Maybe<BookingAvailabilityExtended>;
    readonly bookingAvailabilityFinanceList: BookingFinanceListResponse;
    readonly bookingAvailabilityList: BookingAvailabilityList;
    /** Use to fetch the details of a bookingAvailabilityPerson */
    readonly bookingAvailabilityPerson?: Maybe<BookingAvailabilityPerson>;
    /** Used to fetch a list of booking availability states including an id, value and description. */
    readonly bookingAvailabilityStateOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Booking Event List. */
    readonly bookingEvents?: Maybe<SystemLogList>;
    /** Use to fetch a list of Booking each of which will also contain the booked availability, people and answered question. */
    readonly bookingList?: Maybe<BookingList>;
    readonly bookingOriginTypeOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetcha list of booking states including an id, value and description. */
    readonly bookingStateOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Booking Voucher */
    readonly bookingVoucher?: Maybe<BookingVoucher>;
    /** Use to fetch the details of a brand. */
    readonly brand?: Maybe<Brand>;
    /** Used to fetch list of brand content type including an id, and value. */
    readonly brandContentTypeOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch a list of brands. */
    readonly brandList?: Maybe<BrandListType>;
    /** Used to fetch a list of brand including an id, value and description. */
    readonly brandOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch a list of curation breadcrumbs */
    readonly breadcrumbList: BreadcrumbList;
    /** Use to fetch a list of business principals. */
    readonly businessPrincipal?: Maybe<BusinessPrincipal>;
    /** Used to fetch a list of potential business principal parents */
    readonly businessPrincipalParentOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetcha list of cities including an id, value and description. */
    readonly cityOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Company Entity System Configuration Association List */
    readonly companyEntitySystemConfigurationAssociationList?: Maybe<EntitySystemConfigurationAssociationList>;
    /** Used to retrieve a Consumer record. */
    readonly consumer?: Maybe<Consumer>;
    readonly consumerBookingHierarchyList?: Maybe<ConsumerBookingHierarchyList>;
    /** Used to retrieve a list of consumer records. */
    readonly consumerList?: Maybe<ConsumerList>;
    readonly consumerTrip?: Maybe<ConsumerTrip>;
    readonly consumerTripAvailabilityPersonList?: Maybe<ConsumerTripAvailabilityPersonList>;
    readonly consumerTripByCode?: Maybe<ConsumerTrip>;
    readonly consumerTripContent?: Maybe<ConsumerTripContent>;
    readonly consumerTripEventList?: Maybe<ConsumerTripEventList>;
    readonly consumerTripList?: Maybe<ConsumerTripList>;
    /** Used to retrieve a list of consumer recently viewed products. */
    readonly consumerTripRecentlyViewedProductList?: Maybe<ProductList>;
    readonly consumerTripRecommendedProductList?: Maybe<ProductList>;
    readonly contentComponent?: Maybe<ContentComponent>;
    readonly contentComponentList: ContentComponentList;
    /** Used to fetcha list of countries including an id, value and description. */
    readonly countryOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch a list of cuisine tags. */
    readonly cuisineTagOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch the details of a curation. */
    readonly curation?: Maybe<Curation>;
    readonly curationContent?: Maybe<CurationContent>;
    readonly curationContentLegacy?: Maybe<CurationContent>;
    /** Use to fetch a list of curations. */
    readonly curationList?: Maybe<CurationListType>;
    /** Used to fetch a list of curation including an id, value and description. */
    readonly curationOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetcha list of dataTypes including an id and value. */
    readonly dataTypeOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch a list of curation associated with a destionation theme. */
    readonly destinationThemeCurationAssociationOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** DistributionChannel list. */
    readonly distributionChannelAuthorList?: Maybe<DistributionChannelAuthorList>;
    readonly distributionChannelBlogAuthorList?: Maybe<DistributionChannelAuthorList>;
    readonly distributionChannelBlogCategoryList?: Maybe<DistributionChannelBlogCategoryList>;
    /** DistributionChannelBlogPost list. */
    readonly distributionChannelBlogPostList?: Maybe<DistributionChannelBlogPostList>;
    /** Distribution channel content */
    readonly distributionChannelContent?: Maybe<DistributionChannelContent>;
    /** Used to fetch a list of curations under a distribution channel. */
    readonly distributionChannelCurationOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch a list of hierarchy parent options. */
    readonly distributionChannelCurationParentOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Distribution channel Home Page content */
    readonly distributionChannelHomePageContent?: Maybe<DistributionChannelHomePageContent>;
    /** DistributionChannel list. */
    readonly distributionChannelList?: Maybe<DistributionChannelList>;
    /** Used to fetch list of distribution channel page category options. */
    readonly distributionChannelPageAuthorOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** DistributionChannelCategory list. */
    readonly distributionChannelPageCategoryList?: Maybe<DistributionChannelPageCategoryList>;
    /** Used to fetch list of distribution channel page category options. */
    readonly distributionChannelPageCategoryOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Distribution channel page content */
    readonly distributionChannelPageContent?: Maybe<DistributionChannelPageContent>;
    /** DistributionChannel list. */
    readonly distributionChannelPageList?: Maybe<DistributionChannelPageList>;
    /** Used to fetch a list of pages under a distribution channel. */
    readonly distributionChannelPageOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /**
     * Redirect Url Path For Distribution Channel
     * @deprecated Use distributionChannelRedirectUrlPathNew for support of `type` field
     */
    readonly distributionChannelRedirectUrlPath?: Maybe<Scalars["String"]["output"]>;
    /** Redirect Url Path For Distribution Channel */
    readonly distributionChannelRedirectUrlPathNew?: Maybe<DistributionChannelRedirectUrlPath>;
    readonly distributionChannelTranslationExportList: TranslationExportList;
    readonly distributionChannelTranslationImportList: TranslationImportList;
    /** Use to fetch the extended data for a single EntityComment. The criteria includes a required entityCommentId. */
    readonly entityComment?: Maybe<EntityComment>;
    /** EntityComment list. */
    readonly entityCommentList?: Maybe<EntityCommentList>;
    /** Use to fetch the details of an entity commission. */
    readonly entityCommission?: Maybe<EntityCommission>;
    /** Use to fetch a list of entity commissions. */
    readonly entityCommissionList?: Maybe<EntityCommissionList>;
    /** Used to fetcha list of entities including an id, value and description. */
    readonly entityOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** EntityQuestion List */
    readonly entityQuestionList?: Maybe<EntityQuestionListResponse>;
    readonly entitySecret?: Maybe<EntitySecret>;
    /** EntitySystemTagAssociation list. */
    readonly entitySystemTagAssociationList?: Maybe<SystemTagList>;
    /** Used to fetch a list of epics option */
    readonly epicOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch a list of geo coordinate place */
    readonly geoCoordinatePlaceOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    readonly hierarchyCurationList?: Maybe<HierarchyCurationListType>;
    /** EntityComment list. */
    readonly hierarchyList?: Maybe<HierarchyList>;
    readonly iconList: IconList;
    /** Used to fetch list of languages name including an id, and value. */
    readonly languageOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    readonly languageStaticTranslationLanguages?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Language Static Translation List */
    readonly languageStaticTranslationList?: Maybe<LanguageStaticTranslationListResponse>;
    readonly languageStaticTranslationScopes?: Maybe<ReadonlyArray<Maybe<Option>>>;
    readonly languageStaticTranslationVerified?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Message List */
    readonly messageList?: Maybe<MessageList>;
    /** Used to fetch list of products including an id, value and description. */
    readonly myProductOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch a list of onboarding request. */
    readonly onboardRequestList?: Maybe<OnboardRequestListResponse>;
    /** Operator */
    readonly operator?: Maybe<Operator>;
    /** OperatorGroup */
    readonly operatorGroup?: Maybe<OperatorGroup>;
    /** OperatorGroup List */
    readonly operatorGroupList?: Maybe<OperatorGroupList>;
    /** Used to fetch list of operator groups including an id, and value. */
    readonly operatorGroupOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Operator List */
    readonly operatorList?: Maybe<OperatorList>;
    /** Used to fetch list of operators including an id, and value. */
    readonly operatorOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Partner */
    readonly partner?: Maybe<Partner>;
    /** Partner Channel */
    readonly partnerChannel?: Maybe<PartnerChannel>;
    /** Use to fetch the details of a partner channel booking question. */
    readonly partnerChannelBookingQuestion?: Maybe<PartnerChannelBookingQuestion>;
    /** Use to fetch a list of partner channel booking questions. */
    readonly partnerChannelBookingQuestionList?: Maybe<PartnerChannelBookingQuestionListResponse>;
    /** PartnerChannel List */
    readonly partnerChannelList?: Maybe<PartnerChannelListResponse>;
    /** Used to fetch a list of partner including an id, value and description. */
    readonly partnerChannelOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Partner List */
    readonly partnerList?: Maybe<PartnerList>;
    /** Used to fetch a list of partner including an id, value and description. */
    readonly partnerOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch the state of the supplier payment onboaring process */
    readonly paymentAccount?: Maybe<SupplierPricingCategory>;
    /** Upsert the ProductAttribute */
    readonly paymentAccountRequest?: Maybe<PaymentOnboarding>;
    /** Used to search photos from Pexels */
    readonly pexelsPhotoList: PexelsPhotoList;
    /** Use to fetch the extended data for a single Place. The criteria includes a required placeId. */
    readonly place?: Maybe<Place>;
    /** Use to fetch the extended data for a single Place. The criteria includes a required placeId. */
    readonly placeAlias?: Maybe<PlaceAlias>;
    /** Place Alias List */
    readonly placeAliasList?: Maybe<PlaceAliasListResponse>;
    /** Use to fetch a list of places. Places represent Cities/Towns, Countries, Regions and more. Product are linked to places so so the is of a place can be used as a filter on the productList query. */
    readonly placeList?: Maybe<PlaceListResponse>;
    /** Used to fetch list of place name including an id, and value. */
    readonly placeOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch list of place subType including an id, and value. */
    readonly placeSubTypeOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch list of place type including an id, and value. */
    readonly placeTypeOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch the extended data for a single PlaygroundQuery. The criteria includes a required playgroundQueryId. */
    readonly playgroundQuery?: Maybe<PlaygroundQuery>;
    /** Used to fetch a list of playgroundQuery group name Options including an id and value. */
    readonly playgroundQueryGroupNameOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** PlaygroundQuery list. */
    readonly playgroundQueryList?: Maybe<PlaygroundQueryList>;
    /** Used to fetch a list of playgroundQuery Options including an id and value. */
    readonly playgroundQueryTypeOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Private Root */
    readonly private?: Maybe<PrivateQuery>;
    /** Use to fetch the extended data for a single Product. The criteria includes a required productId. */
    readonly product: Product;
    /** Use to fetch the extended data for a product attachment. The criteria includes a required productId. */
    readonly productAttachment?: Maybe<ProductAttachment>;
    /** Product Attachment List */
    readonly productAttachmentList?: Maybe<ProductAttachmentListResponse>;
    /** Use to fetch the extended data for a single ProductAttribute. The criteria includes a required productAttributeId. */
    readonly productAttribute?: Maybe<ProductAttribute>;
    /** Use to fetch the extended data for a single ProductAttributeAssociation. The criteria includes a required productAttributeAssociationId. */
    readonly productAttributeAssociation?: Maybe<ProductAttributeAssociation>;
    /** ProductAttributeAssociation list. */
    readonly productAttributeAssociationList?: Maybe<ProductAttributeAssociationList>;
    /** ProductAttribute list. */
    readonly productAttributeList?: Maybe<ProductAttributeList>;
    /** Used to fetch a list of ProductAttribute including an id, value and description. */
    readonly productAttributeOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch a single ProductAvailabilityPattern. The criteria includes a required productAvailabilityPatternId. */
    readonly productAvailabilityPattern?: Maybe<ProductAvailabilityPattern>;
    /** Product AvailabilityPattern Pattern List. */
    readonly productAvailabilityPatternList?: Maybe<ProductAvailabilityPatternList>;
    /** Product Meta List */
    readonly productAvailabilityPatternStartTimeInstanceList?: Maybe<ProductAvailabilityPatternStartTimeInstanceList>;
    /** Use to fetch the extended data for a single ProductCancellationPenalty. The criteria includes a required productCancellationPenaltyId. */
    readonly productCancellationPenalty?: Maybe<ProductCancellationPenalty>;
    /** ProductCancellationPenalty list. */
    readonly productCancellationPenaltyList?: Maybe<ProductCancellationPenaltyList>;
    /** Use to fetch the extended data for a single ProductCategory. The criteria includes a required productCategoryId. */
    readonly productCategory?: Maybe<ProductCategory>;
    /** Use to fetch the details of a ProductCategoryAssociation. */
    readonly productCategoryAssociation?: Maybe<ProductCategoryAssociation>;
    /** ProductCategoryAssociation List */
    readonly productCategoryAssociationList?: Maybe<ProductCategoryAssociationList>;
    /** ProductCategory List */
    readonly productCategoryList?: Maybe<ProductCategoryListResponse>;
    /** Used to fetch a list of ProductCategory including an id, value and description. */
    readonly productCategoryOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch the extended data for a single ProductContent. The criteria includes a required productContentId. */
    readonly productContent?: Maybe<ProductContent>;
    /** Product Content List */
    readonly productContentList?: Maybe<ProductContentListResponse>;
    /** Used to fetch a list of product content types including an id and value. */
    readonly productContentTypeOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch the extended data for a single Product. The criteria includes a required productId. */
    readonly productDetail?: Maybe<Product>;
    /** Use to fetch the extended data for a single ProductImage. The criteria includes a required productImageId. */
    readonly productImage?: Maybe<ProductImage>;
    /** Product Image List */
    readonly productImageList?: Maybe<ProductImageListResponse>;
    /** Used to fetch a list of product image types including an id and value. */
    readonly productImageTypeOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch a single ProductInventory. The criteria includes a required productInventoryId. */
    readonly productInventory?: Maybe<ProductInventory>;
    /** Product Inventory List. */
    readonly productInventoryList?: Maybe<ProductInventoryList>;
    /** Use to fetch a list of product. The query support pagination and will accept a wide range of filters from a simple search to more complex filters covering locations, types and attributes. */
    readonly productList?: Maybe<ProductList>;
    /** Use to fetch the extended data for a single ProductMeta. The criteria includes a required productMetaId. */
    readonly productMeta?: Maybe<ProductMeta>;
    /** Product Meta List */
    readonly productMetaList?: Maybe<ProductMetaListResponse>;
    /** Used to fetch list of product including an id, value and description. */
    readonly productOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch list of product permission including a value */
    readonly productPermissionOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch a list of product pricing variant options. */
    readonly productPricingVariantOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch the details of a productReview. */
    readonly productReview?: Maybe<ProductReview>;
    /** Use to fetch a list of productReviews. */
    readonly productReviewList?: Maybe<ProductReviewListType>;
    /** Use to fetch the extended data for a single Product sale restriction. The criteria includes a required productSaleRestrictionId. */
    readonly productSaleRestriction?: Maybe<ProductSaleRestriction>;
    /** Product Sale Restriction List */
    readonly productSaleRestrictionList?: Maybe<ProductSaleRestrictionListResponse>;
    /** Product Sale Restriction List */
    readonly productSaleRestrictionSummaryList?: Maybe<ProductSaleRestrictionSummaryListResponse>;
    /** Used to fetch a list of ProductSaleRestriction type including an id, value and description. */
    readonly productSaleRestrictionTypeOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch the extended data for a single ProductStartTime. The criteria includes a required productStartTimeId. */
    readonly productStartTime?: Maybe<ProductStartTime>;
    /** Product StartTime List by Adam */
    readonly productStartTimeList?: Maybe<ProductStartTimeList>;
    /** Used to fetch list of product staert time options. */
    readonly productStartTimeOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch a list of all suppliers including an id, value and description. */
    readonly productSupplierOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch the extended data for a single ProductTranslation. The criteria includes a required productTranslationId. */
    readonly productTranslation?: Maybe<ProductTranslation>;
    /** Product Translation List */
    readonly productTranslationList?: Maybe<ProductTranslationListResponse>;
    /** Used to fetch list of product types including an id, value and description. */
    readonly productTypeOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch the extended data for a single Provider. The criteria includes a required providerId. */
    readonly provider?: Maybe<Provider>;
    /** Provider list. */
    readonly providerList?: Maybe<ProviderList>;
    readonly questionList: QuestionList;
    /** Used to fetch list of question and its types */
    readonly questionOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetcha list of geographical regions including an id, value and description. */
    readonly regionOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch a list of district for available restaurants */
    readonly restaurantDistrictOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Restaurant list. */
    readonly restaurantList?: Maybe<RestaurantList>;
    /** Provider list. */
    readonly restaurantProductAssociationList?: Maybe<ProductList>;
    /** Used to fetch a list of restaurant suppliers */
    readonly restaurantSupplierOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to get A signed URL for file upload.  The URL will be valid only for a short time. */
    readonly s3SignedDownloadUrl?: Maybe<S3SignedDownloadUrl>;
    /** Used to get A signed URL for file download. The URL will be valid only for a short time. */
    readonly s3SignedUploadUrl?: Maybe<S3SignedUploadUrl>;
    readonly saleAgency?: Maybe<SaleAgency>;
    /** Use to fetch a list of sale agencies */
    readonly saleAgencyList?: Maybe<SaleAgencyList>;
    /**  Sale Restriction Roll Up List */
    readonly saleRestrictionRollUpList?: Maybe<SaleRestrictionRollUpListResponse>;
    /** Use to fetch a length limited list of product, articles, cities and categories that match the given search terms. Note: this query is designed to populate a search bar in the UI in response to user entry and the result will contain only the highest priority matches - it will not return an exhaustive list. */
    readonly search?: Maybe<SearchList>;
    readonly searchAutoComplete?: Maybe<ReadonlyArray<SearchAutoComplete>>;
    readonly securityPrincipalAuthenticationMethodOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch a list of security principals. */
    readonly securityPrincipalHierarchyList?: Maybe<SecurityPrincipalHierarchyListType>;
    /** Used to fetch a list of securityPermissions including an id, value and description. */
    readonly securityPrincipalPermissionOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch a list of security principal types. */
    readonly securityPrincipalTypeOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch the extended data for a single SecurityPrincipalUser. The criteria includes a required securityPrincipalUserId. */
    readonly securityPrincipalUser?: Maybe<SecurityPrincipalUser>;
    /** SecurityPrincipalUser List */
    readonly securityPrincipalUserList?: Maybe<SecurityPrincipalUserListResponse>;
    /** Used to fetch a list of securityPrincipleUsers that are visible to the viewer. */
    readonly securityPrincipalUserOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to validate a SecurityPrincipalUser reset password request */
    readonly securityPrincipalUserResetPasswordRequestValidate?: Maybe<SecurityPrincipalUserResetPasswordRequestValidateResponse>;
    /** Use to fetch the data for a SecurityPrincipalUserSignup */
    readonly securityPrincipalUserSignup?: Maybe<SecurityPrincipalUserSignup>;
    /** Use to fetch the extended data for a single SecurityRule. The criteria includes a required securityRuleId. */
    readonly securityRule?: Maybe<SecurityRule>;
    /** Used to fetch a list of Security Rule Domains Options including an id, value and description. */
    readonly securityRuleDomainOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** SecurityRule list. */
    readonly securityRuleList?: Maybe<SecurityRuleList>;
    /** Used to fetch list of security rules including an id, value and description. */
    readonly securityRuleOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch the extended data for a single SecurityRule. The criteria includes a required securityRuleId. */
    readonly securityRuleWhereStatementValidation?: Maybe<SecurityRuleWhereStatementValidation>;
    readonly sitemapList?: Maybe<SitemapList>;
    /** Get a stripe payment intent */
    readonly stripePaymentIntent?: Maybe<StripePaymentIntent>;
    /** Supplier */
    readonly supplier?: Maybe<Supplier>;
    /** Supplier List */
    readonly supplierList?: Maybe<SupplierList>;
    /** Used to fetch a list of suppliers including an id, value and description. */
    readonly supplierOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch a list of supplier pricing category options. */
    readonly supplierPricingCategoryOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch the extended data for a single Synchronisation report. The criteria includes a required synchronisationReportId. */
    readonly synchronisationReport?: Maybe<SynchronisationReport>;
    /** Synchronisation Report List */
    readonly synchronisationReportList?: Maybe<SynchronisationReportListResponse>;
    /** Used to fetcha list of synchronisation Report Statuses including an id and value. */
    readonly synchronisationReportStatusOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch a list of synchronisationSource including an id, value and description. */
    readonly synchronisationSourceOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    readonly systemConfiguration?: Maybe<SystemConfiguration>;
    /** Used to fetch list of SystemConfiguration options including an id, value and description. */
    readonly systemConfigurationOptions?: Maybe<ReadonlyArray<Maybe<SystemConfigurationOption>>>;
    /** Used to fetch list of SystemIcon options including an id and name. */
    readonly systemIconOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch the extended data for a single SystemLog. The criteria includes a required systemLogId. */
    readonly systemLog?: Maybe<SystemLog>;
    /** Used to fetch list of system log message levels including an id, value and description. */
    readonly systemLogLevelOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** SystemLog list. */
    readonly systemLogList?: Maybe<SystemLogList>;
    /** Used to fetch list of system log services including an id, value and description. */
    readonly systemLogServiceOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch list of system log message levels including an id, value and description. */
    readonly systemLogSpanDurationOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch list of system log message levels including an id, value and description. */
    readonly systemLogStageOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch a list of saved system page states */
    readonly systemPageStateList: SystemPageStateList;
    /** Used to retrieve a list of system tags records. */
    readonly systemTagList?: Maybe<SystemTagList>;
    /** Used to fetch a list of SystemTag including an id, value, type and description. */
    readonly systemTagOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to fetch the extended data for a single SystemTemplate. The criteria includes a required systemTemplateId. */
    readonly systemTemplate?: Maybe<SystemTemplate>;
    /** SystemTemplate list. */
    readonly systemTemplateList?: Maybe<SystemTemplateList>;
    /** ProductAttribute list. */
    readonly taxonomyAttributeList?: Maybe<ProductAttributeList>;
    /** Use to fetch the details of a taxonomy term. */
    readonly taxonomyTerm?: Maybe<TaxonomyTerm>;
    /** Use to fetch a list of taxonomy terms. */
    readonly taxonomyTermList?: Maybe<TaxonomyTermListResponse>;
    /** Use to fetch the details of a taxonomy term match. */
    readonly taxonomyTermMatch?: Maybe<TaxonomyTermMatch>;
    /** Use to fetch a list of taxonomy term matches . */
    readonly taxonomyTermMatchList?: Maybe<TaxonomyTermMatchListResponse>;
    /** Used to fetcha list of users including an id, value and description. */
    readonly usernameOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Use to validate username */
    readonly usernameValidate?: Maybe<UsernameValidateResponse>;
    /** Use to fetch details relating to vault. */
    readonly vault?: Maybe<Vault>;
    /** Use to fetch details relating to YOU, the currently authenticated user. */
    readonly viewer: Viewer;
    /** Use for testing connection and authentication. This simple query is provided for test purposes and will return a welcome message, and the current server time. Use this only to confirm that you are connecting to a authenticating to the API. */
    readonly welcome?: Maybe<Scalars["JSON"]["output"]>;
    /** workstreamAssignNext */
    readonly workstreamAssignNext?: Maybe<WorkstreamAssignNext>;
    /** WorkstreamAssociation list. */
    readonly workstreamAssociationList?: Maybe<WorkstreamAssociationList>;
    /** Workstream List */
    readonly workstreamList?: Maybe<WorkstreamListResponse>;
    /** Used to fetch a list of workstreamPriority including an id, value and description. */
    readonly workstreamPrioritySystemEnumerationOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch a list of WorkSteam projectPrefixes including an id, value and label. */
    readonly workstreamProjectPrefixOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch a list of workstreamPriority including an id, value and description. */
    readonly workstreamStateSystemEnumerationOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch a list of workstreamSystem including an id, value and description. */
    readonly workstreamSystemTagOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
    /** Used to fetch a list of SystemTag including an id, value and description. */
    readonly workstreamTypeSystemEnumerationOptions?: Maybe<ReadonlyArray<Maybe<Option>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesAnalyticLinkOriginListArgs = {
    filter?: InputMaybe<AnalyticLinkOriginListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<AnalyticLinkOriginListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesApiDocumentationListArgs = {
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesArticleArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesArticleListArgs = {
    filter?: InputMaybe<ArticleListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ArticleListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesArticleTypeOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesAssetUploadArgs = {
    input?: InputMaybe<AssetInput>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesAuthUserByAccountArgs = {
    provider: Scalars["String"]["input"];
    providerAccountId: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesAuthUserByEmailArgs = {
    email: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesAuthUserByIdArgs = {
    id: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesAvailabilityArgs = {
    autoFillOptions?: InputMaybe<Scalars["Boolean"]["input"]>;
    id: Scalars["String"]["input"];
    input?: InputMaybe<AvailabilityInput>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesAvailabilityListArgs = {
    autoFillOptions?: InputMaybe<Scalars["Boolean"]["input"]>;
    filter?: InputMaybe<AvailabilityListFilter>;
    optionList?: InputMaybe<ReadonlyArray<InputMaybe<AvailabilityListOptionListItemInput>>>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    productId: Scalars["String"]["input"];
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sessionId?: InputMaybe<Scalars["ID"]["input"]>;
    sort?: InputMaybe<AvailabilityListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBookingArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
    input?: InputMaybe<BookingInput>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBookingAvailabilityArgs = {
    id: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBookingAvailabilityConsumptionArgs = {
    id: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBookingAvailabilityExtendedArgs = {
    id: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBookingAvailabilityFinanceListArgs = {
    filter?: InputMaybe<BookingFinanceListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBookingAvailabilityListArgs = {
    filter?: InputMaybe<BookingAvailabilityListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<BookingAvailabilityListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBookingAvailabilityPersonArgs = {
    id: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBookingAvailabilityStateOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBookingEventsArgs = {
    filter?: InputMaybe<BookingEventsFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBookingListArgs = {
    filter?: InputMaybe<BookingListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<BookingListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBookingOriginTypeOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBookingStateOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBookingVoucherArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBrandArgs = {
    id: Scalars["ID"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBrandContentTypeOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBrandListArgs = {
    filter?: InputMaybe<BrandListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<BrandListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBrandOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBreadcrumbListArgs = {
    items: ReadonlyArray<Scalars["Hashids"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBusinessPrincipalArgs = {
    id: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesBusinessPrincipalParentOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesCityOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesConsumerArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesConsumerBookingHierarchyListArgs = {
    filter?: InputMaybe<ConsumerBookingHierarchyListFilter>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesConsumerListArgs = {
    filter?: InputMaybe<ConsumerListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ConsumerListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesConsumerTripArgs = {
    id: Scalars["ID"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesConsumerTripAvailabilityPersonListArgs = {
    filter?: InputMaybe<ConsumerTripAvailabilityPersonListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ConsumerTripAvailabilityPersonListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesConsumerTripByCodeArgs = {
    code: Scalars["Hashids"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesConsumerTripContentArgs = {
    consumerTripId: Scalars["String"]["input"];
    placeId: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesConsumerTripEventListArgs = {
    filter?: InputMaybe<ConsumerTripEventListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesConsumerTripListArgs = {
    filter?: InputMaybe<ConsumerTripListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ConsumerTripListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesConsumerTripRecentlyViewedProductListArgs = {
    consumerTripId: Scalars["String"]["input"];
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesConsumerTripRecommendedProductListArgs = {
    consumerTripId: Scalars["ID"]["input"];
    placeId: Scalars["ID"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesContentComponentArgs = {
    id: Scalars["ID"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesContentComponentListArgs = {
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesCountryOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesCuisineTagOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesCurationArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesCurationContentArgs = {
    curationId?: InputMaybe<Scalars["Hashids"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesCurationContentLegacyArgs = {
    curationId: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesCurationListArgs = {
    filter?: InputMaybe<CurationListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<CurationListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesCurationOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDataTypeOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDestinationThemeCurationAssociationOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelAuthorListArgs = {
    filter?: InputMaybe<DistributionChannelAuthorListFilter>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<DistributionChannelAuthorListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelBlogAuthorListArgs = {
    filter?: InputMaybe<DistributionChannelBlogAuthorListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<DistributionChannelAuthorListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelBlogCategoryListArgs = {
    filter?: InputMaybe<DistributionChannelBlogCategoryListFilter>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelBlogPostListArgs = {
    filter?: InputMaybe<DistributionChannelBlogPostListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<DistributionChannelPageListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelContentArgs = {
    consumerTripId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelCurationOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelCurationParentOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelHomePageContentArgs = {
    consumerTripId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelListArgs = {
    filter?: InputMaybe<DistributionChannelListFilter>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<DistributionChannelListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelPageAuthorOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelPageCategoryListArgs = {
    filter?: InputMaybe<DistributionChannelPageCategoryListFilter>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<DistributionChannelPageCategoryListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelPageCategoryOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelPageContentArgs = {
    path: Scalars["String"]["input"];
    type?: InputMaybe<DistributionChannelPageTypeType>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelPageListArgs = {
    filter?: InputMaybe<DistributionChannelPageListFilter>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<DistributionChannelPageListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelPageOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelRedirectUrlPathArgs = {
    path: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelRedirectUrlPathNewArgs = {
    path: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelTranslationExportListArgs = {
    filter: TranslationExportListFilter;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesDistributionChannelTranslationImportListArgs = {
    filter: TranslationImportListFilter;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesEntityCommentArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesEntityCommentListArgs = {
    filter?: InputMaybe<EntityCommentListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesEntityCommissionArgs = {
    id: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesEntityCommissionListArgs = {
    filter?: InputMaybe<EntityCommissionListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<EntityCommissionListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesEntityOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesEntityQuestionListArgs = {
    filter?: InputMaybe<EntityQuestionListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<EntityQuestionListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesEntitySecretArgs = {
    hierarchyLevel: EntitySecretHierarchyLevel;
    id: Scalars["ID"]["input"];
    secretType: EntitySecretType;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesEntitySystemTagAssociationListArgs = {
    filter?: InputMaybe<SystemTagListFilter>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesEpicOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesGeoCoordinatePlaceOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesHierarchyCurationListArgs = {
    filter?: InputMaybe<HierarchyCurationListFilterType>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesHierarchyListArgs = {
    filter?: InputMaybe<HierarchyListFilter>;
    isSelectedCriteria?: InputMaybe<HierarchyIsSelectedCriteriaType>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<HierarchyListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesIconListArgs = {
    filter?: InputMaybe<IconListFilter>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesLanguageOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesLanguageStaticTranslationLanguagesArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesLanguageStaticTranslationListArgs = {
    filter?: InputMaybe<LanguageStaticTranslationListFilter>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesLanguageStaticTranslationScopesArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesLanguageStaticTranslationVerifiedArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesMessageListArgs = {
    filter?: InputMaybe<MessageListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesMyProductOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesOnboardRequestListArgs = {
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesOperatorArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesOperatorGroupArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesOperatorGroupListArgs = {
    filter?: InputMaybe<OperatorGroupListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<OperatorGroupListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesOperatorGroupOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesOperatorListArgs = {
    filter?: InputMaybe<OperatorListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<OperatorListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesOperatorOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPartnerArgs = {
    id: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPartnerChannelArgs = {
    id: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPartnerChannelBookingQuestionArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPartnerChannelBookingQuestionListArgs = {
    filter?: InputMaybe<PartnerChannelBookingQuestionListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<PartnerChannelBookingQuestionListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPartnerChannelListArgs = {
    filter?: InputMaybe<PartnerChannelListFilter>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<PartnerChannelListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPartnerChannelOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPartnerListArgs = {
    filter?: InputMaybe<PartnerListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<PartnerListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPartnerOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPexelsPhotoListArgs = {
    filter?: InputMaybe<PexelsPhotoListFilter>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPlaceArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPlaceAliasArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPlaceAliasListArgs = {
    filter?: InputMaybe<PlaceAliasListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<PlaceAliasListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPlaceListArgs = {
    filter?: InputMaybe<PlaceListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<PlaceListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPlaceOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPlaceSubTypeOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPlaceTypeOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPlaygroundQueryArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPlaygroundQueryGroupNameOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPlaygroundQueryListArgs = {
    filter?: InputMaybe<PlaygroundQueryListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesPlaygroundQueryTypeOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductArgs = {
    code?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    slug?: InputMaybe<Scalars["String"]["input"]>;
    useOpenSearch?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductAttachmentArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductAttachmentListArgs = {
    filter?: InputMaybe<ProductAttachmentListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ProductAttachmentListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductAttributeArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductAttributeAssociationArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductAttributeAssociationListArgs = {
    filter?: InputMaybe<ProductAttributeAssociationListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductAttributeListArgs = {
    filter?: InputMaybe<ProductAttributeListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductAttributeOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductAvailabilityPatternArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductAvailabilityPatternListArgs = {
    filter?: InputMaybe<ProductAvailabilityPatternListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductAvailabilityPatternStartTimeInstanceListArgs = {
    filter?: InputMaybe<ProductAvailabilityPatternStartTimeInstanceListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductCancellationPenaltyArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductCancellationPenaltyListArgs = {
    filter?: InputMaybe<ProductCancellationPenaltyListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductCategoryArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductCategoryAssociationArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductCategoryAssociationListArgs = {
    filter?: InputMaybe<ProductCategoryAssociationListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductCategoryListArgs = {
    filter?: InputMaybe<ProductCategoryListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ProductCategoryListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductCategoryOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductContentArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductContentListArgs = {
    filter?: InputMaybe<ProductContentListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ProductContentListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductContentTypeOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductDetailArgs = {
    id: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductImageArgs = {
    contentType?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductImageListArgs = {
    filter?: InputMaybe<ProductImageListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ProductImageListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductImageTypeOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductInventoryArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductInventoryListArgs = {
    filter?: InputMaybe<ProductInventoryListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductListArgs = {
    filter?: InputMaybe<ProductListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ProductListSort>;
    useOpenSearch?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductMetaArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductMetaListArgs = {
    filter?: InputMaybe<ProductMetaListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ProductMetaListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductPermissionOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductPricingVariantOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductReviewArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductReviewListArgs = {
    filter?: InputMaybe<ProductReviewListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ProductReviewListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductSaleRestrictionArgs = {
    id: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductSaleRestrictionListArgs = {
    filter?: InputMaybe<ProductSaleRestrictionListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ProductSaleRestrictionListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductSaleRestrictionSummaryListArgs = {
    filter?: InputMaybe<ProductSaleRestrictionSummaryListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ProductSaleRestrictionSummaryListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductSaleRestrictionTypeOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductStartTimeArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductStartTimeListArgs = {
    filter?: InputMaybe<ProductStartTimeListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductStartTimeOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductSupplierOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductTranslationArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductTranslationListArgs = {
    filter?: InputMaybe<ProductTranslationListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ProductTranslationListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProductTypeOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProviderArgs = {
    id: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesProviderListArgs = {
    filter?: InputMaybe<ProviderListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ProviderListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesQuestionListArgs = {
    filter?: InputMaybe<QuestionListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<QuestionListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesQuestionOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesRegionOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesRestaurantDistrictOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesRestaurantListArgs = {
    filter?: InputMaybe<RestaurantListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesRestaurantProductAssociationListArgs = {
    filter?: InputMaybe<RestaurantProductAssociationListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesRestaurantSupplierOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesS3SignedDownloadUrlArgs = {
    key: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesS3SignedUploadUrlArgs = {
    contentType: Scalars["String"]["input"];
    extension?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSaleAgencyArgs = {
    id: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSaleAgencyListArgs = {
    filter?: InputMaybe<SaleAgencyListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<SaleAgencyListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSaleRestrictionRollUpListArgs = {
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSearchArgs = {
    query?: InputMaybe<Scalars["String"]["input"]>;
    useOpenSearch?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSearchAutoCompleteArgs = {
    phrase: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSecurityPrincipalAuthenticationMethodOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSecurityPrincipalHierarchyListArgs = {
    filter?: InputMaybe<SecurityPrincipalHierarchyListFilter>;
    isSelectedCriteria?: InputMaybe<HierarchyIsSelectedCriteriaType>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<SecurityPrincipalHierarchyListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSecurityPrincipalPermissionOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSecurityPrincipalTypeOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSecurityPrincipalUserArgs = {
    id: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSecurityPrincipalUserListArgs = {
    filter?: InputMaybe<SecurityPrincipalUserListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<SecurityPrincipalUserListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSecurityPrincipalUserOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSecurityPrincipalUserResetPasswordRequestValidateArgs = {
    passwordResetCode: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSecurityPrincipalUserSignupArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSecurityRuleArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSecurityRuleDomainOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSecurityRuleListArgs = {
    filter?: InputMaybe<SecurityRuleListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSecurityRuleOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSecurityRuleWhereStatementValidationArgs = {
    domain: Scalars["String"]["input"];
    whereStatement: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSitemapListArgs = {
    index?: InputMaybe<Scalars["NonNegativeInt"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesStripePaymentIntentArgs = {
    bookingSelector: BookingSelector;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSupplierArgs = {
    id: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSupplierListArgs = {
    filter?: InputMaybe<SupplierListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<SupplierListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSupplierOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSupplierPricingCategoryOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSynchronisationReportArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSynchronisationReportListArgs = {
    filter?: InputMaybe<SynchronisationReportListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<SynchronisationReportListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSynchronisationReportStatusOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSynchronisationSourceOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSystemConfigurationArgs = {
    id: Scalars["ID"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSystemConfigurationOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSystemIconOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSystemLogArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSystemLogLevelOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSystemLogListArgs = {
    filter?: InputMaybe<SystemLogListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSystemLogServiceOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSystemLogSpanDurationOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSystemLogStageOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSystemPageStateListArgs = {
    pageKey: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSystemTagListArgs = {
    filter?: InputMaybe<SystemTagListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<SystemTagListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSystemTagOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSystemTemplateArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
    templateData?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesSystemTemplateListArgs = {
    filter?: InputMaybe<SystemTemplateListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesTaxonomyAttributeListArgs = {
    filter?: InputMaybe<ProductAttributeListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesTaxonomyTermArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesTaxonomyTermListArgs = {
    filter?: InputMaybe<TaxonomyTermListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<TaxonomyTermListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesTaxonomyTermMatchArgs = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesTaxonomyTermMatchListArgs = {
    filter?: InputMaybe<TaxonomyTermMatchListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<TaxonomyTermMatchListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesUsernameOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesUsernameValidateArgs = {
    username: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesVaultArgs = {
    contentType?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["String"]["input"];
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesWorkstreamAssignNextArgs = {
    entityType: Scalars["String"]["input"];
    projectPrefix?: InputMaybe<ProductQualityProjectPrefix>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesWorkstreamAssociationListArgs = {
    filter?: InputMaybe<WorkstreamAssociationListFilter>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<WorkstreamAssociationListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesWorkstreamListArgs = {
    filter?: InputMaybe<WorkstreamListFilter>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<WorkstreamListSort>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesWorkstreamPrioritySystemEnumerationOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesWorkstreamProjectPrefixOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesWorkstreamStateSystemEnumerationOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesWorkstreamSystemTagOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Queries are used to get data from the API. Generally, queries are passed one or more criteria that control the source of the data and a list of nodes that you want the query to return. You can learn more about the criteria and available nodes by looking at the documentation for a specific query. */
export type QueriesWorkstreamTypeSystemEnumerationOptionsArgs = {
    domain?: InputMaybe<SecurityDomainType>;
    filter?: InputMaybe<OptionsFilter>;
    search?: InputMaybe<Scalars["String"]["input"]>;
    typeahead?: InputMaybe<Scalars["Boolean"]["input"]>;
    values?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Question = {
    readonly __typename?: "Question";
    readonly configuration?: Maybe<QuestionConfiguration>;
    readonly createdAt: Scalars["Date"]["output"];
    readonly helper?: Maybe<Scalars["String"]["output"]>;
    readonly helperTranslationList?: Maybe<LanguageTranslation>;
    readonly id: Scalars["ID"]["output"];
    readonly label: Scalars["String"]["output"];
    readonly labelTranslationList: LanguageTranslation;
    readonly type: QuestionType;
    readonly updatedAt?: Maybe<Scalars["Date"]["output"]>;
};

export type QuestionConfiguration = QuestionConfigurationFile | QuestionConfigurationOptions;

export type QuestionConfigurationFile = {
    readonly __typename?: "QuestionConfigurationFile";
    readonly fileType: ReadonlyArray<QuestionFileType>;
};

export type QuestionConfigurationOptions = {
    readonly __typename?: "QuestionConfigurationOptions";
    readonly isSelectMultiple: Scalars["Boolean"]["output"];
    readonly options: ReadonlyArray<QuestionOption>;
};

export type QuestionCreateInput = {
    readonly fileType?: InputMaybe<ReadonlyArray<QuestionFileType>>;
    readonly helperTranslationList?: InputMaybe<LanguageTranslationInput>;
    readonly isSelectMultiple?: InputMaybe<Scalars["Boolean"]["input"]>;
    readonly labelTranslationList: LanguageTranslationInput;
    readonly options?: InputMaybe<ReadonlyArray<QuestionOptionInput>>;
    readonly type: QuestionType;
};

export enum QuestionFileType {
    Image = "image",
    PortableDocument = "portableDocument",
}

export type QuestionList = {
    readonly __typename?: "QuestionList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<Question>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type QuestionListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QuestionListFilter = {
    readonly idList?: InputMaybe<ReadonlyArray<Scalars["ID"]["input"]>>;
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuestionListSort = {
    readonly label?: InputMaybe<SortDirection>;
};

export type QuestionOption = {
    readonly __typename?: "QuestionOption";
    readonly createdAt: Scalars["Date"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly label: Scalars["String"]["output"];
    readonly labelTranslationList: LanguageTranslation;
    readonly updatedAt?: Maybe<Scalars["Date"]["output"]>;
    readonly value?: Maybe<Scalars["JSON"]["output"]>;
};

export type QuestionOptionInput = {
    readonly labelTranslationList: LanguageTranslationInput;
    readonly value?: InputMaybe<Scalars["JSON"]["input"]>;
};

export enum QuestionType {
    Accept = "ACCEPT",
    Boolean = "BOOLEAN",
    Date = "DATE",
    EmailAddress = "EMAIL_ADDRESS",
    File = "FILE",
    LongText = "LONG_TEXT",
    Options = "OPTIONS",
    PhoneNumber = "PHONE_NUMBER",
    Text = "TEXT",
}

export type QuestionUpdateInput = {
    readonly fileType?: InputMaybe<ReadonlyArray<QuestionFileType>>;
    readonly helperTranslationList?: InputMaybe<LanguageTranslationInput>;
    readonly id: Scalars["ID"]["input"];
    readonly isSelectMultiple?: InputMaybe<Scalars["Boolean"]["input"]>;
    readonly labelTranslationList: LanguageTranslationInput;
    readonly options?: InputMaybe<ReadonlyArray<QuestionOptionInput>>;
    readonly type: QuestionType;
};

export type RawIconDefinition = {
    readonly __typename?: "RawIconDefinition";
    readonly height: Scalars["Int"]["output"];
    readonly iconName: Scalars["String"]["output"];
    readonly path: Scalars["String"]["output"];
    readonly prefix: Scalars["String"]["output"];
    readonly width: Scalars["Int"]["output"];
};

export type RecordsFromTemplateInputType = {
    /** Sort by name */
    readonly args?: InputMaybe<Scalars["JSON"]["input"]>;
    /** The entity temaplate Id */
    readonly templateId?: InputMaybe<Scalars["String"]["input"]>;
};

export type RefreshCacheInput = {
    readonly query: Scalars["String"]["input"];
    readonly type: RefreshCacheType;
};

export enum RefreshCacheType {
    ProductList = "PRODUCT_LIST",
    ProductListCount = "PRODUCT_LIST_COUNT",
}

/** This represents a ResponseMessage. */
export type ResponseMessage = {
    readonly __typename?: "ResponseMessage";
    /** The message content. */
    readonly content: Scalars["String"]["output"];
    /** The id. */
    readonly id: Scalars["String"]["output"];
};

export type RestauranSocialLinkList = {
    readonly __typename?: "RestauranSocialLinkList";
    readonly nodes: ReadonlyArray<Maybe<RestaurantSocialLinkListNodes>>;
};

/** This represents a Restaurant. */
export type Restaurant = {
    readonly __typename?: "Restaurant";
    /** The list of Actors with this Restaurant. */
    readonly actorList?: Maybe<RestaurantActorAssociationList>;
    /** The full address of the Restaurant. */
    readonly addressName?: Maybe<Scalars["String"]["output"]>;
    /** The averagePrice for the Restaurant */
    readonly averagePrice?: Maybe<Scalars["Int"]["output"]>;
    readonly description?: Maybe<Scalars["String"]["output"]>;
    readonly district?: Maybe<Scalars["String"]["output"]>;
    readonly enquiryEmail?: Maybe<Scalars["String"]["output"]>;
    readonly hashtags?: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
    /** The unique id for the Restaurant. */
    readonly id: Scalars["String"]["output"];
    /** The list of image attachments with this Restaurant. */
    readonly imageAttachmentList?: Maybe<RestaurantImageAttachmentsList>;
    /** The list of menu attachments with this Restaurant. */
    readonly menuAttachmentList?: Maybe<RestaurantMenuAttachmentList>;
    /** The name. */
    readonly name: Scalars["String"]["output"];
    readonly openingHours?: Maybe<Scalars["String"]["output"]>;
    readonly peakHours?: Maybe<Scalars["String"]["output"]>;
    /** The list of social media links with this restaurant. */
    readonly primaryCuisine?: Maybe<RestaurantTagListNodeTagsByType>;
    /** The list of social media links with this restaurant. */
    readonly socialLinkList?: Maybe<RestauranSocialLinkList>;
    /** The list of SystemTags with this Restaurant. */
    readonly tagList?: Maybe<RestaurantTagList>;
    readonly website?: Maybe<Scalars["String"]["output"]>;
};

/** This represents a Restaurant. */
export type RestaurantDescriptionArgs = {
    contentType?: InputMaybe<DescriptionContentType>;
};

/** This represents a Restaurant. */
export type RestaurantImageAttachmentListArgs = {
    height?: InputMaybe<Scalars["Float"]["input"]>;
    width?: InputMaybe<Scalars["Float"]["input"]>;
};

/** This represents a Restaurant. */
export type RestaurantOpeningHoursArgs = {
    contentType?: InputMaybe<DescriptionContentType>;
};

/** This represents a Restaurant. */
export type RestaurantPeakHoursArgs = {
    contentType?: InputMaybe<DescriptionContentType>;
};

export type RestaurantActorAssociationList = {
    readonly __typename?: "RestaurantActorAssociationList";
    readonly nodes: ReadonlyArray<Maybe<RestaurantActorAssociationListNodes>>;
};

export type RestaurantActorAssociationListNodes = {
    readonly __typename?: "RestaurantActorAssociationListNodes";
    readonly email?: Maybe<Scalars["String"]["output"]>;
    readonly entityKey?: Maybe<Scalars["String"]["output"]>;
    readonly familyName?: Maybe<Scalars["String"]["output"]>;
    readonly givenName?: Maybe<Scalars["String"]["output"]>;
    readonly id: Scalars["String"]["output"];
    readonly name?: Maybe<Scalars["String"]["output"]>;
    readonly otherNames?: Maybe<Scalars["String"]["output"]>;
    readonly phone?: Maybe<Scalars["String"]["output"]>;
    readonly type?: Maybe<ActorTypeType>;
};

export type RestaurantAvailabilityFilter = {
    readonly date: Scalars["String"]["input"];
    readonly endTime?: InputMaybe<Scalars["String"]["input"]>;
    readonly peopleCount: Scalars["Int"]["input"];
    readonly startTime?: InputMaybe<Scalars["String"]["input"]>;
};

export type RestaurantImageAttachmentsList = {
    readonly __typename?: "RestaurantImageAttachmentsList";
    readonly nodes: ReadonlyArray<Maybe<RestaurantImageAttachmentsNodes>>;
};

export type RestaurantImageAttachmentsNodes = {
    readonly __typename?: "RestaurantImageAttachmentsNodes";
    readonly id: Scalars["String"]["output"];
    readonly url: Scalars["String"]["output"];
};

/** This represents a list of Restaurant. */
export type RestaurantList = {
    readonly __typename?: "RestaurantList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<Maybe<Restaurant>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type RestaurantListFilter = {
    /** filter by District/Neighbourhood. */
    readonly district?: InputMaybe<Scalars["String"]["input"]>;
    /** filter by Id. */
    readonly restaurantId?: InputMaybe<Scalars["String"]["input"]>;
    /** filter by list of Ids. */
    readonly restaurantIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** filter by list of Tag Ids. */
    readonly tagList?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

export type RestaurantMenuAttachmentList = {
    readonly __typename?: "RestaurantMenuAttachmentList";
    readonly nodes: ReadonlyArray<Maybe<RestaurantMenuAttachmentListNodes>>;
};

export type RestaurantMenuAttachmentListNodes = {
    readonly __typename?: "RestaurantMenuAttachmentListNodes";
    readonly id: Scalars["String"]["output"];
    readonly url: Scalars["String"]["output"];
};

export type RestaurantProductAssociationDelete = {
    readonly __typename?: "RestaurantProductAssociationDelete";
    readonly deleted: Scalars["Boolean"]["output"];
};

export type RestaurantProductAssociationDeleteInput = {
    readonly productIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    readonly restaurantIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

export type RestaurantProductAssociationListFilter = {
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** Filter by Provider Id. */
    readonly restaurantId?: InputMaybe<Scalars["String"]["input"]>;
};

export type RestaurantProductAssociationUpsert = {
    readonly __typename?: "RestaurantProductAssociationUpsert";
    readonly productIds?: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
};

export type RestaurantProductAssociationUpsertInput = {
    readonly productIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    readonly restaurantId: Scalars["String"]["input"];
};

export type RestaurantSocialLinkListNodes = {
    readonly __typename?: "RestaurantSocialLinkListNodes";
    readonly entityKey: Scalars["String"]["output"];
    readonly value: Scalars["String"]["output"];
};

export type RestaurantTagList = {
    readonly __typename?: "RestaurantTagList";
    readonly nodes: ReadonlyArray<Maybe<RestaurantTagListNode>>;
};

export type RestaurantTagListNode = {
    readonly __typename?: "RestaurantTagListNode";
    readonly nodes: ReadonlyArray<Maybe<RestaurantTagListNodeTagsByType>>;
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

export type RestaurantTagListNodeTagsByType = {
    readonly __typename?: "RestaurantTagListNodeTagsByType";
    readonly name: Scalars["String"]["output"];
    readonly systemTagId: Scalars["String"]["output"];
};

export type S3SignedDownloadUrl = {
    readonly __typename?: "S3SignedDownloadUrl";
    readonly url: Scalars["String"]["output"];
};

export type S3SignedUploadUrl = {
    readonly __typename?: "S3SignedUploadUrl";
    readonly filename: Scalars["String"]["output"];
    readonly url: Scalars["String"]["output"];
};

/** This represents a securityPrincipal */
export type SaleAgency = {
    readonly __typename?: "SaleAgency";
    readonly id: Scalars["String"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly securityPrincipalId: Scalars["String"]["output"];
    readonly securityPrincipalType: SecurityPrincipalTypeType;
};

/** This represents a securityPrincipal */
export type SaleAgencyBusinessPrincipal = {
    readonly __typename?: "SaleAgencyBusinessPrincipal";
    readonly hierarchyNodeType: SecurityPrincipalHiearchyNodeType;
    readonly id: Scalars["String"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly parentSecurityPrincipalId: Scalars["String"]["output"];
    readonly parentSecurityPrincipalType?: Maybe<SecurityPrincipalTypeType>;
    readonly securityPermissionList: SecurityPermissionList;
    readonly securityPrincipalId: Scalars["String"]["output"];
    readonly securityPrincipalType: SecurityPrincipalTypeType;
};

export type SaleAgencyCreateInput = {
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    readonly name: Scalars["String"]["input"];
    readonly parentSecurityPrincipalId: Scalars["String"]["input"];
};

export type SaleAgencyDeleteInput = {
    readonly id: Scalars["String"]["input"];
};

export type SaleAgencyList = {
    readonly __typename?: "SaleAgencyList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<SaleAgencyListItem>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type SaleAgencyListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SaleAgencyListFilter = {
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** Filter by parent security principal id */
    readonly parentSecurityPrincipalId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** This represents a securityPrincipal */
export type SaleAgencyListItem = {
    readonly __typename?: "SaleAgencyListItem";
    readonly id: Scalars["String"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly securityPrincipalId: Scalars["String"]["output"];
    readonly securityPrincipalType: SecurityPrincipalTypeType;
};

export type SaleAgencyListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

export type SaleAgencyUpdateInput = {
    readonly id: Scalars["String"]["input"];
    readonly name: Scalars["String"]["input"];
};

/** This represents an SaleRestrictionRollUp */
export type SaleRestrictionRollUpListItem = {
    readonly __typename?: "SaleRestrictionRollUpListItem";
    /** The number of allsale restrictioned products */
    readonly allRestrictions?: Maybe<Scalars["String"]["output"]>;
    /** The number of available sale restrictioned products */
    readonly available?: Maybe<Scalars["String"]["output"]>;
    /** The number of expected sale restrictioned products */
    readonly expected?: Maybe<Scalars["String"]["output"]>;
    /** The number of frozen sale restrictioned products */
    readonly frozen?: Maybe<Scalars["String"]["output"]>;
    /** The number of no commission sale restrictioned products */
    readonly noCommission?: Maybe<Scalars["String"]["output"]>;
    /** The number of sale restricted products with no guide price */
    readonly noGuidePrice?: Maybe<Scalars["String"]["output"]>;
    /** The number of other sale restricted products */
    readonly other?: Maybe<Scalars["String"]["output"]>;
    /** The percentage of all availablesale restrictioned products */
    readonly percentage?: Maybe<Scalars["String"]["output"]>;
    /** The number of removed products */
    readonly removed?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationSource of the restricted products */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The number of sale restricted products with a valiation error */
    readonly validationError?: Maybe<Scalars["String"]["output"]>;
};

/** This respresents the SaleRestrictionRollUp List response type */
export type SaleRestrictionRollUpListResponse = {
    readonly __typename?: "SaleRestrictionRollUpListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** SaleRestrictionRollUp list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<SaleRestrictionRollUpListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

/** This represents an Search */
export type Search = {
    readonly __typename?: "Search";
    /** type */
    readonly id?: Maybe<Scalars["String"]["output"]>;
    /** subtitle */
    readonly subtitle?: Maybe<Scalars["String"]["output"]>;
    /** title */
    readonly title: Scalars["String"]["output"];
    /** type */
    readonly type: SearchResultType;
};

export type SearchAutoComplete =
    | SearchAutoCompleteCity
    | SearchAutoCompleteCountry
    | SearchAutoCompleteProduct
    | SearchAutoCompleteRegion;

export type SearchAutoCompleteCity = {
    readonly __typename?: "SearchAutoCompleteCity";
    readonly countryName?: Maybe<Scalars["String"]["output"]>;
    readonly geoCoordinate?: Maybe<GeoCoordinate>;
    readonly id: Scalars["String"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly nameFoundPhraseRange: PhraseHighlightRange;
};

export type SearchAutoCompleteCountry = {
    readonly __typename?: "SearchAutoCompleteCountry";
    readonly id: Scalars["String"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly nameFoundPhraseRange: PhraseHighlightRange;
};

export type SearchAutoCompleteProduct = {
    readonly __typename?: "SearchAutoCompleteProduct";
    readonly id: Scalars["String"]["output"];
    readonly image?: Maybe<Asset>;
    readonly name: Scalars["String"]["output"];
    readonly nameFoundPhraseRange: PhraseHighlightRange;
    readonly slug?: Maybe<Scalars["String"]["output"]>;
};

export type SearchAutoCompleteRegion = {
    readonly __typename?: "SearchAutoCompleteRegion";
    readonly countryName?: Maybe<Scalars["String"]["output"]>;
    readonly id: Scalars["String"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly nameFoundPhraseRange: PhraseHighlightRange;
};

/** This respresents the search list response type */
export type SearchList = {
    readonly __typename?: "SearchList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** Search list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<Search>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

/** This represents search result type/category */
export enum SearchResultType {
    Category = "CATEGORY",
    City = "CITY",
    Country = "COUNTRY",
    Curation = "CURATION",
    Destination = "DESTINATION",
    Product = "PRODUCT",
    ProductCategory = "PRODUCT_CATEGORY",
    ProductType = "PRODUCT_TYPE",
    Region = "REGION",
}

export enum SecurityDomainType {
    Booking = "BOOKING",
    Brand = "BRAND",
    DistributionChannel = "DISTRIBUTION_CHANNEL",
    Partner = "PARTNER",
    Product = "PRODUCT",
    Provider = "PROVIDER",
    Supplier = "SUPPLIER",
    Taxonomy = "TAXONOMY",
    Workstream = "WORKSTREAM",
}

/** This represents a SecurityPermission */
export type SecurityPermission = {
    readonly __typename?: "SecurityPermission";
    /** The unique ID for the permission associated with the security permission */
    readonly id: Scalars["String"]["output"];
    /** The value for the permission associated with the security permission */
    readonly value: Scalars["String"]["output"];
};

export type SecurityPermissionList = {
    readonly __typename?: "SecurityPermissionList";
    readonly nodes: ReadonlyArray<SecurityPermission>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

/** This represents a securityPrincipal */
export type SecurityPrincipal = {
    readonly __typename?: "SecurityPrincipal";
    readonly foreignId: Scalars["String"]["output"];
    readonly id: Scalars["String"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly parentId: Scalars["String"]["output"];
    readonly type: SecurityPrincipalTypeType;
};

export type SecurityPrincipalChangeParentInput = {
    /** securityPrincipal id */
    readonly id: Scalars["String"]["input"];
    /** securityPrincipal id */
    readonly parentId: Scalars["String"]["input"];
};

export enum SecurityPrincipalHiearchyNodeType {
    Leaf = "LEAF",
    Node = "NODE",
    Root = "ROOT",
}

/** Use to add one or more filter to the list. */
export type SecurityPrincipalHierarchyListFilter = {
    readonly match?: InputMaybe<MatchFilterInput>;
    readonly parentId?: InputMaybe<Scalars["String"]["input"]>;
    readonly typeList?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** This represents a HierarchyNode. */
export type SecurityPrincipalHierarchyListNode = {
    readonly __typename?: "SecurityPrincipalHierarchyListNode";
    readonly ancestorList?: Maybe<HierarchyNodeParentList>;
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly curationSlugId: Scalars["String"]["output"];
    readonly effectiveName: Scalars["String"]["output"];
    readonly id: Scalars["String"]["output"];
    /** Evaluate hierarchy node selection based on conditions. */
    readonly isSelected?: Maybe<Scalars["Boolean"]["output"]>;
    readonly isVirtualGroupNode?: Maybe<Scalars["Boolean"]["output"]>;
    /** Hierarchy node meta data. */
    readonly metaData?: Maybe<HierarchyNodeMetaData>;
    /** Translated name values. */
    readonly nameTranslationList?: Maybe<LanguageTranslation>;
    readonly ordinalPosition: Scalars["Int"]["output"];
    readonly parentId?: Maybe<Scalars["String"]["output"]>;
    readonly rootName: Scalars["String"]["output"];
    readonly type: SecurityPrincipalTypeType;
    readonly typeName: Scalars["String"]["output"];
    readonly typeSystemEnumerationKey: HierarchyNodeTypeSystemEnumerationKey;
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    readonly withVirtualGroups?: Maybe<Scalars["Boolean"]["output"]>;
};

/** This represents a HierarchyNode. */
export type SecurityPrincipalHierarchyListNodeIsSelectedArgs = {
    criteria?: InputMaybe<HierarchyIsSelectedCriteriaType>;
};

export type SecurityPrincipalHierarchyListSort = {
    readonly name?: InputMaybe<SortDirection>;
};

export type SecurityPrincipalHierarchyListType = {
    readonly __typename?: "SecurityPrincipalHierarchyListType";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<SecurityPrincipalHierarchyListNode>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    readonly parentNode?: Maybe<SecurityPrincipalHierarchyListNode>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type SecurityPrincipalHierarchyListTypeRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SecurityPrincipalPermissionUpsertInput = {
    readonly securityPermissionIdList: ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>;
    readonly securityPrincipalId: Scalars["String"]["input"];
};

export enum SecurityPrincipalTypeType {
    Company = "COMPANY",
    DistributionChannel = "DISTRIBUTION_CHANNEL",
    Partner = "PARTNER",
    PartnerChannel = "PARTNER_CHANNEL",
    SaleAgency = "SALE_AGENCY",
    Supplier = "SUPPLIER",
}

export type SecurityPrincipalUpdateInput = {
    /** VaultId for banner image. */
    readonly bannerImageVaultId?: InputMaybe<Scalars["String"]["input"]>;
    /** The companyAddressLine1 of the securityPrincipal */
    readonly companyAddressLine1?: InputMaybe<Scalars["String"]["input"]>;
    /** The companyAddressLine2 of the securityPrincipal */
    readonly companyAddressLine2?: InputMaybe<Scalars["String"]["input"]>;
    /** The companyAddressPostalcode of the securityPrincipal */
    readonly companyAddressPostalcode?: InputMaybe<Scalars["String"]["input"]>;
    /** The companyAddressRegion of the securityPrincipal */
    readonly companyAddressRegion?: InputMaybe<Scalars["String"]["input"]>;
    /** The companyAddressTown of the securityPrincipal */
    readonly companyAddressTown?: InputMaybe<Scalars["String"]["input"]>;
    /** The companyPhoneMain of the securityPrincipal */
    readonly companyPhoneMain?: InputMaybe<Scalars["String"]["input"]>;
    /** The full address with components separated with comma. This input will be parsed and stored in individual fields. */
    readonly fullAddress?: InputMaybe<Scalars["String"]["input"]>;
    /** securityPrincipal id */
    readonly id: Scalars["String"]["input"];
    /** The languages of the securityPrincipal */
    readonly languages?: InputMaybe<Scalars["String"]["input"]>;
    /** VaultId for logo. */
    readonly logoVaultId?: InputMaybe<Scalars["String"]["input"]>;
    /** The mainContactEmail of the securityPrincipal */
    readonly mainContactEmail?: InputMaybe<Scalars["String"]["input"]>;
    /** The name of the securityPrincipal */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** The openingTimes of the securityPrincipal */
    readonly openingTimes?: InputMaybe<Scalars["String"]["input"]>;
    /** The segment of the securityPrincipal */
    readonly segment?: InputMaybe<Scalars["String"]["input"]>;
    /** The websiteUrl of the securityPrincipal */
    readonly websiteUrl?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a securityPrincipalUser */
export type SecurityPrincipalUser = {
    readonly __typename?: "SecurityPrincipalUser";
    /** The level applied for access control which determines the records the given user can read */
    readonly accessLevel: SecurityPrincipalUserAccessLevelType;
    /**
     * The authentication method used by the user
     * @deprecated This field is deprecated
     */
    readonly authenticationMethod?: Maybe<SecurityPrincipalUserAuthenticationMethod>;
    /** The ID in vault for the securityPrincipalUsers avatar image */
    readonly avatarVaultId?: Maybe<Scalars["String"]["output"]>;
    /** The date the securityPrincipalUser was created */
    readonly createdAt?: Maybe<Scalars["String"]["output"]>;
    /** The date the securityPrincipalUser was deleted - deleted securityPrincipalUsers are unable to authenticate */
    readonly deletedAt?: Maybe<Scalars["String"]["output"]>;
    /** The email of this securityPrincipalUser - can be used on sign-in */
    readonly email?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUser first name */
    readonly firstName?: Maybe<Scalars["String"]["output"]>;
    /** The Id of the securityPrincipalUser */
    readonly id: Scalars["String"]["output"];
    /** The securityPrincipalUser last name */
    readonly lastName?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUser name */
    readonly name: Scalars["String"]["output"];
    /** securityPrincipalUserPermissions List */
    readonly permissionList: SecurityPrincipalUserPermissionList;
    /** The phone number of this securityPrincipalUser */
    readonly phone?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUser preferredUserName - Can be used on sign-in */
    readonly preferredUserName?: Maybe<Scalars["String"]["output"]>;
    /** The Id of the securityPrincipal that the User is associated with. */
    readonly securityPrincipalId: Scalars["String"]["output"];
    /** The type of the securityPrincipal that the User is associated with. */
    readonly securityPrincipalName?: Maybe<Scalars["String"]["output"]>;
    /** The type of the securityPrincipal that the User is associated with. */
    readonly securityPrincipalType: SecurityPrincipalTypeType;
    /** a JSON object containing securityPrincipalUser settings  */
    readonly settings?: Maybe<Scalars["String"]["output"]>;
    /** The date the securityPrincipalUser was last updated */
    readonly updatedAt?: Maybe<Scalars["String"]["output"]>;
};

/** This represents a securityPrincipalUser */
export type SecurityPrincipalUserPermissionListArgs = {
    filter?: InputMaybe<SecurityPrincipalUserPermissionListFilter>;
};

export enum SecurityPrincipalUserAccessLevelType {
    Descendant = "DESCENDANT",
    Principal = "PRINCIPAL",
    User = "USER",
}

export enum SecurityPrincipalUserAuthenticationMethod {
    Cognito = "COGNITO",
    CognitoLegacy = "COGNITO_LEGACY",
    HolibobIdp = "HOLIBOB_IDP",
    Oauth = "OAUTH",
}

export type SecurityPrincipalUserCreateInput = {
    /** The level applied for access control which determines the records the given user can read */
    readonly accessLevel?: InputMaybe<SecurityPrincipalUserAccessLevelType>;
    /** The authentication method used by the user */
    readonly authenticationMethod?: InputMaybe<SecurityPrincipalUserAuthenticationMethod>;
    /** The ID in vault for the securityPrincipalUsers avatar image */
    readonly avatarVaultId?: InputMaybe<Scalars["String"]["input"]>;
    /** The email of this securityPrincipalUser - can be used on sign-in */
    readonly email?: InputMaybe<Scalars["String"]["input"]>;
    /** The securityPrincipalUser first name */
    readonly firstName?: InputMaybe<Scalars["String"]["input"]>;
    /** The Id of the securityPrincipalUser */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The securityPrincipalUser last name */
    readonly lastName?: InputMaybe<Scalars["String"]["input"]>;
    /** permissions */
    readonly permissions?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
    /** The phone number of this securityPrincipalUser */
    readonly phone?: InputMaybe<Scalars["String"]["input"]>;
    /** The securityPrincipalUser preferredUserName - Can be used on sign-in */
    readonly preferredUserName?: InputMaybe<Scalars["String"]["input"]>;
    /** The Id of the securityPrincipal that the User is associated with. */
    readonly securityPrincipalId: Scalars["String"]["input"];
    /** a JSON object containing securityPrincipalUser settings  */
    readonly settings?: InputMaybe<Scalars["String"]["input"]>;
};

export type SecurityPrincipalUserDeleteInput = {
    /** The id of the securityPrincipalUser */
    readonly id: Scalars["String"]["input"];
};

export type SecurityPrincipalUserListFilter = {
    /** filter by deleted securityPrincipalUsers */
    readonly isDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** filter by partnerChannelId */
    readonly partnerChannelId?: InputMaybe<Scalars["String"]["input"]>;
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    readonly securityPrincipalId?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a securityPrincipalUser */
export type SecurityPrincipalUserListItem = {
    readonly __typename?: "SecurityPrincipalUserListItem";
    /** The level applied for access control which determines the records the given user can read */
    readonly accessLevel: SecurityPrincipalUserAccessLevelType;
    /**
     * The authentication method used by the user
     * @deprecated This field is deprecated
     */
    readonly authenticationMethod?: Maybe<SecurityPrincipalUserAuthenticationMethod>;
    /** The ID in vault for the securityPrincipalUsers avatar image */
    readonly avatarVaultId?: Maybe<Scalars["String"]["output"]>;
    /** The date the securityPrincipalUser was created */
    readonly createdAt?: Maybe<Scalars["String"]["output"]>;
    /** The date the securityPrincipalUser was deleted - deleted securityPrincipalUsers are unable to authenticate */
    readonly deletedAt?: Maybe<Scalars["String"]["output"]>;
    /** The email of this securityPrincipalUser - can be used on sign-in */
    readonly email?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUser first name */
    readonly firstName?: Maybe<Scalars["String"]["output"]>;
    /** The Id of the securityPrincipalUser */
    readonly id: Scalars["String"]["output"];
    /** The securityPrincipalUser last name */
    readonly lastName?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUser name */
    readonly name: Scalars["String"]["output"];
    /** The phone number of this securityPrincipalUser */
    readonly phone?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUser preferredUserName - Can be used on sign-in */
    readonly preferredUserName?: Maybe<Scalars["String"]["output"]>;
    /** The Id of the securityPrincipal that the User is associated with. */
    readonly securityPrincipalId: Scalars["String"]["output"];
    /** The type of the securityPrincipal that the User is associated with. */
    readonly securityPrincipalName?: Maybe<Scalars["String"]["output"]>;
    /** The type of the securityPrincipal that the User is associated with. */
    readonly securityPrincipalType: SecurityPrincipalTypeType;
    /** a JSON object containing securityPrincipalUser settings  */
    readonly settings?: Maybe<Scalars["String"]["output"]>;
    /** The date the securityPrincipalUser was last updated */
    readonly updatedAt?: Maybe<Scalars["String"]["output"]>;
};

export type SecurityPrincipalUserListResponse = {
    readonly __typename?: "SecurityPrincipalUserListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<SecurityPrincipalUserListItem>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type SecurityPrincipalUserListResponseRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SecurityPrincipalUserListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

export type SecurityPrincipalUserMigrateInput = {
    /** securityPrincipalUser password */
    readonly password: Scalars["String"]["input"];
    /** securityPrincipalUser id */
    readonly securityPrincipalUserId: Scalars["String"]["input"];
};

export type SecurityPrincipalUserPermissionList = {
    readonly __typename?: "SecurityPrincipalUserPermissionList";
    readonly nodes: ReadonlyArray<SecurityPrincipalUserPermissionListItem>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type SecurityPrincipalUserPermissionListFilter = {
    /** filter by securityPrincipalUserId */
    readonly securityPrincipalUserId: Scalars["String"]["input"];
};

/** This represents a SecurityPrincipalUserPermission */
export type SecurityPrincipalUserPermissionListItem = {
    readonly __typename?: "SecurityPrincipalUserPermissionListItem";
    /** The unique ID for the permission associated with the securityPrincipalUser  */
    readonly id: Scalars["String"]["output"];
    /** The value for the permission associated with the securityPrincipalUser */
    readonly value: Scalars["String"]["output"];
};

export type SecurityPrincipalUserResetPasswordInput = {
    /** The id of the securityPrincipalUser */
    readonly id: Scalars["String"]["input"];
};

export type SecurityPrincipalUserResetPasswordRequestClaimInput = {
    /** The new password of the securityPrincipalUser */
    readonly password: Scalars["String"]["input"];
    /** The id of the securityPrincipalUser */
    readonly passwordResetCode: Scalars["String"]["input"];
};

export type SecurityPrincipalUserResetPasswordRequestClaimResponse = {
    readonly __typename?: "SecurityPrincipalUserResetPasswordRequestClaimResponse";
    readonly securityPrincipalUserId?: Maybe<Scalars["String"]["output"]>;
};

export type SecurityPrincipalUserResetPasswordRequestInput = {
    /** The id of the securityPrincipalUser */
    readonly securityPrincipalUserId: Scalars["String"]["input"];
};

/** This represents a product translation */
export type SecurityPrincipalUserResetPasswordRequestValidateResponse = {
    readonly __typename?: "SecurityPrincipalUserResetPasswordRequestValidateResponse";
    /** The id of the SecurityPrincipalUser if found */
    readonly securityPrincipalUserId?: Maybe<Scalars["String"]["output"]>;
    /** The status of the SecurityPrincipalUserResetPasswordRequest */
    readonly status: SecurityPrincipalUserResetPasswordRequestValidateResponseStatus;
};

/** The status */
export enum SecurityPrincipalUserResetPasswordRequestValidateResponseStatus {
    /** SecurityPrincipalUserResetPasswordRequest is invalid */
    Invalid = "INVALID",
    /** SecurityPrincipalUserResetPasswordRequest has expired */
    InvalidExpired = "INVALID_EXPIRED",
    /** SecurityPrincipalUserResetPasswordRequest is valid */
    Valid = "VALID",
}

/** This represents a securityPrincipalUser */
export type SecurityPrincipalUserSignup = {
    readonly __typename?: "SecurityPrincipalUserSignup";
    /** The email of this securityPrincipalUser */
    readonly email?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUser first name */
    readonly firstName: Scalars["String"]["output"];
    /** The Id of the securityPrincipalUser */
    readonly id: Scalars["String"]["output"];
    /** The securityPrincipalUser last name */
    readonly lastName: Scalars["String"]["output"];
};

export type SecurityPrincipalUserSignupInput = {
    /** securityPrincipalUser id */
    readonly id: Scalars["String"]["input"];
    /** The securityPrincipalUser's password */
    readonly password?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a SecurityPrincipalUserSignupResponseType */
export type SecurityPrincipalUserSignupResponse = {
    readonly __typename?: "SecurityPrincipalUserSignupResponse";
    /** The cognito username of the new created SecurityPrincipalUser */
    readonly cognitoUsername: Scalars["String"]["output"];
};

export type SecurityPrincipalUserUpdateInput = {
    /** The level applied for access control which determines the records the given user can read */
    readonly accessLevel?: InputMaybe<SecurityPrincipalUserAccessLevelType>;
    /** The authentication method used by the user */
    readonly authenticationMethod?: InputMaybe<SecurityPrincipalUserAuthenticationMethod>;
    /** The ID in vault for the securityPrincipalUsers avatar image */
    readonly avatarVaultId?: InputMaybe<Scalars["String"]["input"]>;
    /** The email of this securityPrincipalUser - can be used on sign-in */
    readonly email?: InputMaybe<Scalars["String"]["input"]>;
    /** The securityPrincipalUser first name */
    readonly firstName?: InputMaybe<Scalars["String"]["input"]>;
    /** The Id of the securityPrincipalUser */
    readonly id: Scalars["String"]["input"];
    /** The securityPrincipalUser last name */
    readonly lastName?: InputMaybe<Scalars["String"]["input"]>;
    /** permissions */
    readonly permissions?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** The phone number of this securityPrincipalUser */
    readonly phone?: InputMaybe<Scalars["String"]["input"]>;
    /** The securityPrincipalUser preferredUserName - Can be used on sign-in */
    readonly preferredUserName?: InputMaybe<Scalars["String"]["input"]>;
    /** The Id of the securityPrincipal that the User is associated with. */
    readonly securityPrincipalId: Scalars["String"]["input"];
};

/** This represents a SecurityRule. */
export type SecurityRule = {
    readonly __typename?: "SecurityRule";
    /** The domain of this rule. */
    readonly domain?: Maybe<Scalars["String"]["output"]>;
    /** The unique id for the SecurityRule. */
    readonly id: Scalars["String"]["output"];
    /** The name. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** The whereStatement. */
    readonly whereStatement?: Maybe<Scalars["String"]["output"]>;
};

export type SecurityRuleAssociations = {
    /** The unique Holibob ID for the partner */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** Values for the permission associated with the security permission */
    readonly value?: InputMaybe<SecurityRulePrincipalAssociationTypeUpsertFieldValues>;
};

export type SecurityRuleDeleteInput = {
    /** The ID of the SecurityRule to be deleted. */
    readonly id: Scalars["String"]["input"];
};

/** This represents a list of SecurityRule. */
export type SecurityRuleList = {
    readonly __typename?: "SecurityRuleList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes?: Maybe<ReadonlyArray<Maybe<SecurityRule>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type SecurityRuleListFilter = {
    /** filter by domain */
    readonly domain?: InputMaybe<Scalars["String"]["input"]>;
    /** filter by name */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a SecurityRulePrincipalAssociation */
export type SecurityRulePrincipalAssociation = {
    readonly __typename?: "SecurityRulePrincipalAssociation";
    /** The unique ID for the permission associated with the security permission */
    readonly id: Scalars["String"]["output"];
    /** Values for the permission associated with the security permission */
    readonly value: SecurityRulePrincipalAssociationTypeFieldValues;
};

export type SecurityRulePrincipalAssociationInput = {
    /** The unique ID for the permission associated with the security permission */
    readonly id: Scalars["String"]["input"];
    /** Values for the permission associated with the security permission */
    readonly value: SecurityRulePrincipalAssociationTypeFieldValuesInput;
};

export type SecurityRulePrincipalAssociationListUpsertInput = {
    readonly securityPrincipalId: Scalars["String"]["input"];
    readonly securityRulePrincipalAssociationList: ReadonlyArray<SecurityRulePrincipalAssociationInput>;
};

export enum SecurityRulePrincipalAssociationPermission {
    Amadeus = "AMADEUS",
    Edit = "EDIT",
    Special = "SPECIAL",
    Testing = "TESTING",
    Update = "UPDATE",
    View = "VIEW",
}

export enum SecurityRulePrincipalAssociationType {
    Allow = "ALLOW",
    Deny = "DENY",
}

/** This represents a SecurityRulePrincipalAssociationTypeFieldsValues */
export type SecurityRulePrincipalAssociationTypeFieldValues = {
    readonly __typename?: "SecurityRulePrincipalAssociationTypeFieldValues";
    /** The value for the permission associated with the security permission */
    readonly permission: SecurityRulePrincipalAssociationPermission;
    /** The security rule domain for the permission associated with the security permission */
    readonly securityRuleDomain: Scalars["String"]["output"];
    /** The security rule ID for the permission associated with the security permission */
    readonly securityRuleId: Scalars["String"]["output"];
    /** The type for the permission associated with the security permission */
    readonly type: SecurityRulePrincipalAssociationType;
};

export type SecurityRulePrincipalAssociationTypeFieldValuesInput = {
    /** The value for the permission associated with the security permission */
    readonly permission: SecurityRulePrincipalAssociationPermission;
    /** The security rule domain for the permission associated with the security permission */
    readonly securityRuleDomain: Scalars["String"]["input"];
    /** The security rule ID for the permission associated with the security permission */
    readonly securityRuleId: Scalars["String"]["input"];
    /** The type for the permission associated with the security permission */
    readonly type: SecurityRulePrincipalAssociationType;
};

/** This represents a SecurityRulePrincipalAssociationTypeFieldsValues */
export type SecurityRulePrincipalAssociationTypeUpsertFieldValues = {
    /** The value for the permission associated with the security permission */
    readonly permission: Scalars["String"]["input"];
    /** The security rule domain for the permission associated with the security permission */
    readonly securityRuleDomain: Scalars["String"]["input"];
    /** The security rule ID for the permission associated with the security permission */
    readonly securityRuleId: Scalars["String"]["input"];
    /** The type for the permission associated with the security permission */
    readonly type: Scalars["String"]["input"];
};

export type SecurityRuleUpsertInput = {
    /** The type of the SecurityRule. */
    readonly domain?: InputMaybe<Scalars["String"]["input"]>;
    /** The ID of the SecurityRule. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The name of the SecurityRule. */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** The whereStatement of the SecurityRule. */
    readonly whereStatement?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents the validation of a security rule validation. */
export type SecurityRuleWhereStatementValidation = {
    readonly __typename?: "SecurityRuleWhereStatementValidation";
    /** The number of record that the where statement returns. */
    readonly recordCount?: Maybe<Scalars["Int"]["output"]>;
};

export type SelectorInput = {
    readonly type: SelectorInputTypeEnum;
    readonly value: Scalars["String"]["input"];
};

export enum SelectorInputTypeEnum {
    Id = "id",
    Reference = "reference",
}

export type SignalBookingAvailabilityAcceptInput = {
    readonly bookingAvailabilityId: Scalars["String"]["input"];
    readonly token: Scalars["String"]["input"];
};

export type SignalBookingAvailabilityAcceptResponse = {
    readonly __typename?: "SignalBookingAvailabilityAcceptResponse";
    readonly status: TokenReclaimResponseStatus;
};

export type SignalBookingAvailabilityCancellationIntentInput = {
    readonly bookingAvailabilityId: Scalars["String"]["input"];
    readonly cancellationReason?: InputMaybe<Scalars["String"]["input"]>;
};

export type SignalBookingAvailabilityListCancellationIntentInput = {
    readonly bookingAvailabilityCancellationList?: InputMaybe<ReadonlyArray<BookingAvailabilityCancellationInput>>;
};

export type SitemapEntry = {
    readonly __typename?: "SitemapEntry";
    readonly changeFrequency: Scalars["String"]["output"];
    readonly modifiedAt: Scalars["DateTime"]["output"];
    readonly priority: Scalars["String"]["output"];
    readonly url: Scalars["String"]["output"];
};

export type SitemapEntryList = {
    readonly __typename?: "SitemapEntryList";
    readonly nodes: ReadonlyArray<SitemapEntry>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type SitemapIndex = {
    readonly __typename?: "SitemapIndex";
    readonly entryList?: Maybe<SitemapEntryList>;
    readonly index: Scalars["NonNegativeInt"]["output"];
    readonly modifiedAt: Scalars["DateTime"]["output"];
};

export type SitemapList = {
    readonly __typename?: "SitemapList";
    readonly hostName: Scalars["String"]["output"];
    readonly nodes: ReadonlyArray<SitemapIndex>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export enum SortDirection {
    Asc = "ASC",
    Desc = "DESC",
}

/** This represents a Stripe payment intent */
export type StripePaymentIntent = {
    readonly __typename?: "StripePaymentIntent";
    readonly amount: Scalars["String"]["output"];
    readonly apiKey: Scalars["String"]["output"];
    readonly clientSecret: Scalars["String"]["output"];
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly currency: Scalars["String"]["output"];
    readonly id: Scalars["String"]["output"];
};

export type Supplier = {
    readonly __typename?: "Supplier";
    /** Commission rate */
    readonly commissionRate?: Maybe<Scalars["Float"]["output"]>;
    /** The AddressCountry of the supplier. */
    readonly companyAddressCountry?: Maybe<Scalars["String"]["output"]>;
    /** The AddressCountryCode of the supplier. */
    readonly companyAddressCountryCode?: Maybe<Scalars["String"]["output"]>;
    readonly companyAddressLine1?: Maybe<Scalars["String"]["output"]>;
    /** The AddressLine2 of the supplier. */
    readonly companyAddressLine2?: Maybe<Scalars["String"]["output"]>;
    /** The AdressPostalcode of the supplier. */
    readonly companyAddressPostalcode?: Maybe<Scalars["String"]["output"]>;
    /** The AdressRegion of the supplier. */
    readonly companyAddressRegion?: Maybe<Scalars["String"]["output"]>;
    /** The AddressTown of the supplier. */
    readonly companyAddressTown?: Maybe<Scalars["String"]["output"]>;
    /** The ContactPhone of the supplier. */
    readonly companyContactPhone?: Maybe<Scalars["String"]["output"]>;
    /** The ContactPrimaryFamilyName of the supplier. */
    readonly companyContactPrimaryFamilyName?: Maybe<Scalars["String"]["output"]>;
    /** The ContactPrimaryGivenName of the supplier. */
    readonly companyContactPrimaryGivenName?: Maybe<Scalars["String"]["output"]>;
    readonly companyContactPrimaryRole?: Maybe<Scalars["String"]["output"]>;
    /** The PrimaryEmail of the supplier. */
    readonly companyEmailPrimary?: Maybe<Scalars["String"]["output"]>;
    /** The EmergancyPhone of the supplier . */
    readonly companyPhoneEmergency?: Maybe<Scalars["String"]["output"]>;
    readonly companyPhoneMain?: Maybe<Scalars["String"]["output"]>;
    /** The Mobile of the supplier. */
    readonly companyPhoneMobile?: Maybe<Scalars["String"]["output"]>;
    /** The Contract of the supplier. */
    readonly contract?: Maybe<Scalars["String"]["output"]>;
    /** createdAt */
    readonly createdAt?: Maybe<Scalars["Date"]["output"]>;
    /** The DefaultCurrency of the supplier. */
    readonly defaultCurrency?: Maybe<Scalars["String"]["output"]>;
    /** Brand Configuration List */
    readonly entitySystemConfigurationAssociationList: EntitySystemConfigurationAssociationList;
    /** The full address as returned from the GEO Tables. */
    readonly fullAddress?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the supplier */
    readonly id: Scalars["String"]["output"];
    /** Is supplier active. */
    readonly isActive?: Maybe<Scalars["Boolean"]["output"]>;
    /** The languages spoken by the supplier. */
    readonly languages?: Maybe<Scalars["String"]["output"]>;
    /** The legalJurisdiction of the supplier. */
    readonly legalJurisdiction?: Maybe<Scalars["String"]["output"]>;
    /** The MainContactEmail of the supplier. */
    readonly mainContactEmail?: Maybe<Scalars["String"]["output"]>;
    /** The MainContactEmailOverride of the supplier. */
    readonly mainContactEmailOverride?: Maybe<Scalars["String"]["output"]>;
    /** The MainContactFullName of the supplier. */
    readonly mainContactFullName?: Maybe<Scalars["String"]["output"]>;
    /** The Name of the supplier */
    readonly name: Scalars["String"]["output"];
    /** The Note. */
    readonly note?: Maybe<Scalars["String"]["output"]>;
    /** The times at which the supplier is open. */
    readonly openingTimes?: Maybe<Scalars["String"]["output"]>;
    /** The OutReachComment. */
    readonly outReachComment?: Maybe<Scalars["String"]["output"]>;
    /** The OutReachDate of the supplier. */
    readonly outReachDate?: Maybe<Scalars["Date"]["output"]>;
    /** The outReachComment of the supplier. */
    readonly outReachStatus?: Maybe<Scalars["String"]["output"]>;
    /** The PartnerId for the supplier */
    readonly partnerId?: Maybe<Scalars["String"]["output"]>;
    /** Use to fetch the state of the supplier payment onboaring process */
    readonly paymentAccount?: Maybe<SupplierPricingCategory>;
    /** Is payment split active or not. */
    readonly paymentSplit?: Maybe<Scalars["Boolean"]["output"]>;
    /** The permissions that the current viewer has with this record. */
    readonly permissions?: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
    /** The TOTAL count of Product Records for this supplier */
    readonly productCount: Scalars["Int"]["output"];
    /** The unique Holibob ID for the supplier */
    readonly products?: Maybe<ProductList>;
    /** The count of Product attached to this supplier that have one or more ProductSaleRestriction applied. */
    readonly restrictedProductCount: Scalars["Int"]["output"];
    /** securityPermissions */
    readonly securityPermissions: ReadonlyArray<SecurityPermission>;
    readonly securityPrincipalId?: Maybe<Scalars["String"]["output"]>;
    /** securityRuleAssociations List */
    readonly securityRuleAssociations: ReadonlyArray<SecurityRulePrincipalAssociation>;
    /** The business segment the supplier operates in. */
    readonly segment?: Maybe<Scalars["String"]["output"]>;
    /** The SocialFacebookUrl of the supplier. */
    readonly socialFacebookUrl?: Maybe<Scalars["String"]["output"]>;
    /** The SocialInstagramURL. */
    readonly socialInstagramUrl?: Maybe<Scalars["String"]["output"]>;
    /** The SocialTwitterUrl of the supplier. */
    readonly socialTwitterUrl?: Maybe<Scalars["String"]["output"]>;
    /** Source name */
    readonly sourceName?: Maybe<Scalars["String"]["output"]>;
    /** Survey json */
    readonly survey?: Maybe<Scalars["JSON"]["output"]>;
    readonly synchronisationKey?: Maybe<Scalars["String"]["output"]>;
    /** Synchronisation source */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** Last Synchronisation */
    readonly synchronisedAt?: Maybe<Scalars["Date"]["output"]>;
    /** The TOTAL count of Product Records for this supplier. */
    readonly totalProductCount: Scalars["Int"]["output"];
    /** The TripAdvisorRating of the supplier. */
    readonly tripAdvisorRating?: Maybe<Scalars["String"]["output"]>;
    /** The TripAdvisorUrl of the supplier. */
    readonly tripAdvisorUrl?: Maybe<Scalars["String"]["output"]>;
    /** updatedAt */
    readonly updatedAt?: Maybe<Scalars["Date"]["output"]>;
    /** The VoucherContactEmail of the supplier. */
    readonly voucherContactEmail?: Maybe<Scalars["String"]["output"]>;
    /** The VoucherContactName of the supplier. */
    readonly voucherContactName?: Maybe<Scalars["String"]["output"]>;
    /** The Main VoucherContactPhone of the supplier. */
    readonly voucherContactPhoneMain?: Maybe<Scalars["String"]["output"]>;
    /** The Mobile VoucherContactPhone of the supplier. */
    readonly voucherContactPhoneMobile?: Maybe<Scalars["String"]["output"]>;
    /** The WebsiteURL of the supplier. */
    readonly websiteUrl?: Maybe<Scalars["String"]["output"]>;
};

export type SupplierProductsArgs = {
    filter?: InputMaybe<ProductListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ProductListSort>;
    useOpenSearch?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SupplierBusinessPrincipal = {
    readonly __typename?: "SupplierBusinessPrincipal";
    /** Commission rate */
    readonly commissionRate?: Maybe<Scalars["Float"]["output"]>;
    /** The AddressCountry of the supplier. */
    readonly companyAddressCountry?: Maybe<Scalars["String"]["output"]>;
    /** The AddressCountryCode of the supplier. */
    readonly companyAddressCountryCode?: Maybe<Scalars["String"]["output"]>;
    readonly companyAddressLine1?: Maybe<Scalars["String"]["output"]>;
    /** The AddressLine2 of the supplier. */
    readonly companyAddressLine2?: Maybe<Scalars["String"]["output"]>;
    /** The AdressPostalcode of the supplier. */
    readonly companyAddressPostalcode?: Maybe<Scalars["String"]["output"]>;
    /** The AdressRegion of the supplier. */
    readonly companyAddressRegion?: Maybe<Scalars["String"]["output"]>;
    /** The AddressTown of the supplier. */
    readonly companyAddressTown?: Maybe<Scalars["String"]["output"]>;
    /** The ContactPhone of the supplier. */
    readonly companyContactPhone?: Maybe<Scalars["String"]["output"]>;
    /** The ContactPrimaryFamilyName of the supplier. */
    readonly companyContactPrimaryFamilyName?: Maybe<Scalars["String"]["output"]>;
    /** The ContactPrimaryGivenName of the supplier. */
    readonly companyContactPrimaryGivenName?: Maybe<Scalars["String"]["output"]>;
    readonly companyContactPrimaryRole?: Maybe<Scalars["String"]["output"]>;
    /** The PrimaryEmail of the supplier. */
    readonly companyEmailPrimary?: Maybe<Scalars["String"]["output"]>;
    /** The EmergancyPhone of the supplier . */
    readonly companyPhoneEmergency?: Maybe<Scalars["String"]["output"]>;
    readonly companyPhoneMain?: Maybe<Scalars["String"]["output"]>;
    /** The Mobile of the supplier. */
    readonly companyPhoneMobile?: Maybe<Scalars["String"]["output"]>;
    /** The Contract of the supplier. */
    readonly contract?: Maybe<Scalars["String"]["output"]>;
    /** createdAt */
    readonly createdAt?: Maybe<Scalars["Date"]["output"]>;
    /** The DefaultCurrency of the supplier. */
    readonly defaultCurrency?: Maybe<Scalars["String"]["output"]>;
    /** Brand Configuration List */
    readonly entitySystemConfigurationAssociationList: EntitySystemConfigurationAssociationList;
    /** The full address as returned from the GEO Tables. */
    readonly fullAddress?: Maybe<Scalars["String"]["output"]>;
    readonly hierarchyNodeType: SecurityPrincipalHiearchyNodeType;
    /** The unique Holibob ID for the supplier */
    readonly id: Scalars["String"]["output"];
    /** Is supplier active. */
    readonly isActive?: Maybe<Scalars["Boolean"]["output"]>;
    /** The languages spoken by the supplier. */
    readonly languages?: Maybe<Scalars["String"]["output"]>;
    /** The legalJurisdiction of the supplier. */
    readonly legalJurisdiction?: Maybe<Scalars["String"]["output"]>;
    /** The MainContactEmail of the supplier. */
    readonly mainContactEmail?: Maybe<Scalars["String"]["output"]>;
    /** The MainContactEmailOverride of the supplier. */
    readonly mainContactEmailOverride?: Maybe<Scalars["String"]["output"]>;
    /** The MainContactFullName of the supplier. */
    readonly mainContactFullName?: Maybe<Scalars["String"]["output"]>;
    /** The Name of the supplier */
    readonly name: Scalars["String"]["output"];
    /** The Note. */
    readonly note?: Maybe<Scalars["String"]["output"]>;
    /** The times at which the supplier is open. */
    readonly openingTimes?: Maybe<Scalars["String"]["output"]>;
    /** The OutReachComment. */
    readonly outReachComment?: Maybe<Scalars["String"]["output"]>;
    /** The OutReachDate of the supplier. */
    readonly outReachDate?: Maybe<Scalars["Date"]["output"]>;
    /** The outReachComment of the supplier. */
    readonly outReachStatus?: Maybe<Scalars["String"]["output"]>;
    readonly parentSecurityPrincipalId: Scalars["String"]["output"];
    readonly parentSecurityPrincipalType?: Maybe<SecurityPrincipalTypeType>;
    /** The PartnerId for the supplier */
    readonly partnerId?: Maybe<Scalars["String"]["output"]>;
    /** Use to fetch the state of the supplier payment onboaring process */
    readonly paymentAccount?: Maybe<SupplierPricingCategory>;
    /** Is payment split active or not. */
    readonly paymentSplit?: Maybe<Scalars["Boolean"]["output"]>;
    /** The permissions that the current viewer has with this record. */
    readonly permissions?: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
    /** The TOTAL count of Product Records for this supplier */
    readonly productCount: Scalars["Int"]["output"];
    /** The unique Holibob ID for the supplier */
    readonly products?: Maybe<ProductList>;
    /** The count of Product attached to this supplier that have one or more ProductSaleRestriction applied. */
    readonly restrictedProductCount: Scalars["Int"]["output"];
    readonly securityPermissionList: SecurityPermissionList;
    /** securityPermissions */
    readonly securityPermissions: ReadonlyArray<SecurityPermission>;
    readonly securityPrincipalId: Scalars["String"]["output"];
    readonly securityPrincipalType: SecurityPrincipalTypeType;
    /** securityRuleAssociations List */
    readonly securityRuleAssociations: ReadonlyArray<SecurityRulePrincipalAssociation>;
    /** The business segment the supplier operates in. */
    readonly segment?: Maybe<Scalars["String"]["output"]>;
    /** The SocialFacebookUrl of the supplier. */
    readonly socialFacebookUrl?: Maybe<Scalars["String"]["output"]>;
    /** The SocialInstagramURL. */
    readonly socialInstagramUrl?: Maybe<Scalars["String"]["output"]>;
    /** The SocialTwitterUrl of the supplier. */
    readonly socialTwitterUrl?: Maybe<Scalars["String"]["output"]>;
    /** Source name */
    readonly sourceName?: Maybe<Scalars["String"]["output"]>;
    /** Survey json */
    readonly survey?: Maybe<Scalars["JSON"]["output"]>;
    readonly synchronisationKey?: Maybe<Scalars["String"]["output"]>;
    /** Synchronisation source */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** Last Synchronisation */
    readonly synchronisedAt?: Maybe<Scalars["Date"]["output"]>;
    /** The TOTAL count of Product Records for this supplier. */
    readonly totalProductCount: Scalars["Int"]["output"];
    /** The TripAdvisorRating of the supplier. */
    readonly tripAdvisorRating?: Maybe<Scalars["String"]["output"]>;
    /** The TripAdvisorUrl of the supplier. */
    readonly tripAdvisorUrl?: Maybe<Scalars["String"]["output"]>;
    /** updatedAt */
    readonly updatedAt?: Maybe<Scalars["Date"]["output"]>;
    /** The VoucherContactEmail of the supplier. */
    readonly voucherContactEmail?: Maybe<Scalars["String"]["output"]>;
    /** The VoucherContactName of the supplier. */
    readonly voucherContactName?: Maybe<Scalars["String"]["output"]>;
    /** The Main VoucherContactPhone of the supplier. */
    readonly voucherContactPhoneMain?: Maybe<Scalars["String"]["output"]>;
    /** The Mobile VoucherContactPhone of the supplier. */
    readonly voucherContactPhoneMobile?: Maybe<Scalars["String"]["output"]>;
    /** The WebsiteURL of the supplier. */
    readonly websiteUrl?: Maybe<Scalars["String"]["output"]>;
};

export type SupplierBusinessPrincipalProductsArgs = {
    filter?: InputMaybe<ProductListFilter>;
    page?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    recordCountLimit?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<ProductListSort>;
    useOpenSearch?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** This represents a Supplier Finance */
export type SupplierFinance = {
    readonly __typename?: "SupplierFinance";
    /** activitiesAvailabilityLevel */
    readonly activitiesAvailabilityLevel?: Maybe<Scalars["String"]["output"]>;
    /** bankDetailsVerified */
    readonly bankDetailsVerified?: Maybe<Scalars["String"]["output"]>;
    /** billingCycle */
    readonly billingCycle?: Maybe<Scalars["String"]["output"]>;
    /** bookingOnlineExists */
    readonly bookingOnlineExists?: Maybe<Scalars["String"]["output"]>;
    /** bookingSystemScopeAll */
    readonly bookingSystemScopeAll?: Maybe<Scalars["String"]["output"]>;
    /** bookingSystemUsed */
    readonly bookingSystemUsed?: Maybe<Scalars["String"]["output"]>;
    /** cancellationPolicyVaultContentType */
    readonly cancellationPolicyVaultContentType?: Maybe<Scalars["String"]["output"]>;
    /** cancellationPolicyVaultId */
    readonly cancellationPolicyVaultId?: Maybe<Scalars["String"]["output"]>;
    /** companyAddressCountry */
    readonly companyAddressCountry?: Maybe<Scalars["String"]["output"]>;
    /** companyAddressCountryCode */
    readonly companyAddressCountryCode?: Maybe<Scalars["String"]["output"]>;
    /** companyAddressLine1 */
    readonly companyAddressLine1?: Maybe<Scalars["String"]["output"]>;
    /** companyAddressLine2 */
    readonly companyAddressLine2?: Maybe<Scalars["String"]["output"]>;
    /** companyAddressPostalcode */
    readonly companyAddressPostalcode?: Maybe<Scalars["String"]["output"]>;
    /** companyAddressRegion */
    readonly companyAddressRegion?: Maybe<Scalars["String"]["output"]>;
    /** companyAddressTown */
    readonly companyAddressTown?: Maybe<Scalars["String"]["output"]>;
    /** companyAnnualTurnoverAmount */
    readonly companyAnnualTurnoverAmount?: Maybe<Scalars["String"]["output"]>;
    /** companyAnnualTurnoverCurrency */
    readonly companyAnnualTurnoverCurrency?: Maybe<Scalars["String"]["output"]>;
    /** companyContactAccountsFamilyName */
    readonly companyContactAccountsFamilyName?: Maybe<Scalars["String"]["output"]>;
    /** companyContactAccountsGivenName */
    readonly companyContactAccountsGivenName?: Maybe<Scalars["String"]["output"]>;
    /** companyContactAccountsPhone */
    readonly companyContactAccountsPhone?: Maybe<Scalars["String"]["output"]>;
    /** companyContactAccountsRole */
    readonly companyContactAccountsRole?: Maybe<Scalars["String"]["output"]>;
    /** companyContactPhone */
    readonly companyContactPhone?: Maybe<Scalars["String"]["output"]>;
    /** companyContactPrimaryFamilyName */
    readonly companyContactPrimaryFamilyName?: Maybe<Scalars["String"]["output"]>;
    /** companyContactPrimaryGivenName */
    readonly companyContactPrimaryGivenName?: Maybe<Scalars["String"]["output"]>;
    /** companyContactPrimaryRole */
    readonly companyContactPrimaryRole?: Maybe<Scalars["String"]["output"]>;
    /** companyEmailPrimary */
    readonly companyEmailPrimary?: Maybe<Scalars["String"]["output"]>;
    /** companyLegalAddress */
    readonly companyLegalAddress?: Maybe<Scalars["String"]["output"]>;
    /** companyNumberOfEmployees */
    readonly companyNumberOfEmployees?: Maybe<Scalars["String"]["output"]>;
    /** companyPerYearTurnover */
    readonly companyPerYearTurnover?: Maybe<Scalars["String"]["output"]>;
    /** companyPhoneEmergency */
    readonly companyPhoneEmergency?: Maybe<Scalars["String"]["output"]>;
    /** companyPhoneMain */
    readonly companyPhoneMain?: Maybe<Scalars["String"]["output"]>;
    /** companyPhoneMobile */
    readonly companyPhoneMobile?: Maybe<Scalars["String"]["output"]>;
    /** companyRegisteredName */
    readonly companyRegisteredName?: Maybe<Scalars["String"]["output"]>;
    /** companyRegistrationCountry */
    readonly companyRegistrationCountry?: Maybe<Scalars["String"]["output"]>;
    /** companyRegistrationNumber */
    readonly companyRegistrationNumber?: Maybe<Scalars["String"]["output"]>;
    /** companyTourismLicenseNumber */
    readonly companyTourismLicenseNumber?: Maybe<Scalars["String"]["output"]>;
    /** companyTradingName */
    readonly companyTradingName?: Maybe<Scalars["String"]["output"]>;
    /** createdAt */
    readonly createdAt?: Maybe<Scalars["Date"]["output"]>;
    /** creditLimit */
    readonly creditLimit?: Maybe<Scalars["String"]["output"]>;
    /** creditLimitCurrency */
    readonly creditLimitCurrency?: Maybe<Scalars["String"]["output"]>;
    /** employeeInsuranceExists */
    readonly employeeInsuranceExists?: Maybe<Scalars["String"]["output"]>;
    /** environmentalPolicyExists */
    readonly environmentalPolicyExists?: Maybe<Scalars["String"]["output"]>;
    /** healthAndSafetyPolicyExists */
    readonly healthAndSafetyPolicyExists?: Maybe<Scalars["String"]["output"]>;
    /** The supplier finance id */
    readonly id?: Maybe<Scalars["String"]["output"]>;
    /** indemnityInsuranceExists */
    readonly indemnityInsuranceExists?: Maybe<Scalars["String"]["output"]>;
    /** invoiceTermDays */
    readonly invoiceTermDays?: Maybe<Scalars["String"]["output"]>;
    /** invoiceTermsOrigin */
    readonly invoiceTermsOrigin?: Maybe<Scalars["String"]["output"]>;
    /** liabilityInsurancePublicAmount */
    readonly liabilityInsurancePublicAmount?: Maybe<Scalars["String"]["output"]>;
    /** liabilityInsurancePublicCurrency */
    readonly liabilityInsurancePublicCurrency?: Maybe<Scalars["String"]["output"]>;
    /** liabilityInsurancePublicExists */
    readonly liabilityInsurancePublicExists?: Maybe<Scalars["String"]["output"]>;
    /** liabilityInsurancePublicRecentClaimExists */
    readonly liabilityInsurancePublicRecentClaimExists?: Maybe<Scalars["String"]["output"]>;
    /** liabilityInsurancePublicSupplier */
    readonly liabilityInsurancePublicSupplier?: Maybe<Scalars["String"]["output"]>;
    /** liabilityInsurancePublicValidRange */
    readonly liabilityInsurancePublicValidRange?: Maybe<Scalars["String"]["output"]>;
    /** liabilityInsuranceVaultContentType */
    readonly liabilityInsuranceVaultContentType?: Maybe<Scalars["String"]["output"]>;
    /** liabilityInsuranceVaultId */
    readonly liabilityInsuranceVaultId?: Maybe<Scalars["String"]["output"]>;
    /** logoVaultContentType */
    readonly logoVaultContentType?: Maybe<Scalars["String"]["output"]>;
    /** logoVaultId */
    readonly logoVaultId?: Maybe<Scalars["String"]["output"]>;
    /** paymentBankAba */
    readonly paymentBankAba?: Maybe<Scalars["String"]["output"]>;
    /** paymentBankAccountName */
    readonly paymentBankAccountName?: Maybe<Scalars["String"]["output"]>;
    /** paymentBankAccountNumber */
    readonly paymentBankAccountNumber?: Maybe<Scalars["String"]["output"]>;
    /** paymentBankAddress */
    readonly paymentBankAddress?: Maybe<Scalars["String"]["output"]>;
    /** paymentBankBicCode */
    readonly paymentBankBicCode?: Maybe<Scalars["String"]["output"]>;
    /** paymentBankCode */
    readonly paymentBankCode?: Maybe<Scalars["String"]["output"]>;
    /** paymentBankCountryCode */
    readonly paymentBankCountryCode?: Maybe<Scalars["String"]["output"]>;
    /** paymentBankIban */
    readonly paymentBankIban?: Maybe<Scalars["String"]["output"]>;
    /** paymentBankName */
    readonly paymentBankName?: Maybe<Scalars["String"]["output"]>;
    /** paymentBankSortCode */
    readonly paymentBankSortCode?: Maybe<Scalars["String"]["output"]>;
    /** paymentBankSwiftCode */
    readonly paymentBankSwiftCode?: Maybe<Scalars["String"]["output"]>;
    /** paymentCurrency */
    readonly paymentCurrency?: Maybe<Scalars["String"]["output"]>;
    /** paymentCurrencyPreferred */
    readonly paymentCurrencyPreferred?: Maybe<Scalars["String"]["output"]>;
    /** paymentCurrencyTrading */
    readonly paymentCurrencyTrading?: Maybe<Scalars["String"]["output"]>;
    /** socialFacebook */
    readonly socialFacebook?: Maybe<Scalars["String"]["output"]>;
    /** socialInstagram */
    readonly socialInstagram?: Maybe<Scalars["String"]["output"]>;
    /** socialTwitter */
    readonly socialTwitter?: Maybe<Scalars["String"]["output"]>;
    /** status */
    readonly status?: Maybe<Scalars["String"]["output"]>;
    /** supplierId */
    readonly supplierId?: Maybe<Scalars["String"]["output"]>;
    /** surveyedAt */
    readonly surveyedAt?: Maybe<Scalars["Date"]["output"]>;
    /** termsOfTradeVaultFileType */
    readonly termsOfTradeVaultFileType?: Maybe<Scalars["String"]["output"]>;
    /** termsOfTradeVaultId */
    readonly termsOfTradeVaultId?: Maybe<Scalars["String"]["output"]>;
    /** tripAdvisorRating */
    readonly tripAdvisorRating?: Maybe<Scalars["String"]["output"]>;
    /** tripAdvisorURL */
    readonly tripAdvisorURL?: Maybe<Scalars["String"]["output"]>;
    /** website */
    readonly website?: Maybe<Scalars["String"]["output"]>;
    /** websiteExists */
    readonly websiteExists?: Maybe<Scalars["String"]["output"]>;
};

export type SupplierFinanceDeleteInput = {
    /** The ID of the SupplierFinance to be deleted. */
    readonly id: Scalars["String"]["input"];
};

/** This represents a list of start times for the product. */
export type SupplierFinanceList = {
    readonly __typename?: "SupplierFinanceList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes?: Maybe<ReadonlyArray<Maybe<SupplierFinance>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type SupplierFinanceListFilter = {
    /** filter by supplierId */
    readonly supplierId?: InputMaybe<Scalars["String"]["input"]>;
};

export type SupplierFinanceListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

export type SupplierFinanceUpsertInput = {
    /** The companyAddressCountry of the supplierFinance in the context of the type */
    readonly companyAddressCountry?: InputMaybe<Scalars["String"]["input"]>;
    /** The companyAddressCountryCode of the supplierFinance in the context of the type */
    readonly companyAddressCountryCode?: InputMaybe<Scalars["String"]["input"]>;
    /** The companyAddressLine1 of the supplierFinance in the context of the type */
    readonly companyAddressLine1?: InputMaybe<Scalars["String"]["input"]>;
    /** The companyAddressLine2 of the supplierFinance in the context of the type */
    readonly companyAddressLine2?: InputMaybe<Scalars["String"]["input"]>;
    /** The companyAddressPostalcode of the supplierFinance in the context of the type */
    readonly companyAddressPostalcode?: InputMaybe<Scalars["String"]["input"]>;
    /** The companyAddressRegion of the supplierFinance in the context of the type */
    readonly companyAddressRegion?: InputMaybe<Scalars["String"]["input"]>;
    /** The companyAddressTown of the supplierFinance in the context of the type */
    readonly companyAddressTown?: InputMaybe<Scalars["String"]["input"]>;
    /** The companyEmailPrimary of the supplierFinance */
    readonly companyEmailPrimary?: InputMaybe<Scalars["String"]["input"]>;
    /** The body of the supplierFinance in companyPhoneMain format */
    readonly companyPhoneMain?: InputMaybe<Scalars["String"]["input"]>;
    /** The companyRegisteredName of the supplierFinance */
    readonly companyRegisteredName?: InputMaybe<Scalars["String"]["input"]>;
    /** supplierFinance id */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** supplierId */
    readonly supplierId?: InputMaybe<Scalars["String"]["input"]>;
    /** The website of the supplierFinance in the context of the type */
    readonly website?: InputMaybe<Scalars["String"]["input"]>;
};

/** This respresents the supplierList response type */
export type SupplierList = {
    readonly __typename?: "SupplierList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<Supplier>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    readonly reportUrl?: Maybe<Scalars["String"]["output"]>;
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

/** This respresents the supplierList response type */
export type SupplierListRecordCountArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** This respresents the supplierList response type */
export type SupplierListTotalArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SupplierListFilter = {
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    readonly parentSecurityPrincipalId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by requires commission rate updated */
    readonly requiresCommissionRate?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by one or more synchronisation source */
    readonly synchronisationSources?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

export type SupplierListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
    /** Sort by productCount */
    readonly productCount?: InputMaybe<SortDirection>;
};

export type SupplierOnboardInput = {
    /** The name of the supplier. */
    readonly supplierName: Scalars["String"]["input"];
    /** The user's email */
    readonly userEmail: Scalars["String"]["input"];
    /** The user's first name */
    readonly userFirstName: Scalars["String"]["input"];
    /** The user's last name */
    readonly userLastName: Scalars["String"]["input"];
};

/** This represents a SupplierOnboardResponse. */
export type SupplierOnboardResponse = {
    readonly __typename?: "SupplierOnboardResponse";
    /** The unique id of the security principal created. */
    readonly securityPrincipalId: Scalars["String"]["output"];
    /** The unique id of the security principal user created. */
    readonly securityPrincipalUserId: Scalars["String"]["output"];
    /** Unique sign up url for user. */
    readonly securityPrincipalUserSignupUrl: Scalars["String"]["output"];
    /** The unique id of the supplier created. */
    readonly supplierId: Scalars["String"]["output"];
};

/** This represents an item of product content. */
export type SupplierPricingCategory = {
    readonly __typename?: "SupplierPricingCategory";
    /** Supplier payment onboard state */
    readonly state: Scalars["String"]["output"];
};

/** This represents a Supplier Survey */
export type SupplierSurvey = {
    readonly __typename?: "SupplierSurvey";
    /** activitiesAvailabilityLevel */
    readonly activitiesAvailabilityLevel?: Maybe<Scalars["String"]["output"]>;
    /** bookingOnlineExists */
    readonly bookingOnlineExists?: Maybe<Scalars["Boolean"]["output"]>;
    /** bookingSystemScopeAll */
    readonly bookingSystemScopeAll?: Maybe<Scalars["String"]["output"]>;
    /** cancellationPolicyVaultContentType */
    readonly cancellationPolicyVaultContentType?: Maybe<Scalars["String"]["output"]>;
    /** TcancellationPolicyVaultId */
    readonly cancellationPolicyVaultId?: Maybe<Scalars["String"]["output"]>;
    /** companyAddressCountry */
    readonly companyAddressCountry?: Maybe<Scalars["String"]["output"]>;
    /** companyAddressLine1 */
    readonly companyAddressLine1?: Maybe<Scalars["String"]["output"]>;
    /** companyAddressLine2 */
    readonly companyAddressLine2?: Maybe<Scalars["String"]["output"]>;
    /** companyAddressPostalcode */
    readonly companyAddressPostalcode?: Maybe<Scalars["String"]["output"]>;
    /** companyAddressRegion */
    readonly companyAddressRegion?: Maybe<Scalars["String"]["output"]>;
    /** companyAddressTown */
    readonly companyAddressTown?: Maybe<Scalars["String"]["output"]>;
    /** companyAnnualTurnoverAmount */
    readonly companyAnnualTurnoverAmount?: Maybe<Scalars["String"]["output"]>;
    /** companyAnnualTurnoverCurrency */
    readonly companyAnnualTurnoverCurrency?: Maybe<Scalars["String"]["output"]>;
    /** companyContactAccountsFamilyName */
    readonly companyContactAccountsFamilyName?: Maybe<Scalars["String"]["output"]>;
    /** companyContactAccountsGivenName */
    readonly companyContactAccountsGivenName?: Maybe<Scalars["String"]["output"]>;
    /** companyContactAccountsPhone */
    readonly companyContactAccountsPhone?: Maybe<Scalars["String"]["output"]>;
    /** companyContactAccountsRole */
    readonly companyContactAccountsRole?: Maybe<Scalars["String"]["output"]>;
    /** companyContactPhone */
    readonly companyContactPhone?: Maybe<Scalars["String"]["output"]>;
    /** companyContactPrimaryFamilyName */
    readonly companyContactPrimaryFamilyName?: Maybe<Scalars["String"]["output"]>;
    /** companyContactPrimaryGivenName */
    readonly companyContactPrimaryGivenName?: Maybe<Scalars["String"]["output"]>;
    /** companyContactPrimaryRole */
    readonly companyContactPrimaryRole?: Maybe<Scalars["String"]["output"]>;
    /** companyEmailPrimary */
    readonly companyEmailPrimary?: Maybe<Scalars["String"]["output"]>;
    /** companyLegalAddress */
    readonly companyLegalAddress?: Maybe<Scalars["String"]["output"]>;
    /** companyNumberOfEmployees */
    readonly companyNumberOfEmployees?: Maybe<Scalars["String"]["output"]>;
    /** companyPerYearTurnover */
    readonly companyPerYearTurnover?: Maybe<Scalars["String"]["output"]>;
    /** companyPhoneEmergency */
    readonly companyPhoneEmergency?: Maybe<Scalars["String"]["output"]>;
    /** companyPhoneMain */
    readonly companyPhoneMain?: Maybe<Scalars["String"]["output"]>;
    /** companyPhoneMobile */
    readonly companyPhoneMobile?: Maybe<Scalars["String"]["output"]>;
    /** companyRegisteredName */
    readonly companyRegisteredName?: Maybe<Scalars["String"]["output"]>;
    /** companyRegistrationCountry */
    readonly companyRegistrationCountry?: Maybe<Scalars["String"]["output"]>;
    /** The companyRegistrationNumber */
    readonly companyRegistrationNumber?: Maybe<Scalars["String"]["output"]>;
    /** companyTourismLicenseNumber */
    readonly companyTourismLicenseNumber?: Maybe<Scalars["String"]["output"]>;
    /** companyTradingName */
    readonly companyTradingName?: Maybe<Scalars["String"]["output"]>;
    /** createdAt */
    readonly createdAt?: Maybe<Scalars["String"]["output"]>;
    /** employeeInsuranceExists */
    readonly employeeInsuranceExists?: Maybe<Scalars["Boolean"]["output"]>;
    /** environmentalPolicyExists */
    readonly environmentalPolicyExists?: Maybe<Scalars["Boolean"]["output"]>;
    /** healthAndSafetyPolicyExists */
    readonly healthAndSafetyPolicyExists?: Maybe<Scalars["Boolean"]["output"]>;
    /** The supplier id */
    readonly id: Scalars["String"]["output"];
    /** indemnityInsuranceExists */
    readonly indemnityInsuranceExists?: Maybe<Scalars["Boolean"]["output"]>;
    /** liabilityInsurancePublicAmount */
    readonly liabilityInsurancePublicAmount?: Maybe<Scalars["String"]["output"]>;
    /** liabilityInsurancePublicCurrency */
    readonly liabilityInsurancePublicCurrency?: Maybe<Scalars["String"]["output"]>;
    /** liabilityInsurancePublicExists */
    readonly liabilityInsurancePublicExists?: Maybe<Scalars["Boolean"]["output"]>;
    /** liabilityInsurancePublicRecentClaimExists */
    readonly liabilityInsurancePublicRecentClaimExists?: Maybe<Scalars["Boolean"]["output"]>;
    /** liabilityInsurancePublicSupplier */
    readonly liabilityInsurancePublicSupplier?: Maybe<Scalars["String"]["output"]>;
    /** liabilityInsurancePublicValidRange */
    readonly liabilityInsurancePublicValidRange?: Maybe<Scalars["String"]["output"]>;
    /** liabilityInsuranceVaultContentType */
    readonly liabilityInsuranceVaultContentType?: Maybe<Scalars["String"]["output"]>;
    /** liabilityInsuranceVaultId */
    readonly liabilityInsuranceVaultId?: Maybe<Scalars["String"]["output"]>;
    /** logoVaultContentType */
    readonly logoVaultContentType?: Maybe<Scalars["String"]["output"]>;
    /** logoVaultId */
    readonly logoVaultId?: Maybe<Scalars["String"]["output"]>;
    /** paymentBankAccountName */
    readonly paymentBankAccountName?: Maybe<Scalars["String"]["output"]>;
    /** paymentBankAccountNumber */
    readonly paymentBankAccountNumber?: Maybe<Scalars["String"]["output"]>;
    /** paymentBankAddress */
    readonly paymentBankAddress?: Maybe<Scalars["String"]["output"]>;
    /** paymentBankCountryCode */
    readonly paymentBankCountryCode?: Maybe<Scalars["String"]["output"]>;
    /** paymentBankName */
    readonly paymentBankName?: Maybe<Scalars["String"]["output"]>;
    /** paymentBankSortCode */
    readonly paymentBankSortCode?: Maybe<Scalars["String"]["output"]>;
    /** paymentCurrency */
    readonly paymentCurrency?: Maybe<Scalars["String"]["output"]>;
    /** paymentCurrencyPreferred */
    readonly paymentCurrencyPreferred?: Maybe<Scalars["String"]["output"]>;
    /** paymentCurrencyTrading */
    readonly paymentCurrencyTrading?: Maybe<Scalars["String"]["output"]>;
    /** socialFacebook */
    readonly socialFacebook?: Maybe<Scalars["String"]["output"]>;
    /** socialInstagram */
    readonly socialInstagram?: Maybe<Scalars["String"]["output"]>;
    /** socialTwitter */
    readonly socialTwitter?: Maybe<Scalars["String"]["output"]>;
    /** status */
    readonly status?: Maybe<Scalars["String"]["output"]>;
    /** surveyedAt */
    readonly surveyedAt?: Maybe<Scalars["String"]["output"]>;
    /** termsOfTradeVaultFileType */
    readonly termsOfTradeVaultFileType?: Maybe<Scalars["String"]["output"]>;
    /** termsOfTradeVaultId */
    readonly termsOfTradeVaultId?: Maybe<Scalars["String"]["output"]>;
    /** tripAdvisorRating */
    readonly tripAdvisorRating?: Maybe<Scalars["String"]["output"]>;
    /** tripAdvisorURL */
    readonly tripAdvisorURL?: Maybe<Scalars["String"]["output"]>;
    /** website */
    readonly website?: Maybe<Scalars["String"]["output"]>;
    /** websiteExists */
    readonly websiteExists?: Maybe<Scalars["String"]["output"]>;
};

export type SupplierSurveyUpdateInput = {
    /** activitiesAvailabilityLevel */
    readonly activitiesAvailabilityLevel?: InputMaybe<Scalars["String"]["input"]>;
    /** bookingOnlineExists */
    readonly bookingOnlineExists?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** bookingSystemScopeAll */
    readonly bookingSystemScopeAll?: InputMaybe<Scalars["String"]["input"]>;
    /** cancellationPolicyVaultContentType */
    readonly cancellationPolicyVaultContentType?: InputMaybe<Scalars["String"]["input"]>;
    /** TcancellationPolicyVaultId */
    readonly cancellationPolicyVaultId?: InputMaybe<Scalars["String"]["input"]>;
    /** companyAddressCountry */
    readonly companyAddressCountry?: InputMaybe<Scalars["String"]["input"]>;
    /** companyAddressLine1 */
    readonly companyAddressLine1?: InputMaybe<Scalars["String"]["input"]>;
    /** companyAddressLine2 */
    readonly companyAddressLine2?: InputMaybe<Scalars["String"]["input"]>;
    /** companyAddressPostalcode */
    readonly companyAddressPostalcode?: InputMaybe<Scalars["String"]["input"]>;
    /** companyAddressRegion */
    readonly companyAddressRegion?: InputMaybe<Scalars["String"]["input"]>;
    /** companyAddressTown */
    readonly companyAddressTown?: InputMaybe<Scalars["String"]["input"]>;
    /** companyAnnualTurnoverAmount */
    readonly companyAnnualTurnoverAmount?: InputMaybe<Scalars["String"]["input"]>;
    /** companyAnnualTurnoverCurrency */
    readonly companyAnnualTurnoverCurrency?: InputMaybe<Scalars["String"]["input"]>;
    /** companyContactAccountsFamilyName */
    readonly companyContactAccountsFamilyName?: InputMaybe<Scalars["String"]["input"]>;
    /** companyContactAccountsGivenName */
    readonly companyContactAccountsGivenName?: InputMaybe<Scalars["String"]["input"]>;
    /** companyContactAccountsPhone */
    readonly companyContactAccountsPhone?: InputMaybe<Scalars["String"]["input"]>;
    /** companyContactAccountsRole */
    readonly companyContactAccountsRole?: InputMaybe<Scalars["String"]["input"]>;
    /** companyContactPhone */
    readonly companyContactPhone?: InputMaybe<Scalars["String"]["input"]>;
    /** companyContactPrimaryFamilyName */
    readonly companyContactPrimaryFamilyName?: InputMaybe<Scalars["String"]["input"]>;
    /** companyContactPrimaryGivenName */
    readonly companyContactPrimaryGivenName?: InputMaybe<Scalars["String"]["input"]>;
    /** companyContactPrimaryRole */
    readonly companyContactPrimaryRole?: InputMaybe<Scalars["String"]["input"]>;
    /** companyEmailPrimary */
    readonly companyEmailPrimary?: InputMaybe<Scalars["String"]["input"]>;
    /** companyLegalAddress */
    readonly companyLegalAddress?: InputMaybe<Scalars["String"]["input"]>;
    /** companyNumberOfEmployees */
    readonly companyNumberOfEmployees?: InputMaybe<Scalars["String"]["input"]>;
    /** companyPerYearTurnover */
    readonly companyPerYearTurnover?: InputMaybe<Scalars["String"]["input"]>;
    /** companyPhoneEmergency */
    readonly companyPhoneEmergency?: InputMaybe<Scalars["String"]["input"]>;
    /** companyPhoneMain */
    readonly companyPhoneMain?: InputMaybe<Scalars["String"]["input"]>;
    /** companyPhoneMobile */
    readonly companyPhoneMobile?: InputMaybe<Scalars["String"]["input"]>;
    /** companyRegisteredName */
    readonly companyRegisteredName?: InputMaybe<Scalars["String"]["input"]>;
    /** companyRegistrationCountry */
    readonly companyRegistrationCountry?: InputMaybe<Scalars["String"]["input"]>;
    /** The companyRegistrationNumber */
    readonly companyRegistrationNumber?: InputMaybe<Scalars["String"]["input"]>;
    /** companyTourismLicenseNumber */
    readonly companyTourismLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
    /** companyTradingName */
    readonly companyTradingName?: InputMaybe<Scalars["String"]["input"]>;
    /** createdAt */
    readonly createdAt?: InputMaybe<Scalars["String"]["input"]>;
    /** employeeInsuranceExists */
    readonly employeeInsuranceExists?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** environmentalPolicyExists */
    readonly environmentalPolicyExists?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** healthAndSafetyPolicyExists */
    readonly healthAndSafetyPolicyExists?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** supplier survey id */
    readonly id: Scalars["String"]["input"];
    /** indemnityInsuranceExists */
    readonly indemnityInsuranceExists?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** liabilityInsurancePublicAmount */
    readonly liabilityInsurancePublicAmount?: InputMaybe<Scalars["String"]["input"]>;
    /** liabilityInsurancePublicCurrency */
    readonly liabilityInsurancePublicCurrency?: InputMaybe<Scalars["String"]["input"]>;
    /** liabilityInsurancePublicExists */
    readonly liabilityInsurancePublicExists?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** liabilityInsurancePublicRecentClaimExists */
    readonly liabilityInsurancePublicRecentClaimExists?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** liabilityInsurancePublicSupplier */
    readonly liabilityInsurancePublicSupplier?: InputMaybe<Scalars["String"]["input"]>;
    /** liabilityInsurancePublicValidRange */
    readonly liabilityInsurancePublicValidRange?: InputMaybe<Scalars["String"]["input"]>;
    /** liabilityInsuranceVaultContentType */
    readonly liabilityInsuranceVaultContentType?: InputMaybe<Scalars["String"]["input"]>;
    /** liabilityInsuranceVaultId */
    readonly liabilityInsuranceVaultId?: InputMaybe<Scalars["String"]["input"]>;
    /** logoVaultContentType */
    readonly logoVaultContentType?: InputMaybe<Scalars["String"]["input"]>;
    /** logoVaultId */
    readonly logoVaultId?: InputMaybe<Scalars["String"]["input"]>;
    /** paymentBankAccountName */
    readonly paymentBankAccountName?: InputMaybe<Scalars["String"]["input"]>;
    /** paymentBankAccountNumber */
    readonly paymentBankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
    /** paymentBankAddress */
    readonly paymentBankAddress?: InputMaybe<Scalars["String"]["input"]>;
    /** paymentBankCountryCode */
    readonly paymentBankCountryCode?: InputMaybe<Scalars["String"]["input"]>;
    /** paymentBankName */
    readonly paymentBankName?: InputMaybe<Scalars["String"]["input"]>;
    /** paymentBankSortCode */
    readonly paymentBankSortCode?: InputMaybe<Scalars["String"]["input"]>;
    /** paymentCurrency */
    readonly paymentCurrency?: InputMaybe<Scalars["String"]["input"]>;
    /** paymentCurrencyPreferred */
    readonly paymentCurrencyPreferred?: InputMaybe<Scalars["String"]["input"]>;
    /** paymentCurrencyTrading */
    readonly paymentCurrencyTrading?: InputMaybe<Scalars["String"]["input"]>;
    /** socialFacebook */
    readonly socialFacebook?: InputMaybe<Scalars["String"]["input"]>;
    /** socialInstagram */
    readonly socialInstagram?: InputMaybe<Scalars["String"]["input"]>;
    /** socialTwitter */
    readonly socialTwitter?: InputMaybe<Scalars["String"]["input"]>;
    /** status */
    readonly status?: InputMaybe<Scalars["String"]["input"]>;
    /** surveyedAt */
    readonly surveyedAt?: InputMaybe<Scalars["String"]["input"]>;
    /** termsOfTradeVaultFileType */
    readonly termsOfTradeVaultFileType?: InputMaybe<Scalars["String"]["input"]>;
    /** termsOfTradeVaultId */
    readonly termsOfTradeVaultId?: InputMaybe<Scalars["String"]["input"]>;
    /** tripAdvisorRating */
    readonly tripAdvisorRating?: InputMaybe<Scalars["String"]["input"]>;
    /** tripAdvisorURL */
    readonly tripAdvisorURL?: InputMaybe<Scalars["String"]["input"]>;
    /** website */
    readonly website?: InputMaybe<Scalars["String"]["input"]>;
    /** websiteExists */
    readonly websiteExists?: InputMaybe<Scalars["String"]["input"]>;
};

export type SupplierUpsertInput = {
    /** The main full name for this supplier. */
    readonly companyAddressCountry?: InputMaybe<Scalars["String"]["input"]>;
    /** The main email for this supplier. */
    readonly companyAddressCountryCode?: InputMaybe<Scalars["String"]["input"]>;
    /** The main full name for this supplier. */
    readonly companyAddressLine1?: InputMaybe<Scalars["String"]["input"]>;
    /** The main email for this supplier. */
    readonly companyAddressLine2?: InputMaybe<Scalars["String"]["input"]>;
    /** The main email for this supplier. */
    readonly companyAddressPostalcode?: InputMaybe<Scalars["String"]["input"]>;
    /** The main company mobile of the supplier. */
    readonly companyAddressRegion?: InputMaybe<Scalars["String"]["input"]>;
    /** The main company phone for this supplier. */
    readonly companyAddressTown?: InputMaybe<Scalars["String"]["input"]>;
    /** The main full name for this supplier. */
    readonly companyContactPhone?: InputMaybe<Scalars["String"]["input"]>;
    /** The main email for this supplier. */
    readonly companyContactPrimaryFamilyName?: InputMaybe<Scalars["String"]["input"]>;
    /** The main company mobile of the supplier. */
    readonly companyContactPrimaryGivenName?: InputMaybe<Scalars["String"]["input"]>;
    /** The main company phone for this supplier. */
    readonly companyContactPrimaryRole?: InputMaybe<Scalars["String"]["input"]>;
    /** The main full name for this supplier. */
    readonly companyEmailPrimary?: InputMaybe<Scalars["String"]["input"]>;
    /** The main email for this supplier. */
    readonly companyPhoneEmergency?: InputMaybe<Scalars["String"]["input"]>;
    /** The main company phone for this supplier. */
    readonly companyPhoneMain?: InputMaybe<Scalars["String"]["input"]>;
    /** The main company mobile of the supplier. */
    readonly companyPhoneMobile?: InputMaybe<Scalars["String"]["input"]>;
    /** The Contract of the supplier. */
    readonly contract?: InputMaybe<Scalars["String"]["input"]>;
    /** The defaultCurrency for this supplier. */
    readonly defaultCurrency?: InputMaybe<Scalars["String"]["input"]>;
    /** Supplier entityConfigurationList */
    readonly entitySystemConfigurationAssociationList?: InputMaybe<
        ReadonlyArray<EntitySystemConfigurationAssociationInput>
    >;
    /** The id of the supplier. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The is active of the supplier. */
    readonly isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** The legalJurisdiction of the supplier. */
    readonly legalJurisdiction?: InputMaybe<Scalars["String"]["input"]>;
    /** The MainContactEmail of the supplier. */
    readonly mainContactEmail?: InputMaybe<Scalars["String"]["input"]>;
    /** The MainContactEmailOverride of the supplier. */
    readonly mainContactEmailOverride?: InputMaybe<Scalars["String"]["input"]>;
    /** The main full name for this supplier. */
    readonly mainContactFullName?: InputMaybe<Scalars["String"]["input"]>;
    /** The name of the supplier. */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** The Note. */
    readonly note?: InputMaybe<Scalars["String"]["input"]>;
    /** The OutReachComment. */
    readonly outReachComment?: InputMaybe<Scalars["String"]["input"]>;
    /** The OutReachDate of the supplier. */
    readonly outReachDate?: InputMaybe<Scalars["String"]["input"]>;
    /** The outReachComment of the supplier. */
    readonly outReachStatus?: InputMaybe<Scalars["String"]["input"]>;
    /** the parent security principal id with whcih too associate this supplier */
    readonly parentSecurityPrincipalId?: InputMaybe<Scalars["String"]["input"]>;
    /** The Partnerid of the supplier. */
    readonly partnerId?: InputMaybe<Scalars["String"]["input"]>;
    /** security rule associations */
    readonly securityRuleAssociations?: InputMaybe<ReadonlyArray<InputMaybe<SecurityRuleAssociations>>>;
    /** The SocialFacebookUrl of the supplier. */
    readonly socialFacebookUrl?: InputMaybe<Scalars["String"]["input"]>;
    /** The SocialInstagramURL. */
    readonly socialInstagramUrl?: InputMaybe<Scalars["String"]["input"]>;
    /** The SocialTwitterUrl of the supplier. */
    readonly socialTwitterUrl?: InputMaybe<Scalars["String"]["input"]>;
    /** The TripAdvisorRating of the supplier. */
    readonly tripAdvisorRating?: InputMaybe<Scalars["String"]["input"]>;
    /** The main company mobile of the supplier. */
    readonly tripAdvisorURL?: InputMaybe<Scalars["String"]["input"]>;
    /** The TripAdvisorUrl of the supplier. */
    readonly tripAdvisorUrl?: InputMaybe<Scalars["String"]["input"]>;
    /** The VoucherContactEmail of the supplier. */
    readonly voucherContactEmail?: InputMaybe<Scalars["String"]["input"]>;
    /** The VoucherContactName of the supplier. */
    readonly voucherContactName?: InputMaybe<Scalars["String"]["input"]>;
    /** The Main VoucherContactPhone of the supplier. */
    readonly voucherContactPhoneMain?: InputMaybe<Scalars["String"]["input"]>;
    /** The Mobile VoucherContactPhone of the supplier. */
    readonly voucherContactPhoneMobile?: InputMaybe<Scalars["String"]["input"]>;
    /** The WebsiteURL of the supplier. */
    readonly websiteUrl?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a synchronisation report */
export type SynchronisationReport = {
    readonly __typename?: "SynchronisationReport";
    /** The name of the synchronisation report */
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The name of the synchronisation report */
    readonly data?: Maybe<Scalars["JSON"]["output"]>;
    /** The productId of the synchronisation report */
    readonly duration?: Maybe<Scalars["String"]["output"]>;
    /** The name of the synchronisation report */
    readonly endTime?: Maybe<Scalars["DateTime"]["output"]>;
    /** The ID */
    readonly id: Scalars["String"]["output"];
    /** The description of the synchronisation report */
    readonly startTime?: Maybe<Scalars["DateTime"]["output"]>;
    /** The synchronisationSource of the synchronisation report */
    readonly status?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationId of the synchronisation report */
    readonly synchronisationId?: Maybe<Scalars["String"]["output"]>;
    /** The languageId of the synchronisation report */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The name of the synchronisation report */
    readonly task?: Maybe<Scalars["String"]["output"]>;
    /** The productId of the synchronisation report */
    readonly totalDeprecratedProductCount?: Maybe<Scalars["Int"]["output"]>;
    /** The productName of the synchronisation report */
    readonly totalFailedCount?: Maybe<Scalars["Int"]["output"]>;
    /** The languageName of the synchronisation report */
    readonly totalRecordCount?: Maybe<Scalars["Int"]["output"]>;
};

export type SynchronisationReportListFilter = {
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** filter by status */
    readonly status?: InputMaybe<Scalars["String"]["input"]>;
    /** filter by synchronisationSources */
    readonly synchronisationSources?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** This represents an synchronisationReport */
export type SynchronisationReportListItem = {
    readonly __typename?: "SynchronisationReportListItem";
    /** The name of the synchronisation report */
    readonly createdAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The name of the synchronisation report */
    readonly data?: Maybe<Scalars["JSON"]["output"]>;
    /** The productId of the synchronisation report */
    readonly duration?: Maybe<Scalars["String"]["output"]>;
    /** The name of the synchronisation report */
    readonly endTime?: Maybe<Scalars["DateTime"]["output"]>;
    /** The ID */
    readonly id: Scalars["String"]["output"];
    /** The description of the synchronisation report */
    readonly startTime?: Maybe<Scalars["DateTime"]["output"]>;
    /** The synchronisationSource of the synchronisation report */
    readonly status?: Maybe<Scalars["String"]["output"]>;
    /** The synchronisationId of the synchronisation report */
    readonly synchronisationId?: Maybe<Scalars["String"]["output"]>;
    /** The languageId of the synchronisation report */
    readonly synchronisationSource?: Maybe<Scalars["String"]["output"]>;
    /** The name of the synchronisation report */
    readonly task?: Maybe<Scalars["String"]["output"]>;
    /** The productId of the synchronisation report */
    readonly totalDeprecratedProductCount?: Maybe<Scalars["Int"]["output"]>;
    /** The productName of the synchronisation report */
    readonly totalFailedCount?: Maybe<Scalars["Int"]["output"]>;
    /** The languageName of the synchronisation report */
    readonly totalRecordCount?: Maybe<Scalars["Int"]["output"]>;
};

/** This respresents the SynchronisationReportList response type */
export type SynchronisationReportListResponse = {
    readonly __typename?: "SynchronisationReportListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** SynchronisationReport list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<SynchronisationReportListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type SynchronisationReportListSort = {
    /** Sort by synchronisationSource */
    readonly synchronisationSource?: InputMaybe<SortDirection>;
};

export type SynchronisationSource = {
    readonly __typename?: "SynchronisationSource";
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly id: Scalars["String"]["output"];
    /** A flag indicating if the Source is INTERNAL */
    readonly isInternal: Scalars["Boolean"]["output"];
    /** Synchronisation Source Name */
    readonly name: Scalars["String"]["output"];
    readonly sourceId?: Maybe<Scalars["String"]["output"]>;
    /** Source Name */
    readonly sourceName?: Maybe<Scalars["String"]["output"]>;
    readonly type: SynchronisationSourceType;
    readonly updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export enum SynchronisationSourceType {
    Agent = "AGENT",
    Development = "DEVELOPMENT",
    Internal = "INTERNAL",
    Standard = "STANDARD",
    Switch = "SWITCH",
}

export type SystemConfiguration = {
    readonly __typename?: "SystemConfiguration";
    readonly allowIcon: Scalars["Boolean"]["output"];
    readonly allowMultiple: Scalars["Boolean"]["output"];
    readonly dataType: SystemConfigurationDataType;
    readonly description?: Maybe<Scalars["String"]["output"]>;
    readonly id: Scalars["ID"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly scope: SystemConfigurationScope;
    readonly subType?: Maybe<Scalars["String"]["output"]>;
    readonly type: SystemConfigurationType;
};

export enum SystemConfigurationDataType {
    AssetHeroImage = "ASSET_HERO_IMAGE",
    AuthenticationMethodList = "AUTHENTICATION_METHOD_LIST",
    Boolean = "BOOLEAN",
    DestinationHeaderMenuVisibility = "DESTINATION_HEADER_MENU_VISIBILITY",
    Email = "EMAIL",
    Guid = "GUID",
    ImageVaultId = "IMAGE_VAULT_ID",
    Number = "NUMBER",
    Phone = "PHONE",
    String = "STRING",
    TranslationString = "TRANSLATION_STRING",
}

/** This represents a System Configuration option */
export type SystemConfigurationOption = {
    readonly __typename?: "SystemConfigurationOption";
    /** A flag indicating that the record can have an icon. */
    readonly allowIcon: Scalars["Boolean"]["output"];
    /** A flag indicating that the record can have multiple values. */
    readonly allowMultiple: Scalars["Boolean"]["output"];
    /** code */
    readonly code?: Maybe<Scalars["String"]["output"]>;
    /** description */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID for the industry */
    readonly id: Scalars["String"]["output"];
    /** Descendent level */
    readonly level?: Maybe<Scalars["Int"]["output"]>;
    /** parentId */
    readonly parentId?: Maybe<Scalars["String"]["output"]>;
    /** Type of system configuration. */
    readonly systemConfigurationType: Scalars["String"]["output"];
    /** type */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** value */
    readonly value: Scalars["String"]["output"];
};

export enum SystemConfigurationScope {
    Availability = "AVAILABILITY",
    BookingWeb = "BOOKING_WEB",
    Communication = "COMMUNICATION",
    Global = "GLOBAL",
    HubWeb = "HUB_WEB",
    Payment = "PAYMENT",
}

export enum SystemConfigurationType {
    AuthenticationMethodList = "AUTHENTICATION_METHOD_LIST",
    BookingCutoffDuration = "BOOKING_CUTOFF_DURATION",
    CarouselImageVaultId = "CAROUSEL_IMAGE_VAULT_ID",
    ChannelFailureNotificationEmailAddress = "CHANNEL_FAILURE_NOTIFICATION_EMAIL_ADDRESS",
    Contact = "CONTACT",
    CustomerServiceEmailAddress = "CUSTOMER_SERVICE_EMAIL_ADDRESS",
    CustomerServicePhoneNumber = "CUSTOMER_SERVICE_PHONE_NUMBER",
    DefaultAvailabilityType = "DEFAULT_AVAILABILITY_TYPE",
    Disclaimer = "DISCLAIMER",
    EmailAcknowledgementEntityTemplateId = "EMAIL_ACKNOWLEDGEMENT_ENTITY_TEMPLATE_ID",
    EmailBookingAvailabilityCancellationTemplateId = "EMAIL_BOOKING_AVAILABILITY_CANCELLATION_TEMPLATE_ID",
    EmailBookingAvailabilityConfirmationTemplateId = "EMAIL_BOOKING_AVAILABILITY_CONFIRMATION_TEMPLATE_ID",
    EmailBookingAvailabilityOnArrivalInstructionsTemplateId = "EMAIL_BOOKING_AVAILABILITY_ON_ARRIVAL_INSTRUCTIONS_TEMPLATE_ID",
    EmailEntityTemplateId = "EMAIL_ENTITY_TEMPLATE_ID",
    EnabledCurationBreadcrumbs = "ENABLED_CURATION_BREADCRUMBS",
    EnableAgentConfirmationNotification = "ENABLE_AGENT_CONFIRMATION_NOTIFICATION",
    EnableAgentFailureNotification = "ENABLE_AGENT_FAILURE_NOTIFICATION",
    EnableChannelConsumerFailureNotification = "ENABLE_CHANNEL_CONSUMER_FAILURE_NOTIFICATION",
    EnableChannelConsumerNotification = "ENABLE_CHANNEL_CONSUMER_NOTIFICATION",
    EnableChannelFailureNotification = "ENABLE_CHANNEL_FAILURE_NOTIFICATION",
    EnableChannelSupplierBccNotification = "ENABLE_CHANNEL_SUPPLIER_BCC_NOTIFICATION",
    EnableChannelSupplierNotification = "ENABLE_CHANNEL_SUPPLIER_NOTIFICATION",
    EnableDirectApplicationConsumerNotification = "ENABLE_DIRECT_APPLICATION_CONSUMER_NOTIFICATION",
    EnableSupplierNotification = "ENABLE_SUPPLIER_NOTIFICATION",
    Enforce_3DSecure = "ENFORCE_3D_SECURE",
    ExtraConfirmationEmailAddress = "EXTRA_CONFIRMATION_EMAIL_ADDRESS",
    FooterAddress = "FOOTER_ADDRESS",
    HomePageHeroText = "HOME_PAGE_HERO_TEXT",
    HomePageWeatherWidget = "HOME_PAGE_WEATHER_WIDGET",
    Intro = "INTRO",
    LanguageSupport = "LANGUAGE_SUPPORT",
    MetaRobotsTag = "META_ROBOTS_TAG",
    ProductUpsertMode = "PRODUCT_UPSERT_MODE",
    SearchPlaceholder = "SEARCH_PLACEHOLDER",
    SenderEmailAddressConsumer = "SENDER_EMAIL_ADDRESS_CONSUMER",
    SenderEmailAddressDefault = "SENDER_EMAIL_ADDRESS_DEFAULT",
    SendConsumerConfirmation = "SEND_CONSUMER_CONFIRMATION",
    Setting = "SETTING",
    SignOff = "SIGN_OFF",
    Social = "SOCIAL",
    SupplierUpsertMode = "SUPPLIER_UPSERT_MODE",
    UseBookingProcessStateAsState = "USE_BOOKING_PROCESS_STATE_AS_STATE",
    UseCachedProductList = "USE_CACHED_PRODUCT_LIST",
    UseOpenSearch = "USE_OPEN_SEARCH",
    Usp = "USP",
    VoucherHidePrice = "VOUCHER_HIDE_PRICE",
}

/** This represents a SystemLog. */
export type SystemLog = {
    readonly __typename?: "SystemLog";
    /** The row number. */
    readonly id?: Maybe<Scalars["String"]["output"]>;
    /** The level of the log. ie (Info, Error) */
    readonly level?: Maybe<Scalars["String"]["output"]>;
    /** The message. */
    readonly message?: Maybe<Scalars["String"]["output"]>;
    /** The origin of the request that logged the event.  This may be 'DIRECT' if a call to the API by a partner or it will show the URL of a White-label site if indirectly */
    readonly origin?: Maybe<Scalars["String"]["output"]>;
    /** The originRequestId being typically from the web host or api gateway. */
    readonly originRequestId?: Maybe<Scalars["String"]["output"]>;
    /** The payload from the log. */
    readonly payload?: Maybe<Scalars["JSON"]["output"]>;
    /** The requestId as generated by the Lambda handler. */
    readonly requestId?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalId that was authenticated at the time the log was written. */
    readonly securityPrincipalId?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalName that was authenticated at the time the log was written. */
    readonly securityPrincipalName?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUser that was authenticated at the time the log was written. */
    readonly securityPrincipalUser?: Maybe<SecurityPrincipalUser>;
    /** The securityPrincipalUserId that was authenticated at the time the log was written. */
    readonly securityPrincipalUserId?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUserName that was authenticated at the time the log was written. */
    readonly securityPrincipalUserName?: Maybe<Scalars["String"]["output"]>;
    /** The service that wrote the log. */
    readonly service?: Maybe<Scalars["String"]["output"]>;
    /** The stack to the file reporting the log. */
    readonly stack?: Maybe<Scalars["JSON"]["output"]>;
    /** The stage the system was running on when it wrote the error. */
    readonly stage?: Maybe<Scalars["String"]["output"]>;
    /** The time. */
    readonly time?: Maybe<Scalars["String"]["output"]>;
};

export enum SystemLogLevel {
    /** debug */
    Debug = "debug",
    /** error */
    Error = "error",
    /** info */
    Info = "info",
}

/** This represents a list of SystemLog. */
export type SystemLogList = {
    readonly __typename?: "SystemLogList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes?: Maybe<ReadonlyArray<Maybe<SystemLog>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type SystemLogListFilter = {
    /** Filter by the levels of the log. ie (INFO, DEBUG, ERROR) */
    readonly levels?: InputMaybe<ReadonlyArray<SystemLogLevel>>;
    /** Filter by the partnerChannelId. */
    readonly partnerChannelId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by the phase that the given service was in when writting the log. */
    readonly phase?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by the requestId. */
    readonly requestId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by the contents of the message. */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by the securityPrincipalId of the authenticated SecurityPrincipla responsible for the even. */
    readonly securityPrincipalId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by the securityPrincipalUserId of the authenticated SecurityPrincipalUser (IF ANY) responsible for the event. */
    readonly securityPrincipalUserId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by the service (graphQL, hub, booking-commit etc) that wrote the log message. */
    readonly service?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by the number of spanType to be added. */
    readonly spanDuration?: InputMaybe<SystemLogSpanDuration>;
    /** Filter by the type of spanDuration to be added (D,M,Y). */
    readonly spanType?: InputMaybe<SystemLogSpanType>;
    /** Filter by the stage (Production, Sandbox, Staging etc) that the given service was running on when writing the log. */
    readonly stage?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by the Date form which the logs should be scanned. Default to current UTC hour */
    readonly startDate?: InputMaybe<Scalars["String"]["input"]>;
};

export enum SystemLogSpanDuration {
    /** 1 Hour */
    Span_1 = 1,
    /** 2 Hours */
    Span_2 = 2,
    /** 1 Day */
    Span_24 = 24,
    /** 2 Days */
    Span_48 = 48,
    /** 1 Week */
    Span_168 = 168,
    /** 1 Month */
    Span_720 = 720,
}

export enum SystemLogSpanType {
    /** Days */
    D = NaN,
    /** Hours */
    H = NaN,
    /** Month */
    M = NaN,
}

/** This represents a System Page State */
export type SystemPageState = {
    readonly __typename?: "SystemPageState";
    readonly id: Scalars["ID"]["output"];
    readonly name: Scalars["String"]["output"];
    readonly owningSecurityPrincipalId: Scalars["ID"]["output"];
    readonly owningSecurityPrincipalUserId?: Maybe<Scalars["ID"]["output"]>;
    readonly pageKey: Scalars["String"]["output"];
    readonly stateJson?: Maybe<Scalars["JSON"]["output"]>;
};

export type SystemPageStateCreateInput = {
    readonly name: Scalars["String"]["input"];
    readonly pageKey: Scalars["String"]["input"];
    readonly stateJson: Scalars["JSON"]["input"];
};

export type SystemPageStateDeleteInput = {
    readonly id: Scalars["ID"]["input"];
};

export type SystemPageStateList = {
    readonly __typename?: "SystemPageStateList";
    readonly nodes: ReadonlyArray<SystemPageState>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type SystemTagAssociationUpsertInput = {
    readonly id: Scalars["String"]["input"];
};

/** SystemTagFactor */
export type SystemTagFactor = {
    readonly __typename?: "SystemTagFactor";
    /** The code of the tag. */
    readonly code?: Maybe<Scalars["String"]["output"]>;
    /** The description of the tag. */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** The unique id for the SystemTag. */
    readonly id: Scalars["String"]["output"];
    /** The key of the tag. */
    readonly key: Scalars["String"]["output"];
    /** The hierarchy level of the tag. */
    readonly level?: Maybe<Scalars["Int"]["output"]>;
    /** Maximum child tags allowed for this parent. */
    readonly maxChildren?: Maybe<Scalars["Int"]["output"]>;
    /** Maximum allowed tags for the Factor in the ConsumerTrip UI */
    readonly maxConsumerChildren?: Maybe<Scalars["Int"]["output"]>;
    /** Minimum child tags required for this parent. */
    readonly minChildren?: Maybe<Scalars["Int"]["output"]>;
    /** The name of the Tag. */
    readonly name: Scalars["String"]["output"];
    /** The tag narrative. */
    readonly narrative?: Maybe<Scalars["String"]["output"]>;
    /** The ordinal position of the tag. */
    readonly ordinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** The parentId of the tag. */
    readonly parentId?: Maybe<Scalars["String"]["output"]>;
    /** The type of the Tag. */
    readonly type: Scalars["String"]["output"];
};

/** This represents a list of SystemTagFactor. */
export type SystemTagFactorList = {
    readonly __typename?: "SystemTagFactorList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes?: Maybe<ReadonlyArray<Maybe<SystemTagFactor>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type SystemTagFilter = {
    readonly __typename?: "SystemTagFilter";
    readonly hasAll?: Maybe<ReadonlyArray<Scalars["ID"]["output"]>>;
    readonly hasNot?: Maybe<ReadonlyArray<Scalars["ID"]["output"]>>;
    readonly hasUnion?: Maybe<ReadonlyArray<Maybe<ReadonlyArray<Scalars["ID"]["output"]>>>>;
};

export type SystemTagFilterInput = {
    readonly hasAll?: InputMaybe<ReadonlyArray<Scalars["ID"]["input"]>>;
    readonly hasNot?: InputMaybe<ReadonlyArray<Scalars["ID"]["input"]>>;
    readonly hasUnion?: InputMaybe<ReadonlyArray<InputMaybe<ReadonlyArray<Scalars["ID"]["input"]>>>>;
};

/** This represents a list of SystemTag. */
export type SystemTagList = {
    readonly __typename?: "SystemTagList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes?: Maybe<ReadonlyArray<Maybe<EntitySystemTag>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type SystemTagListFilter = {
    readonly entityId?: InputMaybe<Scalars["String"]["input"]>;
    readonly idList?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** filter by name. */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    readonly typeList?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

export type SystemTagListSort = {
    /** Sort by name. */
    readonly name?: InputMaybe<SortDirection>;
};

/** This represents a SystemTemplate. */
export type SystemTemplate = {
    readonly __typename?: "SystemTemplate";
    /** The fully rendered HTML of the template. */
    readonly html?: Maybe<Scalars["String"]["output"]>;
    /** The unique id for the SystemTemplate. */
    readonly id: Scalars["String"]["output"];
    /** The name. */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    readonly structure?: Maybe<Scalars["String"]["output"]>;
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

export type SystemTemplateDeleteInput = {
    /** The ID of the SystemTemplate to be deleted. */
    readonly id: Scalars["String"]["input"];
};

/** This represents a list of SystemTemplate. */
export type SystemTemplateList = {
    readonly __typename?: "SystemTemplateList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes?: Maybe<ReadonlyArray<Maybe<SystemTemplate>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type SystemTemplateListFilter = {
    /** filter by name. */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
};

export type SystemTemplateUpsertInput = {
    /** The ID of the SystemTemplate. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The name of the SystemTemplate. */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
};

export type TaxonomyTermListFilter = {
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents an taxonomy term */
export type TaxonomyTermListItem = {
    readonly __typename?: "TaxonomyTermListItem";
    /** The unique Holibob ID for the Taxonomy Term */
    readonly id: Scalars["String"]["output"];
    /** The list of match for the taxonomy term. */
    readonly matchList?: Maybe<TaxonomyTermMatchListResponse>;
    /** The type of the Taxonomy Term */
    readonly type: Scalars["String"]["output"];
};

/** This respresents the TaxonomyTermList response type */
export type TaxonomyTermListResponse = {
    readonly __typename?: "TaxonomyTermListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** TaxonomyTerm list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<TaxonomyTermListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type TaxonomyTermListSort = {
    /** Sort by type */
    readonly type?: InputMaybe<SortDirection>;
};

export type TaxonomyTermMatchListFilter = {
    /** Filter by taxonomyTermId */
    readonly requiresMatching?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Search string */
    readonly search?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by taxonomyTermId */
    readonly taxonomyTermId?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents an taxonomy term match */
export type TaxonomyTermMatchListItem = {
    readonly __typename?: "TaxonomyTermMatchListItem";
    /** The dataType of the Taxonomy Term Match */
    readonly dataType?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the Taxonomy Term Match */
    readonly id: Scalars["String"]["output"];
    /** The sourceLabel of the Taxonomy Term Match */
    readonly sourceLabel?: Maybe<Scalars["String"]["output"]>;
    /** The taxonomyTermId of the Taxonomy Term Match */
    readonly taxonomyTermId?: Maybe<Scalars["String"]["output"]>;
    /** The type of the Taxonomy Term Match */
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

/** This respresents the TaxonomyTermMatchList response type */
export type TaxonomyTermMatchListResponse = {
    readonly __typename?: "TaxonomyTermMatchListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** TaxonomyTermMatch list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<TaxonomyTermMatchListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type TaxonomyTermMatchListSort = {
    /** Sort by type */
    readonly type?: InputMaybe<SortDirection>;
};

/** This represents a brnad */
export type TestBooking = {
    readonly __typename?: "TestBooking";
    /** A brief description of the test product */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the test product */
    readonly id: Scalars["String"]["output"];
};

export type TestBookingDeleteInput = {
    /** The id of the test booking */
    readonly id: Scalars["String"]["input"];
};

export type TestBookingListFilter = {
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
};

/** This represents an test booking */
export type TestBookingListItem = {
    readonly __typename?: "TestBookingListItem";
    /** A brief description of the test product */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the test product */
    readonly id: Scalars["String"]["output"];
};

/** This respresents the Test Booking List response type */
export type TestBookingListResponse = {
    readonly __typename?: "TestBookingListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** Test Booking list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<TestBookingListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type TestBookingListSort = {
    /** Sort by id */
    readonly id?: InputMaybe<SortDirection>;
};

export type TestBookingUpsertInput = {
    /** The description of the test booking */
    readonly description: Scalars["String"]["input"];
    /** Test Booking Id */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a test product */
export type TestProduct = {
    readonly __typename?: "TestProduct";
    /** A brief description of the test product */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the test product */
    readonly id: Scalars["String"]["output"];
    /** The productId of the test product */
    readonly productId?: Maybe<Scalars["String"]["output"]>;
    /** The Name of the test product */
    readonly productName?: Maybe<Scalars["String"]["output"]>;
    /** The type of the test product */
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

export type TestProductDeleteInput = {
    /** The id of the test product */
    readonly id: Scalars["String"]["input"];
};

export type TestProductListFilter = {
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** filter by productId */
    readonly productId?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents an test product */
export type TestProductListItem = {
    readonly __typename?: "TestProductListItem";
    /** A brief description of the test product */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the test product */
    readonly id: Scalars["String"]["output"];
    /** The productId of the test product */
    readonly productId?: Maybe<Scalars["String"]["output"]>;
    /** The Name of the test product */
    readonly productName?: Maybe<Scalars["String"]["output"]>;
    /** The type of the test product */
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

/** This respresents the Test Product List response type */
export type TestProductListResponse = {
    readonly __typename?: "TestProductListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** Test Product list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<TestProductListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type TestProductListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

export type TestProductUpsertInput = {
    /** The description of the test product */
    readonly description?: InputMaybe<Scalars["String"]["input"]>;
    /** Test Product Id */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** The product id of test product */
    readonly productId: Scalars["String"]["input"];
    /** The type of the test product */
    readonly type?: InputMaybe<Scalars["String"]["input"]>;
};

export type TimeRange = {
    readonly __typename?: "TimeRange";
    readonly end?: Maybe<Scalars["LocalTime"]["output"]>;
    readonly start?: Maybe<Scalars["LocalTime"]["output"]>;
};

export type TimeRangeInput = {
    readonly end?: InputMaybe<Scalars["LocalTime"]["input"]>;
    readonly start?: InputMaybe<Scalars["LocalTime"]["input"]>;
};

export enum TokenReclaimResponseStatus {
    Accepted = "ACCEPTED",
    Expired = "EXPIRED",
    Incorrect = "INCORRECT",
}

/** This represents a translation. */
export type Translation = {
    readonly __typename?: "Translation";
    /** The language code. */
    readonly languageCode?: Maybe<Scalars["String"]["output"]>;
    /** The value */
    readonly value?: Maybe<Scalars["String"]["output"]>;
};

export type TranslationExport = {
    readonly __typename?: "TranslationExport";
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly distributionChannelId: Scalars["ID"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly state: TranslationExportState;
};

export type TranslationExportFile = {
    readonly __typename?: "TranslationExportFile";
    readonly language: LanguageCode;
    readonly machineTranslated?: Maybe<Scalars["String"]["output"]>;
    readonly translated?: Maybe<Scalars["String"]["output"]>;
};

export type TranslationExportFileList = {
    readonly __typename?: "TranslationExportFileList";
    readonly nodes: ReadonlyArray<TranslationExportFile>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type TranslationExportList = {
    readonly __typename?: "TranslationExportList";
    readonly nodes: ReadonlyArray<TranslationExport>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type TranslationExportListFilter = {
    readonly distributionChannelIdList?: InputMaybe<ReadonlyArray<Scalars["ID"]["input"]>>;
    readonly idList?: InputMaybe<ReadonlyArray<Scalars["ID"]["input"]>>;
    readonly status?: InputMaybe<ReadonlyArray<TranslationExportStatus>>;
};

export type TranslationExportState =
    | TranslationExportStateError
    | TranslationExportStateFinished
    | TranslationExportStateInProgress;

export type TranslationExportStateError = {
    readonly __typename?: "TranslationExportStateError";
    readonly errorMessage: Scalars["String"]["output"];
    readonly finishedAt: Scalars["DateTime"]["output"];
};

export type TranslationExportStateFinished = {
    readonly __typename?: "TranslationExportStateFinished";
    readonly finishedAt: Scalars["DateTime"]["output"];
    readonly rootTranslationFile: Scalars["String"]["output"];
    readonly translationFileList: TranslationExportFileList;
};

export type TranslationExportStateInProgress = {
    readonly __typename?: "TranslationExportStateInProgress";
    readonly status: Scalars["String"]["output"];
};

export enum TranslationExportStatus {
    Error = "error",
    Finished = "finished",
    InProgress = "in-progress",
}

export type TranslationImport = {
    readonly __typename?: "TranslationImport";
    readonly createdAt: Scalars["DateTime"]["output"];
    readonly distributionChannelId: Scalars["ID"]["output"];
    readonly id: Scalars["ID"]["output"];
    readonly state: TranslationImportState;
};

export type TranslationImportList = {
    readonly __typename?: "TranslationImportList";
    readonly nodes: ReadonlyArray<TranslationImport>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
};

export type TranslationImportListFilter = {
    readonly distributionChannelIdList?: InputMaybe<ReadonlyArray<Scalars["ID"]["input"]>>;
    readonly idList?: InputMaybe<ReadonlyArray<Scalars["ID"]["input"]>>;
    readonly status?: InputMaybe<ReadonlyArray<TranslationImportStatus>>;
};

export type TranslationImportState =
    | TranslationImportStateError
    | TranslationImportStateFinished
    | TranslationImportStateInProgress;

export type TranslationImportStateError = {
    readonly __typename?: "TranslationImportStateError";
    readonly errorMessage: Scalars["String"]["output"];
    readonly finishedAt: Scalars["DateTime"]["output"];
};

export type TranslationImportStateFinished = {
    readonly __typename?: "TranslationImportStateFinished";
    readonly finishedAt: Scalars["DateTime"]["output"];
    readonly imported: Scalars["Int"]["output"];
    readonly rejected: Scalars["Int"]["output"];
};

export type TranslationImportStateInProgress = {
    readonly __typename?: "TranslationImportStateInProgress";
    readonly status: Scalars["String"]["output"];
};

export enum TranslationImportStatus {
    Error = "error",
    Finished = "finished",
    InProgress = "in-progress",
}

export type TranslationInput = {
    /** The language code. */
    readonly languageCode?: InputMaybe<Scalars["String"]["input"]>;
    /** The value */
    readonly value?: InputMaybe<Scalars["String"]["input"]>;
};

/** This represents a list of Translation. */
export type TranslationList = {
    readonly __typename?: "TranslationList";
    readonly nodes: ReadonlyArray<Translation>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

/** Type of availability */
export enum UsernameValidateAuthenticationMethod {
    /** COGNITO */
    Cognito = "COGNITO",
    /** COGNITO_LEGACY */
    CognitoLegacy = "COGNITO_LEGACY",
    /** HOLIBOB_IDP */
    HolibobIdp = "HOLIBOB_IDP",
    /** OAUTH */
    Oauth = "OAUTH",
}

/** Username Validate Response */
export type UsernameValidateResponse = {
    readonly __typename?: "UsernameValidateResponse";
    readonly authenticationMethod: UsernameValidateAuthenticationMethod;
    readonly securityPrincipalUserEmail?: Maybe<Scalars["String"]["output"]>;
    readonly securityPrincipalUserId: Scalars["String"]["output"];
};

/** This represents a Vault */
export type Vault = {
    readonly __typename?: "Vault";
    readonly contentType: Scalars["String"]["output"];
    /** createdAt */
    readonly createdAt?: Maybe<Scalars["Date"]["output"]>;
    readonly extension: Scalars["String"]["output"];
    readonly filename: Scalars["String"]["output"];
    readonly id: Scalars["String"]["output"];
    /** updatedAt */
    readonly updatedAt?: Maybe<Scalars["Date"]["output"]>;
    readonly uploadUrl?: Maybe<Scalars["String"]["output"]>;
    readonly url?: Maybe<Scalars["String"]["output"]>;
};

/** This represents a Viewer. */
export type Viewer = {
    readonly __typename?: "Viewer";
    /** The authentication status. */
    readonly authStatus: Scalars["String"]["output"];
    /**
     * The method by which authentication was achieved.
     * @deprecated No longer provided
     */
    readonly authType?: Maybe<Scalars["String"]["output"]>;
    /** Viewer brand. */
    readonly brand: Brand;
    /** The id of the brand assigned to this channel. */
    readonly brandId?: Maybe<Scalars["String"]["output"]>;
    /** The currencies that this viewer can request content in. */
    readonly currencyCodeData?: Maybe<ReadonlyArray<Scalars["String"]["output"]>>;
    readonly defaultLanguage: Language;
    /** The viewer's distributionChannel */
    readonly distributionChannel?: Maybe<DistributionChannel>;
    /** The viewer's entity configuration */
    readonly entityConfiguration: ReadonlyArray<ViewerEntityConfiguration>;
    /**
     * Viewer google tag manager code.
     * @deprecated Use entityConfiguration.googleTrackingId instead
     */
    readonly googleTrackingId?: Maybe<Scalars["String"]["output"]>;
    /** Viewer uses shopping cart. */
    readonly hasShoppingCart?: Maybe<Scalars["Boolean"]["output"]>;
    /** Viewer isConsumerFacing. */
    readonly isConsumerFacing?: Maybe<Scalars["Boolean"]["output"]>;
    /** Viewer isSandboxed. */
    readonly isSandboxed?: Maybe<Scalars["Boolean"]["output"]>;
    readonly language: Language;
    /** Viewer partnerChannelId. */
    readonly partnerChannelId?: Maybe<Scalars["String"]["output"]>;
    /** Viewer partnerChannelName. */
    readonly partnerChannelName?: Maybe<Scalars["String"]["output"]>;
    /**
     * Viewer partnerChannelType.
     * @deprecated No longer provided
     */
    readonly partnerChannelType?: Maybe<Scalars["String"]["output"]>;
    /** Viewer partnerId. */
    readonly partnerId?: Maybe<Scalars["String"]["output"]>;
    /** Viewer partnerName. */
    readonly partnerName?: Maybe<Scalars["String"]["output"]>;
    /** Partner payment type. */
    readonly paymentType?: Maybe<PartnerChannelPaymentType>;
    /** The permissions of this partnerChannel. */
    readonly permissions: ReadonlyArray<Scalars["String"]["output"]>;
    /**
     * Viewer principalId.
     * @deprecated No longer provided
     */
    readonly principalId?: Maybe<Scalars["String"]["output"]>;
    /**
     * Viewer principalType.
     * @deprecated No longer provided
     */
    readonly principalType?: Maybe<Scalars["String"]["output"]>;
    /** Viewer saleCurrency. */
    readonly saleCurrency?: Maybe<Scalars["String"]["output"]>;
    /** The security principal for this viewer. */
    readonly securityPrincipal?: Maybe<ViewerSecurityPrincipal>;
    readonly supportedLanguages: ReadonlyArray<Language>;
    /** Viewer uses suppress new search. */
    readonly suppressNewSearch?: Maybe<Scalars["Boolean"]["output"]>;
};

/** This represents a Viewer. */
export type ViewerEntityConfigurationArgs = {
    scope?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
};

/** This represents an ViewerEntityConfiguration */
export type ViewerEntityConfiguration = {
    readonly __typename?: "ViewerEntityConfiguration";
    /** Entity configuration icon */
    readonly icon?: Maybe<EntitySystemConfigurationAssociationIcon>;
    /** The id of the ViewerEntityConfiguration */
    readonly id: Scalars["String"]["output"];
    /** The ordinal position of the ViewerEntityConfiguration */
    readonly ordinalPosition: Scalars["Int"]["output"];
    /** The subtype of the ViewerEntityConfiguration */
    readonly subType: Scalars["String"]["output"];
    /** The type of the ViewerEntityConfiguration */
    readonly type: Scalars["String"]["output"];
    /** The value of the ViewerEntityConfiguration */
    readonly value: Scalars["String"]["output"];
};

export type ViewerResetPasswordInput = {
    /** The new password to set on the securityPrincipalUser */
    readonly password: Scalars["String"]["input"];
};

/** This represents a viewer's SecurityPrincipal. */
export type ViewerSecurityPrincipal = {
    readonly __typename?: "ViewerSecurityPrincipal";
    /** The ID in vault for the securityPrincipal's bannerImage. */
    readonly bannerImageVaultId?: Maybe<Scalars["String"]["output"]>;
    /** The vaultUrl for the securityPrincipal's bannerImage. */
    readonly bannerImageVaultUrl?: Maybe<Scalars["String"]["output"]>;
    /** The foreignId of securityPrincipal. */
    readonly foreignId?: Maybe<Scalars["String"]["output"]>;
    /** The Id of the securityPrincipal. */
    readonly id: Scalars["String"]["output"];
    /** The ID in vault for the securityPrincipal's logo. */
    readonly logoVaultId?: Maybe<Scalars["String"]["output"]>;
    /** The vaultUrl for the securityPrincipal's logo. */
    readonly logoVaultUrl?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipal name. */
    readonly name: Scalars["String"]["output"];
    /** Indicates that the users securityPrincipal profile is complete. */
    readonly profileComplete?: Maybe<Scalars["Boolean"]["output"]>;
    /** The type of securityPrincipal. */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** The user for the securityPrincipal. */
    readonly user?: Maybe<ViewerSecurityPrincipalUser>;
};

/** This represents a viewer's SecurityPrincipalUser. */
export type ViewerSecurityPrincipalUser = {
    readonly __typename?: "ViewerSecurityPrincipalUser";
    /** The ID in vault for the securityPrincipalUsers avatar image. */
    readonly avatarVaultId?: Maybe<Scalars["String"]["output"]>;
    /** The vaultUrl for the securityPrincipalUsers avatar image. */
    readonly avatarVaultUrl?: Maybe<Scalars["String"]["output"]>;
    /** The email of this securityPrincipalUser. */
    readonly email?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUser first name. */
    readonly firstName?: Maybe<Scalars["String"]["output"]>;
    /** The Id of the securityPrincipalUser. */
    readonly id: Scalars["String"]["output"];
    /** The securityPrincipalUser last name. */
    readonly lastName?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUser name. */
    readonly name: Scalars["String"]["output"];
    /** The phone number of this securityPrincipalUser. */
    readonly phone?: Maybe<Scalars["String"]["output"]>;
};

export enum WeatherForecastTypes {
    ClearDay = "clear-day",
    ClearNight = "clear-night",
    Cloudy = "cloudy",
    Fog = "fog",
    Hail = "hail",
    PartlyCloudyDay = "partly-cloudy-day",
    PartlyCloudyNight = "partly-cloudy-night",
    Rain = "rain",
    RainSnow = "rain-snow",
    RainSnowShowerDay = "rain-snow-showers-day",
    RainSnowShowerNight = "rain-snow-showers-night",
    ShowersDay = "showers-day",
    ShowersNight = "showers-night",
    Sleet = "sleet",
    Snow = "snow",
    SnowShowersDay = "snow-showers-day",
    SnowShowersNight = "snow-showers-night",
    Thunder = "thunder",
    ThunderRain = "thunder-rain",
    ThunderShowersDay = "thunder-showers-day",
    ThunderShowersNight = "thunder-showers-night",
    Wind = "wind",
}

export type WorkflowContextData = {
    readonly __typename?: "WorkflowContextData";
    readonly bookingId: Scalars["ID"]["output"];
    readonly emailConfirmationConsumerSentAt?: Maybe<Scalars["DateTime"]["output"]>;
};

/** This represents a Workstream */
export type Workstream = {
    readonly __typename?: "Workstream";
    /** The color of the  assignedSecurityPrincipalUSer default avatar */
    readonly assignedSecurityPrincipalUserAvatarColor?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUSer that this item is assigned to */
    readonly assignedSecurityPrincipalUserId?: Maybe<Scalars["String"]["output"]>;
    /** The resolved name of the securityPrincipalUSer that this item is assigned to */
    readonly assignedSecurityPrincipalUserName?: Maybe<Scalars["String"]["output"]>;
    readonly childStoryPointDoneCount?: Maybe<Scalars["Int"]["output"]>;
    readonly childStoryPointInProgressCount?: Maybe<Scalars["Int"]["output"]>;
    readonly childStoryPointReadyCount?: Maybe<Scalars["Int"]["output"]>;
    readonly childStoryPointToDoCount?: Maybe<Scalars["Int"]["output"]>;
    readonly childStoryPointTotalCount?: Maybe<Scalars["Int"]["output"]>;
    /** The resolved issuePrefix and issueNumber */
    readonly code?: Maybe<Scalars["String"]["output"]>;
    /** The date this item was created */
    readonly createdAt: Scalars["DateTime"]["output"];
    /** The color of the createdSecurityPrincipalUSer default avatar */
    readonly createdSecurityPrincipalUserAvatarColor?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUser that created this item */
    readonly createdSecurityPrincipalUserId?: Maybe<Scalars["String"]["output"]>;
    /** The resolved name of the securityPrincipalUSer that created this item */
    readonly createdSecurityPrincipalUserName?: Maybe<Scalars["String"]["output"]>;
    /** The description of the item in JSON format */
    readonly descriptionJson?: Maybe<Scalars["JSON"]["output"]>;
    /** The URL to the confluence docmentation for this Workstream */
    readonly documentationUrl?: Maybe<Scalars["String"]["output"]>;
    /** the epic id */
    readonly epicId?: Maybe<Scalars["String"]["output"]>;
    readonly epicName?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID */
    readonly id: Scalars["String"]["output"];
    /** the name */
    readonly isComplete?: Maybe<Scalars["String"]["output"]>;
    /** Indicates that the item is on hold */
    readonly isOnHold?: Maybe<Scalars["Boolean"]["output"]>;
    /** The time at which the Workstream or any of its descendants were last created or updated */
    readonly lastEventAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The time at which the current viewer last viewed the Workstream details. */
    readonly lastSeenAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** the name */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** The prefix for the preview deployments of Hub, Booking and API */
    readonly previewPrefix?: Maybe<Scalars["String"]["output"]>;
    /** the name */
    readonly priorityColor?: Maybe<Scalars["String"]["output"]>;
    /** the name */
    readonly priorityName?: Maybe<Scalars["String"]["output"]>;
    /** the name */
    readonly priorityOrdinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** the name */
    readonly prioritySystemEnumerationId?: Maybe<Scalars["String"]["output"]>;
    /** The count of related Workstream */
    readonly relatedCount?: Maybe<Scalars["Int"]["output"]>;
    /** The release note of the item in JSON format */
    readonly releaseNoteJson?: Maybe<Scalars["JSON"]["output"]>;
    /** The current state of this item */
    readonly stateColor?: Maybe<Scalars["String"]["output"]>;
    /** The current state of this item */
    readonly stateName?: Maybe<Scalars["String"]["output"]>;
    /** The current state of this item */
    readonly stateOrdinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** The current state of this item */
    readonly stateSystemEnumerationId?: Maybe<Scalars["String"]["output"]>;
    /** The estimate for this Workstream */
    readonly storyPointEstimate?: Maybe<Scalars["Int"]["output"]>;
    readonly storyPointProgress?: Maybe<Scalars["Int"]["output"]>;
    readonly storyPointReleased?: Maybe<Scalars["Int"]["output"]>;
    readonly storyPointRemaining?: Maybe<Scalars["Int"]["output"]>;
    readonly storyPointSpike?: Maybe<Scalars["Int"]["output"]>;
    /** The list of Tags association with this Workstream. */
    readonly systemTagAssociationList?: Maybe<SystemTagList>;
    /** The current type of this item */
    readonly typeColor?: Maybe<Scalars["String"]["output"]>;
    /** The current type of this item */
    readonly typeName?: Maybe<Scalars["String"]["output"]>;
    /** The current type of this item */
    readonly typeSystemEnumerationId?: Maybe<Scalars["String"]["output"]>;
    /** Theweek number that the workstream is planned for. */
    readonly week?: Maybe<Scalars["Int"]["output"]>;
};

export type WorkstreamAssociation = {
    readonly __typename?: "WorkstreamAssociation";
    /** The color of the  assignedSecurityPrincipalUSer default avatar */
    readonly assignedSecurityPrincipalUserAvatarColor?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUSer that this item is assigned to */
    readonly assignedSecurityPrincipalUserId?: Maybe<Scalars["String"]["output"]>;
    /** The resolved name of the securityPrincipalUSer that this item is assigned to */
    readonly assignedSecurityPrincipalUserName?: Maybe<Scalars["String"]["output"]>;
    /** The date this item was created */
    readonly createdAt: Scalars["DateTime"]["output"];
    /** The color of the createdSecurityPrincipalUSer default avatar */
    readonly createdSecurityPrincipalUserAvatarColor?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUser that created this item */
    readonly createdSecurityPrincipalUserId?: Maybe<Scalars["String"]["output"]>;
    /** The resolved name of the securityPrincipalUSer that created this item */
    readonly createdSecurityPrincipalUserName?: Maybe<Scalars["String"]["output"]>;
    /** The description of the item in JSON format */
    readonly descriptionJson?: Maybe<Scalars["JSON"]["output"]>;
    /** The ID of the Workstream */
    readonly id: Scalars["String"]["output"];
    /** the name */
    readonly isComplete?: Maybe<Scalars["String"]["output"]>;
    /** Indicates that the item is on hold */
    readonly isOnHold?: Maybe<Scalars["Boolean"]["output"]>;
    /** The time at which the Workstream or any of its descendants were last created or updated */
    readonly lastEventAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The time at which the current viewer last viewed the Workstream details. */
    readonly lastSeenAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** the name */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** the name */
    readonly priorityColor?: Maybe<Scalars["String"]["output"]>;
    /** the name */
    readonly priorityName?: Maybe<Scalars["String"]["output"]>;
    /** the name */
    readonly priorityOrdinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** the name */
    readonly prioritySystemEnumerationId?: Maybe<Scalars["String"]["output"]>;
    /** The issue number of the Workstream */
    readonly projectIssue: Scalars["String"]["output"];
    /** The project of Workstream */
    readonly projectPrefix: Scalars["String"]["output"];
    /** The release note of the item in JSON format */
    readonly releaseNoteJson?: Maybe<Scalars["JSON"]["output"]>;
    /** The current state of this item */
    readonly stateColor?: Maybe<Scalars["String"]["output"]>;
    /** The current state of this item */
    readonly stateName?: Maybe<Scalars["String"]["output"]>;
    /** The current state of this item */
    readonly stateOrdinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** The current state of this item */
    readonly stateSystemEnumerationId?: Maybe<Scalars["String"]["output"]>;
    /** The estimate for this Workstream */
    readonly storyPointEstimate?: Maybe<Scalars["Int"]["output"]>;
    /** The ID of the type of the Association */
    readonly systemAssociationTypeId: Scalars["String"]["output"];
    /** The label of the type of the association accounting for the direction of the association */
    readonly systemAssociationTypeLabel: Scalars["String"]["output"];
    /** The current type of this item */
    readonly typeColor?: Maybe<Scalars["String"]["output"]>;
    /** The current type of this item */
    readonly typeName?: Maybe<Scalars["String"]["output"]>;
    /** The current type of this item */
    readonly typeSystemEnumerationId?: Maybe<Scalars["String"]["output"]>;
    /** Theweek number that the workstream is planned for. */
    readonly week?: Maybe<Scalars["Int"]["output"]>;
    readonly workstreamAssociationId: Scalars["String"]["output"];
};

export type WorkstreamAssociationList = {
    readonly __typename?: "WorkstreamAssociationList";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    readonly nodes: ReadonlyArray<WorkstreamAssociation>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type WorkstreamAssociationListFilter = {
    /** Filter by WorkstreamAssociation Id. */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** Match object */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** Filter by a workstreamId whether as a source or a target */
    readonly workstreamId?: InputMaybe<Scalars["String"]["input"]>;
};

export type WorkstreamAssociationListSort = {
    /** Sort by id */
    readonly id?: InputMaybe<SortDirection>;
};

export type WorkstreamListFilter = {
    readonly assignedTo?: InputMaybe<Scalars["String"]["input"]>;
    readonly assignedToMe?: InputMaybe<Scalars["Boolean"]["input"]>;
    readonly createdBy?: InputMaybe<Scalars["String"]["input"]>;
    readonly createdByMe?: InputMaybe<Scalars["Boolean"]["input"]>;
    readonly entity?: InputMaybe<Scalars["String"]["input"]>;
    /** Include items marked onHold */
    readonly includeOnHold?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Search string */
    readonly match?: InputMaybe<MatchFilterInput>;
    /** Filter by priority */
    readonly priorityMin?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by priority level */
    readonly prioritySystemEnumerationList?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by project prefix */
    readonly projectPrefix?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Include all workstreams that are related to the current workstream */
    readonly relatedWorkstreamId?: InputMaybe<Scalars["String"]["input"]>;
    /** Filter by state */
    readonly stateSystemEnumerationList?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by Tag */
    readonly systemTagList?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by type */
    readonly typeSystemEnumerationList?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
    /** Filter by week number */
    readonly week?: InputMaybe<Scalars["Int"]["input"]>;
};

/** This represents a Workstream */
export type WorkstreamListItem = {
    readonly __typename?: "WorkstreamListItem";
    /** The color of the  assignedSecurityPrincipalUSer default avatar */
    readonly assignedSecurityPrincipalUserAvatarColor?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUSer that this item is assigned to */
    readonly assignedSecurityPrincipalUserId?: Maybe<Scalars["String"]["output"]>;
    /** The resolved name of the securityPrincipalUSer that this item is assigned to */
    readonly assignedSecurityPrincipalUserName?: Maybe<Scalars["String"]["output"]>;
    readonly childStoryPointDoneCount?: Maybe<Scalars["Int"]["output"]>;
    readonly childStoryPointInProgressCount?: Maybe<Scalars["Int"]["output"]>;
    readonly childStoryPointReadyCount?: Maybe<Scalars["Int"]["output"]>;
    readonly childStoryPointToDoCount?: Maybe<Scalars["Int"]["output"]>;
    readonly childStoryPointTotalCount?: Maybe<Scalars["Int"]["output"]>;
    /** The resolved issuePrefix and issueNumber */
    readonly code?: Maybe<Scalars["String"]["output"]>;
    /** The date this item was created */
    readonly createdAt: Scalars["DateTime"]["output"];
    /** The color of the createdSecurityPrincipalUSer default avatar */
    readonly createdSecurityPrincipalUserAvatarColor?: Maybe<Scalars["String"]["output"]>;
    /** The securityPrincipalUser that created this item */
    readonly createdSecurityPrincipalUserId?: Maybe<Scalars["String"]["output"]>;
    /** The resolved name of the securityPrincipalUSer that created this item */
    readonly createdSecurityPrincipalUserName?: Maybe<Scalars["String"]["output"]>;
    /** The description of the item in JSON format */
    readonly descriptionJson?: Maybe<Scalars["JSON"]["output"]>;
    /** The URL to the confluence docmentation for this Workstream */
    readonly documentationUrl?: Maybe<Scalars["String"]["output"]>;
    /** the epic id */
    readonly epicId?: Maybe<Scalars["String"]["output"]>;
    readonly epicName?: Maybe<Scalars["String"]["output"]>;
    /** The unique ID */
    readonly id: Scalars["String"]["output"];
    /** the name */
    readonly isComplete?: Maybe<Scalars["String"]["output"]>;
    /** Indicates that the item is on hold */
    readonly isOnHold?: Maybe<Scalars["Boolean"]["output"]>;
    /** The time at which the Workstream or any of its descendants were last created or updated */
    readonly lastEventAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** The time at which the current viewer last viewed the Workstream details. */
    readonly lastSeenAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** the name */
    readonly name?: Maybe<Scalars["String"]["output"]>;
    /** The prefix for the preview deployments of Hub, Booking and API */
    readonly previewPrefix?: Maybe<Scalars["String"]["output"]>;
    /** the name */
    readonly priorityColor?: Maybe<Scalars["String"]["output"]>;
    /** the name */
    readonly priorityName?: Maybe<Scalars["String"]["output"]>;
    /** the name */
    readonly priorityOrdinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** the name */
    readonly prioritySystemEnumerationId?: Maybe<Scalars["String"]["output"]>;
    /** The count of related Workstream */
    readonly relatedCount?: Maybe<Scalars["Int"]["output"]>;
    /** The release note of the item in JSON format */
    readonly releaseNoteJson?: Maybe<Scalars["JSON"]["output"]>;
    /** The current state of this item */
    readonly stateColor?: Maybe<Scalars["String"]["output"]>;
    /** The current state of this item */
    readonly stateName?: Maybe<Scalars["String"]["output"]>;
    /** The current state of this item */
    readonly stateOrdinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** The current state of this item */
    readonly stateSystemEnumerationId?: Maybe<Scalars["String"]["output"]>;
    /** The estimate for this Workstream */
    readonly storyPointEstimate?: Maybe<Scalars["Int"]["output"]>;
    readonly storyPointProgress?: Maybe<Scalars["Int"]["output"]>;
    readonly storyPointReleased?: Maybe<Scalars["Int"]["output"]>;
    readonly storyPointRemaining?: Maybe<Scalars["Int"]["output"]>;
    readonly storyPointSpike?: Maybe<Scalars["Int"]["output"]>;
    /** The list of Tags association with this Workstream. */
    readonly systemTagAssociationList?: Maybe<SystemTagList>;
    /** The current type of this item */
    readonly typeColor?: Maybe<Scalars["String"]["output"]>;
    /** The current type of this item */
    readonly typeName?: Maybe<Scalars["String"]["output"]>;
    /** The current type of this item */
    readonly typeSystemEnumerationId?: Maybe<Scalars["String"]["output"]>;
    /** Theweek number that the workstream is planned for. */
    readonly week?: Maybe<Scalars["Int"]["output"]>;
};

/** This respresents the WorkstreamList response type */
export type WorkstreamListResponse = {
    readonly __typename?: "WorkstreamListResponse";
    /** Next page number if available. */
    readonly nextPage?: Maybe<Scalars["Int"]["output"]>;
    /** Workstream list */
    readonly nodes?: Maybe<ReadonlyArray<Maybe<WorkstreamListItem>>>;
    /** Total pages */
    readonly pageCount: Scalars["Int"]["output"];
    /**
     * Total pages
     * @deprecated DEPRECATED - use pageCount instead.
     */
    readonly pages: Scalars["Int"]["output"];
    /**
     * Previous page number if available.
     * @deprecated DEPRECATED - use previousPage instead.
     */
    readonly prevPage?: Maybe<Scalars["Int"]["output"]>;
    /** Previous page number if available. */
    readonly previousPage?: Maybe<Scalars["Int"]["output"]>;
    /** Total number of records matching query */
    readonly recordCount: Scalars["Int"]["output"];
    /** The total estimated story points for given filters. */
    readonly storyPointEstimate?: Maybe<Scalars["Int"]["output"]>;
    /** The total planned story points for given filters */
    readonly storyPointOutstanding?: Maybe<Scalars["Int"]["output"]>;
    /**
     * Total number of records matching query
     * @deprecated DEPRECATED - please use recordCount instead
     */
    readonly total: Scalars["Int"]["output"];
};

export type WorkstreamListSort = {
    /** Sort by name */
    readonly name?: InputMaybe<SortDirection>;
};

export type WorkstreamUpsertInput = {
    readonly assignedSecurityPrincipalUserId?: InputMaybe<Scalars["String"]["input"]>;
    readonly createdSecurityPrincipalUserId?: InputMaybe<Scalars["String"]["input"]>;
    /** The description of the item */
    readonly descriptionJson?: InputMaybe<Scalars["String"]["input"]>;
    /** The URL to the confluence documentation */
    readonly documentationUrl?: InputMaybe<Scalars["String"]["input"]>;
    readonly epicId?: InputMaybe<Scalars["String"]["input"]>;
    /** WorkstreamId */
    readonly id?: InputMaybe<Scalars["String"]["input"]>;
    /** Indicates that the item is on hold */
    readonly isOnHold?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** the name */
    readonly name?: InputMaybe<Scalars["String"]["input"]>;
    /** The prefix for all preview environments */
    readonly previewPrefix?: InputMaybe<Scalars["String"]["input"]>;
    /** The priority of the item */
    readonly prioritySystemEnumerationId?: InputMaybe<Scalars["String"]["input"]>;
    /** The release note of the item */
    readonly releaseNoteJson?: InputMaybe<Scalars["String"]["input"]>;
    /** The state of the item */
    readonly stateSystemEnumerationId?: InputMaybe<Scalars["String"]["input"]>;
    /** The storyPointEstimate for this Workstream */
    readonly storyPointEstimate?: InputMaybe<Scalars["Int"]["input"]>;
    /** The storyPointProgress for this Workstream */
    readonly storyPointProgress?: InputMaybe<Scalars["Int"]["input"]>;
    /** The storyPointReleased for this Workstream */
    readonly storyPointReleased?: InputMaybe<Scalars["Int"]["input"]>;
    /** The storyPointRemaining for this Workstream */
    readonly storyPointRemaining?: InputMaybe<Scalars["Int"]["input"]>;
    /** The storyPointSpike for this Workstream */
    readonly storyPointSpike?: InputMaybe<Scalars["Int"]["input"]>;
    /** Taxonomy association list. */
    readonly systemTagAssociationList?: InputMaybe<ReadonlyArray<InputMaybe<SystemTagAssociationUpsertInput>>>;
    /** The type of the item */
    readonly typeSystemEnumerationId?: InputMaybe<Scalars["String"]["input"]>;
    /** The week this Workstream is planned for */
    readonly week?: InputMaybe<Scalars["Int"]["input"]>;
};

/** This represents a article */
export type Article = {
    readonly __typename?: "article";
    /** date the article was created */
    readonly createdAt: Scalars["Date"]["output"];
    /** The description of the article. */
    readonly description?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the artcile */
    readonly id: Scalars["String"]["output"];
    /** The Name of the article */
    readonly name: Scalars["String"]["output"];
    /** The position of the article in the contect of the type */
    readonly ordinalPosition?: Maybe<Scalars["Int"]["output"]>;
    /** Article type */
    readonly type?: Maybe<Scalars["String"]["output"]>;
    /** date the article was last updated */
    readonly updatedAt?: Maybe<Scalars["Date"]["output"]>;
};

/** This represents a article */
export type ArticleDescriptionArgs = {
    contentType?: InputMaybe<DescriptionContentType>;
};

/** This represents a Records From SystemTemplate */
export type RecordsFromTemplate = {
    readonly __typename?: "recordsFromTemplate";
    /** The unique Holibob ID for the SystemTemplate */
    readonly id: Scalars["String"]["output"];
};

/** This represents a Taxonomy Term */
export type TaxonomyTerm = {
    readonly __typename?: "taxonomyTerm";
    /** The unique Holibob ID for the Taxonomy Term */
    readonly id: Scalars["String"]["output"];
    /** The list of match for the taxonomy term. */
    readonly matchList?: Maybe<TaxonomyTermMatchListResponse>;
    /** The type of the Taxonomy Term */
    readonly type: Scalars["String"]["output"];
};

/** This represents a Taxonomy Term Match */
export type TaxonomyTermMatch = {
    readonly __typename?: "taxonomyTermMatch";
    /** The dataType of the Taxonomy Term Match */
    readonly dataType?: Maybe<Scalars["String"]["output"]>;
    /** The unique Holibob ID for the Taxonomy Term Match */
    readonly id: Scalars["String"]["output"];
    /** The sourceLabel of the Taxonomy Term Match */
    readonly sourceLabel?: Maybe<Scalars["String"]["output"]>;
    /** The taxonomyTermId of the Taxonomy Term Match */
    readonly taxonomyTermId?: Maybe<Scalars["String"]["output"]>;
    /** The type of the Taxonomy Term Match */
    readonly type?: Maybe<Scalars["String"]["output"]>;
};

/** Response with the Workstream and EntityAssociation IDs and Entity's tagList associations. */
export type WorkstreamAssignNext = {
    readonly __typename?: "workstreamAssignNext";
    readonly entityId: Scalars["String"]["output"];
    readonly entitySynchronisationSource?: Maybe<Scalars["String"]["output"]>;
    readonly workstreamId: Scalars["String"]["output"];
};
